import React, { useState, useContext, useEffect } from 'react';
import { FieldData, PipelineStagesRowsFieldType } from 'types/FieldTypes';
import { createNewPipelineStage, PipelineStageType } from '../../../views/deal/DealTypes';
import { DragDropContext, Draggable } from 'react-beautiful-dnd';
import { StrictModeDroppable } from 'services/utils/dragDropUtils';
import { useTranslation } from 'react-i18next';
import { useGlobalContext } from 'GlobalContext';
import { v4 as uuidv4 } from 'uuid';
import FormFieldContext from '../FormFieldContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripLines, faTrash } from '@fortawesome/free-solid-svg-icons';
import '../../../style/scss/live-edit.scss';
import '../../../style/scss/forms.scss';

const PipelineStagesRowsField: React.FC<PipelineStagesRowsFieldType & { data: FieldData, viewKey: string }> = ({
    name, data
}) => {
    const { t } = useTranslation();
    const { errorMessages } = useGlobalContext();
    const { editing, setUpdatedData, showErrorAlert } = useContext(FormFieldContext);
    const [rows, setRows] = useState<PipelineStageType[]>([]);

    // If there are already stages, set the current rows
    useEffect(() => {
        if (data && data[name] && data[name].length > 0) {
            // Set the pipeline stages
            const sortedRows = (data[name] || []).sort(
                (a: PipelineStageType, b: PipelineStageType) => a.ordering - b.ordering
            ).map((row: PipelineStageType) => ({ ...row }));

            setRows(sortedRows);

        // If there are no stages, create an empty row
        } else {
            const firstPosition = 1
            const emptyRow = createNewPipelineStage(data['pipeline'], uuidv4(), firstPosition);
            setRows([emptyRow]);
        }
    }, [data]);

    // Handle the input change
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, fieldName: 'name' | 'probability', identifier: string) => {
        // Copy the rows and find the row index
        const updatedRows = [...rows];
        const rowIndex = updatedRows.findIndex(row => row.id?.toString() === identifier || row.uuid === identifier);

        if (rowIndex !== -1) {
            // Set the value for the fields
            if (fieldName === 'name') {
                updatedRows[rowIndex][fieldName] = event.target.value;
            }

            if (fieldName === 'probability') {
                updatedRows[rowIndex][fieldName] = parseInt(event.target.value);
            }
            
            // Update the rows and updated data
            setRows(updatedRows);
            setUpdatedData((prev: any) => ({ ...prev, [name]: updatedRows }))
        }        
    };

    // Handle deletion of a row
    const handleDeleteRow = (identifier: string) => {
        let updatedRows = [...rows];
        const rowIndex = updatedRows.findIndex(row => row.id?.toString() === identifier || row.uuid === identifier);

        if (rowIndex !== -1) {
            // Get the row from the updated rows
            const row = updatedRows[rowIndex]

            // Check if the row is a new row added in the frontend (it has an uuid but no id)
            if (!row.id && row.uuid) {
                // Directly remove it from the updated rows
                updatedRows = updatedRows.filter((_, index) => index !== rowIndex)
        
            } else {
                // Flag the backend known row as deleted without actually delete it
                updatedRows[rowIndex].deleted = true;
            }
    
            // Update the ordering of the items which are not marked as deleted
            const nonDeletedItems = updatedRows.filter(row => !row.deleted);
            const reorderedNonDeletedItems = nonDeletedItems.map((row, index) => ({
                ...row,
                ordering: index + 1,
            }));

            // Add the deleted items to the reordered list to keep them for the updated data
            const finalItems = [...reorderedNonDeletedItems, ...updatedRows.filter(row => row.deleted)];
    
            setRows(finalItems);
            setUpdatedData((currentUpdatedData: any) => ({ ...currentUpdatedData, [name]: finalItems }))
        } 
    }

    const handleAddRow = () => {
        // Split rows in non deleted and deleted rows
        const nonDeletedRows = rows.filter(row => !row.deleted);
        const deletedRows = rows.filter(row => row.deleted);

        // Determine the position of the new row and create it
        const newPosition = nonDeletedRows.length + 1;
        const newRow = createNewPipelineStage(data['pipeline'], uuidv4(), newPosition);
    
        // Add the new row at the end of the list with non deleted rows and add the deleted rows
        const updatedNonDeletedRows = [...nonDeletedRows, newRow];
        const updatedRows = [...updatedNonDeletedRows, ...deletedRows];
    
        // Update the rows and updated data
        setRows(updatedRows);
        setUpdatedData((currentUpdatedData: any) => ({ ...currentUpdatedData, [name]: updatedRows }))
    };

    // Handle ordering change
    const handleOrderingChange = (result: any) => {
        if (!result.destination) return;
        const { source, destination } = result;
        
        // Only use the non deleted rows to determine the new ordering
        const nonDeletedRows = rows.filter(row => !row.deleted);
        const [reorderedRow] = nonDeletedRows.splice(source.index, 1);
        nonDeletedRows.splice(destination.index, 0, reorderedRow);
    
        // Determine the ordering for the non deleted rows
        const updatedNonDeletedRows = nonDeletedRows.map((row, index) => ({
            ...row,
            ordering: index + 1,
        }));
    
        // Add the deleted rows to the list without changing their ordering
        const finalRows = [...updatedNonDeletedRows, ...rows.filter(row => row.deleted)];
    
        setRows(finalRows);
        setUpdatedData((currentUpdatedData: any) => ({ ...currentUpdatedData, [name]: finalRows }));
    };

    return (
        <div className='rows-field'>
            {showErrorAlert &&
                <div className="alert form-alert alert-danger" role="alert">
                    {t(errorMessages.general, { defaultValue: errorMessages.general })}
                </div>
            }
            {editing && (
                // Edit mode
                <div className='edit-mode'>
                    {rows.length > 0 && (
                        <div className='rows-header deal-pipeline-stages-row'>
                            <div className='header-item'>{t('deal.general.stage_name_label')}</div>
                            <div className='header-item'>{t('deal.general.probability_label')}</div>
                            <div className='delete-placeholder'></div>
                            <div className='drag-placeholder'></div>
                        </div>
                    )}
                    <DragDropContext onDragEnd={handleOrderingChange}>
                        <StrictModeDroppable droppableId="droppable">
                            {(provided) => (
                                <div {...provided.droppableProps} ref={provided.innerRef}>
                                    {rows && rows.filter(row => row.deleted === false).map((row, index) => {
                                        const identifier = row.id?.toString() || row.uuid || '';
                                        return (
                                            <Draggable key={identifier} draggableId={`row-${identifier}`} index={index} isDragDisabled={rows.length <= 1}>
                                                {(provided) => {
                                                    return (
                                                        <div className='list-item draggable' ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                            <div className='deal-pipeline-stages-row'>
                                                                <input 
                                                                    type="text"
                                                                    id={`name_${identifier}`}
                                                                    name={`name_${identifier}`}
                                                                    value={row.name ?? ''}
                                                                    onChange={event => handleInputChange(event, 'name', identifier)}
                                                                    placeholder={t('deal.general.stage_name_placeholder')}
                                                                    autoFocus={false}
                                                                    className={identifier && errorMessages?.deal_pipeline_stages?.[identifier]?.['name'] ? 'is-invalid' : undefined}
                                                                />
                                                                <div className='percentage-field'>
                                                                    <input 
                                                                        type="number"
                                                                        id={`probability_${identifier}`}
                                                                        name={`probability_${identifier}`}
                                                                        value={row.probability ?? ''}
                                                                        onChange={event => handleInputChange(event, 'probability', identifier)}
                                                                        placeholder='0'
                                                                        autoFocus={false}
                                                                        className={identifier && errorMessages?.deal_pipeline_stages?.[identifier]?.['probability'] ? 'is-invalid' : undefined}
                                                                    />
                                                                    <span className="percentage-symbol">%</span>
                                                                </div>
                                                                <div className='delete-icon tooltip-icon'>
                                                                    <FontAwesomeIcon 
                                                                        icon={faTrash} 
                                                                        onClick={() => handleDeleteRow(row.id?.toString() || row.uuid || '')} />
                                                                    <span className="tooltip">{t('general.delete')}</span>
                                                                </div>
                                                                <div className={`order-icon tooltip-icon ${rows.length === 1 ? 'visibility-hidden' : ''}`}>
                                                                    <FontAwesomeIcon icon={faGripLines} />
                                                                    <span className="tooltip">{t('general.reorder')}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }}
                                            </Draggable>
                                        )
                                    })}
                                    {provided.placeholder}
                                </div>
                            )}
                        </StrictModeDroppable>
                    </DragDropContext>
                    <div onClick={(e) => {e.preventDefault(); handleAddRow(); }} 
                        className="add-new-button">
                        {t('deal.general.add_new_stage')}
                    </div>
                </div>
            )}
        </div>
    );
}

export default PipelineStagesRowsField;