import React, { useState, useMemo, useRef, useEffect } from 'react';
import { FieldType, FieldOptionFetchResponse, FieldOption, EditableStatusType } from 'types/FieldTypes';
import FormFieldContext from './FormFieldContext';
import PrimaryButton from 'components/buttons/PrimaryButton';
import SecondaryButton from 'components/buttons/SecondaryButton';
import axios, { CancelTokenSource } from 'axios';
import { saveData } from 'services/api/saveData';
import { handleSaveErrors } from 'services/api/handleSaveErrors';
import { formValidation } from 'services/utils/formValidation';
import { shakeElement } from 'services/utils/shakeElement';
import { useGlobalContext } from 'GlobalContext';
import { useModal } from 'components/modals/ModalContext';
import { useSettings } from 'services/settings/SettingsContext';
import { useAllowedRight } from 'services/permissions/permissionChecks';
import TextField from './fields/TextField';
import TextareaField from './fields/TextareaField';
import NumberField from './fields/NumberField';
import PasswordField from './fields/PasswordField';
import EmailField from './fields/EmailField';
import DateField from './fields/DateField';
import TabsField from './fields/TabsField';
import DropdownField from './fields/DropdownField';
import MultiSelectField from './fields/MultiSelectField';
import CheckboxField from './fields/CheckboxField';
import { sanitizeTextareaFromDataObject } from 'services/utils/convertTextareaData';
import ImageField from './fields/ImageField';
import EmailAddressesFieldset from './fieldsets/EmailAddressesFieldset';
import PhoneNumbersFieldset from './fieldsets/PhoneNumbersFieldset';
import LocationsFieldset from './fieldsets/LocationsFieldset';
import AttachmentsFieldset from './fieldsets/AttachmentsFieldset';
import JobItemsFieldset from './fieldsets/JobItemsFieldset';
import TimeRowsFieldset from './fieldsets/TimeRowsFieldset';
import SubJobsFieldset from './fieldsets/SubJobsFieldset';
import SwitchField from './fields/SwitchField';
import SearchSelectField from './fields/SearchSelectField';
import BudgetMultiField from './mutli-fields/BudgetMultiField';
import PriceCurrencyMultiField from './mutli-fields/PriceCurrencyMultiField';
import ReferenceMultiField from './mutli-fields/ReferenceMultiField';
import TargetDateMultiField from './mutli-fields/TargetDateFieldset';
import AppointmentsRowsField from './fieldsets/AppointmentsRowsField';
import DocumentLinesRowsField from './fieldsets/DocumentLinesRowsField';
import ExpirationDaysField from './fields/ExpirationDaysField';
import SentDate from './fields/SentDate';
import WarningModal from 'components/modals/WarningModal';
import PriceField from './fields/PriceField';
import PercentageField from './fields/PercentageField';

interface LiveEditFieldProps {
    viewKey: string;
    apiObject?: string;
    fieldItem: FieldType;
    itemId: string | number;
    params?: any;
    data: any;
    dropdownData?: Record<string, FieldOptionFetchResponse>;
    groupOptions?: FieldOption[];
    alignment?: 'horizontal' | 'stacked';
    isEditable?: EditableStatusType;
    refetchData: () => void;
}

/*
 * LiveEditField.tsx
 * Handles the main logic of a live edit field. It generates the right underlaying field
 * components, it handles the edit or view mode, click outside to close (or shake) the
 * field, ESC-close, submitting of changes to the server and error handling.
 */

const LiveEditField: React.FC<LiveEditFieldProps> = ({ 
    viewKey, apiObject, fieldItem, itemId, data, dropdownData, alignment = 'stacked', 
    isEditable = { editability: true }, refetchData
 }) => {
    const { setErrorMessages, setFloatingAlert, unsavedChangesMap, setUnsavedChanges, preventClosingMap } = useGlobalContext();
    const { initializeModal } = useModal();
    const { getTimeFormat } = useSettings();
    const hasRightCheck = useAllowedRight;
    const [userHasOnlyViewRights] = useState<boolean>(hasRightCheck('only_view'));
    const [editing, setEditing] = useState(false);
    const [updatedData, setUpdatedData] = useState<Record<string, any>>({});
    const [handleFieldSubmit, setHandleFieldSubmit] = useState(false);
    const [cancelSource, setCancelSource] = useState<CancelTokenSource | null>(null);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const hasUnsavedChanges = unsavedChangesMap[viewKey];
    const hasPreventClose = preventClosingMap[viewKey];
    const liveEditRef = useRef<HTMLFormElement>(null);
    let hasErrors = false;

    // When editing, set unsaved changes to true for fieldsets, to prevent closing when clicking outside the field or modal
    useEffect(() => {
        if (editing) {
            if (['timerows', 'job-items', 'document-lines', 'appointments', 'attachments', 'textarea'].includes(fieldItem.type)) {
                setUnsavedChanges(viewKey, true);
            }
        };
    }, [editing])

    // Handles the toggle to the edit mode
    const toggleEditMode = async () => {

        // Only continue for users which are not restricted to only viewing
        if (!userHasOnlyViewRights) {

            // If a warning is set for the is editable state, let the user approve before editing
            if (isEditable.warning) {

                // Get the fields to show in the warning modal
                const { header, message, buttonLabel } = isEditable.warning;

                const editAnyway = await new Promise<boolean>((resolve) => {
                    initializeModal(
                        <WarningModal
                            header={header}
                            message={message}
                            buttonLabel={buttonLabel}
                            onApproval={() => resolve(true) } 
                        />, { modalSize: 'extra-small' }
                    );
                });
                
                if (editAnyway) {
                    setEditing(true);
                }
            }

            // Only set to edit mode when the editability of the field is set to true
            if (isEditable.editability) {
                setEditing(true);
            }
        }
    };

    // Handles mouse click outside to close the field
    const handleClickOutside = (event: MouseEvent) => {
        const target = event.target as Node;
        if (liveEditRef.current && !liveEditRef.current.contains(target)) {
            
            // Prevent closing the timerows and job-items fieldsets when clicking outside the field
            if (['timerows', 'job-items', 'document-lines', 'appointments', 'attachments'].includes(fieldItem.type)) {
                return;

            // If save on blur is set, always submit & close
            } else if ('saveOnBlur' in fieldItem && fieldItem.saveOnBlur && hasUnsavedChanges) {
                handleSubmit();

            // If the prevent close flag is set for this field, do nothing
            } else if (hasPreventClose) {
                return;

            // If normal save is set and the field has unsaved changes or errors, shake the field
            } else if (hasUnsavedChanges || hasErrors) {
                shakeElement(liveEditRef.current as HTMLElement)

            // If the field doesn't have unsaved changes, close the field
            } else {
                handleClose();
            }
        }
    };

    // Handles escape key press event to close the field
    const handleEscape = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
            handleClose();
        }
    };

    // Adds event listeners for keydown and mousedown events
    useEffect(() => {
        document.addEventListener('keydown', handleEscape);
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('keydown', handleEscape);
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [hasUnsavedChanges, preventClosingMap, editing]);

    // Handle submit
    const handleSubmit = async () => {
        // Exits from this function if the field item itself handles the submit
        if (fieldItem.type === 'locations' || fieldItem.type === 'emailaddresses' || fieldItem.type === 'phonenumbers') {
            setHandleFieldSubmit(true);
            return;
        }

        // Perform form validation on submit
        const is12HourFormat = fieldItem.type === 'time' && getTimeFormat() === '12h';
        const validationError = formValidation(fieldItem, updatedData, is12HourFormat);

        if (validationError) {
            // Set the error messages to show in the field
            setErrorMessages(validationError);

            // Shake te element
            shakeElement(liveEditRef.current as HTMLElement)

            return;
        }

        // If there are textarea fields, convert and sanitize the text
        const dataToSanitize = { ...updatedData };
        const sanitizedData = sanitizeTextareaFromDataObject(dataToSanitize, undefined, fieldItem);

        try {
            setButtonLoader(true);

            // Create the cancel token source
            const source = axios.CancelToken.source();
            setCancelSource(source);

            if (apiObject) {
                const submitData = { ...sanitizedData }
                await saveData({ apiObject, method: 'patch', itemId, data: submitData, source });

                // Refetch data after saving
                await refetchData();
            }
            setErrorMessages({});
            setUpdatedData({});
            setFloatingAlert({ 'type': 'success' })
            setUnsavedChanges(viewKey, false);
            setEditing(false);
        } catch (error) {
            // Handle the errors using the handleSaveErrors function
            const fieldNames = ('name' in fieldItem && fieldItem.name ? [fieldItem.name] : []) 
            const errorData = handleSaveErrors(error, fieldNames); 
            setErrorMessages(errorData);

            // Set hasErrors to true to prevent click outside closing
            hasErrors = true;

            // If the errorState has a general error, show the error alert
            if (errorData.general) 
                setShowErrorAlert(true);

            // Shake the field in any case of an error
            shakeElement(liveEditRef.current as HTMLElement);
        } finally {
            setButtonLoader(false);
        }
    }

    // Handles close and reset states
    const handleClose = () => {
        if (cancelSource) {
            cancelSource.cancel();
        }
        setEditing(false);
        setUnsavedChanges(viewKey, false)
        setShowErrorAlert(false);
        setErrorMessages({});
        hasErrors = false;
        setButtonLoader(false);
    }

    // Generates the field components
    const fieldComponent = useMemo(() => {
        switch(fieldItem.type) {
            case 'text':
                return <TextField {...fieldItem} viewKey={viewKey} data={data} alignment={alignment} isEditable={isEditable} shouldAutoFocus={true} />;
            case 'textarea':
                return <TextareaField {...fieldItem} viewKey={viewKey} data={data} alignment={alignment} isEditable={isEditable} shouldAutoFocus={true} />;
            case 'number':
                return <NumberField {...fieldItem} viewKey={viewKey} data={data} alignment={alignment} isEditable={isEditable} shouldAutoFocus={true} />;
            case 'percentage':
                return <PercentageField {...fieldItem} viewKey={viewKey} data={data} alignment={alignment} isEditable={isEditable} shouldAutoFocus={true} />;  
            case 'date':
                return <DateField {...fieldItem} viewKey={viewKey} data={data} alignment={alignment} isEditable={isEditable} shouldAutoFocus={true} />;
            case 'sent-date':
                return <SentDate {...fieldItem} viewKey={viewKey} data={data} alignment={alignment} isEditable={isEditable} shouldAutoFocus={true} />;
            case 'dropdown':
                return <DropdownField {...fieldItem} viewKey={viewKey} data={data} alignment={alignment} isEditable={isEditable} dropdownData={dropdownData} />;
            case 'multiselect':
                return <MultiSelectField {...fieldItem} viewKey={viewKey} data={data} alignment={alignment} isEditable={isEditable} dropdownData={dropdownData} />;
            case 'searchselect':
                return <SearchSelectField {...fieldItem} viewKey={viewKey} data={data} alignment={alignment} isEditable={isEditable} refetchData={() => {refetchData()}} />
            case 'tabs':
                return <TabsField {...fieldItem} viewKey={viewKey} data={data} alignment={alignment} isEditable={isEditable} dropdownData={dropdownData} />;
            case 'switch':
                return <SwitchField {...fieldItem} viewKey={viewKey} data={data} alignment={alignment} isEditable={isEditable} />
            case 'checkbox':
                return <CheckboxField {...fieldItem} viewKey={viewKey} data={data} alignment={alignment} isEditable={isEditable} />;
            case 'email':
                return <EmailField {...fieldItem} viewKey={viewKey} data={data} alignment={alignment} isEditable={isEditable} shouldAutoFocus={true} />;
            case 'password':
                return <PasswordField {...fieldItem} viewKey={viewKey} data={data} alignment={alignment} isEditable={isEditable} shouldAutoFocus={true} />;
            case 'image':
                return <ImageField {...fieldItem} viewKey={viewKey} data={data} alignment={alignment} isEditable={isEditable} />;
            case 'phonenumbers':
                return <PhoneNumbersFieldset {...fieldItem} viewKey={viewKey} data={data} alignment={alignment} isEditable={isEditable} shouldAutoFocus={true} object={apiObject} handleFieldSubmit={handleFieldSubmit} refetchData={refetchData} fieldSubmitted={() => setHandleFieldSubmit(false)} />;
            case 'emailaddresses':
                return <EmailAddressesFieldset {...fieldItem} viewKey={viewKey} data={data} alignment={alignment} isEditable={isEditable} shouldAutoFocus={true} object={apiObject} handleFieldSubmit={handleFieldSubmit} refetchData={refetchData} fieldSubmitted={() => setHandleFieldSubmit(false)} />;
            case 'expiration-days':
                return <ExpirationDaysField {...fieldItem} viewKey={viewKey} data={data} alignment={alignment} isEditable={isEditable} shouldAutoFocus={true} />;
            case 'price':
                return <PriceField {...fieldItem} viewKey={viewKey} data={data} alignment={alignment} dropdownData={dropdownData} isEditable={isEditable} shouldAutoFocus={true} />;
            case 'price-currency':
                return <PriceCurrencyMultiField {...fieldItem} viewKey={viewKey} data={data} alignment={alignment} dropdownData={dropdownData} isEditable={isEditable} />;
            case 'locations':
                return <LocationsFieldset {...fieldItem} viewKey={viewKey} data={data} alignment={alignment} isEditable={isEditable} shouldAutoFocus={true} object={apiObject} objectId={itemId} handleFieldSubmit={handleFieldSubmit} refetchData={refetchData} fieldSubmitted={() => setHandleFieldSubmit(false)} />;
            case 'attachments':
                return <AttachmentsFieldset {...fieldItem} viewKey={viewKey} data={data} dropdownData={dropdownData} isEditable={isEditable} alignment={alignment} jobId={parseInt(itemId as string)} toggleEditMode={toggleEditMode} />;
            case 'reference':
                return <ReferenceMultiField {...fieldItem} viewKey={viewKey} data={data} alignment={alignment} isEditable={isEditable} />;
            case 'target-date':
                return <TargetDateMultiField {...fieldItem} viewKey={viewKey} data={data} alignment={alignment} isEditable={isEditable} />;
            case 'document-lines':
                return <DocumentLinesRowsField {...fieldItem} data={data} dropdownData={dropdownData} isEditable={isEditable} documentId={parseInt(itemId as string)} />;
            case 'job-items':
                return <JobItemsFieldset {...fieldItem} data={data} dropdownData={dropdownData} isEditable={isEditable} jobId={parseInt(itemId as string)} />;
            case 'subjobs':
                return <SubJobsFieldset {...fieldItem} viewKey={viewKey} data={data} isEditable={isEditable} jobId={parseInt(itemId as string)} />;
            case 'timerows':
                return <TimeRowsFieldset {...fieldItem} data={data} dropdownData={dropdownData} isEditable={isEditable} jobId={parseInt(itemId as string)} />;
            case 'appointments':
                return <AppointmentsRowsField {...fieldItem} data={data} dropdownData={dropdownData} isEditable={isEditable} jobId={parseInt(itemId as string)} />;
            case 'budget':
                return <BudgetMultiField {...fieldItem} viewKey={viewKey} data={data} dropdownData={dropdownData} isEditable={isEditable} alignment={alignment} />;
            default:
                return null;
        }
    }, [fieldItem, data, handleFieldSubmit])

    const formsetClassName = 'name' in fieldItem ? `formset ${fieldItem.name}` : 'formset';

    return (
        <FormFieldContext.Provider value={{ editing, setEditing, updatedData, setUpdatedData, buttonLoader, 
            setButtonLoader, showErrorAlert, setShowErrorAlert, handleSubmit }}>
            <div className="live-edit-field">
                {editing || ('viewInEditMode' in fieldItem ? fieldItem.viewInEditMode === true : false) ?  (
                    // Edit mode
                        <form className={formsetClassName} ref={liveEditRef}>
                            <div>
                                {fieldComponent}
                            </div>
                            {('saveOnBlur' in fieldItem ? fieldItem.saveOnBlur === false : true) && (
                                <div className='button-row'>
                                    <div className='buttons-right'>
                                        <SecondaryButton 
                                            onClick={() => handleClose()} 
                                            label="general.cancel"
                                            size="extra-small"/>
                                        <PrimaryButton 
                                            onClick={() => handleSubmit()}  
                                            label="general.save"
                                            size="extra-small"
                                            loading={buttonLoader}/>
                                    </div>
                                </div>
                            )}
                        </form>
                ) : (
                    // View mode
                    <div onClick={toggleEditMode}>
                        {fieldComponent}
                    </div>
                )}
            </div>
        </FormFieldContext.Provider>
    );
};

function areEqual(prevProps: LiveEditFieldProps, nextProps: LiveEditFieldProps) {
    return prevProps.apiObject === nextProps.apiObject && 
    prevProps.viewKey === nextProps.viewKey &&
    prevProps.fieldItem === nextProps.fieldItem &&
    prevProps.itemId === nextProps.itemId &&
    prevProps.data === nextProps.data;
}

export default React.memo(LiveEditField, areEqual);