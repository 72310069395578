import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import { useAuthContext } from 'services/authentication/AuthenticationContext';
import PrivateRoute from 'services/routes/PrivateRoute';
import PublicRoute from 'services/routes/PublicRoute';
import ProductList from './views/products/ProductList';
import ProductDetail from './views/products/ProductDetail';
import ServiceList from './views/products/ServiceList';
import ProductCategoryList from './views/products/ProductCategoryList';
import SettingsMenu from './components/settings/SettingsMenu';
import LoginPage from './views/account/LoginPage';
import TwoFactorPage from './views/account/TwoFactorPage';
import ForgotPasswordPage from './views/account/ForgotPasswordPage';
import CreateTrialPage from './views/account/CreateTrialPage';
import SettingsPage from './components/settings/SettingsPage';
import OrganizationDetail from './views/contacts/OrganizationDetail';
import PersonDetail from './views/contacts/PersonDetail';
import LocationList from './views/contacts/LocationList';
import LocationDetail from './views/contacts/LocationDetail';
import ObjectList from './views/contacts/ObjectList';
import PageAlert from 'components/alerts/PageAlert';
import PageNotFound from 'components/alerts/PageNotFound';
import JobList from './views/jobs/JobList';
import PersonalPreferencesPage from './views/settings/PersonalPreferencesPage';
import Dashboard from './views/dashboard/Dashboard';
import DraggableScheduler from './views/scheduling/DraggableScheduler';
import PdfLayoutPage from './views/settings/pdf/PdfLayoutPage';
import CompanyDetailsPage from './views/settings/CompanyDetailsPage';
import ContactList from './views/contacts/ContactList';
import HourlyRateList from './views/products/HourlyRateList';
import ReleaseList from './views/dashboard/releases/ReleaseList';
import BetaHomePage from './views/dashboard/BetaHomePage';
import TrialWizard from './views/dashboard/TrialWizard';
import TrialHomePage from './views/dashboard/TrialHomePage';
import InvoiceList from './views/invoices/InvoiceList';
import ContactDetail from './views/contacts/ContactDetail';
import ContactImport from './views/contacts/ContactImport';
import AttachmentImport from './views/attachment/AttachmentImport';
import ProposalList from './views/proposals/ProposalList';
import OrderList from './views/order/OrderList';
import DealDetail from './views/deal/DealDetail';
import DealPage from './views/deal/DealPage';
import ImportAction from 'components/import/ImportAction';

/*
 * Routes 
 * The purpose of the Routes file is to be the place to name links and 
 * paths to other pages, and store them in one place.
 * 
 * Use the PrivateRoute component for links that should only be opened
 * by logged in users with the right permissions.
 * 
 * Use the PublicRoute component for links that should only be opened
 * by users which are not logged in. For example, a login form or 
 * trial creation form.
 * 
 * The PrivateRoute and PublicRoute components are a proprietary 
 * function that extends the original Routes component.
 * 
 * Use the Route component for links that may be opened by users
 * which are, or which are not logged in.
 * 
 * Use 'exact' for main directories which contains other paths
 * below it. For example for '/products' where also is '/products/1201010/'
 * below. Otherwise the below paths doesn't render.
 */

const Routes: React.FC = () => {
    const { isLoggedIn, redirectTo } = useAuthContext();

    return (
        <Switch>
            {/* Redirect '/' to dashboard or login page */}
            <Route path="/" exact>
                {isLoggedIn === true && redirectTo 
                    ? <Redirect to={`/${redirectTo}`} /> 
                    : <Redirect to="/login" />
                }
            </Route>
            
            {/* Public routes for login or account creation pages */}
            <PublicRoute path="/login" exact component={LoginPage} />
            <PublicRoute path="/login/two-factor" component={TwoFactorPage} />
            <PublicRoute path="/password-forgotten" component={ForgotPasswordPage} />
            <PublicRoute path="/create-account" component={CreateTrialPage} />

            {/* Home */}
            <PrivateRoute path="/dashboard" component={Dashboard} />
            <PrivateRoute path="/trial-wizard" component={TrialWizard} />
            <PrivateRoute path="/trial-homepage" component={TrialHomePage} />
            <PrivateRoute path="/beta-homepage" component={BetaHomePage} />
            <PrivateRoute path="/releases" component={ReleaseList} />
            <PrivateRoute path="/tasks" component={undefined} />

            {/* Sales */}
            <PrivateRoute path="/proposals" activeFeature='proposals' component={ProposalList} />
            <PrivateRoute path="/orders" component={OrderList} />
            <PrivateRoute path="/deals" component={DealPage} exact />
            <PrivateRoute path="/deals/:id" component={DealDetail} />

            {/* Jobs */}
            <PrivateRoute path="/scheduling" component={DraggableScheduler} />
            <PrivateRoute path="/jobs" component={JobList} />
            <PrivateRoute path="/contracts" component={undefined} />

            {/* Invoices */}
            <PrivateRoute path="/invoices" component={InvoiceList} />

            {/* Team */}
            <PrivateRoute path="/timesheet" component={undefined} />
            <PrivateRoute path="/employees" component={undefined} />
            <PrivateRoute path="/subcontractors" component={undefined} />
            <PrivateRoute path="/leave-requests" component={undefined} />
            <PrivateRoute path="/expenses" component={undefined} />

            {/* Products */}
            <PrivateRoute path="/products/" activeFeature='products' component={ProductList} exact />
            <PrivateRoute path="/products/:id" activeFeature='products' component={ProductDetail} />
            <PrivateRoute path="/hourly-rates/" activeFeature='products' component={HourlyRateList} exact />
            <PrivateRoute path="/services" activeFeature='products' component={ServiceList} exact />
            <PrivateRoute path="/inventory" component={undefined} />
            <PrivateRoute path="/stockrooms" component={undefined} />
            <PrivateRoute path="/purchases" component={undefined} />
            <PrivateRoute path="/product_categories" activeFeature='product_categories' component={ProductCategoryList} />

            {/* Contacts */}
            <PrivateRoute path="/contacts/" activeFeature='contacts' component={ContactList} exact />
            <PrivateRoute path="/contacts/contact/:id" activeFeature='contacts' component={ContactDetail} />
            <PrivateRoute path="/contacts/organization/:id" activeFeature='contacts' component={OrganizationDetail} />
            <PrivateRoute path="/contacts/person/:id" activeFeature='contacts' component={PersonDetail} />
            <PrivateRoute path="/contacts/work-locations/" activeFeature='contacts' component={LocationList} exact />
            <PrivateRoute path="/contacts/work-locations/:id" activeFeature='contacts' component={LocationDetail} />
            <PrivateRoute path="/contacts/objects/" activeFeature='location_objects' component={ObjectList} exact />

            {/* Import data */}
            <PrivateRoute path="/import-data/contact/" component={ContactImport} />
            <PrivateRoute path="/import-data/attachment/" component={AttachmentImport} />
            <PrivateRoute path="/correct-contact-names/" component={ImportAction} />

            {/* Redirect /settings to /company-details */}
            <PrivateRoute path="/settings" exact permissionCheck='change_settings'>
                <Redirect to="/settings/general/company-details" />
            </PrivateRoute>

            {/* Full page setting pages such as pdf layout or workflow customizer */}
            <PrivateRoute path="/settings/templates/layout/pdf-layout/:id" permissionCheck='change_settings' component={PdfLayoutPage} />

            {/* Settings pages next to the settings submenu */}
            <PrivateRoute path="/settings" permissionCheck='change_settings'>
                <SettingsMenu>
                    <Switch>
                        <PrivateRoute path="/settings/admin/environments" permissionCheck='no_user' component={SettingsPage} />
                        <PrivateRoute path="/settings/general/company-details" component={SettingsPage} />
                        <PrivateRoute path="/settings/general/divisions" activeFeature='divisions' component={SettingsPage} />                    
                        <PrivateRoute path="/settings/general/users" component={SettingsPage} />
                        <PrivateRoute path="/settings/general/security" activeFeature='enhanched_security' component={SettingsPage} />
                        <PrivateRoute path="/settings/features/scheduling" component={SettingsPage} />
                        <PrivateRoute path="/settings/features/jobs" component={SettingsPage} />
                        <PrivateRoute path="/settings/features/timesheets" component={SettingsPage} />
                        <PrivateRoute path="/settings/features/mobile-app" component={SettingsPage} />
                        <PrivateRoute path="/settings/features/deals" component={SettingsPage} />
                        <PrivateRoute path="/settings/features/proposals" component={SettingsPage} />
                        <PrivateRoute path="/settings/features/orders" component={SettingsPage} />
                        <PrivateRoute path="/settings/features/invoices" component={SettingsPage} />
                        <PrivateRoute path="/settings/features/email" component={SettingsPage} />
                        <PrivateRoute path="/settings/features/contacts" component={SettingsPage} />
                        <PrivateRoute path="/settings/features/reporting" activeFeature='reporting' component={SettingsPage} />
                        <PrivateRoute path="/settings/features/additional-modules" component={SettingsPage} />
                        <PrivateRoute path="/settings/custom-fields/labels" component={SettingsPage} />
                        <PrivateRoute path="/settings/custom-fields/categories" component={SettingsPage} />
                        <PrivateRoute path="/settings/custom-fields/workflows" component={SettingsPage} />
                        <PrivateRoute path="/settings/custom-fields/data-imports" component={SettingsPage} />
                        <PrivateRoute path="/settings/templates/forms" component={SettingsPage} />
                        <PrivateRoute path="/settings/templates/email-sms-templates" component={SettingsPage} />
                        <PrivateRoute path="/settings/templates/layouts" component={SettingsPage} />
                        <PrivateRoute path="/settings/integrations/calendar" component={SettingsPage} />
                        <PrivateRoute path="/settings/integrations/email-integration" component={SettingsPage} />
                        <PrivateRoute path="/settings/integrations/marketplace-app-store" component={SettingsPage} />
                        <PrivateRoute path="/settings/integrations/api-keys" component={SettingsPage} />
                        <PrivateRoute path="/settings/billing/plan" component={SettingsPage} />
                        <PrivateRoute path="/settings/billing/payments" component={SettingsPage} />
                    </Switch>
                </SettingsMenu>
            </PrivateRoute>

            {/* Personal preferences */}
            <PrivateRoute path="/preferences" component={PersonalPreferencesPage} />

            {/* Redirect to access denied page */}
            <PrivateRoute path="/access-denied">
                <PageAlert 
                    title='alert.access_denied_label' 
                    type='danger'
                    message='alert.access_denied_message' 
                />
            </PrivateRoute>

            {/* Redirect not existing pages to the login page or show 404 page alert */}
            <Route path="*"><PageNotFound /></Route>
        </Switch>
    );
};

export default Routes;