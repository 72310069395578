import React, { useState, useContext, useEffect } from 'react';
import { DocumentLinesRowsFieldType, FieldData } from 'types/FieldTypes';
import { useTranslation } from 'react-i18next';
import { useGlobalContext } from 'GlobalContext';
import { useSettings } from 'services/settings/SettingsContext';
import { useAllowedRight } from 'services/permissions/permissionChecks';
import FormFieldContext from '../FormFieldContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripLines, faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import { DocumentLineType, createNewDocumentLine } from '../../../views/invoices/InvoiceTypes';
import Dropdown from '../basefields/Dropdown';
import { DragDropContext, Draggable } from 'react-beautiful-dnd';
import { StrictModeDroppable } from 'services/utils/dragDropUtils';
import { parseQuantityAndUnit } from 'services/utils/parseQuantityAndUnit';
import { v4 as uuidv4 } from 'uuid';
import { LedgerAccountType, VatRateType } from '../../../views/settings/SettingsTypes';
import { CurrencyType } from '../../../views/products/ProductTypes';
import '../../../style/scss/live-edit.scss';
import '../../../style/scss/forms.scss';
import { formatPriceCurrencyString, formatPriceString } from 'services/utils/amountFormatting';
import { renderRowsFieldErrorMessages } from './functions/renderRowsFieldErrorMessages';
import TextareaInput from '../basefields/TextareaInput';
import TabsButton from 'components/buttons/TabsButton';

const DocumentLinesRowsField: React.FC<DocumentLinesRowsFieldType & { data: FieldData }> = ({
    name, featureName, documentId, data, dropdownData, helperText, isEditable
}) => {
    const { t } = useTranslation();
    const { editing, setUpdatedData, showErrorAlert } = useContext(FormFieldContext);
    const { errorMessages } = useGlobalContext();
    const { userLocale, defaultCurrency, defaultCurrencySymbol } = useSettings();
    const hasRightCheck = useAllowedRight;
    const [rows, setRows] = useState<DocumentLineType[]>([]);
    const [selectedCurrency, setSelectedCurrency] = useState<string>('eur');
    const [vatRates, setVatRates] = useState<VatRateType[]>([]);
    const [ledgerAccounts, setLedgerAccounts] = useState<LedgerAccountType[]>([]);
    const [currencies, setCurrencies] = useState<CurrencyType[]>([]);
    const [userHasOnlyViewRights] = useState<boolean>(hasRightCheck('only_view'));
    const [vatCalculationMethod, setVatCalculationMethod] = useState<'vat_included' | 'vat_excluded'>('vat_excluded');
    const priceDisplayModes = [
        { name: 'document_lines.price_display_mode.line_prices', value: 'line_prices'}, 
        { name: 'document_lines.price_display_mode.total_price', value: 'total_price'}
    ];
    const [priceDisplayMode, setPriceDisplayMode] = useState<{ name: string, value: string }>({ name: 'document_lines.price_display_mode.line_prices', value: 'line_prices'});
    const [showVatReverseCharge, setShowVatReverseCharge] = useState(false);
    const [vatReverseChargeNumber, setVatReverseChargeNumber] = useState<string | null>(null);

    // If there are document lines in the fetched data, show them. Otherwise show an empty row
    useEffect(() => {
        if (data[name]) {
            // Get the currency from the data or fallback to the default currency
            const currencyKey = data['currency'] ?? defaultCurrency ?? 'EUR';

            // Check if the recipient has vat reverse charge
            const recipientHasVatReverseCharge = data['recipient']?.['vat_reverse_charge'] === true;

            // Find the first vat rate with the vat reverse charge property
            const vatReverseChargeRate = dropdownData?.vatrate?.results?.find(
                (vatRate: VatRateType) => vatRate.vat_reverse_charge === true
            );

            // Find the default vat rate
            const defaultVatRate = dropdownData?.vatrate?.results?.find(
                (vatRate: VatRateType) => vatRate.is_default === true
            );

            // Set the display mode from the data
            const currentDisplayMode = priceDisplayModes.find(displayMode => data['price_display_mode'] === displayMode.value);
            if (currentDisplayMode) {
                setPriceDisplayMode(currentDisplayMode);
            }

            // Set the vat calculation method from the data
            setVatCalculationMethod(data['vat_method']);

            // Set the fetched rows from the data
            if (data[name].length > 0) {
                const sortedRows = (data[name] || []).sort((a: any, b: any) => a.position - b.position).map((row: any) => ({
                    ...row,
                    quantity_string: formatQuantityUnit(row.quantity, userLocale, row.unit),
                    sale_price_string: formatPriceForDisplay(row.sale_price, userLocale),
                    sale_price_currency: currencyKey,
                }));

                // Check if any row has a vat rate with vat reverse charge
                const hasVatReverseCharge = sortedRows.some((row: DocumentLineType) => {
                    const vatRate = dropdownData?.vatrate?.results?.find(
                        (rate: VatRateType) => rate.id === row.vat_rate
                    );
                    return vatRate?.vat_reverse_charge === true;
                });
    
                // Set the rows
                setRows(sortedRows)

                // Show the vat reverse charge and vat number
                setShowVatReverseCharge(recipientHasVatReverseCharge && hasVatReverseCharge);
                setVatReverseChargeNumber(data['recipient']?.['vat_number']);

            // If no rows are fetched, show an empty row
            } else {
                // Create a new empty row
                const newRow = createNewDocumentLine(documentId!, 'invoice', uuidv4(), currencyKey, 1);

                // If recipient has vat reverse charge, update the vat rate in the empty row
                if (recipientHasVatReverseCharge && vatReverseChargeRate) {
                    newRow.vat_rate = vatReverseChargeRate.id;
                } 
                
                // Otherwise if a default vat rate is set, set this as the vat rate
                else if (defaultVatRate) {
                    newRow.vat_rate = defaultVatRate.id;
                }

                // Show the vat reverse charge and vat number
                setShowVatReverseCharge(recipientHasVatReverseCharge);
                setVatReverseChargeNumber(data['recipient']?.['vat_number']);

                // Set the new row
                setRows([newRow]);
            }
        }
    }, [data, documentId, dropdownData]);

    // Set the vat rates from the dropdown data
    useEffect(() => {
        setVatRates(dropdownData?.vatrate?.results || []);
        setLedgerAccounts(dropdownData?.ledgeraccount?.results || []);
        setCurrencies(dropdownData?.currency?.results || []);
    }, [dropdownData])

    // Set the selected currency
    useEffect(() => {
        if (data && dropdownData) {
            // Base the currency on the data, or fallback
            const currencyKey = data['currency'] ?? defaultCurrency ?? 'EUR'

            const selectedCurrencyOption = currencies.find(currency => currency.key == currencyKey);

            // When an option is found, set the selected currency
            if (selectedCurrencyOption) {
                setSelectedCurrency(selectedCurrencyOption.key)
            }
        }
    }, [data, dropdownData])

    // Format the backend quantity and unit to show in the quantity_string field
    const formatQuantityUnit = (quantity: number, userLocale: string, unit?: string): string => {
        
        // If the unit is a percentage sign, multiply the quantity by 100 to show it as a percentage 
        const adjustedQuantity = unit === '%' ? quantity * 100 : quantity;

        // Format the quantity based on the user locale
        const formattedQuantity = new Intl.NumberFormat(userLocale, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 4,
        }).format(adjustedQuantity);

        // Combine the formatted quantity with the unit, if it exists
        return unit ? `${formattedQuantity} ${unit}` : formattedQuantity;
    };

    // Format the backend sale_price to show in the sale_price_string field
    const formatPriceForDisplay = (price: number | null, userLocale: string): string | undefined => {
        if (price === null || isNaN(price)) {
            return undefined;
        };
    
        // Format the price
        return new Intl.NumberFormat(userLocale, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }).format(price);
    };

    // Generic function to update the rows with a changed value
    const updateRows = (identifier: string, updateFunction: (item: DocumentLineType, ...args: any[]) => DocumentLineType, ...args: any[]) => {
        // Copy the current rows and find its index
        const updatedRows = [...rows];
        const rowIndex = updatedRows.findIndex(row => row.id?.toString() === identifier || row.uuid === identifier);
      
        if (rowIndex !== -1) {
            // Set the new value in the specific row
            updatedRows[rowIndex] = updateFunction(updatedRows[rowIndex], ...args);
      
            // Update the rows and updated data
            setRows(updatedRows);
            setUpdatedData((prev: any) => ({ ...prev, [name]: updatedRows }))
        }
    };

    // Handle the quantity change
    const handleQuantityChange = (event: React.ChangeEvent<HTMLInputElement>, identifier: string) => {
        // Copy the rows and find the item index
        const updatedRows = [...rows];
        const rowIndex = updatedRows.findIndex(row => row.id?.toString() === identifier || row.uuid === identifier);

        if (rowIndex !== -1) {
            // Parse the quantity and unit from the quantity field
            const { quantity, unit } = parseQuantityAndUnit(event.target.value)

            // Set the quantity and unit in the updated rows
            updatedRows[rowIndex].quantity_string = event.target.value;
            updatedRows[rowIndex].quantity = quantity;
            updatedRows[rowIndex].unit = unit;

            // Calculate the total price change, only if a sale price is set
            if (updatedRows[rowIndex].sale_price !== null) {
                const salePrice = updatedRows[rowIndex].sale_price || 0;
                updatedRows[rowIndex].total_price = quantity * salePrice;
            }
            
            // Update the rows and updated data
            setRows(updatedRows);
            setUpdatedData((prev: any) => ({ ...prev, [name]: updatedRows }))
        }        
    };

    // Handle the price change
    const handlePriceChange = (event: React.ChangeEvent<HTMLInputElement>, identifier: string) => {
        // Copy the rows and find the item index
        const updatedRows = [...rows];
        const rowIndex = updatedRows.findIndex(row => row.id?.toString() === identifier || row.uuid === identifier);

        if (rowIndex !== -1) {
            // Convert the input into a number
            const salePriceNumber = parseFloat(event.target.value.replace(',', '.'));

            // Set the sale price in the updated rows
            updatedRows[rowIndex].sale_price_string = event.target.value;
            updatedRows[rowIndex].sale_price = salePriceNumber;

            // If sale price is set, calculate the total price
            if (salePriceNumber !== null) {
                const quantity = updatedRows[rowIndex].quantity || 0;
                updatedRows[rowIndex].total_price = quantity * salePriceNumber;
            }

            // Update the rows
            setRows(updatedRows);

            // Convert the sale price to a number for the backend
            setUpdatedData((currentUpdatedData: any) => {
                const copyOfUpdatedData = { ...currentUpdatedData };
    
                // If no updated data exist, create a new array
                if (!copyOfUpdatedData[name]) {
                    copyOfUpdatedData[name] = [];
                }
                
                // Find the row from the updated data to update
                const updatedIndex = copyOfUpdatedData[name].findIndex((item: any) => item.id?.toString() === identifier || item.uuid === identifier);
    
                // Set the sale price in the updated data for the found row or add the value to the new row
                if (updatedIndex !== -1) {
                    copyOfUpdatedData[name][updatedIndex] = {
                        ...updatedRows[rowIndex],
                        sale_price: salePriceNumber ? salePriceNumber : null
                    };
                } else {
                    copyOfUpdatedData[name].push({
                        ...updatedRows[rowIndex],
                        sale_price: salePriceNumber ? salePriceNumber : null
                    });
                }
    
                return copyOfUpdatedData;
            });
        }
    }

    // Handle text area change
    const handleTextAreaChange = (value: string | React.ChangeEvent<HTMLTextAreaElement>, identifier: string) => {
        // Copy the rows and find the row index
        const updatedRows = [...rows];
        const rowIndex = updatedRows.findIndex(item => item.id?.toString() === identifier || item.uuid === identifier);

        if (rowIndex !== -1) {
            // Set the description in the updated rows
            updatedRows[rowIndex].description = String(value);

            // Update rows and updated data
            setRows(updatedRows);
            setUpdatedData((prev: any) => ({ ...prev, [name]: updatedRows }))
        }
    }

    // Handle text area tab to focus to the sale price field
    const handleTextareaTab = (identifier: string) => {
        const element = document.getElementById(`sale_price_string_${identifier}`)

        if (element) {
            element.focus();
        }
    };

    // Handle vat calculation method change
    const handleVatCalculationMethodChange = (vatCalculationMethod: 'vat_excluded' | 'vat_included') => {

        // Update the vat calculation method state
        setVatCalculationMethod(vatCalculationMethod);

        // Update the method in the updated data
        setUpdatedData((currentUpdatedData: any) => ({ ...currentUpdatedData, ['vat_method']: vatCalculationMethod }));
    };

    // Handle price display mode change
    const handlePriceDisplayModeChange = (selectedValue: string) => {
        const selectedOption = priceDisplayModes.find(displayMode => displayMode.value === selectedValue);

        if (selectedOption) {
            // Update the price display mode state
            setPriceDisplayMode(selectedOption)

            // Update the updated data
            setUpdatedData((currentUpdatedData: any) => ({ ...currentUpdatedData, ['price_display_mode']: selectedOption.value }));
        }
    }

    // // Handle currency change
    // const handleCurrencyChange = (selectedValue: string) => {
    //     // Find the selected value inside the fetched currencies
    //     const selectedCurrencyOption = currencies.find(currency => currency.key == selectedValue);

    //     if (selectedCurrencyOption) {
    //         // Set the selectedValueInt as selected currency
    //         setSelectedCurrency(selectedCurrencyOption.key);

    //         // Update the updated data
    //         setUpdatedData((currentUpdatedData: any) => ({ ...currentUpdatedData, ['currency']: selectedCurrencyOption.key }))
    //     }        
    // };

    // Handle vat reverse charge number change
    const handleVatReverseChargeNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {

        // Update the vat number
        setVatReverseChargeNumber(event.target.value);

        // Update the vat number in the updated data
        setUpdatedData((currentUpdatedData: any) => ({ ...currentUpdatedData, ['recipient_vat_number']: event.target.value }));
    };

    // Handle the vat rate change
    const handleVatRateChange = (selectedValue: string, identifier: string) => {
        updateRows(identifier, (item, newVatRate) => {
            // Set the new vat rate
            const updatedItem = {
                ...item,
                vat_rate: parseFloat(newVatRate)
            };

            // Check if the new vat rate has vat reverse charge
            const vatRate = dropdownData?.vatrate?.results?.find(
                (rate: VatRateType) => rate.id === parseFloat(newVatRate)
            );

            if (vatRate) {
                setShowVatReverseCharge(vatRate.vat_reverse_charge);
            }

            return updatedItem;
        }, selectedValue);
    };

    // Handle the ledger account change
    const handleLedgerAccountChange = (selectedValue: string, identifier: string) => {
        // Copy the rows and find the row index
        const updatedRows = [...rows];
        const rowIndex = updatedRows.findIndex(row => row.id?.toString() === identifier || row.uuid === identifier);

        if (rowIndex !== -1) {
            // Set the selected value as 
            updatedRows[rowIndex].ledger_account = parseFloat(selectedValue)

            // Update the rows and updated data
            setRows(updatedRows);
            setUpdatedData((currentUpdatedData: any) => ({ ...currentUpdatedData, [name]: updatedRows }))
        }
    };
    
    // Handle the creation of a new row
    const handleAddRow = () => {
        // Split rows in non deleted and deleted rows
        const nonDeletedRows = rows.filter(row => !row.deleted);
        const deletedRows = rows.filter(row => row.deleted);

        // Determine the position of the new item and the currency
        const newPosition = nonDeletedRows.length + 1;
        const currency = data['currency'] ? data['currency'] : defaultCurrency
        const newRow = createNewDocumentLine(documentId!, 'invoice', uuidv4(), currency, newPosition);

        // If recipient has vat reverse charge, update the vat rate in the empty row
        if (showVatReverseCharge) {

            // Find the first vat rate with the vat reverse charge property
            const vatReverseChargeRate = dropdownData?.vatrate?.results?.find(
                (vatRate: VatRateType) => vatRate.vat_reverse_charge === true
            );

            if (vatReverseChargeRate) {
                newRow.vat_rate = vatReverseChargeRate.id;
            }

        // Otherwise try to find if a default vat rate is set, set this as the vat rate
        } else {
            // Find the default vat rate
            const defaultVatRate = dropdownData?.vatrate?.results?.find(
                (vatRate: VatRateType) => vatRate.is_default === true
            );

            if (defaultVatRate) {
                newRow.vat_rate = defaultVatRate.id;
            }
        }
        
        // Add the new row at the end of the list with non deleted rows and add the deleted rows
        const updatedNonDeletedRows = [...nonDeletedRows, newRow];
        const updatedRows = [...updatedNonDeletedRows, ...deletedRows];
    
        // Update the rows and updated data
        setRows(updatedRows);
        setUpdatedData((prev: any) => ({ ...prev, [name]: updatedRows }))
    };

    // Handle deletion of a row
    const handleDeleteRow = (identifier: string) => {
        const updatedRows = [...rows];
        const rowIndex = updatedRows.findIndex(row => row.id?.toString() === identifier || row.uuid === identifier);

        if (rowIndex !== -1) {
            // Mark the row as deleted without actually deleting it
            updatedRows[rowIndex].deleted = true;
    
            // Update the ordering of the rows which are not marked as deleted
            const nonDeletedRows = updatedRows.filter(row => !row.deleted);
            const reorderedNonDeletedRows = nonDeletedRows.map((row, index) => ({
                ...row,
                position: index + 1,
            }));

            // Add the deleted rows to the reordered list to keep them for the updated data
            const finalRows = [...reorderedNonDeletedRows, ...updatedRows.filter(row => row.deleted)];
    
            setRows(finalRows);
            setUpdatedData((prev: any) => ({ ...prev, [name]: finalRows }))
        } 
    }

    // Handle ordering change
    const handleOrderingChange = (result: any) => {
        if (!result.destination) return;
        const { source, destination } = result;
        
        // Only use the non deleted rows to determine the new ordering
        const nonDeletedRows = rows.filter(row => !row.deleted);
        const [reorderedRow] = nonDeletedRows.splice(source.index, 1);
        nonDeletedRows.splice(destination.index, 0, reorderedRow);
    
        // Determine the ordering for the non deleted rows
        const updatedNonDeletedRows = nonDeletedRows.map((row, index) => ({
            ...row,
            position: index + 1,
        }));
    
        // Add the deleted rows to the list without changing their ordering
        const finalRows = [...updatedNonDeletedRows, ...rows.filter(row => row.deleted)];
    
        setRows(finalRows);
        setUpdatedData((prev: any) => ({ ...prev, [name]: finalRows }))
    };

    // Format the total price value
    const formatPrice = (row: DocumentLineType, fieldName: 'sale_price' | 'total_price', showFallback?: boolean): string => {
        
        // Get the value from the field
        const fieldValue = row[fieldName];

        // If a value exists, format the value
        if (fieldValue) {

            let currency;
            if (row.sale_price_currency) {
                // If a sale price currency is found, use the currency of the product
                currency = row.sale_price_currency.toUpperCase();
            } else {
                // Otherwise fallback to the default currency of the environment
                currency = defaultCurrency?.toUpperCase() || 'EUR';
            }

            // Format the total price
            const formattedTotalPrice = formatPriceCurrencyString(fieldValue.toString(), userLocale, currency);

            return formattedTotalPrice;

        // If no value exists, format 0 as fallback
        } else if (showFallback) {
            const formattedZero = formatPriceString("0", userLocale);

            return `${defaultCurrencySymbol} ${formattedZero}`
        } else {
            return '';
        }
    }

    // Calculate the total amount of the entered rows
    const totalAmountOfEnteredRows = (rows: DocumentLineType[]): number => {
        const totalAmount = rows
            .filter(row => !row.deleted)
            .reduce((acc, row) => {
                // Convert total price strings to a number
                const totalPrice = typeof row.total_price === 'string'
                    ? parseFloat(row.total_price)
                    : row.total_price ?? 0;

                return acc + totalPrice;
            }, 0);

        return totalAmount;
    };

    // Calculate the subtotal of the document lines
    const subTotalPrice = (rows: DocumentLineType[]): number => {

        // For vat excluded document lines, sum the amount of the entered rows
        if (vatCalculationMethod === 'vat_excluded') {
            const totalAmount = totalAmountOfEnteredRows(rows)

            return totalAmount;
        }

        // For vat included document lines, subtract the vat amount from the total amount of the entered rows 
        else {
            // Calculate the total amount of the entered rows
            const totalAmountOfRows = totalAmountOfEnteredRows(rows);

            // Calculate the total amount of vat
            const vatTotal = vatSummary(rows, vatRates).reduce((acc, vat) => acc + vat.amount, 0);

            // Subtract the vat amount from the total amount of the entered rows
            const subTotal = totalAmountOfRows - vatTotal;

            return subTotal;
        }
    };

    // Calculate the total amount per used vat rate
    const vatSummary = (rows: DocumentLineType[], vatRates: VatRateType[]): { name: string; amount: number }[] => {
        const vatAmountPerVatRate = vatRates
            // Filter on the used vat rates in the document lines
            .filter(vatRate => rows.some(row => row.vat_rate === vatRate.id))
            .map(vatRate => {
                // Calculate the total amount per vat rate
                const subtotal = rows
                    .filter(row => !row.deleted && row.vat_rate === vatRate.id)
                    .reduce((acc, row) => {
                        // Convert total price strings to a number
                        const totalPrice = typeof row.total_price === 'string'
                            ? parseFloat(row.total_price)
                            : row.total_price ?? 0;
                        
                        return acc + totalPrice;
                    }, 0);
                
                // For vat excluded document lines, multiply the amount by the vat percentage
                if (vatCalculationMethod === 'vat_excluded') {

                    // Convert the vat percentage to a decimal
                    const vatPercentageDecimal = ((vatRate.percentage ?? 0) / 100);

                    // Calculate the amount for this vat rate
                    const taxAmount = subtotal * vatPercentageDecimal;

                    return { name: vatRate.name, amount: taxAmount };
                }

                // For vat included document lines, divide the amount by the vat percentage
                else {

                    // Convert the vat percentage to a decimal
                    const vatPercentageDecimal = ((vatRate.percentage ?? 0) / 100);

                    // Convert the vat percentage to a gross multiplier
                    const vatPercentageGrossDecimal = (vatPercentageDecimal + 1)

                    // Calculate the tax excluded part of the amount of the vat rate
                    const taxExcludedAmount = subtotal / vatPercentageGrossDecimal

                    // Subtract the tax excluded amount from the subtotal to get the tax amount
                    const taxAmount = subtotal - taxExcludedAmount;

                    return { name: vatRate.name, amount: taxAmount };
                }
            });

        return vatAmountPerVatRate;
    };

    // Calculate the total price of the document lines
    const totalPrice = (rows: DocumentLineType[], vatRates: VatRateType[]): number => {

        // For vat excluded document lines, sum the amount of the entered rows plus the vat amount
        if (vatCalculationMethod === 'vat_excluded') {

            // Calculate the total amount of the entered rows
            const subTotal = totalAmountOfEnteredRows(rows);

            // Calculate the total amount of vat
            const vatTotal = vatSummary(rows, vatRates).reduce((acc, vat) => acc + vat.amount, 0);

            // Set the total price
            return subTotal + vatTotal;
        }

        // For vat included document lines, sum the amount of the entered rows
        else {
            const totalAmount = totalAmountOfEnteredRows(rows)

            return totalAmount;
        }
    };

    const formatTotalValue = (value: number) => {
        return formatPriceCurrencyString(value.toString(), userLocale, selectedCurrency);
    }

    return (
        <div className='document-lines'>
            {showErrorAlert &&
                <div className="alert form-alert alert-danger" role="alert">
                    {t(errorMessages.general, { defaultValue: errorMessages.general })}
                </div>
            }
            {editing ? (
                // Edit mode
                <div className='edit-mode'>
                    <div className='document-lines-settings'>                            
                        <Dropdown<{ name: string, value: string }>
                            options={priceDisplayModes}
                            id='display_mode'
                            name='display_mode'
                            disabled_selected={t('forms.general_disabled_selected')}
                            selectedOption={priceDisplayMode}
                            onChange={(selectedValue) => handlePriceDisplayModeChange(selectedValue)}
                            selectionFormat={(option) => `${t(option.name)}`}
                            optionFormat={(option) => option.name}
                            allowNoneOption={false}
                            showSearch={false}
                        />
                        {/* <Dropdown<CurrencyType>
                            options={currencies}
                            id='currency'
                            name='currency'
                            disabled_selected={t('invoice.general.currency_placeholder')}
                            selectedOption={currencies.find(currency => data['currency'] === currency.key)}
                            onChange={(selectedValue) => handleCurrencyChange(selectedValue)}
                            selectionFormat={(option) => `${t(option.name)} (${option.symbol})`}
                            optionFormat={(option) => `${option.name}`}
                            allowNoneOption={false}                       
                        /> */}
                        <TabsButton
                            buttons={[{
                                label: 'document_lines.general.vat_excluded_label',
                                tabValue: 'vat_excluded',
                            },
                            { 
                                label: 'document_lines.general.vat_included_label',
                                tabValue: 'vat_included',
                            }]}
                            currentTabValue={vatCalculationMethod}
                            onTabsClick={(value) => handleVatCalculationMethodChange(value as 'vat_included' | 'vat_excluded')}
                            size="extra-small"
                        />
                    </div>
                    
                    {rows.length > 0 && (
                        <div className='rows-header document-lines-row'>
                            <div className='header-item'>{t('invoice.lines.quantity_label')}</div>
                            <div className='header-item'>{t('invoice.lines.description_label')}</div>
                            <div className='header-item'>{t('invoice.lines.price_label')}</div>
                            <div className='header-item'>{t('invoice.lines.total_label')}</div>
                            <div className='header-item'>{t('invoice.lines.extra_label')}</div>
                            <div className='delete-placeholder'></div>
                            <div className='drag-placeholder'></div>
                        </div>
                    )}
                    <DragDropContext onDragEnd={handleOrderingChange}>
                        <StrictModeDroppable droppableId="droppable">
                            {(provided) => (
                                <div {...provided.droppableProps} ref={provided.innerRef}>
                                    {rows && rows.filter(row => row.deleted === false).map((row, index) => {
                                        const identifier = row.id?.toString() || row.uuid;
                                        return (
                                            <Draggable key={identifier} draggableId={`item-${identifier}`} index={index} isDragDisabled={rows.length <= 1}>
                                                {(provided) => {
                                                    return (
                                                        <div className='list-item draggable' ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                            <div className='document-lines-row'>
                                                                <input 
                                                                    type="text"
                                                                    id={`quantity_string_${identifier}`}
                                                                    name={`quantity_string_${identifier}`}
                                                                    value={row.quantity_string ?? ''}
                                                                    onChange={event => handleQuantityChange(event, identifier || '')}
                                                                    placeholder={t('invoice.lines.quantity_placeholder')}
                                                                    autoFocus={false}
                                                                    className={identifier && errorMessages?.lines?.[identifier]?.['quantity'] ? 'is-invalid' : undefined}
                                                                />
                                                                <TextareaInput
                                                                    name={name}
                                                                    identifier={identifier || ''}
                                                                    value={row.description ?? undefined}
                                                                    placeholder='invoice.lines.description_placeholder'
                                                                    rows={2}
                                                                    enableStyling='simple'
                                                                    showToolbarInitially={false}
                                                                    onChange={event => handleTextAreaChange(event, identifier || '')}
                                                                    onTabClose={() => handleTextareaTab(identifier || '')}
                                                                />
                                                                <input 
                                                                    type="text"
                                                                    id={`sale_price_string_${identifier}`}
                                                                    name={`sale_price_string_${identifier}`}
                                                                    value={row.sale_price_string ?? undefined}
                                                                    onChange={event => handlePriceChange(event, identifier || '')}
                                                                    placeholder={t('invoice.lines.price_placeholder')}
                                                                    className={`price-field ${identifier && errorMessages?.lines?.[identifier]?.['sale_price'] ? 'is-invalid' : ''}`}
                                                                />
                                                                <div className='string-value price-value'>
                                                                    <div className='text-right'>{formatPrice(row, 'total_price', true)}</div>
                                                                </div>
                                                                <div className='multi-line'>
                                                                    <Dropdown<VatRateType>
                                                                        options={vatRates}
                                                                        id={`vat_rate_${identifier}`}
                                                                        name={`vat_rate_${identifier}`}
                                                                        disabled_selected={t('invoice.lines.vat_rate_placeholder')}
                                                                        selectedOption={vatRates.find(vatRate => row.vat_rate === vatRate.id)}
                                                                        onChange={(selectedValue) => handleVatRateChange(selectedValue, row.id?.toString() || row.uuid || '')}
                                                                        selectionFormat={(option) => `${option.name}`}
                                                                        optionFormat={(option) => `${option.name}`}
                                                                        allowNoneOption={false}                           
                                                                    />
                                                                    <Dropdown<LedgerAccountType>
                                                                        options={ledgerAccounts}
                                                                        id={`ledger_account_${identifier}`}
                                                                        name={`ledger_account_${identifier}`}
                                                                        disabled_selected={t('invoice.lines.ledger_account_placeholder')}
                                                                        selectedOption={ledgerAccounts.find(ledgerAccount => row.ledger_account === ledgerAccount.id)}
                                                                        onChange={(selectedValue) => handleLedgerAccountChange(selectedValue, row.id?.toString() || row.uuid || '')}
                                                                        selectionFormat={(option) => `${option.name}`}
                                                                        optionFormat={(option) => `${option.ledgeraccount_name_code}`}
                                                                        allowNoneOption={true}                           
                                                                    />
                                                                </div>
                                                                <div className='delete-icon tooltip-icon'>
                                                                    <FontAwesomeIcon 
                                                                        icon={faTrash} 
                                                                        onClick={() => handleDeleteRow(row.id?.toString() || row.uuid || '')} />
                                                                    <span className="tooltip">{t('general.delete')}</span>
                                                                </div>
                                                                <div className={`order-icon tooltip-icon ${rows.length === 1 ? 'visibility-hidden' : ''}`}>
                                                                    <FontAwesomeIcon icon={faGripLines} />
                                                                    <span className="tooltip">{t('general.reorder')}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }}
                                            </Draggable>
                                        )
                                    })}
                                    {provided.placeholder}
                                </div>
                            )}
                        </StrictModeDroppable>
                    </DragDropContext>
                    {errorMessages && 
                        <div className='error-message'>
                            {renderRowsFieldErrorMessages(errorMessages, 'lines', t)}
                        </div>
                    }
                    <div onClick={(e) => {e.preventDefault(); handleAddRow(); }} 
                        className="add-new-button">
                        {t('invoice.lines.add_new_item')}
                    </div>    
                    {helperText && 
                        <div className="helper-text">
                            {t(helperText)}
                        </div>
                    }
                    {rows.filter(row => !row.deleted).length > 0 && (
                        <div className='document-lines-totals'>
                            {vatSummary(rows, vatRates).length > 0 && (
                                // Only show sub total if there are vat rates
                                <div className='sub-total-summary'>
                                    <div></div>
                                    <div className='text-right'>{t('invoice.lines.subtotal_label')}</div>
                                    <div className='text-right'>{formatTotalValue(subTotalPrice(rows))}</div>
                                    <div></div>
                                    <div className='icons-placeholder'></div>
                                </div>
                            )}

                            {vatSummary(rows, vatRates).map((vatRate, index) => (
                                <div key={index} className='vat-summary'>
                                    <div></div>
                                    <div className='text-right'>{vatRate.name}</div>
                                    <div className='text-right'>{formatPriceCurrencyString(vatRate.amount.toString(), userLocale, selectedCurrency)}</div>
                                    <div></div>
                                    <div className='icons-placeholder'></div>
                                </div>
                            ))}

                            <div className='total-summary'>
                                <div></div>
                                <div className='text-right'>{t('invoice.lines.total_label')}</div>
                                <div className='text-right'>{formatTotalValue(totalPrice(rows, vatRates))}</div>
                                <div></div>
                                <div className='icons-placeholder'></div>
                            </div>
                        </div>
                    )}
                    {showVatReverseCharge && featureName === 'invoice' && (
                        <div className="vat-reverse-charge">
                            <div className='vat-reverse-charge-label'>
                                {t('invoice.lines.vat_reverse_charge_label')}
                            </div>
                            <input 
                                type="text"
                                id='vat_reverse_charge_number'
                                name='vat_reverse_charge_number'
                                value={vatReverseChargeNumber ?? ''}
                                onChange={(event) => handleVatReverseChargeNumberChange(event)}
                                placeholder={t('invoice.lines.quantity_placeholder')}
                                autoFocus={false}
                                className='vat-reverse-charge-field'
                            />
                            <div></div>
                        </div>
                    )}
                </div>
            ) : (
                // View mode
                <div className={`full-width-alignment ${isEditable?.editability && !userHasOnlyViewRights ? 'editable' : ''}`}>
                    <div className="view-mode">
                        <span className='p'>
                            <div className='document-lines-table'>
                                {rows.length > 0 && (
                                    <div className='table-header'>
                                        <div className='header-item'>{t('invoice.lines.quantity_label')}</div>
                                        <div className='header-item'>{t('invoice.lines.description_label')}</div>
                                        {priceDisplayMode.value === 'line_prices' ? (
                                            <div className='header-item text-right'>{t('invoice.lines.price_label')}</div>
                                        ) : (
                                            <div></div>
                                        )}
                                        <div className='header-item text-right'>{t('invoice.lines.total_label')}</div>
                                        <div className='header-item text-right'>{t('invoice.lines.vat_rate_label')}</div>
                                    </div>
                                )}
                                {rows.filter(row => row.id).map((row, index, filteredRows) => {   
                                    
                                    // Get the vat rate of the document line
                                    const vatRate = vatRates.find(vatRate => vatRate.id === row.vat_rate);

                                    // Configure the table row classname to add the middle border
                                    const tableRowClassName = `table-row ${index === filteredRows.length - 1 ? 'last-border' : ''}`;

                                    return (
                                        <div key={row.id!.toString()} className={tableRowClassName}>
                                            <div>{row.quantity_string} </div>
                                            <div dangerouslySetInnerHTML={{ __html: row.description as string }}></div>

                                            {priceDisplayMode.value === 'line_prices' ? (
                                                <>
                                                    <div className='text-right'>{formatPrice(row, 'sale_price', true)}</div>
                                                    <div className='text-right'>{formatPrice(row, 'total_price', true)}</div>
                                                </>
                                            ) : (
                                                <>
                                                    <div></div>
                                                    <div></div>
                                                </>
                                            )}

                                            {vatRate && 
                                                <div className='text-right'>{vatRate.name}</div>
                                            }
                                        </div>
                                    )
                                })}
                                {isEditable?.editability && !userHasOnlyViewRights && (
                                    // Only show add item label when the object is editable
                                    <div className='add-item'>
                                        {t('invoice.lines.add_item')}
                                    </div>
                                )}
                            
                                {rows.filter(row => !row.deleted).length > 0 && (
                                    <>
                                        {vatSummary(rows, vatRates).length > 0 && (
                                            // Only show sub total if there are vat rates
                                            <div className='sub-total-summary'>
                                                <div></div>
                                                <div className='text-right'>{t('invoice.lines.subtotal_label')}</div>
                                                <div className='text-right'>{formatTotalValue(subTotalPrice(rows))}</div>
                                                <div></div>
                                            </div>
                                        )}
  
                                        {vatSummary(rows, vatRates).map((vatRate, index) => (
                                            <div key={index} className='vat-summary'>
                                                <div></div>
                                                <div className='text-right'>{vatRate.name}</div>
                                                <div className='text-right'>{formatPriceCurrencyString(vatRate.amount.toString(), userLocale, selectedCurrency)}</div>
                                                <div></div>
                                            </div>
                                        ))}
      
                                        <div className='total-summary'>
                                            <div></div>
                                            <div className='text-right'>{t('invoice.lines.total_label')}</div>
                                            <div className='text-right'>{formatTotalValue(totalPrice(rows, vatRates))}</div>
                                            <div></div>
                                        </div>
                                    </>
                                )}
                            </div>
                            {showVatReverseCharge && featureName === 'invoice' && (
                                <div className="vat-reverse-charge">
                                    {data['recipient']?.['vat_number'] ? (
                                        t('invoice.lines.vat_reverse_charge_notice', { vat_number: data['recipient']?.['vat_number'] })
                                    ) : (
                                        t('invoice.lines.vat_reverse_charge_notice_unknown_vat')
                                    )}
                                </div>
                            )}
                        </span>
                        <span className='edit-icon'>
                            <FontAwesomeIcon icon={faPen} />
                        </span> 
                    </div>
                </div>
            )}
        </div>
    );
}

export default DocumentLinesRowsField;