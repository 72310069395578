import React from 'react';
import { FieldType, FieldOptionFetchResponse, FieldOption } from 'types/FieldTypes';
import TextField from './fields/TextField';
import TextareaField from './fields/TextareaField';
import NumberField from './fields/NumberField';
import PasswordField from './fields/PasswordField';
import EmailField from './fields/EmailField';
import DateField from './fields/DateField';
import TabsField from './fields/TabsField';
import DropdownField from './fields/DropdownField';
import SearchSelectField from './fields/SearchSelectField';
import TimeField from './fields/TimeField';
import MultiSelectField from './fields/MultiSelectField';
import CheckboxField from './fields/CheckboxField';
import FieldHeader from './fields/elements/FieldHeader';
import RadioField from './fields/RadioField';
import TotalPriceField from './fields/TotalPriceField';
import ImageField from './fields/ImageField';
import PriceCurrencyFieldset from './mutli-fields/PriceCurrencyMultiField';
import PhoneNumbersFieldset from './fieldsets/PhoneNumbersFieldset';
import EmailAddressesFieldset from './fieldsets/EmailAddressesFieldset';
import AttachmentsFieldset from './fieldsets/AttachmentsFieldset';
import LocationsFieldset from './fieldsets/LocationsFieldset';
import ExpirationDaysField from './fields/ExpirationDaysField';
import PipelineStagesRowsField from './fieldsets/PipelineStagesRowsField';
import PriceField from './fields/PriceField';
import PercentageField from './fields/PercentageField';

/*
 * GenerateFieldComponents.tsx
 * General function which generates the field components of a form. It is used by the InlineForm component
 * which gets the fields from certain form configuration files. This function maps these fields and its
 * properties to the right fields and return these components to the Inline Form to use in the return jsx.
 * The function also handles if fields needs to be wrapped inside a div or not which is used for styling
 * and positioning purposes on the form.
 */

export const generateFieldComponents = (
    viewKey: string,
    fields: FieldType[],
    dropdownData: Record<string, FieldOptionFetchResponse> | undefined,
    groupOptions: FieldOption[] | undefined,
    data: any,
    updatedData: any,
    itemId?: number | null,
    onTypeChange?: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void,
): JSX.Element[] => {
    // Handle if dropdown data or groupoptions are undefined
    dropdownData = dropdownData ?? {};
    groupOptions = groupOptions ?? [];

    // Create an empty array to store the components in the given order
    let components: JSX.Element[] = [];

    // Loop through the fields and construct the components in the right order
    fields.forEach((field, index) => {
        let FieldComponent;
        let fieldComponentsInGroup;

        // Skip rendering if showOnEdit is set and editItemId is not available
        if ('showOnEdit' in field) {
            if ((field.showOnEdit && !itemId) || (!field.showOnEdit && itemId)) {
                return;
            }
        }

        // Check if the field is dependent on another field to show
        let shouldShowField = true;
        if ('dependentOn' in field && field.dependentOn) {

            // Check if either on or more dependencies exist for this field
            const dependencies = Array.isArray(field.dependentOn) ? field.dependentOn : [field.dependentOn];

            // Loop through the dependencies
            for (const dependency of dependencies) {
                const { fieldName, value } = dependency;

                // Check if the dependend field exists in the data or updated data
                const dependentFieldExists = (data && fieldName in data) || (updatedData && fieldName in updatedData);

                // If the dependent field doesn't exist in the data, don't show the related field
                if (!dependentFieldExists) {
                    shouldShowField = false;
                    break
                }
                
                // Check for the most recent value between updated data and fetched data
                const dependentFieldValue = updatedData[fieldName] ?? data[fieldName];

                // Determine if the related field has to be shown
                if (typeof value === 'boolean') {
                    // Handle boolean value dependency
                    if (dependentFieldValue !== value) {
                        shouldShowField = false;
                        break;
                    }
                } else if (Array.isArray(value)) {
                    // If a value array is provided, check if one of its value correspondes
                    if (Array.isArray(dependentFieldValue)) {
                        if (!value.some(val => dependentFieldValue.includes(val))) {
                            shouldShowField = false;
                            break;
                        }
                    } else if (!value.includes(dependentFieldValue)) {
                        shouldShowField = false;
                        break;
                    }
                } else {
                    // If a single value is provided
                    if (Array.isArray(dependentFieldValue)) {
                        if (!dependentFieldValue.includes(value)) {
                            shouldShowField = false;
                            break;
                        }
                    } else if (dependentFieldValue !== value) {
                        shouldShowField = false;
                        break;
                    }
                }
            }            
        }

        // Check if the field only may show if it's filled
        if ('showIfFilled' in field && field.showIfFilled) {
            const fieldDataExists = data && data[field.name] !== undefined;
            const isBooleanField = field.type === 'checkbox';
            shouldShowField = fieldDataExists && (!isBooleanField || data[field.name] === true);
        }

        // Skip rendering if the field should not be shown
        if (!shouldShowField) {
            return;
        }

        // Pick the right component based on the field type
        switch(field.type) {
            case 'text':
                FieldComponent = <TextField {...field} viewKey={viewKey} data={data} shouldAutoFocus={index === 0} />;
                break;
            case 'textarea':
                FieldComponent = <TextareaField {...field} viewKey={viewKey} data={data} shouldAutoFocus={index === 0} />;
                break;
            case 'number':
                FieldComponent = <NumberField {...field} viewKey={viewKey} data={data} shouldAutoFocus={index === 0} />;
                break;
            case 'percentage':
                FieldComponent = <PercentageField {...field} viewKey={viewKey} data={data} shouldAutoFocus={index === 0} />;
                break;
            case 'expiration-days':
                FieldComponent = <ExpirationDaysField {...field} viewKey={viewKey} data={data} shouldAutoFocus={index === 0} />;
                break;    
            case 'price':
                FieldComponent = <PriceField {...field} viewKey={viewKey} data={data} dropdownData={dropdownData} />;
                break;
            case 'totalprice':
                FieldComponent = <TotalPriceField {...field} viewKey={viewKey} data={data} />;
                break;
            case 'date':
                FieldComponent = <DateField {...field} viewKey={viewKey} data={data} />;
                break;
            case 'time':
                FieldComponent = <TimeField {...field} viewKey={viewKey} data={data} />
                break;
            case 'dropdown':
                FieldComponent = <DropdownField {...field} viewKey={viewKey} data={data} dropdownData={dropdownData} />;
                break;
            case 'multiselect':
                FieldComponent = <MultiSelectField {...field} viewKey={viewKey} data={data} dropdownData={dropdownData} groupOptions={groupOptions} />;
                break;
            case 'searchselect':
                FieldComponent = <SearchSelectField {...field} viewKey={viewKey} data={data} />
                break;
            case 'radio':
                FieldComponent = <RadioField {...field} viewKey={viewKey} data={data} />;
                break;
            case 'tabs':
                FieldComponent = <TabsField {...field} viewKey={viewKey} data={data} dropdownData={dropdownData} onChange={onTypeChange}/>;
                break;
            case 'checkbox':
                FieldComponent = <CheckboxField {...field} viewKey={viewKey} data={data}/>;
                break;
            case 'email':
                FieldComponent = <EmailField {...field} viewKey={viewKey} data={data} shouldAutoFocus={index === 0} />;
                break;
            case 'password':
                FieldComponent = <PasswordField {...field} viewKey={viewKey} data={data} shouldAutoFocus={index === 0} />;
                break;
            case 'image':
                FieldComponent = <ImageField {...field} viewKey={viewKey} data={data} />;
                break;
            case 'separator':
                FieldComponent = <div className='separator'>—</div>
                break;
            case 'divider':
                FieldComponent = <div className='divider'></div>
                break;
            case 'header':
                FieldComponent = <FieldHeader {...field} />
                break;
            case 'phonenumbers':
                FieldComponent = <PhoneNumbersFieldset viewKey={viewKey} {...field} data={data} shouldAutoFocus={index === 0} />;
                break;
            case 'emailaddresses':
                FieldComponent = <EmailAddressesFieldset viewKey={viewKey} {...field} data={data} shouldAutoFocus={index === 0} />;
                break;
            case 'price-currency':
                FieldComponent = <PriceCurrencyFieldset viewKey={viewKey} {...field} data={data} dropdownData={dropdownData} shouldAutoFocus={index === 0} />;
                break;
            case 'locations':
                FieldComponent = <LocationsFieldset viewKey={viewKey} {...field} data={data} objectId={itemId} shouldAutoFocus={index === 0} />;
                break;
            case 'attachments':
                FieldComponent = <AttachmentsFieldset viewKey={viewKey} {...field} data={data} />;
                break;
            case 'pipeline-stages':
                FieldComponent = <PipelineStagesRowsField viewKey={viewKey} {...field} data={data} />;
                break;
            default:
                FieldComponent = null;
        }

        // Render the field component
        if (FieldComponent) {
            fieldComponentsInGroup = (
                <div key={'name' in field && field.name ? `${field.name}_${index}` : `${field.type}_${index}`}
                     style={{ width: field.width || '100%', display: ('honeypotField' in field && field.honeypotField) ? 'none' : 'inline-block' }} 
                     className={`formfield ${('layout' in field && field.layout) ? field.layout : ''}`}>
                    {FieldComponent}
                </div>
            );
            // If the field has a group, wrap the components inside a div with the group name as it's class
            if (field.group) {
                // If the previous component is a group with the same name, add this component to that group
                if (components.length > 0 && components[components.length - 1].key === field.group) {
                    components[components.length - 1] = (
                        <div key={field.group} className={`${field.group}`}>
                            {components[components.length - 1].props.children}
                            {fieldComponentsInGroup}
                        </div>
                    );
                // Otherwise, create a new group
                } else {
                    components.push(
                        <div key={field.group} className={`${field.group}`}>
                            {fieldComponentsInGroup}
                        </div>
                    );
                }
            // If the field has no group, add the component directly to the array
            } else {
                components.push(fieldComponentsInGroup);
            }
        }
    });

    return components;
}