import React from 'react';
import { FieldType } from 'types/FieldTypes';
import FormModal from 'components/modals/FormModal';

// Rename the name of the function:
const PersonForm: React.FC<{ viewKey?: string, linkedItem?: { [key: string]: number } }> = ({ viewKey, linkedItem }) => {

    const apiObject = 'person'

    // Plug in the fields and their properties:
    const fields: FieldType[] = [
        {
            name: 'title',
            label: 'person.general.salutation_label',
            type: 'text',
            placeholder: 'person.general.salutation_placeholder',
        },
        {
            name: 'first_name',
            label: 'person.general.first_name_label',
            type: 'text',
            placeholder: 'person.general.first_name_placeholder',
        },
        {
            name: 'last_name',
            label: 'person.general.last_name_label',
            type: 'text',
            placeholder: 'person.general.last_name_placeholder',
        },
    ];

    return (
        <FormModal 
            viewKey={viewKey}
            apiObject={apiObject}
            fields={fields}
            linkedItem={linkedItem}
        />
    );
};

export default PersonForm;