import React from 'react';
import { FieldType } from 'types/FieldTypes';
import FormModal from 'components/modals/FormModal';

// Rename the name of the function:
const DealPipelineEditForm: React.FC<{ viewKey?: string }> = ({ viewKey }) => {

    const apiObject = 'pipeline'

    // Plug in the fields and their properties:
    const fields: FieldType[] = [
        {
            name: 'name',
            label: 'deal.general.pipeline_name_label',
            type: 'text',
            placeholder: 'deal.general.pipeline_name_placeholder',
            width: '50%',
            group: 'field-row-1',
        },
        {
            type: 'header',
            header: 'deal.general.stages_header',
        },
        {
            name: 'stages',
            type: 'pipeline-stages',
        },
    ];

    return (
        <FormModal
            viewKey={viewKey}
            apiObject={apiObject}
            fields={fields}
        />
    );
};

export default DealPipelineEditForm;