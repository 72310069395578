import React from 'react';
import { useLocation } from 'react-router-dom';
import { useAuthContext } from 'services/authentication/AuthenticationContext';
import logoGrey from '../../assets/crafter-logo-grey.png';
import { useGlobalContext } from 'GlobalContext';

const FooterLogo = () => {
    const location = useLocation();
    const { showFooterLogo } = useGlobalContext();
    const { isLoggedIn } = useAuthContext();

    // Don't show the footer logo if this state is given
    if (!showFooterLogo) {
        return null;
    }

    // // Don't show the footer logo on the scheduling route
    // if (location.pathname === '/scheduling' || location.pathname === '/deals') {
    //     return null;
    // }

    // Don't show the footer logo when not logged in
    if (!isLoggedIn) {
        return null;
    }

    return (
        <footer className='bottom-container'>
            <img src={logoGrey} alt="Logo" className='bottom-logo'/>
        </footer>
    );
};

export default FooterLogo;