import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from 'services/authentication/AuthenticationContext';
import { useSettings } from 'services/settings/SettingsContext';
import { ListColumnType } from 'types/ListTypes';
import AbstractList from 'components/lists/AbstractList';
import ReleaseViewModal from './ReleaseViewModal';
import ReleaseForm from './ReleaseForm';
import ReleaseEditModal from './ReleaseEditModal';
import { formatDateString } from 'services/utils/dateTimeUtils';

// Rename the name of the function:
const ReleaseList: React.FC = () => {
    const { t } = useTranslation();
    const { allowedRights } = useAuthContext();
    const { userLocale } = useSettings();

    // Specify the options of this list:
    const query = {
        endpoint: 'get_release_list',
        object: 'release'
    }
    const objectName = 'release.general.object_name'
    const showDeleteOrDeactivate = allowedRights.includes('no_user') ? 'flag_deleted' : undefined;

    // Specify form, detail modal or detailpage for this list
    const formModal = <ReleaseForm />;

    const detailModal = <ReleaseEditModal />;
    const detailModalSize = 'medium-large';

    const releaseViewModal = <ReleaseViewModal />
    const onRowClick = allowedRights.includes('no_user') ? 'detailModal' : 'releaseViewModal';

    // Don't show add button for normal users
    const showAddButton = allowedRights.includes('no_user') ? true : false;

    // Custom jsx to render the release name within the version column
    const renderVersionNameColumn = (version: string, release_name: string | null) => {
        if (release_name) {
            return (
                <div>
                    <span>{version}</span>
                    <span style={{ margin: '0 5px' }}>•</span>
                    <span>{release_name}</span>
                </div>
            )
        } else {
            return <>{version}</>
        }
    }

    // Custom jsx to render the release type as badge
    const renderTypeBadges = (releaseType: string) => {
        
        // Determine the badge color based on the release type
        let color;
        if (releaseType === 'feature_release') color = 'background-green';
        if (releaseType === 'fix_release') color = 'background-yellow';

        return (
            <div className='badge-container'>
                <div className={`label-badge ${color}`}>
                    {t(`release.release_type.${releaseType}`)}
                </div>
            </div>
        );
    }

    // Format the date column into a date with written out month
    const renderDateColumn = (dateString: string) => {
        const formattedDate = formatDateString(dateString, userLocale);
        return <>{formattedDate}</>
    }

    // Specify the columns of this list
    const columns: ListColumnType[] = [
        {   
            width: '1fr', 
            field: 'version', 
            label: 'release.general.version_label_short',
            render: (_, row) => renderVersionNameColumn(row.version, row.release_name)
        },
        {   
            width: '3fr', 
            field: 'description_plain', 
            label: 'release.general.description_label' 
        },
        {
            width: '1fr',
            field: 'type',
            label: 'release.general.release_type_label_short',
            render: (_, row) => renderTypeBadges(row.type)
        },
        {   
            width: '1fr',
            field: 'release_date', 
            label: 'release.general.release_date_label_short',
            render: (_, row) => renderDateColumn(row.release_date)
        },
    ];

    return (
        <AbstractList
            query={query}
            objectName={objectName}
            columns={columns}
            showDeleteOrDeactivate={showDeleteOrDeactivate}
            showAddButton={showAddButton}
            formModal={formModal}
            detailModal={detailModal}
            detailModalSize={detailModalSize}
            releaseViewModal={releaseViewModal}
            onRowClick={onRowClick}
        />
    );
};

export default ReleaseList;