import React, { useState, useContext, useEffect } from 'react';
import { LocationsFieldsetType, FieldData, FieldOption } from 'types/FieldTypes';
import { LocationType, createEmptyLocation } from '../../../views/contacts/ContactTypes';
import { useTranslation } from 'react-i18next';
import { useGlobalContext } from 'GlobalContext';
import { useAllowedRight } from 'services/permissions/permissionChecks';
import { v4 as uuidv4 } from 'uuid';
import FormFieldContext from '../FormFieldContext';
import SearchSelect from '../basefields/SearchSelect';
import { saveData } from 'services/api/saveData';
import { handleSaveErrors } from 'services/api/handleSaveErrors';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrash, faCircleQuestion } from '@fortawesome/free-solid-svg-icons';
import '../../../style/scss/live-edit.scss';
import '../../../style/scss/forms.scss';

interface LocationsFieldsetProps extends LocationsFieldsetType {
    object?: string;
    objectId: string | number | null | undefined;
    errorMessage?: string | string[] | undefined;
    shouldAutoFocus?: boolean;
    handleFieldSubmit?: boolean;
    fieldSubmitted?: () => void;
    refetchData?: () => void;
}

const LocationsFieldset: React.FC<LocationsFieldsetProps & { data: FieldData, viewKey: string }> = ({
    viewKey, name, label = 'location.general.address_label', object, objectId, apiField = 'locations', 
    data, helperText, tooltipText, viewInEditMode, alignment, handleFieldSubmit, fieldSubmitted, refetchData,
    isEditable, addNewItem
}) => {
    const { t } = useTranslation();
    const { editing, setEditing, updatedData, setButtonLoader, setUpdatedData, showErrorAlert, setShowErrorAlert } = useContext(FormFieldContext);
    const { errorMessages, setErrorMessages, setUnsavedChanges, setPreventClosing, setFloatingAlert } = useGlobalContext();
    const hasRightCheck = useAllowedRight;
    const [ rows, setRows ] = useState<LocationType[]>([]);
    const [userHasOnlyViewRights] = useState<boolean>(hasRightCheck('only_view'));
    const defaultAddressType = 'billing';

    // If there are addresses in the fetched data, show the current ones
    useEffect(() => {
        if (data && data[name] && data[name].length > 0) {
            const fetchedRows = data[name].map((row: LocationType) => ({ ...row }));
            setRows(fetchedRows)

        // If there are no addresses, show an empty row
        } else if (editing || viewInEditMode) {
            const emptyAddress = createEmptyLocation(defaultAddressType, uuidv4());
            setRows([emptyAddress]);
        }
    }, [data]);

    // Map address to fieldoption
    const addressToFieldOption = (address: any): FieldOption => {
        return {
            id: address.id,
            value: address.address,
            name: 'address',
            address: address.address,
        };
    }

    // Handle selection of an address
    const handleAddressSelection = (selectedItem: any, identifier: string) => {
        // Copy the rows and find the row index
        const updatedRows = [...rows];
        const rowIndex = updatedRows.findIndex(row => row.id?.toString() === identifier || row.uuid === identifier);

        if (rowIndex !== -1) {
            // Set the id and address from the selected item
            updatedRows[rowIndex].id = selectedItem.id;
            updatedRows[rowIndex].address = selectedItem.address;      

            // Update the rows and updated data
            setRows(updatedRows);
            setUpdatedData((currentUpdatedData: any) => ({ ...currentUpdatedData, [apiField]: updatedRows }))
        }
    }

    // Handle unlink or deletion of an address row
    const handleUnlink = (identifier: string) => {
        // Copy the rows and find the row index
        const updatedRows = [...rows];
        const rowIndex = updatedRows.findIndex(row => row.id?.toString() === identifier || row.uuid === identifier);

        if (rowIndex !== -1) {
            // Create a new empty address
            const emptyAddress = createEmptyLocation(defaultAddressType, uuidv4());

            // Replace the unlinked row by the empty address
            updatedRows[rowIndex] = emptyAddress;

            // Update the rows and updated data
            setRows(updatedRows);
            setUpdatedData((currentUpdatedData: any) => ({ ...currentUpdatedData, [apiField]: updatedRows }))
        }
    }

    // Handle deletion of an address row. Deleting means not putting the deletion flag to true of the location, but removing the location from the list of this object
    const handleRemoveItemFromObject = (identifier: string) => {
        // Copy the rows and find the row index
        const updatedRows = [...rows];
        const rowIndex = updatedRows.findIndex(row => row.id?.toString() === identifier || row.uuid === identifier);

        if (rowIndex !== -1) {
            // Delete the row
            updatedRows.splice(rowIndex, 1);

            // Update the rows and updated data
            setRows(updatedRows);
            setUpdatedData((currentUpdatedData: any) => ({ ...currentUpdatedData, [apiField]: updatedRows }))
        }
    }

    // Handle adding of a new row
    const handleAddRow = () => {
        // Create a new row
        const newRow = createEmptyLocation(defaultAddressType, uuidv4());
    
        // Add the row to the list of rows
        const updatedRows = [...rows, newRow];
    
        // Update the rows and updated data
        setRows(updatedRows);
        setUpdatedData((currentUpdatedData: any) => ({ ...currentUpdatedData, [apiField]: updatedRows }))
    };

    // Handle save of the selected items
    const handleFieldSubmitFunction = async() => {
        try {
            if (setButtonLoader) setButtonLoader(true);

            // Patch the items and refetch the data after saving
            await saveData({ apiUrl: `patch_${object}/${objectId}`, method: 'patch', data: updatedData });
            if (refetchData) await refetchData();

            // Clear the updatedData to prevent resubmitting and duplicate items
            setUpdatedData({});

            // Clear the errors, show a success alert, set unsaved changes to false and close the edit mode
            setErrorMessages({});
            setFloatingAlert({ type: 'success' })
            setUnsavedChanges(viewKey, false);
            if (setEditing) setEditing(false);
        } catch (error) {
            const fieldNames = ['address'];
            const errorData = handleSaveErrors(error, fieldNames); 
            if (setShowErrorAlert) setShowErrorAlert(true)
            setErrorMessages(errorData);
            setUnsavedChanges(viewKey, true);
        } finally {
            if (setButtonLoader) setButtonLoader(false);
            // If the submitting is finished, reset the submitting status in the parent component
            if (fieldSubmitted) fieldSubmitted();
        }
    }

    // Executes the submit of the field, when the handleFieldSubmit property in the parent component is set to true
    useEffect(() => {
        if (handleFieldSubmit === true) handleFieldSubmitFunction();
    }, [handleFieldSubmit]);

    const content = (
        <>
            <label htmlFor={name}>
                {t(label)}
                {tooltipText && 
                    (alignment === 'stacked' || (alignment === 'horizontal' && !editing)) &&
                    <span className="tooltip-icon">
                        <FontAwesomeIcon icon={faCircleQuestion} />
                        <span className="tooltip">{t(tooltipText)}</span>
                    </span>
                }
                {alignment === 'horizontal' && !editing && !viewInEditMode && 
                    <span className='edit-icon'>
                        <FontAwesomeIcon icon={faPen} />
                    </span>
                }
            </label>
            <div>
                {showErrorAlert &&
                    <div className="alert form-alert alert-danger" role="alert">
                        {t(errorMessages.general, { defaultValue: errorMessages.general })}
                    </div>
                }
                {(editing || viewInEditMode) ? (
                    // Edit mode
                    <div className='edit-mode'>
                        {rows && rows.filter(row => row.deleted === false).map((row, index) => {
                            const identifier = row.id?.toString() || row.uuid || '';

                            return (
                                <div key={index} className='list-item'>
                                    <div className='single-field'>
                                        <SearchSelect 
                                            key={row.id}
                                            type='searchselect' 
                                            name='address'
                                            value={addressToFieldOption(row)}
                                            data={data}
                                            placeholder='location.general.search_location_label'
                                            objectName='location.general.object_name.singular'
                                            query={{ endpoint: 'get_location_list', params: { deleted: false }}}
                                            optionFormat={{ title: { field: 'address', format: (value) => value } }}
                                            selectionFormat='address'
                                            onSelect={selectedItem => {handleAddressSelection(selectedItem, identifier)}}
                                            onUnlink={() => {handleUnlink(identifier)}}
                                            addNewItem={
                                                addNewItem && addNewItem.linkedObject
                                                    ? {
                                                        method: "modal-form",
                                                        modalForm: addNewItem.modalForm,
                                                        linkedItem: { [addNewItem.linkedObject]: [parseInt(String(objectId))] }
                                                    }
                                                    : undefined
                                            }
                                            onPreventClose={(shouldPreventClose) => setPreventClosing(viewKey, shouldPreventClose)}
                                        />
                                        <div className='delete-icon'>
                                            <FontAwesomeIcon 
                                                icon={faTrash} 
                                                onClick={() => {handleRemoveItemFromObject(identifier)}} />
                                        </div>
                                    </div>
                                    {errorMessages[index]?.address && 
                                        <div className='error-message'>
                                            {t(errorMessages[index]?.address, { defaultValue: errorMessages[index]?.address })}
                                        </div>
                                    }
                                </div>
                            )
                        })}
                        <div onClick={(e) => {e.preventDefault(); handleAddRow(); }} 
                            className="add-new-button">
                            {t('forms.add_another')}
                        </div>
                        {helperText && 
                            <div className="helper-text">
                                {t(helperText)}
                            </div>
                        }
                    </div>
                ) : (
                    // View mode
                    <div className="view-mode">
                        <span className='p'>
                            {rows && rows.length > 0 ? (
                                <>
                                    <div className='item-list'>
                                        {rows.map((address, index) => (
                                            <p key={index}>
                                                {address.is_work_location ? (
                                                    // Only show worklocations as link, because other locations don't have a location page
                                                    <Link className='item-link' 
                                                          to={`/contacts/work-locations/${address.id}`}
                                                          key={address.id}>
                                                        {address.address}
                                                    </Link>
                                                ) : (
                                                    <span key={address.id}>{address.address}</span>
                                                )}
                                                {address.is_billing_address && (
                                                    <span className='type'> ({t('location.type.billing_short')})</span>
                                                )}
                                                {address.is_work_location && (
                                                    <span className='type'> ({t('location.type.worklocation')})</span>
                                                )}
                                            </p>
                                        ))}
                                    </div>
                                    {alignment === 'stacked' &&
                                        <span className='edit-icon'>
                                            <FontAwesomeIcon icon={faPen} />
                                        </span>
                                    }
                                </>
                            ) : (
                                <span className="no-value">
                                    <span className='minus-sign'>-</span>
                                    <span className="hover-text">{t('forms.add_value')}</span>
                                </span>
                            )}
                        </span>
                    </div>
                )}
            </div>
        </>
    )

    return (
        <div className='widget-field'>
            { alignment === 'horizontal' 
                ? <div className={`horizontal-alignment ${isEditable && !userHasOnlyViewRights ? 'editable' : ''} ${editing ? 'editing' : ''}`}>{content}</div> 
                : <div className={`stacked-alignment ${isEditable && !userHasOnlyViewRights ? 'editable' : ''}`}>{content}</div> 
            }
        </div>
    );
}

export default LocationsFieldset;