import React, { useRef, ReactNode, useEffect, useState } from 'react';
import { useDragDropContext } from './DragDropContext';

interface DroppableProps<DroppableId> {
    droppableId: DroppableId & { index?: number };
    children: ReactNode;
    placeholderStyle?: React.CSSProperties;
    showPlaceholder?: boolean;
    onDragOver?: (droppableId: DroppableId) => void;
}

export function Droppable<DroppableId>({
    droppableId,
    children,
    placeholderStyle,
    showPlaceholder = true,
    onDragOver
}: DroppableProps<DroppableId>) {
    const ref = useRef<HTMLDivElement>(null);
    const { dragging, updatePlaceholder, setDragOver } = useDragDropContext<any, DroppableId>();
    const [isOver, setIsOver] = useState(false);

    // Handle the mouse move
    const handleMouseMove = (event: MouseEvent) => {
        if (!ref.current || !dragging) return;

        const rect = ref.current.getBoundingClientRect();
        const isInside =
            event.clientX >= rect.left &&
            event.clientX <= rect.right &&
            event.clientY >= rect.top &&
            event.clientY <= rect.bottom;

        if (isInside) {
            if (!isOver) {
                setIsOver(true);
                updatePlaceholder(droppableId);

                setDragOver(droppableId);

                // Callback the droppable id on drag over
                if (onDragOver) {
                    onDragOver(droppableId);
                }
            }
    
            // Calculate the index based on the mouse position
            const childNodes = Array.from(ref.current.children);
            const mouseY = event.clientY;
    
            let index = childNodes.findIndex((child) => {
                const childRect = child.getBoundingClientRect();
                return mouseY < childRect.bottom;
            });
    
            // If the mouse is under all items, set the index to the last index
            if (index === -1) {
                index = childNodes.length;
            }
    
            // Update the index of the placeholder
            updatePlaceholder({ ...droppableId, index });
        } else {
            if (isOver) {
                setIsOver(false);
            }
        }
    };

    // Add mouse move event listeners
    useEffect(() => {
        window.addEventListener('mousemove', handleMouseMove);

        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
        };
    }, [isOver, dragging]);

    // Helper function to determine if the source area is the same as the current droppable area
    function isSameDroppable<DroppableId>(id1: DroppableId, id2: DroppableId): boolean {
        return JSON.stringify(id1) === JSON.stringify(id2);
    }

    // Determine if this is the active placeholder
    const isActivePlaceholder = dragging && isOver && !isSameDroppable(dragging.origin.droppableId, droppableId);

    // Get the child element
    const child = React.Children.only(children) as React.ReactElement;

    // Add the placeholder to the selected child element
    const enhancedChild = React.cloneElement(child, {
        ref,
        children: (
            <>
                {child.props.children}
                {isActivePlaceholder && showPlaceholder && (
                    <div
                        style={{
                            height: dragging?.origin?.rect?.height || '50px',
                            backgroundColor: '#d3d3d3',
                            margin: '0.25rem',
                            ...placeholderStyle,
                        }}
                    />
                )}
            </>
        ),
    });

    return enhancedChild;
};