import React, { useState } from 'react';
import { DragDropEvent, DragDropProvider } from 'services/dragdrop/DragDropContext';
import { SchedulerDraggableId, SchedulerDroppableId } from './SchedulingTypes';
import Scheduler from './Scheduling';

const DraggableScheduler: React.FC = () => {
    const [dragEvent, setDragEvent] = useState<DragDropEvent<SchedulerDraggableId, SchedulerDroppableId> | null>(null);

    return (
        <DragDropProvider<SchedulerDraggableId, SchedulerDroppableId>
            onDragEnd={(event) => setDragEvent(event)}>
            <Scheduler dragResult={dragEvent} />
        </DragDropProvider>
    )
}

export default DraggableScheduler;