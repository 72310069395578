// DealTypes

import { ContactType } from "../../views/contacts/ContactTypes";

export type PipelineType = {
    id: number;
    name: string;
    ordering: number;
    is_active: boolean;
    stages: StageType[];
}

export type StageType = {
    id: number;
    name: string;
    ordering: number;
    probability: number | null;
    deleted: boolean;
}

export type DealType = {
    id: number;
    created: string;
    modified: string;
    contact: number | ContactType;
    contact_person: number | ContactType | null;
    contact_name: string;
    pipeline: number;
    stage: number;
    stage_name: string;
    ordering: number;
    resolution: string;
    title: string;
    reference: string | null;
    source: number | null;
    value: string | null;
    win_chance: number | null;
    expected_close_date: string | null;
    close_date: string | null;
    lost_reason: number | null;
    labels: number[];
    deleted: boolean;
    division: number | null;
}

export interface PipelineStageType {
    id: number | null;
    uuid?: string;
    created: string | null;
    modified: string | null;
    pipeline: number;
    name: string;
    probability: number | null;
    ordering: number;
    deleted: boolean;
}

export const createNewPipelineStage = (pipelineId: number, uuid: string, ordering: number): PipelineStageType => ({
    id: null,
    uuid: uuid,
    created: null,
    modified: null,
    pipeline: pipelineId,
    name: '',
    probability: null,
    ordering: ordering,
    deleted: false
})