import React, { useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useAuthContext } from 'services/authentication/AuthenticationContext';
import CompanyDetailsForm from './CompanyDetailsForm';
import '../../style/scss/navigation.scss';

const CompanyDetailsPage: React.FC = () => {
    const { environmentHash } = useAuthContext();
    const viewKey = useMemo(() => uuidv4(), []);

    return (
        <div className='container-center align-center'>
            <div className='width-100'>
                <div className='container-filled'>
                    <div className='section'>
                        <div className='medium-width align-center'>
                            <CompanyDetailsForm
                                environmentHash={environmentHash} 
                                viewKey={viewKey} 
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CompanyDetailsPage;