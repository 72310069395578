import React from 'react';
import { TabsButtonType } from 'types/ButtonTypes';
import { useTranslation } from 'react-i18next';
import Tooltip from 'components/alerts/Tooltip';
import '../../style/scss/button.scss';
import '../../style/scss/tooltip.scss';

const TabsButton: React.FC<TabsButtonType> = ({ 
    buttons, onTabsClick, currentTabValue, size = 'medium', type = 'button', 
}) => {
    const { t } = useTranslation();

    // Set the size classes
    const buttonSizeClass = `button-${size}`;
    
    return (
        <div className='tabs-button'>
            {buttons.map((button, index) => {

                // Determine wheter the tab is active
                const isActive = button.tabValue === currentTabValue;

                return (
                    <button key={index}
                            type={type}
                            onClick={() => onTabsClick(button.tabValue)}
                            className={`button button-secondary tab-button ${buttonSizeClass} tooltip-hover ${isActive ? 'active-tab' : ''}`}>
                        {button.icon && 
                            button.icon
                        }
                        {button.label && 
                            <span className='icon-button-label'>{t(button.label)}</span>
                        }
                        {button.tooltipText &&
                            <Tooltip>{t(button.tooltipText)}</Tooltip>
                        }
                    </button>
                )
            })}
        </div>
    );
};

export default TabsButton;