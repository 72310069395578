import React, { useEffect, useState } from 'react';
import { TimelineItemType, TimelineTaskTypeType, TimelineUserType } from 'types/TimelineTypes';
import { fetchData } from 'services/api/fetchData';
import { websocketBaseUrl } from 'App';
import TimelineNoteForm from './TimelineNoteForm';
import TimelineTaskForm from './TimelineTaskForm';
import { GenerateTimelineComponents } from './GenerateTimelineComponents';
import { useAuthContext } from 'services/authentication/AuthenticationContext';
import { useTranslation } from 'react-i18next';

interface ApiResponse<TimelineItemType> {
    results: TimelineItemType[];
}

interface TimelineProps {
    viewKey: string;
    timeline_page_id: string;
    showOnlyTimelineUpdates?: boolean;
    timeline_page: string;
}

const Timeline: React.FC<TimelineProps> = ({ viewKey, timeline_page_id, showOnlyTimelineUpdates, timeline_page }) => {
    const { t } = useTranslation();
    const { environmentHash, handleLogout } = useAuthContext();
    const [loading, setLoading] = useState(true);
    const [activeButtonItem, setActiveButtonItem] = useState('');
    const [timelineItems, setTimelineItems] = useState<TimelineItemType[]>([]);
    const [users, setUsers] = useState<TimelineUserType[]>([]);
    const [taskTypes, setTaskTypes] = useState<TimelineTaskTypeType[]>([]);
    const [pinnedNote, setPinnedNote] = useState<TimelineItemType | null>(null);
    const [pinnedNoteIds, setPinnedNoteIds] = useState<number[]>([]);
    const [isNoteFormOpen, setIsNoteFormOpen] = useState(false);
    const [isTaskFormOpen, setIsTaskFormOpen] = useState(false);

    // Fetch the data of the timeline
    useEffect(() => {
        const fetchTimelineData = async () => {
            try {
                // Fetch timeline items
                const timelineItemsResponse: ApiResponse<TimelineItemType> = await fetchData({ 
                    apiUrl: `get_timeline_item_list/${timeline_page}/${timeline_page_id}`,
                    handleLogout 
                });
                const data: TimelineItemType[] = timelineItemsResponse.results;
                const timelineItems: TimelineItemType[] = data.map(item => ({
                    ...item,
                    timeline_object_type: 
                    item.update ? 'update'
                    : item.note ? 'note'
                    : item.task ? 'task' 
                    : 'email',
                }));

                // Fetch the users
                const userResponse: ApiResponse<TimelineUserType> = await fetchData({ apiObject: 'user', handleLogout });

                // Fetch the task types
                const taskTypeResponse: ApiResponse<TimelineTaskTypeType> = await fetchData({ apiObject: 'tasktype', handleLogout });

                // Find the pinned note in the fetched data
                const pinnedNote = timelineItems.find(item => item.note?.pinned === true);
                if (pinnedNote) {
                    setPinnedNote(pinnedNote);
                }

                // Sort the timeline items: unchecked tasks on top, ordered by date
                const sortedItems = sortTimelineItems(timelineItems);

                setUsers(userResponse.results);
                setTaskTypes(taskTypeResponse.results);
                setTimelineItems(sortedItems);

                // Hide loader when loading is finished
                setLoading(false);
            } catch (error) {
                console.error('Error fetching timeline data:', error)
            }
        };
        fetchTimelineData();
    }, [timeline_page_id]);

    // Sort the timeline items. Show unchecked tasks on top, show the other items below.
    const sortTimelineItems = (items: TimelineItemType[]): TimelineItemType[] => {
        return items.sort((a, b) => {
            // Assign score based on checked state
            const scoreA = (a.timeline_object_type === 'task' && !a.task?.checked) ? 2 : 1;
            const scoreB = (b.timeline_object_type === 'task' && !b.task?.checked) ? 2 : 1;
    
            // Sort based on score
            if (scoreA !== scoreB) return scoreA - scoreB;
    
            // If scores are equal, sort by ordering_date
            return new Date(a.ordering_date).getTime() - new Date(b.ordering_date).getTime();
        });
    }

    // Websocket connection to receive new timeline items
    useEffect(() => {
        let webSocket: WebSocket | null = null;
        let shouldReconnect = true;

        const setupWebSocket = () => {
            webSocket = new WebSocket(`${websocketBaseUrl}/timeline/${environmentHash}/`);

            webSocket.onmessage = (event) => {
                const message = JSON.parse(event.data);

                // If the message is about a deleted item, remove it from the timeline
                if (message.type === 'delete_timeline_item') {
                    setTimelineItems(prevState => prevState.filter(item => item.id !== message.instance_id));

                    // If the deleted item was a pinned note, also remove it from the pinned notes
                    if (pinnedNote && pinnedNote.id === message.instance_id) {
                        setPinnedNote(null);
                        setPinnedNoteIds(prevState => prevState.filter(id => id !== message.instance_id));
                    }

                // If the message is about a new or updated item, add it to the timeline
                } else if (message.type === 'updated_timeline_item') {

                    // Check if message.message is not null
                    if (message.message) {

                        // Only show timeline messages for this specific timeline page id
                        if (message.message.timeline_page_id === timeline_page_id) {

                            // Add the timeline object type to the received parsed item
                            const newTimelineItem: TimelineItemType = {
                                ...message.message,
                                timeline_object_type:
                                    message.message.update ? 'update'
                                    : message.message.note ? 'note'
                                    : message.message.task ? 'task' 
                                    : 'email',
                            };

                            // Set the timeline item from the received message
                            setTimelineItems((prevState) => {
                                // Delete all items with the same id from the current state
                                const filteredState = prevState.filter(item => item.id !== newTimelineItem.id);
                                
                                // Add the new item to the filtered state
                                const newState = [...filteredState, newTimelineItem];
                                
                                // Sort de newState and return it
                                return sortTimelineItems(newState);
                            });

                            // Check if the new item is a pinned note
                            if (newTimelineItem.note) {
                                if (newTimelineItem.note.pinned) {
                                    setPinnedNote(newTimelineItem);
                                    setPinnedNoteIds((prev) => [...prev, newTimelineItem.timeline_object_id]);
                                } else if (pinnedNote && newTimelineItem.timeline_object_id === pinnedNote.timeline_object_id) {
                                    setPinnedNote(null);
                                    setPinnedNoteIds((prev) => prev.filter(id => id !== newTimelineItem.timeline_object_id));
                                }
                            }
                        }                        
                    } else {
                        console.error('message.message is null:', message)
                    }
                }
            };

            webSocket.onclose = () => {
                if (shouldReconnect) {
                    // Try to reconnect in 10 seconds
                    setTimeout(setupWebSocket, 10000);
                }
            };
        }
        setupWebSocket();

        // Clear websocket by unmount
        shouldReconnect = false;
        return () => {
            if (webSocket) webSocket.close();
        };
    }, [pinnedNote]);

    // Opens or closes the note form
    const handleNoteForm = () => {
        if (isTaskFormOpen) {
            setIsTaskFormOpen(false);
        }
        setIsNoteFormOpen(prevIsNoteFormOpen => !prevIsNoteFormOpen);
        setActiveButtonItem(prevActive => prevActive === 'note' ? '' : 'note')
    };

    // Opens or closes the task form
    const handleTaskForm = () => {
        if (isNoteFormOpen) {
            setIsNoteFormOpen(false);
        }
        setIsTaskFormOpen(prevIsTaskFormOpen => !prevIsTaskFormOpen);
        setActiveButtonItem(prevActive => prevActive === 'task' ? '' : 'task')
    };

    // Opens or closes the email form
    const handleEmailForm = () => {
        // TO DO
    };

    // Reset the active button item
    const resetActiveButtonItem = () => {
        setActiveButtonItem('');
    }

    // Determine which note is pinned, so that it can be removed from the timeline, otherwise it will be duplicated
    useEffect(() => {
        if (pinnedNote) {
            setPinnedNoteIds((prevState) => {
                const currentNoteId = new Set(prevState);
                if (pinnedNote.note && pinnedNote.note.pinned) {
                    currentNoteId.add(Number(pinnedNote.timeline_object_id));
                } else {
                    currentNoteId.delete(Number(pinnedNote.timeline_object_id));
                }
                return Array.from(currentNoteId);
            });
        }
    }, [pinnedNote, timelineItems]);

    return (
        <div className='timeline'>
            {loading ? (
                <div className='timeline-loader'>
                    <div className="loader"></div>
                </div>
            ) : (
                <GenerateTimelineComponents 
                    viewKey={viewKey}
                    timelineItems={timelineItems}
                    showOnlyTimelineUpdates={showOnlyTimelineUpdates}
                    pinnedNoteIds={pinnedNoteIds}
                    pinnedNote={pinnedNote}
                    users={users}
                    taskTypes={taskTypes}
                    timeline_page_id={timeline_page_id}
                    timeline_page={timeline_page}>
                    {({ renderPinnedNote, timelineComponents }) => (
                        <>
                            {renderPinnedNote()}
                            {!showOnlyTimelineUpdates && 
                                <div className='buttonbar'>
                                    <ul>
                                        <li onClick={handleNoteForm}
                                            className={activeButtonItem === 'note' ? 'active' : ''}>
                                                {t('timeline.note.general.add_note_label')}
                                        </li>
                                        {/* TODO: Commented out. Need to be finished in a later stage. */}
                                        {/* <li onClick={handleTaskForm}
                                            className={activeButtonItem === 'task' ? 'active' : ''}>
                                                {t('timeline.task.general.add_task_label')}
                                        </li> */}
                                        {/* TODO: Commented out. Need to be finished in a later stage. */}
                                        {/* <li onClick={handleEmailForm}
                                            className={activeButtonItem === 'email' ? 'active' : ''}>
                                                {t('timeline.email.general.add_email_label')}
                                        </li> */}
                                    </ul>
                                    {!isNoteFormOpen && !isTaskFormOpen &&
                                        <div className='blank-note' onClick={handleNoteForm}></div>
                                    }
                                    {isNoteFormOpen && 
                                        <TimelineNoteForm 
                                            timeline_page_id={timeline_page_id}
                                            timeline_page={timeline_page}
                                            resetActiveButtonItem={resetActiveButtonItem}
                                            isNoteFormOpen={isNoteFormOpen} 
                                            setIsNoteFormOpen={setIsNoteFormOpen} />
                                    }
                                    {isTaskFormOpen && 
                                        <TimelineTaskForm
                                            viewKey={viewKey}
                                            timeline_page_id={timeline_page_id}
                                            timeline_page={timeline_page}
                                            resetActiveButtonItem={resetActiveButtonItem}
                                            isTaskFormOpen={isTaskFormOpen} 
                                            setIsTaskFormOpen={setIsTaskFormOpen} />
                                    }
                                </div>
                            }            
                            <div>
                                {timelineComponents}
                            </div>
                        </>
                    )}
                </GenerateTimelineComponents>
            )}
        </div>
    );
};

export default Timeline;