import React from 'react';
import { DetailCardType } from 'types/DetailPageTypes';
import { FieldType } from 'types/FieldTypes';
import { TabType } from 'types/TabTypes';
import DetailPage from 'components/layout/DetailPage';
import { HeaderButtons } from 'types/ButtonTypes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

// Rename the name of the function:
const DealDetail: React.FC = () => {

    // Specify the parameters of this detail page:
    const apiObject = 'deal'
    const pageObject = 'deal'

    // Specify the status bar config
    const statusBarConfig = {
        showStatusBar: true,
        apiFields: {
            workflowField: 'pipeline',
            statusesField: 'stages',
            statusField: 'stage',
            resolutionField: 'resolution'
        },
        changeStatusOnClick: true,
    }
    
    // Define the header title fields:
    const headerTitleFields: FieldType[] = [
        {
            name: 'title',
            type: 'text',
            placeholder: 'deal.general.title_placeholder',
            isEditable: { editability: false },
            saveOnBlur: true,
        },
    ]

    // Define the header buttons:
    const headerButtons: HeaderButtons = [
        // {   
        //     type: 'secondary',
        //     label: 'button.merge_button_label',
        //     action: 'merge',
        //     size: 'medium'
        // },
        {   
            type: 'icon',
            icon: <FontAwesomeIcon icon={faTrash} />,
            tooltipText: 'general.delete',
            action: 'delete',
            onSuccess: 'redirect',
            redirectTo: '/deals',
            size: 'medium'
        },
        {   
            type: 'secondary',
            label: 'deal.general.reopen_button_label',
            action: 'deal_reopen',
            restrictedRight: ['only_view'],
            condition: [{ field: 'resolution', values: ['won', 'lost'] }],
            size: 'medium'
        },
        {   
            type: 'primary',
            label: 'deal.general.lost_button_label',
            action: 'deal_change_resolution',
            actionValue: 'lost',
            restrictedRight: ['only_view'],
            customClass: 'lost',
            condition: [{ field: 'resolution', values: ['open'] }],
            size: 'medium'
        },
        {   
            type: 'primary',
            label: 'deal.general.won_button_label',
            action: 'deal_change_resolution',
            actionValue: 'won',
            restrictedRight: ['only_view'],
            customClass: 'won',
            condition: [{ field: 'resolution', values: ['open'] }],
            size: 'medium'
        },
    ]

    // Define the detail page tabs:
    const tabs: TabType[] = [ 
        { label: 'timeline.general.timeline_label', type: 'timeline', showOnlyTimelineUpdates: false },
        { label: 'proposal.general.proposals_label', type: 'proposals' },
        { label: 'document.general.documents_label', type: 'attachments' },
    ]

    // Define detail components and add fields to FieldGroup cards:
    const detailCards: DetailCardType[] = [
        {
            component: 'FieldsCard',
            title: 'deal.general.general_details_card_label',
            alignment: 'horizontal',
            fields: [
                { 
                    type: 'multiselect', 
                    name: 'labels', 
                    label: 'deal.general.labels_label', 
                    apiObject: 'label',
                    apiRequest: [{
                        endpoint: 'get_label_list',
                        object: 'label',
                        params: { is_active: true, connected_feature: 'deals' }
                    }],
                    allowNoneOption: false,
                    showSearch: false,
                    disabled_selected: 'deal.general.labels_disabled_selected',
                },
                {
                    type: 'text', 
                    name: 'reference', 
                    label: 'deal.general.reference_label', 
                    placeholder: 'deal.general.reference_placeholder', 
                },
                { 
                    type: 'price', 
                    name: 'value', 
                    label: 'deal.general.value_label', 
                    apiObject: 'currency',
                    roundPrice: true
                },
                { 
                    type: 'percentage', 
                    name: 'win_chance', 
                    label: 'deal.general.probability_label', 
                },
                {
                    type: 'date',
                    name: 'expected_close_date',
                    label: 'deal.general.expected_close_date_label',
                },
                {
                    type: 'date',
                    name: 'close_date',
                    label: 'deal.general.close_date_label',
                    dataCondition: [
                        { 'resolution': 'won' },
                    ],
                },
                {
                    type: 'date',
                    name: 'close_date',
                    label: 'deal.general.close_date_label',
                    dataCondition: [
                        { 'resolution': 'lost' },
                    ],
                },
                { 
                    type: 'dropdown', 
                    name: 'lost_reason', 
                    label: 'deal.general.lost_reason_label',
                    disabled_selected: 'deal.general.source_disabled_selected',
                    apiObject: 'lost_reason',
                    apiRequest: [{
                        endpoint: 'get_lost_reason_list',
                        object: 'lost_reason',
                        params: { deleted: false }
                    }],
                    allowNoneOption: false,
                    showSearch: true,
                    dataCondition: [
                        { 'resolution': 'lost' },
                    ],
                },
                { 
                    type: 'dropdown', 
                    name: 'source', 
                    label: 'deal.general.source_label',
                    apiObject: 'source',
                    apiRequest: [{
                        endpoint: 'get_source_list',
                        object: 'source',
                        params: { deleted: false }
                    }],
                    disabled_selected: 'deal.general.source_disabled_selected',
                    allowNoneOption: false,
                    showSearch: true,
                },
                { 
                    type: 'dropdown', 
                    name: 'owner', 
                    label: 'deal.general.owner_label',
                    apiObject: 'user',
                    apiRequest: [{
                        endpoint: 'get_user_list',
                        object: 'user',
                        params: { 
                            is_active: true,
                            office_access: true
                        }
                    }],
                    disabled_selected: 'deal.general.owner_disabled_selected',
                    selectionFormat: 'full_name',
                    optionFormat: (option) => option.full_name,
                    allowNoneOption: true,
                    showSearch: true,
                },
            ]
        },
        {
            component: 'LinkedDocumentsCard',
        },
        {
            component: 'ContactCard',
        }
    ]

    return (
        <DetailPage
            apiObject={apiObject}
            pageObject={pageObject}
            headerTitleFields={headerTitleFields}
            headerButtons={headerButtons}
            statusBar={statusBarConfig}
            detailCards={detailCards}
            tabs={tabs}
        />
    );
};

export default DealDetail;