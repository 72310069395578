import React from 'react';
import { ListColumnType } from 'types/ListTypes';
import DealPipelineForm from './DealPipelineForm';
import DealPipelineEditForm from './DealPipelineEditForm';
import AbstractList from 'components/lists/AbstractList';

// Rename the name of the function:
const DealPipelineList: React.FC = () => {

    // Specify the options of this list:
    const query = {
        endpoint: 'get_pipeline_list',
        defaultParams: { 'is_active': true },
        object: 'pipeline'
    }
    const objectName = 'deal.pipeline.general.object_name'
    const showSearch = false
    const showDeleteOrDeactivate = 'flag_deactivated'
    const isDraggable = true

    // Set isPaginated to true if this list may have 100.000+ items:
    const isPaginated = false

    // Specify form, detail modal or detailpage for this list
    const formModal = <DealPipelineForm />;
    const detailModal = <DealPipelineEditForm />;
    const onRowClick = 'detailModal'
    const detailModalSize = 'medium'

    // Specify the columns of this list:
    const columns: ListColumnType[] = [
        { width: '1fr', field: 'name', label: 'deal.general.pipeline_label' },
    ];

    return (
        <AbstractList
            query={query}
            objectName={objectName}
            columns={columns}
            isDraggable={isDraggable}
            showSearch={showSearch}
            showDeleteOrDeactivate={showDeleteOrDeactivate}
            formModal={formModal}
            detailModal={detailModal}
            detailModalSize={detailModalSize}
            isPaginated={isPaginated}
            onRowClick={onRowClick}
        />
    );
};

export default DealPipelineList;