import React, { useEffect, useMemo, useRef, useState } from 'react';
import { PipelineType, DealType, StageType } from '../../views/deal/DealTypes';
import { useFetchData } from 'services/api/useFetchData';
import PrimaryButton from 'components/buttons/PrimaryButton';
import DealForm from '../../views/deal/DealForm';
import { useTranslation } from 'react-i18next';
import { useModal } from 'components/modals/ModalContext';
import { useHistory } from 'react-router-dom';
import { useGlobalContext } from 'GlobalContext';
import useAdjustColumnsHeight from './functions/useAdjustColumnsHeight';
import DropdownButton from 'components/buttons/DropdownButton';
import TabsButton from 'components/buttons/TabsButton';
import '../../style/scss/kanban.scss';
import { useSettings } from 'services/settings/SettingsContext';
import { currencySymbols } from 'internationalization/currencySymbols';
import { parsePriceToStringOnUserLocale } from 'services/utils/parsePriceString';
import { DragDropEvent, useDragDropContext } from 'services/dragdrop/DragDropContext';
import { Droppable } from 'services/dragdrop/Droppable';
import { Draggable } from 'services/dragdrop/Draggable';
import { saveData } from 'services/api/saveData';
import { convertLocalDateTimeToUTC } from 'internationalization/timezoneConversions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faScaleUnbalanced } from '@fortawesome/pro-solid-svg-icons';

interface KanbanBoardProps {
    detailPageUrl: string;
    changeViewButton?: {
        buttons: {
            icon?: React.ReactNode;
            label?: string;
            tabValue: string;
            tooltipText?: string;
        }[];
        currentView: string;
        onViewChange: (view: string) => void;
    };
    completedDragEvent: DragDropEvent<KanbanCardDraggableId, KanbanDroppableId> | null;
}

export interface KanbanCardDraggableId {
    id: number;
}

export interface KanbanDroppableId {
    zone: string;
    column?: number;
    value?: string;
}

const KanbanBoard: React.FC<KanbanBoardProps> = ({ detailPageUrl, changeViewButton, completedDragEvent }) => {
    const { t } = useTranslation();
    const { isTopNavbarEnabled, setShowFooterLogo, setFloatingAlert } = useGlobalContext();
    const { initializeModal } = useModal();
    const { defaultCurrency, userLocale, userTimezone } = useSettings();
    const { isDragging } = useDragDropContext<KanbanCardDraggableId, KanbanDroppableId>();
    const [currentPipeline, setCurrentPipeline] = useState<PipelineType | null>(null);
    const dealFetchParams = useMemo(() => {return currentPipeline ? { pipeline_id: currentPipeline.id } : null}, [currentPipeline]);
    const { response: fetchedPipelines, loading: pipelinesLoading } = useFetchData({ apiUrl: 'get_pipeline_list' });
    const { response: fetchedDeals, loading: dealsLoading, refetch } = useFetchData({ useGuard: true, guardValue: currentPipeline, apiUrl: 'get_deal_list', params: dealFetchParams });    
    const history = useHistory();
    const [pipelines, setPipelines] = useState<PipelineType[]>([]);
    const [deals, setDeals] = useState<DealType[]>([]);
    const kanbanBoardRef = useRef(null);
    const kanbanColumnHeight = useAdjustColumnsHeight(kanbanBoardRef, isTopNavbarEnabled, isDragging);

    // Disable footer logo on first render
    useEffect(() => {
        setShowFooterLogo(false);
    }, []);

    // Set the pipelines from the fetched data
    useEffect(() => {
        if (fetchedPipelines) {
            setPipelines(fetchedPipelines.results);

            // Find the pipeline with the lowest ordering
            const defaultPipeline = fetchedPipelines.results.reduce(
                (lowest: PipelineType, pipeline: PipelineType) => {
                    return pipeline.ordering < lowest.ordering ? pipeline : lowest;
                },
                fetchedPipelines.results[0] as PipelineType
            );
            
            setCurrentPipeline(defaultPipeline);
        };
    }, [fetchedPipelines]);

    // Set the deals from the fetched data
    useEffect(() => {
        if (fetchedDeals) {
            setDeals(fetchedDeals.results);
        }
    }, [fetchedDeals]);

    // Adjust the columns height to fill the screen
    useAdjustColumnsHeight(kanbanBoardRef, isTopNavbarEnabled, isDragging);

    // Handle the pipeline change
    const handlePipelineChange = (pipelineId: number) => {
        const selectedPipeline = pipelines.find((pipeline) => pipeline.id === pipelineId);
        if (selectedPipeline) setCurrentPipeline(selectedPipeline);
    };

    // Calculate pipeline totals
    const pipelineTotals = useMemo(() => {
        if (!deals || !currentPipeline?.stages) return { count: 0, totalValue: 0, weightedValue: 0 };
    
        return deals.reduce(
            (acc, deal) => {
                // Only include deals which are open and not deleted
                if (deal.deleted || deal.resolution !== 'open') return acc;
    
                // Find the stage of the deal
                const stage = currentPipeline.stages.find((s) => s.id === deal.stage);
    
                // Get the probability of the stage. Fallback to 100% if no probability is set for this stage
                const probability = stage?.probability !== null && stage?.probability !== undefined 
                    ? stage.probability / 100 
                    : 1;
    
                // Determine the deal value
                const dealValue = deal.value ? parseFloat(deal.value) : 0;
    
                // Calculate the totals
                acc.count += 1;
                acc.totalValue += dealValue;
                acc.weightedValue += dealValue * probability;
    
                return acc;
            },
            { count: 0, totalValue: 0, weightedValue: 0 }
        );
    }, [deals, currentPipeline]);

    // Handle completion of the dragging of a card
    useEffect(() => {
        if (completedDragEvent) {
            const handleCardDrag = async () => {
                // Get the values from the completed drag event
                const { source, destination, draggableId } = completedDragEvent;

                // If there is no destination, do nothing
                if (!destination) return;
        
                // Find the deal index from the deals state
                const updatedDeals = [...deals];
                const dealIndex = updatedDeals.findIndex((deal) => deal.id === draggableId.id);
        
                // Return if the deal is not found
                if (dealIndex === -1) return;
        
                const draggedDeal = updatedDeals[dealIndex];
        
                // Update the deal if it is dragged to a resolution
                if (destination.droppableId.zone === 'resolution') {
                    if (destination.droppableId.value) {
                        draggedDeal.resolution = destination.droppableId.value;
                        setDeals(updatedDeals);
        
                        // Convert the current date time into utc based on the timezone of the user
                        const currentDateTime = new Date();
                        const utcDateTime = convertLocalDateTimeToUTC(currentDateTime, userLocale, userTimezone);
        
                        // Send the request
                        const response = await saveData({
                            apiUrl: `patch_deal/${draggedDeal.id}`,
                            method: 'patch',
                            data: {
                                resolution: draggedDeal.resolution,
                                close_date: utcDateTime
                            }
                        });
        
                        // Handle response
                        if (response && response.status === 200) {
                            // Show success alert
                            setFloatingAlert({
                                type: 'success',
                                message: `validation.deal.deal_${draggedDeal.resolution}_successfully`,
                            });
                        }
                    }
                    return;
                }
        
                // Update the deal if it is dragged to a different column
                if (source.droppableId?.column !== destination.droppableId.column) {
                    if (destination.droppableId.column) {
        
                        // Update the stage of the deal
                        draggedDeal.stage = destination.droppableId.column;
        
                        // Count the number of deals in the new column
                        const countedDestinationColumnDeals = updatedDeals.filter(
                            (deal) => deal.stage === destination.droppableId.column && !deal.deleted
                        ).length;
        
                        // Put the deal at the end of the new column
                        draggedDeal.ordering = countedDestinationColumnDeals + 1;
        
                        // Update the deals
                        setDeals(updatedDeals);
        
                        // Send the request
                        const response = await saveData({
                            apiUrl: `patch_deal/${draggedDeal.id}`,
                            method: 'patch',
                            data: { stage: draggedDeal.stage }
                        });
        
                        // Show success alert
                        if (response && response.status === 200) {
                            setFloatingAlert({ type: 'success' });
                        }
                    }
                    return;
                }
            };
            handleCardDrag();
        }
    }, [completedDragEvent]);

    return (
        <div ref={kanbanBoardRef} className="kanban-page">

            {/* Kanban navigation */}
            <div className="container kanban-navigation">
                <div className="content-left">
                    <PrimaryButton
                        onClick={() => initializeModal(
                            React.cloneElement(<DealForm />, { 
                                ...currentPipeline !== null ? { linkedItem: { pipeline: currentPipeline.id } } : {} 
                            }), {
                                title: t('button.add_object_label', { object_name: t(`deal.general.object_name.singular`) }),
                                onSuccess: () => refetch()
                            } 
                        )}
                        onlyViewRestriction={true}
                        label={t('button.plus_add_object_label', { object_name: t(`deal.general.object_name.singular`) })}
                        size="small"
                    />
                </div>
                <div className="content-right button-container">
                    <div className="kanban-totals">
                        {(() => {
                            // Get the currency symbol of the default currency
                            const currencySymbol = defaultCurrency ? currencySymbols[defaultCurrency.toLowerCase()] : null;

                            // Format the values
                            const formattedTotalValue = parsePriceToStringOnUserLocale(String(pipelineTotals.totalValue), userLocale, true);
                            const formattedWeightedValue = parsePriceToStringOnUserLocale(String(pipelineTotals.weightedValue), userLocale, true);

                            return (
                                <span>
                                    <span>{pipelineTotals.count} {t('deal.general.object_name.plural')} • </span>
                                    <span className='currency'>{currencySymbol}</span><span> {formattedTotalValue} • </span>
                                    <span className='weighted-value'>
                                        <FontAwesomeIcon 
                                            className='weighted-icon'
                                            icon={faScaleUnbalanced} 
                                        />
                                        <span className='currency'>{currencySymbol}</span> 
                                        <span> {formattedWeightedValue}</span>
                                    </span>
                                </span>
                            )
                        })()}
                    </div>
                    {changeViewButton && (
                        <TabsButton
                            buttons={changeViewButton?.buttons}
                            currentTabValue={changeViewButton?.currentView}
                            onTabsClick={(view) => changeViewButton?.onViewChange(view)}
                            size="small"
                        />
                    )}
                    <DropdownButton
                        label={currentPipeline ? `${t('deal.general.select_pipeline_prefix')} ${currentPipeline.name}` : t('deal.general.select_pipeline')}
                        activeItem={currentPipeline ? currentPipeline.name : ''}
                        size="small"
                        items={pipelines.map((pipeline) => ({
                            label: pipeline.name, onClick: () => handlePipelineChange(pipeline.id)
                        }))}
                    />
                </div>
            </div>

            {/* Kanban board */}
            <div className="kanban-board">
                {pipelinesLoading === 'show-loader' || dealsLoading === 'show-loader' ? (
                    <div className="loader"></div>
                ) : pipelinesLoading === 'success' && dealsLoading === 'success' && (

                    // Kanban columns
                    currentPipeline && currentPipeline.stages
                        ?.sort((a, b) => a.ordering - b.ordering)
                        .filter((stage) => !stage.deleted)
                        .map((stage: StageType) => {

                            const stageDeals = deals
                                ?.filter((deal) => deal.stage === stage.id && !deal.deleted && deal.resolution === 'open')
                                ?.sort((a, b) => a.ordering - b.ordering);

                            // Calculate the number of deals in this column
                            const dealCount = stageDeals?.length || 0;

                            // Calculate the total value of the deals in this column
                            const totalValue = stageDeals?.reduce((sum, deal) => {
                                return sum + (deal.value ? parseFloat(deal.value) : 0);
                            }, 0).toFixed(2) || "0";

                            // Calculate the weighted value of the deals in this column
                            const weightedValue = stageDeals?.reduce((sum, deal) => {
                                const dealValue = deal.value ? parseFloat(deal.value) : 0;
                                const stageProbability = stage.probability ? stage.probability / 100 : 1;
                                return sum + dealValue * stageProbability;
                            }, 0).toFixed(2) || "0";

                            // Format the values
                            const formattedTotalValue = parsePriceToStringOnUserLocale(totalValue, userLocale, true);
                            const formattedWeightedValue = parsePriceToStringOnUserLocale(weightedValue, userLocale, true);

                            // Get the currency symbol
                            const currencySymbol = defaultCurrency ? currencySymbols[defaultCurrency?.toLowerCase()] : null;
                    
                            return (
                                <div className="kanban-column"
                                     key={stage.id}>
                                    <div className='column-header'>
                                        <h6>{stage.name}</h6>
                                        <div className='stats'>
                                            <span className='deals-amount'>{dealCount} {t('deal.general.object_name.plural')} • </span>
                                            <span className='total-value'>
                                                <span className='currency'>{currencySymbol}</span> 
                                                <span> {formattedTotalValue}</span>
                                            </span>
                                            <span className='weighted-value'>
                                                <FontAwesomeIcon 
                                                    className='weighted-icon'
                                                    icon={faScaleUnbalanced} 
                                                />
                                                <span className='currency'>{currencySymbol}</span> 
                                                <span> {formattedWeightedValue} ({stage.probability}% {t('general.of')} <span className='currency'>{currencySymbol}</span>{formattedTotalValue})</span>
                                            </span>
                                        </div>
                                    </div>
                                    <Droppable droppableId={{ zone: 'kanban', column: stage.id }}>
                                        <div className="kanban-column-cards" 
                                                style={{ height: `${kanbanColumnHeight}px`}}>
                                            
                                            {/* Kanban cards */}
                                            {stageDeals?.map((deal, index) => {

                                                const currencySymbol = defaultCurrency ? currencySymbols[defaultCurrency?.toLowerCase()] : null;
                                                const formattedDealValue = deal.value ? parsePriceToStringOnUserLocale(deal.value, userLocale, true) : null;

                                                return (
                                                    <Draggable key={deal.id}
                                                               draggableId={{ id: deal.id }}
                                                               sourceDroppableArea={{ zone: 'kanban', column: stage.id }}
                                                               index={index}>
                                                        <div id={`kanban-card-${deal.id}`} 
                                                                className="kanban-card" 
                                                                onClick={() => history.push(`/${detailPageUrl}/${deal.id}`)}>
                                                            <div className='card-details'>
                                                                <h6>
                                                                    {deal.title}
                                                                </h6>
                                                                <div className='subtitle'>
                                                                    {deal.contact_name}
                                                                </div>
                                                            </div>
                                                            <div className='card-value'>
                                                                <div className='currency'>{currencySymbol} <span>{formattedDealValue}</span></div> 
                                                            </div>
                                                        </div>
                                                    </Draggable>
                                                )
                                            })}
                                        </div>
                                    </Droppable>
                                </div>
                            )
                        }
                    )
                )}
                {isDragging && (
                    <div className='resolution-footer'>
                        <div className='resolution-column'></div>
                        <Droppable droppableId={{ zone: 'resolution', value: 'lost' }} showPlaceholder={false}>
                            <div className="resolution-column lost">
                                {t('deal.general.lost_label')}
                            </div>
                        </Droppable>
                        <Droppable droppableId={{ zone: 'resolution', value: 'won' }} showPlaceholder={false}>
                            <div className="resolution-column won">
                                {t('deal.general.won_label')}
                            </div>
                        </Droppable>
                        <div className='resolution-column'></div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default KanbanBoard;