import React from 'react';
import { useSettings } from 'services/settings/SettingsContext';
import DetailModal from 'components/modals/DetailModal';
import { DetailCardType } from 'types/DetailPageTypes';
import { HeaderButtonsConfig } from 'types/ButtonTypes';
import { FieldOption } from 'types/FieldTypes';
import ContactForm from '../../views/contacts/ContactForm';
import PersonForm from '../../views/contacts/PersonForm';
import LocationForm from '../../views/contacts/LocationForm';

// Function to determine the client field link url based on its entity type
const determineLinkUrl = (recipient: any) => {
    if (recipient && recipient['entity_type'] && recipient['id']) {
        const baseUrl = recipient['entity_type'] === 'organization' ? '/contacts/organization' : '/contacts/person';
        return `${baseUrl}/${recipient['id']}`;
    }
    return null;
}

const filterLocationsOnRecipient = (recipient: any) => {
    if (recipient) {

        const contactFilterParam = { organization_id: recipient['id'] };

        console.log(contactFilterParam)

        return contactFilterParam;
    }
    return null;
}

// Rename the name of the function:
const InvoiceModal: React.FC<{ viewKey?: string, itemId?: number }> = ({ viewKey, itemId }) => {
    const { environmentSettings } = useSettings();

    // Specify the parameters of this detail modal:
    const apiObject = 'invoice';
    const object = 'invoice';
    const showStatusBar = false;

    // Define the header buttons of the modal:
    const headerButtons: HeaderButtonsConfig = {
        leftButtons: [
            {   
                type: 'secondary',
                label: 'button.download_pdf_button_label',
                action: 'download_pdf',
                objectType: 'invoice',
                allowedRight: ['can_download'],
                query: {
                    endpoint: 'get_pdf_layout_list/invoice',
                    object: 'pdf_layout',
                    defaultParams: { 'deleted': false },
                }
            },
            {   
                type: 'dropdown',
                label: 'button.more_button_label',
                buttons: [
                    {
                        label: 'button.create_credit_invoice_button_label',
                        action: 'create_credit_invoice',
                        restrictedRight: ['only_view'],
                    },
                    // {
                    //     label: 'button.duplicate_invoice_button_label',
                    //     action: 'duplicate',
                    //     restrictedRight: ['only_view'],
                    // },
                    {
                        label: 'button.delete_invoice_button_label',
                        action: 'delete',
                        restrictedRight: ['only_view'],
                        allowedRight: ['can_delete'],
                    }
                ]
            },

        ],
        rightButtons: [
            {   
                type: 'primary',
                label: 'button.send_invoice_button_label',
                action: 'send_invoice',
                restrictedRight: ['only_view'],
                customClass: 'sent_invoice',
                condition: [{ field: 'status', values: ['draft'] }]
            },
        // {   
        //     type: 'dropdown',
        //     label: 'button.send_invoice_button_label',
        //     buttons: [
        //         {
        //             label: 'button.send_invoice_reminder_button_label',
        //             action: 'send_invoice_reminder',
        //             restrictedRight: ['only_view'],
        //             condition: [{ field: 'status', values: ['overdue', 'reminded'] }]
        //         },
        //         {
        //             label: 'button.send_invoice_again_button_label',
        //             action: 'send_invoice',
        //             restrictedRight: ['only_view'],
        //             condition: [{ field: 'status', notValues: ['draft'] }]
        //         }
        //     ]
        // },
            {   
                type: 'secondary',
                label: 'button.register_payment_button_label',
                action: 'register_payment',
                restrictedRight: ['only_view'],
                condition: [{ field: 'status', notValues: ['draft', 'paid'] }]
            },
        ]
    }

    // Define the summary of the modal
    const summary = true;

    // Define the base fields of the modal
    const baseFields: DetailCardType[] = [
        {
            component: 'FieldsCard',
            title: 'invoice.general.recipient_header',
            alignment: 'horizontal',
            fieldColumns: [
                [
                    { 
                        type: 'searchselect', 
                        name: 'recipient',
                        label: 'invoice.general.recipient_label',
                        placeholder: 'invoice.general.recipient_placeholder',
                        objectName: 'contact.general.object_name.singular',
                        query: {
                            // Get all contacts which have the contact type 'contact', 'prospect' and 'client'
                            endpoint: 'get_contact_search',
                            params: {
                                contact_type: ['contact', 'prospect', 'client'] 
                            }
                        },
                        viewModeLinkUrl: { function: determineLinkUrl },
                        selectionFormat: 'name',
                        optionFormat: {
                            title: { field: 'name', format: (value) => value },
                            subtitle: { field: 'primary_location', format: (value) => value.address, optional: true},
                        },
                        addNewItem: { method: 'modal-form', modalForm: <ContactForm /> }
                    },
                    { 
                        type: 'searchselect', 
                        name: 'recipient_person',
                        label: 'invoice.general.recipient_person_label',
                        placeholder: 'invoice.general.recipient_person_placeholder',
                        objectName: 'person.general.object_name.singular',
                        query: {
                            // Only get persons and when a recipient is selected, get the persons connected to that contact
                            endpoint: 'get_person_search',
                            params: {
                                
                            }
                        },
                        dataCondition: [{'recipient.entity_type': 'organization'}],
                        viewModeLinkUrl: { baseUrl: '/contacts/person' },
                        selectionFormat: 'name',
                        optionFormat: {
                            title: { field: 'name', format: (value) => value },
                        },
                        addNewItem: { method: 'modal-form', modalForm: <PersonForm /> }
                    },
                    { 
                        type: 'searchselect', 
                        name: 'invoice_address',
                        label: 'invoice.general.invoice_address_label',
                        placeholder: 'invoice.general.invoice_address_placeholder',
                        objectName: 'location.general.object_name.singular',
                        query: {
                            // Get all active locations
                            // TODO: Get all locations and when a client is selected, get the primary locations connected to that contact
                            endpoint: 'get_location_search',
                            params: filterLocationsOnRecipient
                        },
                        postEndpoint: 'post_location',
                        selectionFormat: 'address',
                        optionFormat: {
                            title: { field: 'address', format: (value) => value },
                        },
                        addNewItem: { method: 'modal-form', modalForm: <LocationForm /> }
                    },
                ],
                [
                    { 
                        type: 'dropdown', 
                        name: 'workflow', 
                        label: 'invoice.general.workflow_label', 
                        apiObject: 'workflow',
                        apiRequest: [{
                            endpoint: 'get_workflow_list',
                            object: 'workflow',
                            params: { is_active: true, connected_feature: 'invoice' }
                        }],
                        selectionFormat: 'name',
                        optionFormat: (option: FieldOption) => option.name,
                        backendField: 'id',
                        // activeFeature: 'workflows',
                        disabled_selected: 'invoice.general.workflow_disabled_selected',
                        allowNoneOption: false,
                        showSearch: false,
                    },
                    { 
                        type: 'reference', 
                        name: 'reference',
                        label: 'invoice.general.reference_label',
                    },
                    // { 
                    //     type: 'multiselect', 
                    //     name: 'labels', 
                    //     label: 'invoice.general.labels_label', 
                    //     apiObject: 'label',
                    //     apiRequest: [{
                    //         endpoint: 'get_label_list',
                    //         object: 'label',
                    //         params: { is_active: true, connected_feature: 'invoices' }
                    //     }],
                    //     allowNoneOption: false,
                    //     showSearch: false,
                    //     disabled_selected: 'invoice.general.labels_disabled_selected',
                    // },
                    {
                        type: 'sent-date', 
                        name: 'invoice_date', 
                        label: 'invoice.general.invoice_date_label',
                        tooltipText: 'invoice.general.invoice_date_tooltipText',
                    },
                    {
                        type: 'expiration-days',
                        name: 'expiration_days',
                        label: 'invoice.general.due_date_label',
                        featureName: 'invoice'
                    }
                ]
            ]
        },
        {
            component: 'FieldsCard',
            title: 'invoice.general.lines_header',
            fields: [
                { 
                    name: 'lines',
                    type: 'document-lines',
                    featureName: 'invoice',
                    documentId: itemId,
                    apiRequest: [
                        {
                            endpoint: 'get_vatrate_list',
                            object: 'vatrate',
                            params: { is_active: true }
                        },
                        {
                            endpoint: 'get_ledgeraccount_list',
                            object: 'ledgeraccount',
                            params: { is_active: true, ledger_account_type: 'revenue' }
                        },
                        {
                            endpoint: 'get_currency_list',
                            object: 'currency',
                            params: {}
                        }
                    ]
                }
            ]
        },
    ]

    // Define the optional tax blocked account field
    const taxBlockedAccountField: DetailCardType | null =
        environmentSettings?.invoice_show_tax_blocked_acount ? {
            component: 'FieldsCard',
            title: 'invoice.general.tax_blocked_account_text_label',
            alignment: 'stacked',
            fields: [
                { 
                    type: 'text', 
                    name: 'tax_blocked_account_text', 
                    emptyLabel: 'invoice.general.tax_blocked_account_text_emptyLabel',
                    placeholder: 'invoice.general.tax_blocked_account_text_placeholder', 
                },
            ]
        } : null

    // Define the terms and conditions field
    const termsAndConditionsField: DetailCardType = {
        component: 'FieldsCard',
        title: 'invoice.general.terms_and_conditions_header',
        alignment: 'stacked',
        fields: [
            { 
                type: 'textarea', 
                name: 'terms_and_conditions', 
                emptyLabel: 'invoice.general.terms_and_conditions_emptyLabel',
                placeholder: 'invoice.general.terms_and_conditions_placeholder', 
                enableStyling: 'simple',
                showToolbarInitially: true,
                rows: 3,
            },
        ]
    }

    // Combine the sections
    const sections: DetailCardType[] = [
        ...baseFields,
        ...(taxBlockedAccountField ? [taxBlockedAccountField] : []),
        termsAndConditionsField,
    ];

    return (
        <DetailModal 
            viewKey={viewKey}
            objectType={object}
            apiObject={apiObject}
            itemId={itemId}
            headerButtons={headerButtons}
            statusBar={showStatusBar}
            sections={sections}
            summary={summary}
        />
    );
};

export default InvoiceModal;