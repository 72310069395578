import React from 'react';
import { useFilteredDropdownOptionsByFeature } from 'services/permissions/permissionChecks';
import { FieldType } from 'types/FieldTypes';
import FormModal from 'components/modals/FormModal';

// Rename the name of the function:
const WorkflowEditForm: React.FC<{ viewKey?: string }> = ({ viewKey }) => {

    const apiObject = 'workflow'

    // Plug in the fields and their properties:
    const fields: FieldType[] = [
        {
            name: 'name',
            label: 'workflow.general.name_label',
            type: 'text',
            placeholder: 'workflow.general.name_placeholder',
            width: '50%',
            group: 'field-row-1',
        },
        { 
            type: 'dropdown', 
            name: 'connected_feature', 
            label: 'workflow.general.connected_feature_label', 
            disabled_selected: 'forms.select_type_disabled_selected',
            allowNoneOption: false,
            showSearch: false,
            width: '50%',
            group: 'field-row-2',
            hardcodedOptions: useFilteredDropdownOptionsByFeature([
                { value: 'proposal', name: 'workflow.connected_feature.proposal', requiredFeature: 'proposals' },
                { value: 'order', name: 'workflow.connected_feature.order', requiredFeature: 'proposals' },
                { value: 'job', name: 'workflow.connected_feature.job', requiredFeature: 'jobs' },
                { value: 'invoice', name: 'workflow.connected_feature.invoice', requiredFeature: 'invoices' },
            ]),
        },
        {
            name: 'is_default',
            label: 'workflow.general.is_default_label',
            type: 'checkbox',
            width: '50%',
            group: 'field-row-3',
        },

        // Invoice workflow details
        {
            type: 'divider',
            dependentOn: { fieldName: 'connected_feature', value: ['invoice'] },
        },
        {
            type: 'header',
            header: 'workflow.general.invoice_settings_header',
            dependentOn: { fieldName: 'connected_feature', value: ['invoice'] },
        },
        {
            name: 'invoice_expiration_days',
            type: 'expiration-days',
            featureName: 'invoice',
            placeholder: 'workflow.general.expiration_placeholder',
            dependentOn: { fieldName: 'connected_feature', value: ['invoice'] },
            group: 'field-row-4',
            width: '50%',
        },
        {
            name: 'invoice_default_vat_method',
            type: 'dropdown',
            label: 'workflow.general.vat_method_label',
            tooltipText: 'workflow.general.invoice_vat_method_tooltipText',
            hardcodedOptions: [
                { name: 'document_lines.general.vat_excluded_label', value: 'vat_excluded' },
                { name: 'document_lines.general.vat_included_label', value: 'vat_included' },
            ],
            defaultValue: { value: 'vat_excluded' },
            allowNoneOption: false,
            showSearch: false,
            dependentOn: { fieldName: 'connected_feature', value: ['invoice'] },
            group: 'field-row-5',
            width: '33%',
        },
        {
            name: 'invoice_default_price_display_mode',
            type: 'dropdown',
            label: 'workflow.general.price_display_mode_label',
            tooltipText: 'workflow.general.invoice_price_display_mode_tooltipText',
            hardcodedOptions: [
                { name: 'document_lines.price_display_mode.line_prices', value: 'line_prices' },
                { name: 'document_lines.price_display_mode.total_price', value: 'total_price' },
            ],
            defaultValue: { value: 'line_prices' },
            allowNoneOption: false,
            showSearch: false,
            dependentOn: { fieldName: 'connected_feature', value: ['invoice'] },
            group: 'field-row-6',
            width: '33%',
        },
        {
            type: 'header',
            header: 'workflow.general.invoice_is_partial_header',
            description: 'workflow.general.invoice_is_partial_description',
            dependentOn: { fieldName: 'connected_feature', value: ['invoice'] },
        },
        {
            name: 'is_partial_invoice',
            label: 'workflow.general.invoice_is_partial_label',
            type: 'checkbox',
            align: 'left',
            group: 'field-row-7',
            width: '50%',
            dependentOn: { fieldName: 'connected_feature', value: ['invoice'] },
        },
        {
            name: 'partial_percentage',
            label: 'workflow.general.invoice_partial_percentage_label',
            type: 'percentage',
            width: '20%',
            dependentOn: [
                { fieldName: 'connected_feature', value: ['invoice'] },
                { fieldName: 'is_partial_invoice', value: true }
            ],
        },
        {
            type: 'header',
            header: 'workflow.general.default_invoice_line_text_label',
            description: 'workflow.general.default_invoice_line_text_description',
            dependentOn: { fieldName: 'connected_feature', value: ['invoice'] },
        },
        {
            name: 'use_default_invoice_line',
            label: 'workflow.general.use_default_invoice_line_label',
            type: 'checkbox',
            align: 'left',
            width: '50%',
            group: 'field-row-8',
            dependentOn: { fieldName: 'connected_feature', value: ['invoice'] },
        },
        {
            name: 'default_invoice_line_text',
            type: 'textarea',
            placeholder: 'workflow.general.default_invoice_line_text_placeholder',
            rows: 2,
            enableStyling: 'simple',
            showToolbarInitially: true,
            dependentOn: [
                { fieldName: 'connected_feature', value: ['invoice'] },
                { fieldName: 'use_default_invoice_line', value: true }
            ],
        },
        {
            type: 'header',
            header: 'workflow.general.default_invoice_conditions_header',
            description: 'workflow.general.default_invoice_conditions_description',
            dependentOn: { fieldName: 'connected_feature', value: ['invoice'] },
        },
        {
            name: 'default_invoice_conditions',
            type: 'textarea',
            placeholder: 'workflow.general.default_invoice_conditions_placeholder',
            rows: 3,
            enableStyling: 'simple',
            showToolbarInitially: true,
            dependentOn: { fieldName: 'connected_feature', value: ['invoice'] },
        },

        // Proposal workflow details
        {
            type: 'divider',
            dependentOn: { fieldName: 'connected_feature', value: ['proposal'] },
        },
        {
            type: 'header',
            header: 'workflow.general.proposal_settings_header',
            dependentOn: { fieldName: 'connected_feature', value: ['proposal'] },
        },
        {
            name: 'proposal_expiration_days',
            type: 'expiration-days',
            featureName: 'proposal',
            placeholder: 'workflow.general.expiration_placeholder',
            dependentOn: { fieldName: 'connected_feature', value: ['proposal'] },
            width: '50%',
        },
        {
            name: 'proposal_default_vat_method',
            type: 'dropdown',
            label: 'workflow.general.vat_method_label',
            tooltipText: 'workflow.general.proposal_vat_method_tooltipText',
            hardcodedOptions: [
                { name: 'document_lines.general.vat_excluded_label', value: 'vat_excluded' },
                { name: 'document_lines.general.vat_included_label', value: 'vat_included' },
            ],
            defaultValue: { value: 'vat_excluded' },
            allowNoneOption: false,
            showSearch: false,
            dependentOn: { fieldName: 'connected_feature', value: ['proposal'] },
            group: 'field-row-5',
            width: '33%',
        },
        {
            name: 'proposal_default_price_display_mode',
            type: 'dropdown',
            label: 'workflow.general.price_display_mode_label',
            tooltipText: 'workflow.general.proposal_price_display_mode_tooltipText',
            hardcodedOptions: [
                { name: 'document_lines.price_display_mode.line_prices', value: 'line_prices' },
                { name: 'document_lines.price_display_mode.total_price', value: 'total_price' },
            ],
            defaultValue: { value: 'line_prices' },
            allowNoneOption: false,
            showSearch: false,
            dependentOn: { fieldName: 'connected_feature', value: ['proposal'] },
            group: 'field-row-6',
            width: '33%',
        },
        {
            type: 'header',
            header: 'workflow.general.default_proposal_introduction_header',
            description: 'workflow.general.default_proposal_introduction_description',
            dependentOn: { fieldName: 'connected_feature', value: ['proposal'] },
        },
        {
            name: 'default_proposal_introduction',
            type: 'textarea',
            placeholder: 'workflow.general.default_proposal_introduction_placeholder',
            rows: 3,
            enableStyling: 'simple',
            showToolbarInitially: true,
            dependentOn: { fieldName: 'connected_feature', value: ['proposal'] },
        },
        {
            type: 'header',
            header: 'workflow.general.default_proposal_conditions_header',
            description: 'workflow.general.default_proposal_conditions_description',
            dependentOn: { fieldName: 'connected_feature', value: ['proposal'] },
        },
        {
            name: 'default_proposal_conditions',
            type: 'textarea',
            placeholder: 'workflow.general.default_proposal_conditions_placeholder',
            rows: 3,
            enableStyling: 'simple',
            showToolbarInitially: true,
            dependentOn: { fieldName: 'connected_feature', value: ['proposal'] },
        },

        // Order workflow details
        {
            type: 'divider',
            dependentOn: { fieldName: 'connected_feature', value: ['order'] },
        },
        {
            type: 'header',
            header: 'workflow.general.order_settings_header',
            dependentOn: { fieldName: 'connected_feature', value: ['order'] },
        },
        {
            name: 'order_default_vat_method',
            type: 'dropdown',
            label: 'workflow.general.vat_method_label',
            tooltipText: 'workflow.general.order_vat_method_tooltipText',
            hardcodedOptions: [
                { name: 'document_lines.general.vat_excluded_label', value: 'vat_excluded' },
                { name: 'document_lines.general.vat_included_label', value: 'vat_included' },
            ],
            defaultValue: { value: 'vat_excluded' },
            allowNoneOption: false,
            showSearch: false,
            dependentOn: { fieldName: 'connected_feature', value: ['order'] },
            group: 'field-row-5',
            width: '33%',
        },
        {
            name: 'order_default_price_display_mode',
            type: 'dropdown',
            label: 'workflow.general.price_display_mode_label',
            tooltipText: 'workflow.general.order_price_display_mode_tooltipText',
            hardcodedOptions: [
                { name: 'document_lines.price_display_mode.line_prices', value: 'line_prices' },
                { name: 'document_lines.price_display_mode.total_price', value: 'total_price' },
            ],
            defaultValue: { value: 'line_prices' },
            allowNoneOption: false,
            showSearch: false,
            dependentOn: { fieldName: 'connected_feature', value: ['order'] },
            group: 'field-row-6',
            width: '33%',
        },
        {
            type: 'header',
            header: 'workflow.general.default_order_introduction_header',
            description: 'workflow.general.default_order_introduction_description',
            dependentOn: { fieldName: 'connected_feature', value: ['order'] },
        },
        {
            name: 'default_order_introduction',
            type: 'textarea',
            placeholder: 'workflow.general.default_order_introduction_placeholder',
            rows: 3,
            enableStyling: 'simple',
            showToolbarInitially: true,
            dependentOn: { fieldName: 'connected_feature', value: ['order'] },
        },
        {
            type: 'header',
            header: 'workflow.general.default_order_conditions_header',
            description: 'workflow.general.default_order_conditions_description',
            dependentOn: { fieldName: 'connected_feature', value: ['order'] },
        },
        {
            name: 'default_order_conditions',
            type: 'textarea',
            placeholder: 'workflow.general.default_order_conditions_placeholder',
            rows: 3,
            enableStyling: 'simple',
            showToolbarInitially: true,
            dependentOn: { fieldName: 'connected_feature', value: ['order'] },
        },
    ];

    return (
        <FormModal
            viewKey={viewKey}
            apiObject={apiObject}
            fields={fields}
        />
    );
};

export default WorkflowEditForm;