import React, { useRef, ReactNode } from 'react';
import { useDragDropContext } from './DragDropContext';

interface DraggableProps<DraggableId, DroppableId> {
    draggableId: DraggableId;
    sourceDroppableArea: DroppableId;
    children: ReactNode;
    index?: number;
}

export function Draggable<DraggableId, DroppableId>({ 
    draggableId, 
    sourceDroppableArea,
    children,
    index
}: DraggableProps<DraggableId, DroppableId>) {
    const ref = useRef<HTMLDivElement>(null);
    const { startDrag, setDraggingVisual } = useDragDropContext<DraggableId, DroppableId>();

    // Handle mouse down when clicking on a draggable item
    const handleMouseDown = (event: React.MouseEvent) => {

        // Only listen to the left mouse button
        if (event.button !== 0) {
            return;
        }
    
        // Only continue if the selected html element is known
        if (!ref.current) {
            return;
        }
    
        // Prevent text selection on drag
        event.preventDefault();
    
        const rect = ref.current.getBoundingClientRect();
        const offsetX = event.clientX - rect.left;
        const offsetY = event.clientY - rect.top;
    
        let dragStarted = false;
    
        // Set the drag timer
        const dragTimer = setTimeout(() => {
            dragStarted = true;
    
            // Copy the selected html element into the dragging visual
            const visual = ref.current!.cloneNode(true) as HTMLElement;

            // Copy the styles of the selected html element
            copyComputedStyles(ref.current!, visual);
      
            // Apply the styles of the selected html element into the dragging visual
            visual.style.position = 'fixed';
            visual.style.left = `${rect.left}px`;
            visual.style.top = `${rect.top}px`;
            visual.style.pointerEvents = 'none';
            visual.style.zIndex = '1000';
            visual.style.transition = 'none';
    
            document.body.appendChild(visual);
    
            // Temporarily hide the original html element, because now the cloned dragging visual is shown
            ref.current!.style.visibility = 'hidden';

            // Set the dragging visual in the state to show it
            setDraggingVisual(visual);

            // Call the start drag function in the drag drop context
            startDrag(draggableId, { droppableId: sourceDroppableArea, rect }, ref.current, index);
    
            // Add mouse move handler
            const handleMouseMove = (moveEvent: MouseEvent) => {

                // Prevent text selection while dragging
                moveEvent.preventDefault();
                visual.style.left = `${moveEvent.clientX - offsetX}px`;
                visual.style.top = `${moveEvent.clientY - offsetY}px`;
            };
    
            // Add mouse up handler
            const handleMouseUp = () => {
                if (dragStarted) {
                    ref.current!.style.visibility = 'visible';
                    document.body.removeChild(visual);
                    setDraggingVisual(null);
                }
    
                window.removeEventListener('mousemove', handleMouseMove);
                window.removeEventListener('mouseup', handleMouseUp);
            };
    
            window.addEventListener('mousemove', handleMouseMove);
            window.addEventListener('mouseup', handleMouseUp);

            // Wait 150 ms before starting the drag, to be sure its a drag action and no click
        }, 150);
    
        // Cancel the drag timer if the user releases the mouse click early
        const handleMouseUpEarly = () => {
            clearTimeout(dragTimer);
            window.removeEventListener('mouseup', handleMouseUpEarly);
        };
    
        window.addEventListener('mouseup', handleMouseUpEarly);
    };

    const isReactElement = (child: ReactNode): child is React.ReactElement => {
        return React.isValidElement(child);
    };

    return isReactElement(children)
    ? React.cloneElement(children, {
          ref,
          onMouseDown: handleMouseDown,
          style: {
              ...(children.props.style || {}),
              userSelect: 'none',
          },
      })
    : null;

    // // Return the element
    // return React.cloneElement(children as React.ReactElement, {
    //     ref,
    //     onMouseDown: handleMouseDown,

    //     // Prevent text selection by css
    //     style: { 
    //         ...(children?.props.style || {}),
    //         userSelect: 'none' 
    //     },
    // });
}

// Helper function to copy the styles of the original selected element to the dragging visual element
function copyComputedStyles(source: HTMLElement, target: HTMLElement) {
    const computedStyle = getComputedStyle(source);
    Array.from(computedStyle).forEach((key) => {
        target.style.setProperty(key, computedStyle.getPropertyValue(key));
    });

    // Make sure sub elements are also copied
    Array.from(source.children).forEach((child, index) => {
        if (target.children[index]) {
            copyComputedStyles(child as HTMLElement, target.children[index] as HTMLElement);
        }
    });
};