import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { DropdownFieldType, FieldOptionFetchResponse, FieldData, FieldOption } from 'types/FieldTypes';
import FormFieldContext from '../FormFieldContext';
import { useGlobalContext } from 'GlobalContext';
import CustomDropdown from './CustomDropdown';
import FieldWrapper from '../FieldWrapper';
import FieldViewMode from './elements/FieldViewMode';
import '../../../style/scss/live-edit.scss';
import '../../../style/scss/forms.scss';
import '../../../style/scss/tooltip.scss';

interface DropdownFieldProps extends DropdownFieldType {
    name: string;
    onChange?: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void;
    viewInEditMode?: boolean;
    dropdownData?: Record<string, FieldOptionFetchResponse>;
    errorMessage?: string | string[] | undefined;
    showIfFilled?: boolean;
}

const DropdownField: React.FC<DropdownFieldProps & { data: FieldData, viewKey: string }> = ({ 
    viewKey, name, label, disabled_selected, data, hardcodedOptions, helperText, tooltipText, 
    apiObject, onChange, dropdownData, viewInEditMode, allowNoneOption, showIfFilled, selectionFormat, 
    optionFormat, defaultValue, alignment, showSearch, isEditable, disabled, saveOnBlur, backendField
}) => {
    const { t } = useTranslation();
    const { editing, updatedData, setUpdatedData, handleSubmit } = useContext(FormFieldContext);
    const { errorMessages, setUnsavedChanges, unsavedChangesMap } = useGlobalContext();
    const [value, setValue] = useState('');
    const [selectedOption, setSelectedOption] = useState<FieldOption | null>(null);
    const [options, setOptions] = useState<FieldOption[]>([]);
    const hasUnsavedChanges = unsavedChangesMap[viewKey];
    const [isSelectedNone, setIsSelectedNone] = useState(false);

    // Set dropdown options from the fetched dropdown data array
    useEffect(() => {
        if (apiObject && dropdownData && dropdownData[apiObject]) {
            setOptions(dropdownData[apiObject].results);

            if (backendField) {
                const selectedOption = dropdownData[apiObject].results.find(option => 
                    option[backendField] === data[name] ||
                    option[backendField] === data[name][backendField]
                );
                if (selectedOption) {
                    setSelectedOption(selectedOption);
                }
            } else {
                const selectedOption = dropdownData[apiObject].results.find(option => option.id === data[name]);
                if (selectedOption) {
                    setSelectedOption(selectedOption);
                }
            }
        } else if (hardcodedOptions) {
            setOptions(hardcodedOptions)
            if (data) {
                const selectedOption = hardcodedOptions.find(option => option.value === data[name]);
                if (selectedOption) {
                    setSelectedOption(selectedOption);
                }
            }
        }
    }, [dropdownData, apiObject, data, name])

    // If a default value is given, set it as the selected option
    useEffect(() => {
        if (defaultValue?.value && options.length > 0 && defaultValue.value !== value && !data[name]) {
            let defaultOption;
            // If the options are hardcoded, determine the defaultoption on the value of the option
            if (hardcodedOptions) {
                defaultOption = hardcodedOptions.find(option => option.value === defaultValue.value);
                if (defaultOption) {
                    setSelectedOption(defaultOption);
                    setValue(defaultValue.value)
                    setUpdatedData({ ...updatedData, [name]: defaultValue.value})
                }
            // If the options are fetched, determine the default option from them
            } else if (options.length > 0) {
                // Determine the default option on the given api field of the option
                if (defaultValue.apiField && defaultValue.apiField !== null) {
                    defaultOption = options.find((option: FieldOption) => option[defaultValue.apiField!] === defaultValue.value)
                // If no api field is given, determine the default option on the id of the option
                } else {
                    defaultOption = options.find((option: FieldOption) => option.id === Number(defaultValue.value));
                }

                // If a defaultOption is found, update the state
                if (defaultOption) {
                    setSelectedOption(defaultOption);
                    setValue(defaultValue.value);
                    setUpdatedData({ ...updatedData, [name]: defaultOption.id})
                }
            }
        }
    }, [defaultValue, options, hardcodedOptions, data, name]);

    // Shows the current selected option in the dropdown field
    useEffect(() => {
        // Handle the selection of the none option
        if (isSelectedNone) {
            setSelectedOption({ name: t('forms.none_option'), id: 'none', value: 'none' });
            setValue('none');
            return
        }

        // Determines the current value of the field, taking into account updated data
        let currentValue: string | null = null;

        if (hardcodedOptions) {
            // Determine the current value in case of hard coded options
            if (updatedData[name]) {
                currentValue = updatedData[name];
            } else if (data && data[name]) {
                // If no updated data, initialize with the current hard coded option from the backend data
                const matchingOption = hardcodedOptions.find(option => option.value === data[name]);
                currentValue = matchingOption ? matchingOption.value as string : null;
            } else {
                currentValue = null;
            }
        } else {
            // Determine the current value for non-hardcoded options
            currentValue = hasUnsavedChanges && updatedData[name] ? updatedData[name] : data[name];
        }

        // Finds the selected option based on the current value
        const selectedOption = hardcodedOptions
            ? hardcodedOptions.find(option => option.value === currentValue)
            : options.find(option => option.id === Number(currentValue));
    
        // Sets the found option as the selected option
        if (selectedOption) {
            setSelectedOption(selectedOption);
            if (currentValue !== null) {
                setValue(currentValue);
            }
        }
    }, [data, name, options, updatedData, hardcodedOptions, hasUnsavedChanges, isSelectedNone]);

    // Don't show the field if it only may be visible if it contains a value
    if (showIfFilled && (!value || value === '')) {
        return null;
    }

    // Updates the value after changing the dropdown selection
    const handleDropdownChange = (selectedValueOrId: string) => {
        // Set selected option to none
        if (selectedValueOrId === 'none') {
            setIsSelectedNone(true);
            setUnsavedChanges(viewKey, true);
            setValue('none');
            setSelectedOption({ name: t('forms.none_option'), id: 'none', value: 'none' });
            setUpdatedData({...updatedData, [name]: null});

        // Updates value if another value is selected
        } else {
            setValue(selectedValueOrId);

            let selectedOption: FieldOption | undefined;
            // If the options are hardcoded, determine the selected value on the value of the option
            if (hardcodedOptions) {
                selectedOption = hardcodedOptions.find(option => option.value === selectedValueOrId);
            // If the options are fetched, determine the selected value on the id of the option
            } else {
                if (backendField) {
                    selectedOption = options.find((option: FieldOption) => option[backendField] === selectedValueOrId);
                } else {
                    selectedOption = options.find((option: FieldOption) => option.id === selectedValueOrId);
                }
            }

            if (selectedOption) {
                setSelectedOption(selectedOption);
            }

            setUnsavedChanges(viewKey, String(selectedValueOrId) !== String(data[name]));
            setUpdatedData({...updatedData, [name]: selectedValueOrId});
            
            if (onChange) {
                onChange({
                    target: {
                        value: `${selectedValueOrId}`
                    }
                } as React.ChangeEvent<HTMLInputElement>);
            }
        }

        if (saveOnBlur && handleSubmit) {
            handleSubmit({ [name]: selectedValueOrId })
        }
    }

    // Get the error message from the errorState
    const errorMessage = errorMessages[name];

    const fieldValue = selectedOption ? selectedOption[selectionFormat ? selectionFormat : 'name'] : '';

    return (
        <FieldWrapper
            name={name}
            label={label}
            tooltipText={tooltipText}
            helperText={helperText}
            isEditable={isEditable}
            disabled={disabled}
            viewInEditMode={viewInEditMode}
            alignment={alignment}>
            {(editing || viewInEditMode) ? (
                // Edit mode
                <CustomDropdown 
                    options={options}
                    selectedOption={selectedOption || null}
                    value={value}
                    onChange={handleDropdownChange}
                    disabled_selected={disabled_selected}
                    disabled={disabled}
                    showSearch={showSearch}
                    selectionFormat={selectionFormat}
                    optionFormat={optionFormat}
                    errorMessage={errorMessage}
                    allowNoneOption={allowNoneOption}
                    backendField={backendField}
                />
            ) : (
                // View mode
                <FieldViewMode 
                    value={fieldValue} 
                    alignment={alignment}
                />
            )}
        </FieldWrapper>
    );
};

export default DropdownField;