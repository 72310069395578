import React, { useEffect, useState } from 'react';
import { TabType } from 'types/TabTypes';
import { useTranslation } from 'react-i18next';
import Timeline from 'components/timeline/Timeline';
import PdfLayoutExample from '../../views/settings/pdf/PdfLayoutExample';
import JobDetailList from '../../views/jobs/JobDetailList';
import AttachmentDetailList from '../../views/attachment/AttachmentDetailList';
import ProposalDetailList from '../../views/proposals/ProposalDetailList';

interface TabBarProps {
    tabs: TabType[];
    viewKey?: string;
    itemId?: string;
    pageContext?: string;
    reloadTabs?: number;
}

export const TabBar = ({ tabs, viewKey, itemId, pageContext, reloadTabs }: TabBarProps) => {
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState(0);

    // Sets the first tab to active
    useEffect(() => {
        if (setActiveTab && typeof activeTab === 'undefined') {
            setActiveTab(0);
        }
    }, [setActiveTab, activeTab]);

    // Generates the tab components
    const tabComponents = tabs.map((tab, index) => {
        let component = tab.component;
        if (!component && tab.type && viewKey) {
            switch(tab.type) {
                case 'timeline':
                    component = itemId && pageContext ? <Timeline key={index} viewKey={viewKey} timeline_page_id={itemId} showOnlyTimelineUpdates={tab.showOnlyTimelineUpdates} timeline_page={pageContext || ''} /> : null;
                    break;
                case 'proposals': 
                    component = itemId && pageContext ? <ProposalDetailList key={index} itemId={itemId} detailPage={pageContext} /> : null;
                    break;
                case 'jobs': 
                    component = itemId && pageContext ? <JobDetailList key={index} itemId={itemId} detailPage={pageContext} /> : null;
                    break;
                case 'pdf-layout':
                    component = itemId ? <PdfLayoutExample itemId={itemId} reloadPdf={reloadTabs ? reloadTabs : null} /> : null;
                    break;
                case 'attachments':
                    component = itemId && pageContext ? <AttachmentDetailList key={index} detailPageId={itemId} detailPage={pageContext} /> : null;
                    break;
                // Add other tab components here
            }
        }

        // Sets the tab label and set the active tab
        const label = (
            <li key={index} 
                onClick={() => setActiveTab(index)}
                className={index === activeTab ? "active" : ""}>
                {t(tab.label)}
            </li>
        );
    
        // Renders the label and the component
        return {
            label,
            component: index === activeTab ? component : null
        };
    });

    const tabLabel = tabComponents.map(tab => tab.label);
    const tabComponent = tabComponents.map(tab => tab.component);

    return { tabLabel, tabComponent };
};