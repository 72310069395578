import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { PeopleCardProps } from 'types/DetailPageTypes';
import { ContactType } from '../../views/contacts/ContactTypes'; 
import { useTranslation } from 'react-i18next';
import { useFetchData } from 'services/api/useFetchData';
import { useGlobalContext } from 'GlobalContext';
import { useModal } from 'components/modals/ModalContext';
import { useAllowedRight } from 'services/permissions/permissionChecks';
import LinkItemModal from 'components/modals/LinkItemModal';
import PersonForm from '../../views/contacts/PersonForm';
import IconButton from 'components/buttons/IconButton';
import Tooltip from 'components/alerts/Tooltip';
import { saveData } from 'services/api/saveData';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faXmark } from '@fortawesome/free-solid-svg-icons';
import '../../style/scss/card.scss';

const PeopleCard: React.FC<PeopleCardProps> = ({ 
    itemId, loadStatus, isEditable
}) => {
    const { t } = useTranslation();
    const { setFloatingAlert } = useGlobalContext();
    const { initializeModal } = useModal();
    const { response, loading, refetch } = useFetchData({ apiUrl: `get_person_search/${itemId}` });
    const [people, setPeople] = useState<ContactType[]>([]);
    const [personIds, setPersonIds] = useState<number[]>([]);
    const hasRightCheck = useAllowedRight;
    const [userHasOnlyViewRights] = useState<boolean>(hasRightCheck('only_view'));

    // Set the fetched people
    useEffect(() => {
        if (response) {
            // Set the people from the response
            setPeople(response.results)

            // Extract the ids from the persons
            setPersonIds(response.results.map((person: ContactType) => person.id));
        }
    }, [response]);

    // Handle unlink of an item
    const handleUnlink = async (personId: number) => {

        // Remove the unlinked person from the personIds
        const updatedPersonIds = personIds.filter(id => id !== personId);
  
        // Save the data to the server      
        await saveData({ 
            apiUrl: `patch_organization/${itemId}`, 
            method: 'patch', 
            data: { ['primary_persons']: updatedPersonIds } 
        });

        // Remove the unlinked person from the local people data
        setPeople(prevPeople => prevPeople.filter(person => person.id !== personId));

        // Update the person ids state
        setPersonIds(updatedPersonIds);

        // Show success alert
        setFloatingAlert({ 'type': 'success' });
    };

    return (
        <div className='card linked-items-card'>
            <div className='header'>
                <h6>
                    {t('person.general.people_label')}
                    {people && people.length > 0 && (
                        <span> ({people.length})</span>
                    )}    
                </h6>
                {isEditable && !userHasOnlyViewRights && (
                    <IconButton
                        onClick={() => {
                            initializeModal(
                                <LinkItemModal  
                                    itemId={parseInt(itemId)}
                                    searchFieldConfig={{
                                        name: 'person',
                                        placeholder: 'person.general.link_person_placeholder',
                                        objectName: 'person.general.object_name.singular',
                                        endpoint: 'get_person_search',
                                        addNewItem: { modalForm: <PersonForm /> }
                                    }}
                                    submitConfig={{ 
                                        endpoint: 'patch_organization', 
                                        apiField: 'primary_persons',
                                        existingSelectedIds: personIds
                                    }}
                                    refetchData={() => refetch()}
                                />, { 
                                    title: t('forms.link_title', { object_name: t('person.general.object_name.singular') }),
                                    modalSize: 'small' 
                                }
                            )
                        }}
                        size='extra-small' 
                        icon={<FontAwesomeIcon icon={faPlus} />}
                        tooltipText={t('forms.link_title', { object_name: t('person.general.object_name.singular') })}
                    /> 
                )}
            </div>
            {loadStatus === "loading" || loading === "show-loader" ? (
                <div className="loader"></div>
            ) : loadStatus === "loaded" && loading === "success" && (
                people.map((contact) => (
                    <div className='card-list-item'>
                        <div>
                            <Link className='card-link' 
                                to={`/contacts/person/${contact.id}`}
                                key={contact.id}>
                                {contact.name}
                            </Link>
                            {contact.entity_type === 'person' && contact.role && <span> ({contact.role})</span>}
                        </div>
                       {!userHasOnlyViewRights && (
                            <div className='unlink-icon tooltip-icon'>
                                <FontAwesomeIcon 
                                    icon={faXmark} 
                                    onClick={() => {handleUnlink(contact.id)}}/>
                                <Tooltip>{t('Ontkoppel')}</Tooltip>
                            </div>
                       )}
                    </div>
                ))
            )}
        </div>
    );
}

export default PeopleCard;