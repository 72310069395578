import React from 'react';
import { FieldType } from 'types/FieldTypes';
import FormModal from 'components/modals/FormModal';

// Rename the name of the function:
const DealPipelineForm: React.FC<{ viewKey?: string }> = ({ viewKey }) => {

    const apiObject = 'pipeline'

    // Plug in the fields and their properties:
    const fields: FieldType[] = [
        {
            name: 'name',
            label: 'deal.general.pipeline_name_label',
            type: 'text',
            placeholder: 'deal.general.pipeline_name_placeholder',
        },
    ];

    return (
        <FormModal 
            viewKey={viewKey}
            apiObject={apiObject}
            fields={fields}
        />
    );
};

export default DealPipelineForm;