import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { useAuthContext } from 'services/authentication/AuthenticationContext';
import { FieldOptionFetchResponse, FieldType } from 'types/FieldTypes';
import SwitchButton from 'components/buttons/SwitchButton';
import FormFieldContext from 'components/forms/FormFieldContext';
import { EnvironmentSettingsType } from 'services/settings/SettingsTypes';
import { generateFieldComponents } from 'components/forms/GenerateFieldComponents';
import { saveData } from 'services/api/saveData';
import { useGlobalContext } from 'GlobalContext';
import { useFetchData } from 'services/api/useFetchData';
import '../../style/scss/settings.scss';
import { fetchDropdownDataFromFields } from 'services/api/fetchFieldData';

export interface SettingsSettingType {
    title: string;
    switchButton?: {
        enabled: boolean;
        name: string; 
    };
    explanationText?: string;
    requiredFeature?: string;
    dependentOn?: { fieldName: string, value: string | string[] | boolean };
    fields?: FieldType[];
}

export interface SettingsContainerType {
    settings: SettingsSettingType[]
}

const SettingsContainer: React.FC<SettingsContainerType> = ({ settings }) => {
    const { t } = useTranslation();
    const viewKey = useMemo(() => uuidv4(), []);
    const { packageFeatures, environmentHash, handleLogout } = useAuthContext();
    const { setFloatingAlert } = useGlobalContext();
    const { response: fetchedEnvironmentSettings, loading } = useFetchData({ apiUrl: `get_environment_settings/${environmentHash}` });
    const [data, setData] = useState<EnvironmentSettingsType>();
    const [dropdownData, setDropdownData] = useState<Record<string, FieldOptionFetchResponse>>({});
    const [editing, setEditing] = useState(false);
    const [updatedData, setUpdatedData] = useState<Record<string, any>>({});
    const [buttonLoader, setButtonLoader] = useState(false);
    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const [toggleSettings, setToggleSettings] = useState<Record<string, boolean>>({});

    // Set the fetched settings in the data
    useEffect(() => {
        if (fetchedEnvironmentSettings) setData(fetchedEnvironmentSettings);
    }, [fetchedEnvironmentSettings]);

    // Fetch the dropdown data if an api object is given for the field
    useEffect(() => {
        const fetchDropdownData = async () => {
            const dropdownData: Record<string, FieldOptionFetchResponse> = {};
            for (let setting of settings) {
                const fields = setting.fields || [];

                for (let field of fields) {
                    if (field.type === 'dropdown' && field.apiObject) {
                        const results = await fetchDropdownDataFromFields(field.apiObject, field.params, handleLogout);
                        dropdownData[field.apiObject] = results;
                    };
                };
            }
            setDropdownData(dropdownData);
        };
        fetchDropdownData();
    }, []);

    // Render setting items based on its dependent on value
    const showSetting = (setting: SettingsSettingType): boolean => {
        if (data && setting.dependentOn) {
            const { fieldName, value } = setting.dependentOn;

            const fieldKey = fieldName as keyof EnvironmentSettingsType;

            // Check if the dependent field exists in the data or updated data
            const dependentFieldExists = (data && fieldKey in data) || (updatedData && fieldKey in updatedData);

            if (!dependentFieldExists) {
                return false;
            } else {
                // Check for the most recent value between updated data and fetched data
                let dependentFieldValue = updatedData[fieldKey] ?? data[fieldKey];

                // Determine if the setting has to be shown
                if (typeof value === 'boolean') {
                    if (dependentFieldValue !== value) {
                        return false;
                    }
                } else if (Array.isArray(value)) {
                    return Array.isArray(dependentFieldValue)
                        ? value.some(val => dependentFieldValue.includes(val))
                        : value.includes(dependentFieldValue);
                } else {
                    return Array.isArray(dependentFieldValue)
                        ? dependentFieldValue.includes(value)
                        : dependentFieldValue === value;
                }
            }
        }
        return true;
    };    

    // Handle the toggling of fields if the setting switch is on or off
    const handleToggle = (name: string, isOn: boolean) => {
        setToggleSettings(prev => ({...prev, [name]: isOn }));
    };

    // Render the fields
    const renderFields = useCallback((fields: FieldType[]) => {
        if (viewKey && fetchedEnvironmentSettings) {
            // Set the view in edit mode and save on blur parameters to true for all fields
            const setFields = fields.map(field => ({ ...field, viewInEditMode: true, saveOnBlur: true }));

            // Generate the fields
            return generateFieldComponents(viewKey, setFields, dropdownData, undefined, fetchedEnvironmentSettings, updatedData)
        }
    }, [fetchedEnvironmentSettings, dropdownData, updatedData]);

    // Handle submit
    const handleSubmit = async (newData?: Record<string, any>) => {
        // Data to submit is either the given new data or else the updated data
        const submitData = newData || updatedData;

        try {
            await saveData({ apiUrl: `patch_environment_settings/${environmentHash}`, method: 'patch', data: submitData });
            setFloatingAlert({ type: 'success' })
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <FormFieldContext.Provider value={{ editing, setEditing, updatedData, setUpdatedData, buttonLoader, 
            setButtonLoader, showErrorAlert, setShowErrorAlert, handleSubmit }}>
            <div className='container-filled'>
                {loading === 'show-loader' ? (
                    <div className='inline-form-loader'>
                        <div className="loader"></div>
                    </div>
                ) : loading === 'success' ? (
                    <div className='section settings-form'>
                        {settings.map((setting, index) => {
                            // Determine if the setting is disabled because the feature isn't included in the current package
                            const isSettingDisabled = setting.requiredFeature ? !packageFeatures.includes(setting.requiredFeature) : false;

                            // Determine to show the setting in case its dependent on a value of another setting
                            if (showSetting(setting)) {
                                return (
                                    <div key={index} className='setting-item'>
                                        {setting.switchButton && (
                                            <div className='switch-button-wrapper'>
                                                <SwitchButton
                                                    key={setting.switchButton.name}
                                                    isDisabled={isSettingDisabled}
                                                    name={setting.switchButton.name}
                                                    data={data}
                                                    apiUrl={`patch_environment_settings/${environmentHash}`}
                                                    onToggle={(isOn) => handleToggle(setting.title, isOn)}
                                                />
                                            </div>
                                        )}
                                        <div>
                                            <div className={`title ${setting.switchButton ? 'switch' : ''} `}>
                                                <h5>{t(setting.title)}</h5>
                                                {isSettingDisabled && (
                                                    // If the setting is disabled, show upgrade badge
                                                    <Link className='upgrade-badge' 
                                                        to="/settings/billing/plan">
                                                        {t('billing.upgrade_badge_label')}
                                                    </Link>
                                                )}
                                            </div>
                                            <div className='content'>
                                                {(!setting.switchButton || toggleSettings[setting.title]) && 
                                                    // Render tab fields on top of the explanation text. If the setting has a switch button, only show the field if the switch is turned on
                                                    renderFields(setting.fields?.filter(field => field.type === 'tabs') || [])
                                                }
                                                {setting.explanationText && (
                                                    // Render an explanation text if it is set
                                                    <div className='col-100'>
                                                        <div className='col-70'>
                                                            <p className='explanation-text'>
                                                                {t(setting.explanationText)}
                                                            </p>
                                                        </div>
                                                    </div>
                                                )}
                                                {(!setting.switchButton || toggleSettings[setting.title]) && 
                                                    // Render other fields below the explanation text. If the setting has a switch button, only show the field if the switch is turned on
                                                    renderFields(setting.fields?.filter(field => field.type !== 'tabs') || [])
                                                }
                                            </div>
                                        </div>
                                    </div>
                                );
                            }
                            return null;
                        })}
                    </div>
                ): null}
            </div>
        </FormFieldContext.Provider>
    );
};

export default SettingsContainer;