import React, { useEffect, useState } from 'react';
import { useGlobalContext } from 'GlobalContext';
import { WorkflowStatus } from '../../views/settings/workflows/WorkflowWizard';
import { useTranslation } from 'react-i18next';
import { saveData } from 'services/api/saveData';
import '../../style/scss/status-bar.scss';

interface StatusBarProps {
    statuses: WorkflowStatus[];
    currentStatus?: number | null;
    pageId?: number;
    resolution?: string | null;
    statusClickable?: boolean; // Change the status by clicking on it
    refetchData?: () => void,
}

interface StatusBarStep {
    stepNumber: number;
    statuses: WorkflowStatus[];
    activeClassName?: string;
    statusId?: number | null;
    currentOrdering?: number | null;
}

// TODO: refactor this in the future by Statuses and SubSteps
const mapConditionsToStatusSteps: Record<string, number> = {
    'job_created': 1,
    'one_open_purchase': 1,
    'all_purchases_delivered': 1,
    'one_reserved_appointment': 2,
    'one_scheduled_appointment': 2,
    'running_travel_timer': 3,
    'running_work_timer': 3,
    'incomplete': 4,
    'completed': 5,
    'finished': 6,
};

const StatusBar: React.FC<StatusBarProps> = ({ 
    statuses, currentStatus, pageId, resolution, statusClickable, refetchData 
}) => {
    const { t } = useTranslation();
    const { setFloatingAlert } = useGlobalContext();
    const [statusBarSteps, setStatusBarSteps] = useState<StatusBarStep[]>([]);

    // Render the status steps, based on its condition or ordering
    useEffect(() => {
        const groupStatusesByStep: StatusBarStep[] = [];
        let currentCondition: string = '';
        let currentOrdering: number | null = null;
    
        // Determine the current status object for the condition or ordering
        const currentStatusObj = statuses.find(status => status.id === currentStatus);
        if (currentStatusObj) {
            currentCondition = currentStatusObj.condition || '';
            currentOrdering = currentStatusObj.ordering || null;
        }
    
        // Group the statuses per step (based on condition or ordering)
        statuses.forEach(status => {
            const stepNumber = status.condition
                ? mapConditionsToStatusSteps[status.condition] || 1
                : status.ordering || 1;
    
            const existingStep = groupStatusesByStep.find(s => s.stepNumber === stepNumber);
    
            if (existingStep) {
                existingStep.statuses.push(status);
            } else {
                groupStatusesByStep.push({
                    stepNumber,
                    statuses: [status],
                    statusId: status.ordering ? status.id : null,
                    currentOrdering: currentOrdering
                });
            }
        });
    
        // Sort the steps on stepnumber
        groupStatusesByStep.sort((a, b) => a.stepNumber - b.stepNumber);
    
        // Mark the steps until the current status as active
        groupStatusesByStep.forEach(step => {

            // Activate steps based on condition
            if (currentCondition) {
                const maxStep = mapConditionsToStatusSteps[currentCondition] || 0;
                if (step.stepNumber <= maxStep) {
                    step.activeClassName = currentCondition;
                }
            }
    
            // Activate steps based on ordering, if the object does not contain a condition
            if (!currentCondition && currentOrdering !== null) {

                if (step.stepNumber <= currentOrdering) {
                    step.activeClassName = 'active';

                    if (step.stepNumber === currentOrdering) {
                        step.activeClassName = 'active current'
                    }
                }
            }
        });
    
        setStatusBarSteps(groupStatusesByStep);
    }, [statuses, currentStatus]);
    
    // Handle status change by click
    const handleStatusChange = async (statusId?: number | null) => {

        // Only continue if the status is clickable and the status id is given
        if (statusClickable && statusId) {

            // Only update the status if the resolution is not lost
            if (resolution !== 'lost' && resolution !== 'won') {

                // Save the status change
                const response = await saveData({
                    apiUrl: `patch_deal/${pageId}`,
                    method: 'patch',
                    data: { stage: statusId }
                });

                // Handle successful response
                if (response && response.status === 200) {

                    // Show success alert
                    setFloatingAlert({ type: 'success' });

                    // Refetch the data
                    if (refetchData) {
                        refetchData();
                    }
                };
            }
        }
    };

    // Determine the status bar classname
    const statusBarClassName = `status-bar ${statusClickable ? `status-clickable` : ``} ${resolution ? resolution : ``}`;

    return (
        <ol className={statusBarClassName}>
            {statusBarSteps.map((step) => (
                <li key={step.stepNumber} 
                    className={`status-step ${step.activeClassName || ''}`}
                    onClick={() => {if (step.stepNumber !== step.currentOrdering) handleStatusChange(step.statusId)}}>
                    {step.statuses.map((status) => (
                        
                        // If the statuses are clickable, show every status name on hover. Don't show it when the resolution is lost
                        statusClickable ? (
                            <span className={`status-name ${status.id === currentStatus ? 'current-status' : ''}`} key={status.id}>
                                {t(`workflow.default_status_names.${status.name}`, { defaultValue: status.name })}
                            </span>

                        // If the statuses are not clickable, only show the status name in the current step
                        ) : (
                            status.id === currentStatus && (
                                <span className='status-name' key={status.id}>
                                    {t(`workflow.default_status_names.${status.name}`, { defaultValue: status.name })}
                                </span>
                            )
                        ) 
                    ))}
                </li>
            ))}
        </ol>
    );
};

export default StatusBar;