import React from 'react';
import SettingsContainer, { SettingsSettingType } from '../../../components/settings/SettingsContainer';
import { useSettings } from 'services/settings/SettingsContext';
import { FieldOption } from 'types/FieldTypes';
import { languageOptions, localeOptions } from 'internationalization/i18n';

const GeneralSettings: React.FC = () => {
    const { environmentSettings } = useSettings();

    // Function to convert hardcoded options into dropdown options
    function convertOptionsToDropdownOptions(options: Record<string, { label: string }>): FieldOption[] {
        return Object.entries(options).map(([value, { label }]) => ({
            value,
            name: label,
        }))
    };
    
    // Convert language and locale options into dropdown options
    const languageDropdownOptions: FieldOption[] = convertOptionsToDropdownOptions(languageOptions);
    const localeDropdownOptions: FieldOption[] = convertOptionsToDropdownOptions(localeOptions);


    const settings: SettingsSettingType[] = [
        {
            title: 'Standaard valuta',
            fields: [
                {
                    type: 'dropdown',
                    name: 'default_currency',
                    disabled_selected: 'forms.general_disabled_selected',
                    allowNoneOption: false,
                    saveOnBlur: true,
                    apiObject: 'currency',
                    backendField: 'key',
                    defaultValue: { value: environmentSettings?.default_currency || 'EUR' }
                },
            ]
        },
        {
            title: 'Standaard taal',
            fields: [
                {
                    type: 'dropdown',
                    name: 'default_language',
                    disabled_selected: 'forms.general_disabled_selected',
                    allowNoneOption: false,
                    saveOnBlur: true,
                    hardcodedOptions: languageDropdownOptions,
                    defaultValue: { value: environmentSettings?.default_language }
                },
            ]
        },
        {
            title: 'Standaard datum-/getalnotatie',
            fields: [
                {
                    type: 'dropdown',
                    name: 'default_locale',
                    disabled_selected: 'forms.general_disabled_selected',
                    allowNoneOption: false,
                    saveOnBlur: true,
                    hardcodedOptions: localeDropdownOptions,
                    defaultValue: { value: environmentSettings?.default_locale }
                },
            ]
        },
    ];

    return (
        <SettingsContainer settings={settings} />
    );
};

export default GeneralSettings;