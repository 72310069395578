import React, { useContext, useState, useEffect } from 'react';
import { addDays, parseISO } from 'date-fns';
import { NumberFieldType, FieldData } from 'types/FieldTypes';
import FormFieldContext from '../FormFieldContext';
import { useGlobalContext } from 'GlobalContext';
import { useSettings } from 'services/settings/SettingsContext';
import { useTranslation } from 'react-i18next';
import FieldViewMode from './elements/FieldViewMode';
import FieldWrapper from '../FieldWrapper';
import '../../../style/scss/live-edit.scss';
import '../../../style/scss/forms.scss';
import '../../../style/scss/tooltip.scss';

interface NumberFieldProps extends NumberFieldType {
    name: string;
    onChange?: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void;
    viewInEditMode?: boolean;
    shouldAutoFocus?: boolean;
    errorMessage?: string | string[] | undefined;
}

const ExpirationDaysField: React.FC<NumberFieldProps & { data: FieldData, viewKey: string }> = ({ 
    viewKey, name, label, data, helperText, tooltipText, onChange, viewInEditMode, shouldAutoFocus,
    min, max, disabled, alignment, isEditable, featureName
}) => {
    const { t } = useTranslation();
    const { editing, updatedData, setUpdatedData, handleSubmit } = useContext(FormFieldContext);
    const { errorMessages, setErrorMessages, setUnsavedChanges } = useGlobalContext();
    const { userLocale } = useSettings();
    const [value, setValue] = useState('');
    const [displayValue, setDisplayValue] = useState('');
    const [dueDate, setDueDate] = useState<Date | null>(null);

    console.log("expirationDays", data[name])

    useEffect(() => {
        if (dueDate) console.log("due date", dueDate)
    }, [dueDate])

    // Calculate the expiration/due date, based on the sent date
    useEffect(() => {
        if (data && data[name] !== undefined) {

            // For invoices, calculate the due date based on the invoice date
            if (featureName === 'invoice') {
                // Only continue if the invoice date field exists in the data
                if (data['invoice_date'] !== undefined || data['invoice_date'] === null) {

                    // Retrieve invoice date and expiration days
                    const invoiceDate = data['invoice_date'] ? parseISO(data['invoice_date']) : null;
                    const expirationDays = data[name];

                    // Calculate due date
                    const calculatedDueDate = invoiceDate

                        // If the invoice date exists, add the number of days
                        ? addDays(invoiceDate, expirationDays)

                        // If the invoice date is null, add the number of days from today
                        : addDays(new Date(), expirationDays);

                    // Set the calculated due date
                    setDueDate(calculatedDueDate);
                }
            }

            // For proposals, calculate the expiration date based on the proposal date
            if (featureName === 'proposal') {
                // Only continue if the proposal date field exists in the data
                if (data['proposal_date'] !== undefined || data['proposal_date'] === null) {

                    // Retrieve proposal date and expiration days
                    const proposalDate = data['proposal_date'] ? parseISO(data['proposal_date']) : null;
                    const expirationDays = data[name];

                    // Calculate due date
                    const calculatedDueDate = proposalDate

                        // If the proposal date exists, add the number of days
                        ? addDays(proposalDate, expirationDays)

                        // If the proposal date is null, add the number of days from today
                        : addDays(new Date(), expirationDays);

                    // Set the calculated due date
                    setDueDate(calculatedDueDate);
                }
            }            
        }
    }, [data, name, featureName]);

    // Updates the value when the data is later received then the initialization of the useState
    useEffect(() => {
        if (data && data[name] !== undefined) {
            const formattedValue = formatForInput(String(data[name]));
            if (formattedValue !== value) {
                setValue(formattedValue);
                setDisplayValue(formatForDisplay(formattedValue));
            }
        }
    }, [data, name]);

    // Formats the displayed input by inserting dots every 3 digits, and separating the decimal part by a comma
    function formatForDisplay(input: string) {
        // Removes all dots from the input
        const inputWithoutDots = input.replace(/\./g, '');
        
        // Splits the input into a whole part, comma (if any), and a decimal part (if any)
        let whole, comma, decimal;
        const commaIndex = inputWithoutDots.indexOf(',');
        if (commaIndex !== -1) {
            whole = inputWithoutDots.substring(0, commaIndex);
            comma = ',';
            decimal = inputWithoutDots.substring(commaIndex + 1);
        } else {
            whole = inputWithoutDots;
        }
    
        // Reverse the whole part for easier formatting
        const reverseWhole = whole.split('').reverse().join('');
        // Inserts a dot every three digits, and reverses back the string
        const formattedWhole = reverseWhole.replace(/(\d{3}(?=\d))/g, '$1.').split('').reverse().join('');

        // Returns the formatted number with the comma if only a comma is present
        if (!decimal && comma) {
            return `${formattedWhole}${comma}`;
        }
    
        // Returns the formatted number with both the comma and decimal part if the decimal part is present
        return decimal ? `${formattedWhole}${comma}${decimal}` : `${formattedWhole}`;
    }

    // Prepares the value for input by replacing dots with commas, and inserting dots every three digits
    const formatForInput = (value: string) => {
        return value.replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }

    // Prepares the value for sending to the server by removing all dots, and replacing the comma with a dot
    const formatForServer = (value: string) => {
        return value.replace(/\./g, '').replace(',', '.');
    }

    // Handles the change of the input by updating the value, checking the validity of the input, and updating the state accordingly
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;

        // Unsaved changes check
        setUnsavedChanges(viewKey, value !== data[name]);

        if (value === '') {
            setValue('');
            setDisplayValue('');
            setErrorMessages((prevMessages: any) => ({ ...prevMessages, [name]: undefined }));
            setUpdatedData({...updatedData, [name]: ''});
        } else {
            setValue(value);
            setDisplayValue(formatForDisplay(value));
            if (onChange) {
                onChange(e);
            }
            setErrorMessages((prevMessages: any) => ({ ...prevMessages, [name]: undefined }));
            setUpdatedData({...updatedData, [name]: formatForServer(value)});
        }
    };

    // Submits the value after pressing enter
    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            if (!errorMessages[name]) {
                if (handleSubmit) handleSubmit();
            }
        }
    }

    // Get the error message from the errorState
    const errorMessage = errorMessages[name];

    // Format the due date to show in the view mode
    const formatViewModeDueDate = () => {
        // Only show the due date in the view mode, if the expiration days are set

        if (dueDate) {
            console.log("test2")
        }

        if (dueDate && data[name] !== undefined) {
            console.log("test")

            return dueDate.toLocaleDateString(userLocale, {year: 'numeric', month: '2-digit', day: '2-digit'});
        }
        return null;
    }

    return (
        <FieldWrapper
            name={name}
            label={label}
            tooltipText={tooltipText}
            helperText={helperText}
            isEditable={isEditable}
            disabled={disabled}
            viewInEditMode={viewInEditMode}
            alignment={alignment}>
            {(editing || viewInEditMode) ? (
                // Edit mode
                <div className='expiration-days-field'>
                    <label htmlFor={name}>{t(`${featureName}.general.expiration_label`)}</label>
                    <input
                        type="text"
                        id={name}
                        name={name}
                        min={min}
                        max={max}
                        placeholder={''}
                        value={displayValue}
                        onChange={handleInputChange}
                        className={errorMessage ? 'is-invalid' : ''}
                        autoFocus={shouldAutoFocus}
                        onKeyDown={handleKeyDown}
                    />
                    <label htmlFor={name}>{t(`${featureName}.general.expiration_label_days`)}</label>
                </div>
            ) : (
                // View mode
                <FieldViewMode 
                    value={formatViewModeDueDate()} 
                    alignment={alignment}
                />
            )}
        </FieldWrapper>
    );
};

export default ExpirationDaysField;