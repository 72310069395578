import React from 'react';
import { DetailCardType } from 'types/DetailPageTypes';
import { TabType } from 'types/TabTypes';
import DetailPage from 'components/layout/DetailPage';
import { HeaderButtons } from 'types/ButtonTypes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import LocationForm from './LocationForm';

// Rename the name of the function:
const PersonDetail: React.FC = () => {

    // Specify the parameters of this detail page:
    const apiObject = 'contact'
    const pageObject = 'person'
    const headerTitleDataField = 'name'

    // Define the header buttons:
    const headerButtons: HeaderButtons = [
        // {   
        //     type: 'secondary',
        //     label: 'button.merge_button_label',
        //     action: 'merge',
        //     size: 'medium'
        // },
        {   
            type: 'icon',
            icon: <FontAwesomeIcon icon={faTrash} />,
            tooltipText: 'general.delete',
            action: 'delete',
            onSuccess: 'redirect',
            redirectTo: '/contacts',
            size: 'medium'
        },
    ]

    // Define the detail page tabs:
    const tabs: TabType[] = [ 
        { label: 'timeline.general.timeline_label', type: 'timeline', showOnlyTimelineUpdates: false },
        { label: 'proposal.general.proposals_label', type: 'proposals' },
        { label: 'job.general.jobs_label', type: 'jobs' },
        { label: 'invoice.general.invoices_label', type: 'invoices' },
        { label: 'document.general.documents_label', type: 'attachments' },
    ]

    // Define detail components and add fields to FieldGroup cards:
    const detailCards: DetailCardType[] = [
        {
            component: 'FieldsCard',
            title: 'person.general.general_card_title',
            alignment: 'horizontal',
            fields: [
                { 
                    type: 'text', 
                    name: 'title', 
                    label: 'person.general.salutation_label', 
                    placeholder: 'person.general.salutation_placeholder', 
                },
                { 
                    type: 'text', 
                    name: 'first_name', 
                    label: 'person.general.first_name_label', 
                    placeholder: 'person.general.first_name_placeholder', 
                },
                { 
                    type: 'text', 
                    name: 'last_name', 
                    label: 'person.general.last_name_label', 
                    placeholder: 'person.general.last_name_placeholder', 
                },
                { 
                    type: 'text', 
                    name: 'role', 
                    label: 'person.general.role_label', 
                    placeholder: 'person.general.role_placeholder', 
                },
            ]
        },
        {
            component: 'FieldsCard',
            title: 'person.general.detail_card_title',
            alignment: 'horizontal',
            fields: [
                { 
                    type: 'text', 
                    name: 'number', 
                    label: 'person.general.number_label', 
                    placeholder: 'person.general.number_placeholder', 
                },
                { 
                    type: 'text', 
                    name: 'reference', 
                    label: 'person.general.reference_label', 
                    placeholder: 'person.general.reference_placeholder', 
                    tooltipText: 'person.general.reference_tooltip',
                },
                { 
                    type: 'dropdown', 
                    name: 'contact_type', 
                    label: 'person.general.contact_type_label', 
                    disabled_selected: 'forms.select_type_disabled_selected',
                    defaultValue: { value: 'contact' },
                    allowNoneOption: false,
                    showSearch: false,
                    hardcodedOptions: [
                        { value: 'contact', name: 'person.contact_type.contact'},
                        { value: 'prospect', name: 'person.contact_type.prospect'},
                        { value: 'client', name: 'person.contact_type.client'},
                    ],
                },
                { 
                    type: 'multiselect', 
                    name: 'labels', 
                    label: 'person.general.labels_label', 
                    apiObject: 'label',
                    apiRequest: [{
                        endpoint: 'get_label_list',
                        object: 'label',
                        params: { is_active: true, connected_feature: 'people' }
                    }],
                    allowNoneOption: false,
                    showSearch: false,
                    disabled_selected: 'person.general.select_label_disabled_selected',
                },
                { 
                    name: 'primary_locations',
                    type: 'locations', 
                    label: 'person.general.address_label',
                    apiField: 'primary_locations',
                    addNewItem: { 
                        modalForm: <LocationForm />, 
                        linkedObject: 'primary_locations' 
                    }
                },
                { 
                    name: 'phone_numbers',
                    type: 'phonenumbers',
                },
                { 
                    name: 'email_addresses',
                    type: 'emailaddresses',
                },
            ]
        },
    ];

    return (
        <DetailPage
            apiObject={apiObject}
            pageObject={pageObject}
            headerTitleDataField={headerTitleDataField}
            headerButtons={headerButtons}
            detailCards={detailCards}
            tabs={tabs}
        />
    );
};

export default PersonDetail;