import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ContactCardProps } from 'types/DetailPageTypes';
import { ContactType } from '../../views/contacts/ContactTypes'; 
import { FieldType } from 'types/FieldTypes';
import { useTranslation } from 'react-i18next';
import { useGlobalContext } from 'GlobalContext';
import { useModal } from 'components/modals/ModalContext';
import { useAllowedRight } from 'services/permissions/permissionChecks';
import LinkItemModal from 'components/modals/LinkItemModal';
import LocationForm from '../../views/contacts/LocationForm';
import LiveEditField from 'components/forms/LiveEditField';
import ContactForm from '../../views/contacts/ContactForm';
import PersonForm from '../../views/contacts/PersonForm';
import IconButton from 'components/buttons/IconButton';
import { saveData } from 'services/api/saveData';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faUser } from '@fortawesome/free-solid-svg-icons';
import { faArrowsRepeat, faLinkSimpleSlash } from '@fortawesome/pro-solid-svg-icons';
import '../../style/scss/card.scss';

const ContactCard: React.FC<ContactCardProps> = ({ 
    viewKey, itemId, loadStatus, data, isEditable, refetchData
}) => {
    const { t } = useTranslation();
    const { setFloatingAlert } = useGlobalContext();
    const { initializeModal } = useModal();
    const [contact, setContact] = useState<ContactType | null>(null);
    const [contactPerson, setContactPerson] = useState<ContactType | null>(null);
    const hasRightCheck = useAllowedRight;
    const [userHasOnlyViewRights] = useState<boolean>(hasRightCheck('only_view'));

    // Set the contact and/or contact person from the data
    useEffect(() => {
        if (data) {
            setContact(data.contact as ContactType);
            setContactPerson(data.contact_person as ContactType | null);
        }
    }, [data]);

    // Editable field config for the contact with organization entity type
    const organizationContactFields: FieldType[] = [
        {
            name: 'name',
            type: 'text',
            label: 'organization.general.organization_name_label',
            placeholder: 'organization.general.organization_name_placeholder',
        },
        { 
            name: 'primary_locations',
            type: 'locations', 
            label: 'organization.general.address_label',
            apiField: 'primary_locations',
            addNewItem: { 
                modalForm: <LocationForm />, 
                linkedObject: 'primary_locations' 
            }
        },
        { 
            name: 'phone_numbers',
            type: 'phonenumbers', 
        },
        { 
            name: 'email_addresses',
            type: 'emailaddresses', 
        },
    ]

    // Editable field config for the contact person of an organization
    const contactPersonFields: FieldType[] = [
        { 
            type: 'text', 
            name: 'first_name', 
            label: 'person.general.first_name_label', 
            placeholder: 'person.general.first_name_placeholder', 
        },
        { 
            type: 'text', 
            name: 'last_name', 
            label: 'person.general.last_name_label', 
            placeholder: 'person.general.last_name_placeholder', 
        },
        { 
            type: 'text', 
            name: 'role', 
            label: 'person.general.role_label', 
            placeholder: 'person.general.role_placeholder', 
        },
        { 
            name: 'phone_numbers',
            type: 'phonenumbers',
        },
        { 
            name: 'email_addresses',
            type: 'emailaddresses',
        },
    ]

    // Editable field config for the contact with person entity type
    const personContactFields: FieldType[] = [
        { 
            type: 'text', 
            name: 'first_name', 
            label: 'person.general.first_name_label', 
            placeholder: 'person.general.first_name_placeholder', 
        },
        { 
            type: 'text', 
            name: 'last_name', 
            label: 'person.general.last_name_label', 
            placeholder: 'person.general.last_name_placeholder', 
        },
        { 
            name: 'primary_locations',
            type: 'locations', 
            label: 'person.general.address_label',
            apiField: 'primary_locations',
            addNewItem: { 
                modalForm: <LocationForm />, 
                linkedObject: 'primary_locations' 
            }
        },
        { 
            name: 'phone_numbers',
            type: 'phonenumbers',
        },
        { 
            name: 'email_addresses',
            type: 'emailaddresses',
        },
    ]

    // Render the given fields
    const renderFields = (data: ContactType, fields: FieldType[], apiObject: 'organization' | 'person') => (
        fields.map((field, index) => (

            <LiveEditField
                key={index}
                viewKey={viewKey}
                apiObject={apiObject}
                fieldItem={field}
                itemId={data.id}
                data={data}
                alignment='horizontal'
                isEditable={isEditable}
                refetchData={refetchData}           
            />
        ))
    );

    // Handle the unlink of an item
    const handleUnlink = async (object: 'contact' | 'contact_person') => {
        // Unlink to item in the server
        await saveData({ 
            apiUrl: `patch_deal/${itemId}`, 
            method: 'patch', 
            data: { [object]: null } 
        });

        // Update the state
        if (object === 'contact') {
            setContact(null);
        } else {
            setContactPerson(null);
        }

        // Show success alert
        setFloatingAlert({ 'type': 'success' });
    }

    // Handle the link of a new item
    const handleLink = (object: 'contact' | 'contact_person') => {

        // Set the contact link item modal config
        const contactLinkConfig = {
            linkItemModalTitle: 'contact.general.object_name.singular',
            name: 'contact',
            placeholder: 'contact.general.link_contact_placeholder',
            objectName: 'contact.general.object_name.singular',
            endpoint: 'get_contact_search',
            addNewItem: { modalForm: <ContactForm /> },
            apiField: 'contact'
        }

        // Set the contact person link item modal config
        const contactPersonLinkConfig = {
            linkItemModalTitle: 'person.general.object_name.singular',
            name: 'person',
            placeholder: 'person.general.link_person_placeholder',
            objectName: 'person.general.object_name.singular',
            endpoint: 'get_person_search',
            addNewItem: { modalForm: <PersonForm /> },
            apiField: 'contact_person'
        }

        // Determine the link item modal config based on the object
        let config;
        if (object === 'contact') {
            config = contactLinkConfig;
        } else {
            config = contactPersonLinkConfig
        }

        // Initialize the modal
        initializeModal(
            <LinkItemModal  
                itemId={parseInt(itemId)}
                searchFieldConfig={{
                    name: config.name,
                    placeholder: config.placeholder,
                    objectName: config.objectName,
                    endpoint: config.endpoint,
                    addNewItem: { modalForm: config.addNewItem.modalForm }
                }}
                submitConfig={{ 
                    endpoint: 'patch_deal', 
                    apiField: config.apiField
                }}
                refetchData={() => refetchData()}
            />, { 
                title: t('forms.link_title', { object_name: t(config.linkItemModalTitle) }),
                modalSize: 'small' 
            }
        )
    }

    return (
        <div>
            <div className='card contact-card'>
                <div className="header">
                    <h6>
                        {contact?.entity_type === 'organization' ? (t('deal.general.organization_detail_card_label')) 
                            : contact?.entity_type === 'person' ? (t('deal.general.private_individual_detail_card_label')) 
                            : (t('deal.general.contact_detail_card_label'))}
                    </h6>
                    {loadStatus === "loaded" && isEditable && !userHasOnlyViewRights && contact && (
                        <div className='buttons'>
                            <IconButton
                                onClick={() => handleLink('contact')}
                                size="extra-small"
                                icon={<FontAwesomeIcon icon={faArrowsRepeat} />}
                                tooltipText={t('deal.general.switch_contact_tooltip')}
                            />
                        </div> 
                    )}
                </div>
                
                {loadStatus === "loading" ? (
                    <div className="loader"></div>
                ) : loadStatus === "loaded" && (
                    contact ? (
                        <>
                            <div className='linked-contact'>
                                {contact.entity_type === 'organization' ? (
                                    <FontAwesomeIcon 
                                        className='linked-contact-icon' 
                                        icon={faBuilding} 
                                    />
                                ) : (
                                    <FontAwesomeIcon 
                                        className='linked-contact-icon' 
                                        icon={faUser} 
                                    />
                                )}
                                <Link className='linked-contact-link' 
                                    to={`/contacts/${contact.entity_type === 'organization' ? 'organization' : 'person'}/${contact.id}`}
                                    key={contact.id}>
                                    {contact.name}
                                </Link>
                            </div>
                            {renderFields(
                                contact,
                                contact.entity_type === 'organization' ? organizationContactFields : personContactFields, 
                                contact.entity_type === 'organization' ? 'organization' : 'person'
                            )}
                        </>
                    ) : (
                        <div onClick={() => handleLink('contact')}
                             className='link-button'>
                            {t('deal.general.link_contact')}
                        </div>
                    )
                )}
            </div>

            {contact && contact.entity_type === 'organization' && (
                <div className="card contact-card">
                    <div className="header">
                        <h6>
                            {t('deal.general.contact_person_detail_card_label')}
                        </h6>
                        {loadStatus === "loaded" && isEditable && !userHasOnlyViewRights && contactPerson && (
                            <div className='buttons'>
                                <IconButton
                                    onClick={() => handleUnlink('contact_person')}
                                    size="extra-small"
                                    icon={<FontAwesomeIcon icon={faLinkSimpleSlash} />}
                                    tooltipText={t('deal.general.unlink_contact_person_tooltip')}
                                />
                                <IconButton
                                    onClick={() => handleLink('contact_person')}
                                    size="extra-small"
                                    icon={<FontAwesomeIcon icon={faArrowsRepeat} />}
                                    tooltipText={t('deal.general.switch_contact_person_tooltip')}
                                />
                            </div> 
                        )}
                    </div>
                    {loadStatus === "loading" ? (
                        <div className="loader"></div>
                    ) : loadStatus === "loaded" && (
                        contactPerson ? (
                            <>
                                <div className='linked-contact'>
                                    <FontAwesomeIcon 
                                        className='linked-contact-icon' 
                                        icon={faUser} 
                                    />
                                    <Link className='linked-contact-link' 
                                        to={`/contacts/person/${contactPerson.id}`}
                                        key={contactPerson.id}>
                                        {contactPerson.name}
                                    </Link>
                                </div>
                                {renderFields(contactPerson, contactPersonFields, 'person')}
                            </>
                        ) : (
                            <div onClick={() => handleLink('contact_person')}
                                 className='link-button'>
                                {t('deal.general.link_contact_person')}
                            </div>
                        )
                    )}
                </div>
            )}
        </div>
    );
}

export default ContactCard;