/*
 * convertDecimalToPrice.ts
 * Formats a backend decimal field to a price, formatted in the right
 * locale and with its currency.
 */

import { currencySymbols } from "internationalization/currencySymbols";
import { parsePriceToStringOnUserLocale } from "./parsePriceString";

// Render price column, given as decimal by the backend
export const convertDecimalToPrice = (price: string, currency: string | undefined, userLocale: string, roundPrice?: boolean) => {
    // Check wheter a sale price currency exists
    const currencyKey = currency ? currency.toLowerCase() : '';

    // Get the currency symbol for the currency
    const currencySymbol = currencySymbols[currencyKey];

    // Format the price based on the user locale
    const formattedPrice = parsePriceToStringOnUserLocale(price, userLocale, roundPrice);

    // Return the price and the currency symbol
    return `${currencySymbol} ${formattedPrice}`;
}