import React, { useEffect } from 'react';
import { useGlobalContext } from 'GlobalContext';
import { useModal } from './ModalContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { shakeElement } from 'services/utils/shakeElement';
import { useTranslation } from 'react-i18next';

/*
 * GenericModal.tsx
 * Listens to the ModalContext to show modals, and handles the base
 * behaviour of modals such showing the modal title and close button,
 * handle the closing by clicking outside the modal or pressing escape, 
 * and make the modal scrollable if it gets too big for the screen.
 */

const GenericModal: React.FC = () => {
    const { t } = useTranslation();
    const { modalStack, closeModal } = useModal();
    const { unsavedChangesMap } = useGlobalContext();

    // Handles mouse click outside the modal to close it
    const handleClickOutside = (event: MouseEvent, modalKey: string) => {

        // Check if the modal backdrop is clicked
        const modalElement = document.querySelector(`[data-modal-key='${modalKey}']`);
        const isBackdropClick = event.target === modalElement;
    
        if (isBackdropClick) {
            // Determine if the current modal has unsaved changes
            const hasUnsavedChanges = unsavedChangesMap[modalKey];
    
            if (hasUnsavedChanges) {
                // If the modal has unsaved changes, shake the modal
                const modalContent = modalElement?.querySelector('.modal');
                if (modalContent) {
                    shakeElement(modalContent as HTMLElement);
                }
            } else {
                // If the modal doesn't have unsaved changes, close the modal
                closeModal();
            }
        }
    };

    // Aligns smaller, small and medium modals in the right way
    const adjustModalPosition = () => {
        modalStack.forEach((modal) => {
            const modalElement = modal.modalRef.current;
            if (modalElement) {
                // Determine it's a small or medium window and determine the heights
                const isSmallOrMedium = modalElement.classList.contains('smaller') 
                    || modalElement.classList.contains('small') 
                    || modalElement.classList.contains('medium');
                const windowHeight = window.innerHeight;
                const modalHeight = modalElement.offsetHeight + (1.75 * 16 * 2);
    
                if (isSmallOrMedium) {
                    if (modalHeight > windowHeight) {
                        // If the modal is bigger than the window height, give it a margin
                        modalElement.style.marginTop = '1.75rem';
                        modalElement.style.marginBottom = '1.75rem';

                        // And set the align items property of the modal-backdrop css class
                        if (modalElement.parentNode && modalElement.parentNode instanceof HTMLElement) {
                            modalElement.parentNode.style.alignItems = 'start';
                        }
                    } else {
                        // If the modal fits inside the modal height, open it from 20% of the top
                        modalElement.style.marginTop = '12rem';
                        modalElement.style.marginBottom = 'auto';

                        // And set the align items 
                        if (modalElement.parentNode && modalElement.parentNode instanceof HTMLElement) {
                            modalElement.parentNode.style.alignItems = 'center';
                        }
                    }
                }
            }
        });
    };

    // Added later on: Made the modal fixed on 12rem margin top... Still have to determine to remove this feature


    // Set the right position and scroll height of the modals
    // useEffect(() => {
    //     // Make modal scrollable if it gets too big for the screen
    //     document.body.style.overflow = modalStack.length > 0 ? 'hidden' : 'auto';

    //     // Configures an observer function to receive height mutations of small and medium modals
    //     const observerCallback: MutationCallback = (mutationsList) => {
    //         for(const mutation of mutationsList) {
    //             if (mutation.type === 'childList' || mutation.type === 'attributes') {
    //                 adjustModalPosition();
    //             }
    //         }
    //     };
    //     const observer = new MutationObserver(observerCallback);
    //     const config = { attributes: true, childList: true, subtree: true };

    //     // Loop through every modal item to add the right properties
    //     modalStack.forEach((modal, index) => {
    //         const modalElement = modal.modalRef.current;
    //         const isTopModal = index === modalStack.length - 1;
    //         if (modalElement) {
    //             // Add the mutation observer to every modal item
    //             observer.observe(modalElement, config);
    //         }
    //     });

    //     // Reset observer on component unmount
    //     return () => {
    //         observer.disconnect()
    //     };
    // }, [modalStack, closeModal, unsavedChangesMap, adjustModalPosition, handleClickOutside]);
    
    // Handle the click outside the modal to close the modal
    useEffect(() => {
        // Create a map of event listeners to correctly remove them
        const eventListenersMap = new Map();
    
        // Loop over each modal to add the event listeners
        modalStack.forEach(modal => {
            const handleMouseDown = (event: MouseEvent) => handleClickOutside(event, modal.key);
            document.addEventListener('mousedown', handleMouseDown);
            eventListenersMap.set(modal.modalRef, handleMouseDown);
        });
    
        return () => {
            // Delete the event listeners
            eventListenersMap.forEach((handleMouseDown) => {
                document.removeEventListener('mousedown', handleMouseDown);
            });
        };
    }, [modalStack, closeModal, unsavedChangesMap]);

    // Handle the closing of the modal after pressing escape
    useEffect(() => {
        const handleEscape = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                closeModal();
            }
        };
        document.addEventListener('keydown', handleEscape);
    
        return () => {
            // Delete the event listeners
            document.removeEventListener('keydown', handleEscape);
        };
    }, [closeModal]);

    if (modalStack.length === 0) return null;

    return (
        <>
            {modalStack.map((modal) => (
                <div key={modal.key} 
                     className={`modal-backdrop ${modal.isOpen ? '' : 'hidden'}`} 
                     data-modal-key={modal.key} 
                     onClick={(event) => handleClickOutside(event as unknown as MouseEvent, modal.key)}>
                    <div className={`modal ${modal.isOpen ? '' : 'hidden'} ${modal.props?.modalSize || 'small'} ${modal.props?.showSideTab ? 'sidetab' : ''}`} 
                         ref={modal.modalRef}>
                        {modal.props?.title ? (
                            <div className='modal-header'>
                                <h4>{t(modal.props?.title)}</h4>
                                <FontAwesomeIcon icon={faXmark} className='modal-close' onClick={closeModal} />
                            </div>
                        ) : (
                            <FontAwesomeIcon icon={faXmark} className='modal-close top-right' onClick={closeModal}
                            />
                        )}
                        {modal.content}
                    </div>
                </div>
            ))}
        </>
    );
};

export default GenericModal;