import { useEffect, useState } from 'react';

/*
 * useAdjustColumnsHeight.ts
 * A custom hook to use in the kanban board component to maximize
 * the screen height for the kanban columns.
 */

const useAdjustColumnsHeight = (
    kanbanBoardRef: React.RefObject<HTMLDivElement>, 
    isTopNavbarEnabled: boolean,
    isDragging: boolean
) => {
    const [columnHeight, setColumnHeight] = useState<number | undefined>(undefined);

    useEffect(() => {
        const adjustColumnsHeight = () => {

            // Get the height of the window of the user
            const windowHeight = window.innerHeight;

            // If the top navbar is enabled, get its height
            let topNavbarHeight = 0;
            if (isTopNavbarEnabled) {
                const topNavbar = document.querySelector('.topbar') as HTMLElement;
                topNavbarHeight = topNavbar ? topNavbar.offsetHeight : 0;
            }

            // Get the navbar height
            const navBar = document.querySelector('.navbar') as HTMLElement;
            const subNavBar = document.querySelector('.subnavbar') as HTMLElement;
            const navBarHeight = navBar ? navBar.offsetHeight : 0;
            const subNavBarHeight = subNavBar ? subNavBar.offsetHeight : 0;
        
            // Get the height of the page header and scheduling header
            const mainElement = document.querySelector('main') as HTMLElement;
            const mainElementMarginTop = document.querySelector('main') ? parseInt(window.getComputedStyle(mainElement).marginTop) : 0;
            const pageNavigation = document.querySelector('.kanban-navigation') as HTMLElement;
            const pageNavigationMarginBottom = pageNavigation ? parseInt(window.getComputedStyle(pageNavigation).marginBottom) : 0;
            const columnHeader = document.querySelector('.column-header') as HTMLElement;
            
            const pageNavigationHeight = pageNavigation ? pageNavigation.offsetHeight + pageNavigationMarginBottom : 0;
            const columnHeaderHeight = columnHeader ? columnHeader.offsetHeight : 0;
    
            // Calculate the height above the kanban columns
            const estimatedOtherElementsHeight = topNavbarHeight + navBarHeight + subNavBarHeight + mainElementMarginTop + pageNavigationHeight + columnHeaderHeight;
    
            // Calculate the height available for the scheduling rows
            const totalColumnsHeight = windowHeight - estimatedOtherElementsHeight;

            setColumnHeight(totalColumnsHeight);
        };
    
        // Create the mutation observer instance
        const observer = new MutationObserver(mutations => {
            mutations.forEach(mutation => {
                if (mutation.type === 'childList' || mutation.type === 'attributes') {
                    adjustColumnsHeight();
                }
            });
        });

        // Start the observer
        if (kanbanBoardRef.current) {
            observer.observe(kanbanBoardRef.current, { childList: true, subtree: true, attributes: true });
        }

        // Execute the adjustment of the columns height
        adjustColumnsHeight();
    
        // Clear on unmount
        return () => observer.disconnect();
    }, [kanbanBoardRef, isTopNavbarEnabled, isDragging]);

    return columnHeight;
};

export default useAdjustColumnsHeight;