import React from 'react';
import { DetailCardType } from 'types/DetailPageTypes';
import { FieldType } from 'types/FieldTypes';
import { TabType } from 'types/TabTypes';
import DetailPage from 'components/layout/DetailPage';
import { HeaderButtons } from 'types/ButtonTypes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

// Rename the name of the function:
const ContactDetail: React.FC = () => {

    // Specify the parameters of this detail page:
    const apiObject = 'contact'
    const pageObject = 'contact'

    // Define the header title fields:
    const headerTitleFields: FieldType[] = [
        {
            name: 'name',
            type: 'text',
            placeholder: 'organization.general.contact_name_placeholder',
            isEditable: { editability: false },
            saveOnBlur: true,
        },
    ]

    // Define the header buttons:
    const headerButtons: HeaderButtons = [
        // {   
        //     type: 'secondary',
        //     label: 'button.merge_button_label',
        //     action: 'merge',
        //     size: 'medium'
        // },
        {   
            type: 'icon',
            icon: <FontAwesomeIcon icon={faTrash} />,
            tooltipText: 'general.delete',
            action: 'delete',
            onSuccess: 'redirect',
            redirectTo: '/contacts',
            size: 'medium'
        },
    ]

    // Define the detail page tabs:
    const tabs: TabType[] = [ 
        { label: 'timeline.general.timeline_label', type: 'timeline', showOnlyTimelineUpdates: false },
        // { label: 'proposal.general.proposals_label', type: 'proposals' },
        { label: 'job.general.jobs_label', type: 'jobs' },
        { label: 'invoice.general.invoices_label', type: 'invoices' },
        { label: 'document.general.documents_label', type: 'attachments' },
    ]

    // Define detail components and add fields to FieldGroup cards:
    const detailCards: DetailCardType[] = [
        {
            component: 'FieldsCard',
            title: 'contact.general.general_details_card_label',
            alignment: 'horizontal',
            fields: [
                { 
                    type: 'text', 
                    name: 'number', 
                    label: 'contact.general.number_label', 
                    placeholder: 'contact.general.number_placeholder', 
                },
                { 
                    type: 'text', 
                    name: 'reference', 
                    label: 'contact.general.reference_label', 
                    placeholder: 'contact.general.reference_placeholder', 
                    tooltipText: 'contact.general.reference_tooltip'
                },
                { 
                    type: 'dropdown', 
                    name: 'contact_type', 
                    label: 'contact.general.type_label', 
                    disabled_selected: 'forms.select_type_disabled_selected',
                    defaultValue: { value: 'contact' },
                    allowNoneOption: false,
                    showSearch: false,
                    hardcodedOptions: [
                        { value: 'contact', name: 'organization.contact_type.contact'},
                        { value: 'prospect', name: 'organization.contact_type.prospect'},
                        { value: 'client', name: 'organization.contact_type.client'},
                        { value: 'supplier', name: 'organization.contact_type.supplier'},
                    ],
                },
                { 
                    name: 'primary_locations',
                    type: 'locations', 
                    label: 'contact.general.address_label',
                    apiField: 'primary_locations',
                    tooltipText: 'contact.general.address_tooltip'
                },
                { 
                    name: 'phone_numbers',
                    type: 'phonenumbers', 
                },
                { 
                    name: 'email_addresses',
                    type: 'emailaddresses', 
                },
            ]
        },
    ]

    return (
        <DetailPage
            apiObject={apiObject}
            pageObject={pageObject}
            headerTitleFields={headerTitleFields}
            headerButtons={headerButtons}
            detailCards={detailCards}
            tabs={tabs}
        />
    );
};

export default ContactDetail;