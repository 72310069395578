import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useModal } from 'components/modals/ModalContext';
import { DeletionType, getDeletionLabel } from 'services/utils/deletionUtils';
import { getCsrfToken } from 'services/authentication/csrfFunctions';
import axios, { AxiosError } from 'axios';
import { apiBaseUrl } from 'App';
import { useGlobalContext } from 'GlobalContext';
import { DetailPageUrlType, EditModalType, ListRowType, PostNewItemType } from 'types/ListTypes';
import DeleteModal from 'components/modals/DeleteModal';
import JobModal from '../../../views/jobs/JobModal';
import { saveData } from 'services/api/saveData';
import ImportDetailModal from '../../../views/settings/ImportDetailModal';
import { convertLocalDateTimeToUTC } from 'internationalization/timezoneConversions';

/*
 * useListClickHandlers.ts
 * Custom hook to handle the click handlers for a list. For example to open modals or detailpages when 
 * clicking on a row in the list or when clicking on a button to add or delete an item.
 */

export const useListClickHandlers = ({
    apiObject,
    objectName,
    detailPageUrl,
    refetchList,
    onRowClick,
    detailModal,
    detailModalName,
    releaseViewModal,
    wizardModal,
    postNewItem,
    formModal,
    editModal,
    modalSize,
    detailModalSize,
    userTimezone,
    userLocale,
}: {
    apiObject?: string,
    objectName?: string,
    detailPageUrl?: DetailPageUrlType | undefined, 
    refetchList?: () => void,
    onRowClick?: 'editModal' | 'detailModal' | 'releaseViewModal' | 'wizardModal' | 'detailPage',
    detailModal?: React.ReactElement,
    detailModalName?: string,
    // detailModal?: React.ReactElement<DetailModalProps> | ((toggleSideTab: (showSideTab: boolean) => void) => React.ReactElement<DetailModalProps>),
    releaseViewModal?: React.ReactElement,
    wizardModal?: React.ReactElement,
    postNewItem?: PostNewItemType,
    formModal?: React.ReactElement,
    editModal?: EditModalType;
    modalSize?: 'small' | 'medium' | 'large' | 'xtra-large',
    detailModalSize?: 'small' | 'medium' | 'medium-large' | 'large' | 'xtra-large',
    userTimezone: string,
    userLocale: string,
}) => {
    const { t } = useTranslation();
    const { initializeModal } = useModal();
    const { setFloatingAlert } = useGlobalContext();
    const history = useHistory();

    // Map the given modal component name to the right component
    const modalMapping: Record<string, React.FC<any>> = {
        'job-modal': JobModal,
        'import-detail-modal': ImportDetailModal,
    };

    // Handle row click to open a detail modal, an edit modal or a detail page
    const handleRowClick = (itemId: number, rowData: ListRowType) => {
        switch (onRowClick) {
            case 'detailModal':
                // if (detailModal) {
                    // if (detailModal) {
                    //     if (typeof detailModal === 'function') {
                    //         // Maak een state om de showSideTab waarde op te slaan
                    //         let showSideTabState = false;
                    
                    //         // Maak een toggle functie om de side tab te beheren
                    //         const toggleSideTab = (showSideTab: boolean) => {
                    //             showSideTabState = showSideTab;  // Update de state met de waarde die uit JobModal komt
                    //         };
                    
                    //         // Roep de detailModal functie aan met de toggleSideTab en de itemId
                    //         const modalElement = detailModal(toggleSideTab);
                    
                    //         console.log("showSummary", modalElement.props.summary);
                    
                    //         // Gebruik de initializeModal functie om de modal te openen
                    //         initializeModal(modalElement, { 
                    //             itemId, 
                    //             modalSize: detailModalSize ?? 'large',
                    //             showSideTab: showSideTabState  // Gebruik de geüpdatete state voor showSideTab
                    //         });
                    //     }
                    // }

                    // if (typeof detailModal === 'function') {
                    //     // Als detailModal een functie is, roep het aan met de itemId
                    //     const modalElement = detailModal(showSideTab);

                    //     console.log("showSummary", modalElement.props.summary)
        
                    //     initializeModal(modalElement, { 
                    //         itemId, 
                    //         modalSize: detailModalSize ?? 'large',
                    //         showSideTab: modalElement.props.summary ?? false
                    //     });
                    // }
                // }

                if (detailModalName) {
                    // Map the given modal name to the right modal component
                    const ModalComponent = modalMapping[detailModalName];

                    if (ModalComponent) {
                        initializeModal(
                            <ModalComponent itemId={itemId} refetchList={refetchList} />, { 
                                itemId, 
                                modalSize: detailModalSize ?? 'large'
                            }
                        );
                    }
                } else if (detailModal) {
                    initializeModal(
                        React.cloneElement(detailModal, { itemId, refetchList }), { 
                            // Set the item id of the item to view
                            itemId, 

                            // Set the modalSize to large for detail modals
                            ...(detailModalSize ? { modalSize: detailModalSize } : { modalSize: 'large' }),
                        }
                    );
                }
                break;
            case 'editModal':
                if (formModal && !editModal) {
                    initializeModal(
                        React.cloneElement(formModal, { itemId }), { 
                            // Set the title of the modal of the object. For example: 'Edit job'
                            title: t('button.edit_object_label', { object_name: t(`${objectName}.singular`) }), 

                            // Set the item id of the item to edit
                            itemId,

                            // Set the modalSize if given in the props, otherwise use default modal size
                            ...(modalSize ? { modalSize: modalSize } : {})
                        }
                    );
                } else if (editModal) {
                    initializeModal(
                        React.cloneElement(editModal.modal, { itemId }), { 
                            // Set the title of the modal of the object. For example: 'Edit job'
                            title: t('button.edit_object_label', { object_name: t(`${objectName}.singular`) }), 

                            // Set the item id of the item to edit
                            itemId,

                            // Set the modalSize if given in the props, otherwise use default modal size
                            ...(editModal.modalSize ? { modalSize: editModal.modalSize } : {})
                        }
                    );
                }
                break;
            case 'releaseViewModal':
                if (releaseViewModal) {
                    initializeModal(
                        React.cloneElement(releaseViewModal, { itemId }), { 
                            // Set the item id of the item to view
                            itemId, 

                            // Set the modalSize to medium large
                            modalSize: 'medium-large' 
                        }
                    );
                }
                break;
            case 'wizardModal':
                if (wizardModal) {
                    initializeModal(
                        React.cloneElement(wizardModal, { itemId }), { 
                            // Set the item id of the item to view
                            itemId, 

                            // Set the modalSize to large for wizard modals
                            modalSize: 'medium-large' 
                        }
                    );
                }
            case 'detailPage':
                // Initialize the url variable
                let url: string | undefined;

                // If the detail page url is a fixed string, set the url to this string
                if (typeof detailPageUrl === 'string') {
                    url = detailPageUrl;
                }

                // If it's not a string but an object with a custom url, construct that url
                else if (detailPageUrl) {
                    url = detailPageUrl.baseUrl;

                    if (detailPageUrl.urlSuffixApiField) {
                        // Set the suffix to the value from the given api field, or to the fallback if that value doesn't exists
                        const suffix = rowData[detailPageUrl.urlSuffixApiField] ?? detailPageUrl.urlSuffixFallback;

                        // Construct the custom url
                        url = `${url}/${suffix}`;
                    }
                }

                if (itemId && url) {
                    // Open the detail page for the directory and item
                    history.push(`/${url}/${itemId}`);
                }
                break;
            default:
                console.warn('onRowClick action not defined or recognized');
        }
    };

    // Handle primary button click
    const handlePrimaryButtonClick = async () => {

        // Open the form modal, if given
        if (formModal) {
            initializeModal(
                React.cloneElement(formModal, {}), { 
                    // Set the title of the modal of the object. For example: 'Add job'
                    title: t('button.add_object_label', { object_name: t(`${objectName}.singular`) }),

                    // Set the modalSize if given in the props, otherwise use default modal size
                    ...(modalSize ? { modalSize: modalSize } : {}),

                    // Handle on success callback to refetch the data
                    onSuccess: () => {if (refetchList) refetchList()}
                }
            );
        }

        // Post a new item to the backend and open the detail modal afterwards
        if (postNewItem) {
            // Create the new item in the backend
            const postItem = async () => {
                try {
                    // Add the current date to the date, especially for the creation of orders, to set the order_date
                    const currentDateTime = new Date();
                    const utcDateTime = convertLocalDateTimeToUTC(currentDateTime, userLocale, userTimezone);

                    // Configure the submit data with the optional default linked item
                    const submitData = {
                        current_date: utcDateTime,
                        ...(postNewItem.linkedItem && postNewItem.linkedItem)
                    }

                    // Send the request to the server
                    const response = await saveData({ apiUrl: postNewItem.url, method: 'post', data: submitData });

                    // Return the id of the newly created item
                    if (response && response.status == 201) {
                        return response.data.id;

                    // Throw error when creation failed
                    } else {
                        throw new Error(`failed to ${postNewItem.url}`);
                    }
                } catch (error) {
                    console.log(`error on ${postNewItem.url}`, error);
                    return null;
                }
            };

            // Call the create item function
            const newItemId = await postItem();

            // Open the modal of the newly created item
            if (newItemId && detailModal) {

                // Open the modal directly
                initializeModal(
                    React.cloneElement(detailModal, { newItemId, refetchList }), { 
                        // Set the item id of the item to view
                        itemId: newItemId, 
    
                        // Set the modalSize to large for detail modals
                        ...(detailModalSize ? { modalSize: detailModalSize } : { modalSize: 'large' }),
                    }
                );

                // Refresh the list in the background
                if (refetchList) {
                    await refetchList();
                }
            }
        }
    };

    // Handle secondary button click to open a form modal, such as import/export or choose columns
    const handleSecondaryButtonClick = () => {
        // To be implemented
        // initializeModal(
        //     React.cloneElement(formModal, {}), { 
        //         // Set the title of the modal of the object. For example: 'Add job'
        //         title: t('button.add_object_label', { object_name: t(`${objectName}.singular`) }),

        //         // Set the modalSize if given in the props, otherwise use default modal size
        //         ...(modalSize ? { modalSize: modalSize } : {})
        //     }
        // );
    };

    // Handle delete to remove an existing item
    const handleDelete = async (id: number, deletionType: DeletionType) => {
        if (deletionType !== undefined) {
            const deletionLabel = getDeletionLabel(deletionType);
            
            // Delete the item after confirmation
            const onConfirmDelete = async () => {
                const csrfToken = getCsrfToken();

                try {
                    if (deletionType === 'fully_delete') {
                        // Send a DELETE request to delete the item
                        await axios.delete(`${apiBaseUrl}/delete_${apiObject}/${id}/`, {
                            withCredentials: true,
                            headers: {
                                'X-CSRFToken': csrfToken,
                            },
                        });
                    } else if (deletionType === 'flag_deleted') {
                        // Send a PATCH request to flag the item as deleted
                        await axios.patch(`${apiBaseUrl}/patch_${apiObject}/${id}/`, {
                            deleted: true,
                        }, {
                            withCredentials: true,
                            headers: {
                                'X-CSRFToken': csrfToken,
                            },
                        });
                    } else if (deletionType === 'flag_deactivated') {
                        // Send a PATCH request to deactivate the item
                        await axios.patch(`${apiBaseUrl}/patch_${apiObject}/${id}/`, {
                            is_active: false,
                        }, {
                            withCredentials: true,
                            headers: {
                                'X-CSRFToken': csrfToken,
                            },
                        });
                    }

                    // Refetch the list to remove the item
                    if (refetchList) refetchList();

                    // Show alert on success
                    setFloatingAlert({ type: 'success', message: 'alert.floating_alert_deleted' });
                } catch (error) {
                    // Show a floating alert with an error
                    const axiosError = error as AxiosError;
                    const message = (axiosError.response?.data as any)?.general || (axiosError.response?.data as any)?.non_field_errors || 'alert.general_error_message';
                    setFloatingAlert({ 'type': 'danger', 'message': message });
                }
            }

            // Open the delete modal to get the delete confirmation
            const deleteModalElement = (
                <DeleteModal onConfirmDelete={onConfirmDelete} deletionType={deletionLabel} />
            );

            // Open the delete modal
            initializeModal(deleteModalElement, { modalSize: 'extra-small' });
        }
    };

    return { handleRowClick, handlePrimaryButtonClick, handleSecondaryButtonClick, handleDelete };
};