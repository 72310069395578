import React, { useEffect, useState } from 'react';
import { useGlobalContext } from 'GlobalContext';
import { useModal } from 'components/modals/ModalContext';
import SearchSelect from 'components/forms/basefields/SearchSelect';
import { FieldOption } from 'types/FieldTypes';
import SecondaryButton from 'components/buttons/SecondaryButton';
import PrimaryButton from 'components/buttons/PrimaryButton';
import { saveData } from 'services/api/saveData';

interface LinkItemModalProps {
    itemId: number; // The id of the page to link the item to. For example: organization id 17
    searchFieldConfig: {
        name: string;
        placeholder: string;
        objectName: string;
        endpoint: string;
        addNewItem?: {
            modalForm: React.ReactElement;
        }
    };
    submitConfig: {
        endpoint: string;
        apiField: string; // The field in the api to update the selected value to. For example: 'primary_persons'
        existingSelectedIds?: number[]; // In case of a many to many field, pass the existing ids to add to the submit data
    };
    refetchData: () => void;
}

const LinkItemModal: React.FC<LinkItemModalProps> = ({
    itemId, searchFieldConfig, submitConfig, refetchData
}) => {
    const { setFloatingAlert } = useGlobalContext();
    const { revealModal, closeModal } = useModal();
    const [selectedItem, setSelectedItem] = useState<FieldOption | null>(null);
    const [buttonLoader, setButtonLoader] = useState(false);

    // Reveal the modal on first render
    useEffect(() => {revealModal()}, []);

    // Handle submit of a linked item
    const handleSubmit = async () => {
        if (selectedItem) {
            // Start button loader
            setButtonLoader(true);

            // If the backend field is a many to many field, combine the existing ids and the new selected id to an array
            let submitValue;
            if (submitConfig.existingSelectedIds) {
                submitValue = [...(submitConfig.existingSelectedIds || []), selectedItem.id];

            // Otherwise, just only save the selected id
            } else {
                submitValue = selectedItem.id;
            }

            // Save the data to the server      
            await saveData({ 
                apiUrl: `${submitConfig.endpoint}/${itemId}`, 
                method: 'patch', 
                data: { [submitConfig.apiField]: submitValue } 
            });

            // Refetch the data in the parent component
            refetchData();

            // Stop button loader
            setButtonLoader(false);

            // Close the current modal
            closeModal();

            // Show success alert
            setFloatingAlert({ 'type': 'success' });
        }
    }

    // Close the modal
    const handleClose = () => {
        setSelectedItem(null);
        setButtonLoader(false);
        closeModal();
    }

    return (
        <div>
            <div className='formset add-edit-form'>
                <div className='formfield'>
                    <SearchSelect
                        type='searchselect'
                        name={searchFieldConfig.name}
                        placeholder={searchFieldConfig.placeholder}
                        objectName={searchFieldConfig.objectName}
                        query={{endpoint: searchFieldConfig.endpoint}}
                        selectionFormat='name'
                        optionFormat={{ title: { field: 'name', format: (value) => value } }}
                        onSelect={(option) => setSelectedItem(option)}
                        addNewItem={searchFieldConfig.addNewItem ? { method: "modal-form", modalForm: searchFieldConfig.addNewItem?.modalForm } : undefined}
                    />
                </div>
                <div className='buttons-right'>
                    <SecondaryButton
                        onClick={handleClose} 
                        label='general.cancel'
                        size="small"/>
                    <PrimaryButton
                        onClick={handleSubmit}
                        label='general.save'
                        size="small"
                        onlyViewRestriction={true}
                        loading={buttonLoader}/>
                </div>
            </div>
        </div>
    )
}

export default LinkItemModal;