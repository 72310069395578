import React, { useState, useEffect, useContext } from 'react';
import { FieldData, PercentageFieldType } from 'types/FieldTypes';
import FormFieldContext from '../FormFieldContext';
import { useGlobalContext } from 'GlobalContext';
import FieldWrapper from '../FieldWrapper';
import FieldViewMode from '../fields/elements/FieldViewMode';
import '../../../style/scss/live-edit.scss';
import '../../../style/scss/forms.scss';
import '../../../style/scss/tooltip.scss';

interface PercentageFieldProps extends PercentageFieldType {
    errorMessage?: string | string[] | undefined;
    shouldAutoFocus?: boolean;
}

const PercentageField: React.FC<PercentageFieldProps & { data: FieldData, viewKey: string }> = ({ 
    viewKey, name, label, data, helperText, tooltipText, viewInEditMode, shouldAutoFocus, 
    alignment, isEditable, disabled, onChange
}) => {
    const { editing, updatedData, setUpdatedData, handleSubmit } = useContext(FormFieldContext);
    const { errorMessages, setUnsavedChanges } = useGlobalContext();
    const [value, setValue] = useState('');

    // Set the current price from the data
    useEffect(() => {
        if (data && data[name] !== undefined) {
            setValue(data[name]);
        }
    }, [data, name]);

    // Updates the value after changing the input
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {

        // Set the entered value
        setValue(e.target.value);

        // Return the value in the callback if given
        if (onChange) { onChange(e);}
        
        // Register change if it exist
        const hasChanges = e.target.value !== data[name];
        setUnsavedChanges(viewKey, hasChanges);

        // Set the updated data
        setUpdatedData({...updatedData, [name]: e.target.value});
    };

    // Submits the value after pressing enter
    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            if (name && !errorMessages[name]) {
                if (handleSubmit) handleSubmit();
            }
        }
    }

    // Get the error message from the errorState
    const errorMessage = name && errorMessages[name];

    return (
        <FieldWrapper
            name={name}
            label={label}
            tooltipText={tooltipText}
            helperText={helperText}
            isEditable={isEditable}
            disabled={disabled}
            viewInEditMode={viewInEditMode}
            alignment={alignment}>
            {(editing || viewInEditMode) ? (
                // Edit mode
                <div className='percentage-field'>
                    <input 
                        type="number"
                        id={name}
                        name={name}
                        value={value}
                        onChange={handleInputChange}
                        placeholder='0'
                        disabled={disabled}
                        autoFocus={shouldAutoFocus}
                        className={errorMessage ? 'is-invalid' : ''}
                        onKeyDown={handleKeyDown}
                    />
                    <span className="percentage-symbol">%</span>
                </div>
            ) : (
                // View mode
                <FieldViewMode 
                    type="percentage"
                    value={value}
                    alignment={alignment}
                />
            )}
        </FieldWrapper>
    );
};

export default PercentageField;