import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSettings } from 'services/settings/SettingsContext';
import '../../style/scss/modals.scss';
import Timeline from 'components/timeline/Timeline';
import LinkedItemsList from './LinkedItemsList';
import { convertDecimalToPrice } from 'services/utils/convertDecimalToPrice';

export interface DetailModalSummaryProps {
    viewKey: string;
    objectType: 'proposal' | 'order' | 'job' | 'project' | 'invoice';
    itemId: number;
    data: any;
}

const DetailModalSummary: React.FC<DetailModalSummaryProps> = ({ viewKey, objectType, data, itemId }) => {
    const { t } = useTranslation();
    const { userLocale } = useSettings();

    const renderSummaryDetails = useMemo(() => {
        if (data) {
            switch (objectType) {
                case 'proposal': {
                    return (
                        <div>
                            {data.status !== 'draft' && (
                                <div className='badge-container'>
                                    <div className={`status-badge ${data.status}`}>
                                        {t(`workflow.default_status_names.${data.status}`)}
                                    </div>                        
                                </div>
                            )}
                            <div className='detail-content'>
                                
                            </div>
                        </div>
                    )
                }

                case 'order': {
                    // Format the order amount and invoice amounts
                    const orderAmount = convertDecimalToPrice(data.amount, data.currency, userLocale);
                    const amountInvoiced = convertDecimalToPrice(data.amount_invoiced, data.currency, userLocale);
                    const amountToInvoice = convertDecimalToPrice(data.amount_to_invoice, data.currency, userLocale);
                    const amountAdditional = convertDecimalToPrice(data.amount_additional, data.currency, userLocale);

                    // Calculate percentages
                    const invoicedPercentage = data.amount > 0 ? Math.floor((data.amount_invoiced / data.amount) * 100) : 0;
                    const toInvoicePercentage = data.amount > 0 ? Math.floor((data.amount_to_invoice / data.amount) * 100) : 0;

                    return (
                        <div>
                            <div className='detail-content'>
                                <span>{t('order.general.order_amount_label')}: {orderAmount}</span>

                                {data.amount_invoiced > 0 && (
                                    // Only show the invoiced amount if there is an invoiced amount
                                    <span>
                                        {t('order.general.amount_invoiced_label')}: {amountInvoiced} 
                                        {invoicedPercentage > 0 && invoicedPercentage < 100 && ` (${invoicedPercentage}%)`}
                                    </span>
                                )}

                                {data.amount > 0 && (
                                    // Only show the amount to be invoiced, if the order amount is greater than 0
                                    <span>
                                        {t('order.general.amount_to_invoice_label')}: {amountToInvoice} 
                                        {toInvoicePercentage > 0 && toInvoicePercentage < 100 && ` (${toInvoicePercentage}%)`}
                                    </span>
                                )}

                                <span>{t('order.general.amount_additional_label')}: {amountAdditional}</span>
                            </div>
                        </div>
                    )
                }

                case 'invoice': {

                    // Format the invoice amount
                    const invoiceAmount = convertDecimalToPrice(data.amount, data.currency, userLocale);
    
                    return (
                        data.status !== 'draft' && (
                            <div>
                                {data.status !== 'draft' && (
                                    <div className='badge-container'>
                                        <div className={`status-badge ${data.status}`}>
                                            {t(`workflow.default_status_names.${data.status}`)}
                                        </div>                        
                                    </div>
                                )}
                                <div className='detail-content'>
                                    {data.status !== 'draft' && <span>Openstaand: {invoiceAmount}</span>}
                                </div>
                            </div>
                        )
                    )
                }
                
                default:
                    return null;
            }
        }
    }, [data]) 

    return (
        <div className='summary'>
            <div className='wrapper'>
                <div className='summary-header'>
                    <h5>{t('general.summary')}</h5>
                    <div className='summary-details'>
                        {renderSummaryDetails}
                    </div>
                </div>

                <div className='summary-section'>
                    <LinkedItemsList 
                        itemId={itemId}
                        objectType={objectType}
                        data={data}
                    />
                </div>

                <div className='summary-section'>
                    {/* <h6>Tijdlijn</h6> */}
                    <Timeline 
                        viewKey={viewKey} 
                        timeline_page_id={String(itemId)} 
                        timeline_page={objectType} 
                    />
                </div>
            </div>
        </div>
    );
};

export default DetailModalSummary;