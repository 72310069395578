import { saveData } from "services/api/saveData";

/*
 * handleUpdateValue.ts
 * Handles updating a single value when clicking
 * a button.
 */

interface HandleUpdateValueProps {
    apiObject: string;
    itemId: number;
    apiField: string;
    updateValue: string;
    buttonAction: string;
    setButtonLoader: React.Dispatch<React.SetStateAction<Record<string, boolean>>>;
    setFloatingAlert: Function;
    fetchData?: () => void;
}

export const handleUpdateValue = async ({ 
    apiObject, itemId, apiField, updateValue, buttonAction, setButtonLoader, setFloatingAlert, fetchData
}: HandleUpdateValueProps) => {
    // If no item id is given, return
    if (!itemId) return;

    // Start the button loader for this button
    setButtonLoader(prev => ({ ...prev, [buttonAction]: true }));

    // Try to save the changed resolution
    try {
        const response = await saveData({
            apiObject,
            method: 'patch',
            itemId,
            data: { [apiField]: updateValue },
        })

        // If save is successful, show floating alert
        if (response && response.status === 200) {
            setFloatingAlert({ 'type': 'success' })

            if (fetchData) {
                await fetchData();
            }
        }
    
    // Catch error
    } catch (error) {
        console.error(`Error executing action ${buttonAction} for ${apiObject}:`, error);

    // Stop button loader after successfully saving the data
    } finally {
        setButtonLoader(prev => ({ ...prev, [buttonAction]: false }));
    }
};