import React, { useEffect, useState, useRef } from 'react';
import { FieldData, FieldOption, SearchSelectFieldType } from 'types/FieldTypes';
import { useTranslation, Trans } from 'react-i18next';
import { useModal } from 'components/modals/ModalContext';
import { useClickOutside, useSetDropdownWidth, useSetDropdownPosition } from 'services/utils/dropdownBehavior'
import { useFetchSearchHook } from 'services/api/fetchSearchHook'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { saveData } from 'services/api/saveData';
import axios from 'axios';
import '../../../style/scss/forms.scss';

interface SearchSelectProps extends SearchSelectFieldType {
    onPreventClose?: (shouldPreventClose: boolean) => void;
}

const SearchSelect: React.FC<SearchSelectProps & { data?: FieldData }> = ({
    query, onSelect, value, placeholder, objectName, name, data, selectionFormat, optionFormat, 
    isInvalid, onlyAddUniqueItems = false, disableAddNew = false, allowTextualInput = false, isTextualValue = false, 
    onUnlink, addNewItem, onPreventClose, refetchData
}) => {
    const { t } = useTranslation();
    const { initializeModal } = useModal();
    const [isOpen, setIsOpen] = useState(false);
    const [search, setSearch] = useState('');
    const [textualValue, setTextualValue] = useState('');
    const { options, loading, fetchFinished, resetLastFetchValue } = useFetchSearchHook(query.endpoint, query.params, search);
    const [selectedOption, setSelectedOption] = useState<FieldOption | null>(null);
    const [isEditingTextualInput, setIsEditingTextualInput] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const dropdownListRef = useRef<HTMLDivElement>(null);
    const fieldRef = useRef<HTMLTextAreaElement>(null);
    const [dropdownWidth, setDropdownWidth] = useState<number>(200);
    const [dropdownAbove, setDropdownAbove] = useState(false);
    const [dropdownMaxHeight, setDropdownMaxHeight] = useState<number | undefined>(undefined);

    const isExactMatch = () => {
        return onlyAddUniqueItems ? options.some((option) => option.name.toLowerCase() === search.toLowerCase()) : false;
    };

    // Set the current value as selected option
    useEffect(() => {
        if (data && data[name] !== undefined) {
            setSelectedOption(data[name])

            // Determine if the component should start in text editing mode
            if (isTextualValue) {
                setIsEditingTextualInput(true);
            }
        }
    }, [data, name, isTextualValue]);

    // Helper function that gives the value of an object by it's path
    function getValueByPath(obj: any, path: string) {
        const parts = path.split(/[.\[\]]/).filter((part: string) => part.length);
        return parts.reduce((acc: any, part: string) => acc && acc[part], obj);
    }

    // Format the text in the selection list options
    function formatOption(option: any) {
        let title = '';
        let bracket = '';
        let subtitle = '';

        if (optionFormat) {
            // Format the title
            if (optionFormat.title) {
                const { field, format } = optionFormat.title;
                const value = getValueByPath(option, field);
                title = format(value);
            }

            // Format the optional text between the brackets
            if (optionFormat.bracket) {
                const { field, format, optional } = optionFormat.bracket;
                const value = getValueByPath(option, field);
                if (value || !optional) {
                    bracket = `(${format(value)})`;
                }
            }

            // Format the optional subtitle
            if (optionFormat.subtitle) {
                const { field, format, optional } = optionFormat.subtitle;
                const value = getValueByPath(option, field);
                if (value || !optional) {
                    subtitle = format(value);
                }
            }
        // Set the title in case no option format is given
        } else {
            title = option.name || '';
        }

        // Render the format of the option
        return (
            <React.Fragment>
                <div>{title} {bracket}</div>
                {subtitle && <div className='subtitle'>{subtitle}</div>}
            </React.Fragment>
        );
    }

    const handleLinkNewAddedItem = (response: Record<string, any>) => {
        // On refetch, stop prevent closing
        if (onPreventClose) onPreventClose(false);

        // Convert the newly added item to an option and handle as selected
        // const newAddedItem = { id: response['id'], value: response['id'], name: response[selectionFormat], [selectionFormat]: response[selectionFormat] };
        const newAddedItem = { id: response['id'], value: response['id'], name: response[selectionFormat], ...response };

        handleSelect(newAddedItem);
    }

    // Create a new option
    const createNewOption = async (newOption: string) => {
        if (addNewItem) {
            // Call the parent component to add a new item
            if (addNewItem.method === 'callback') {
                addNewItem.onAddNew();
            }
            
            // Open form modal to add a new item
            if (addNewItem.method === 'modal-form') {

                // Prevent closing of the field when editing the modal
                if (onPreventClose) {
                    onPreventClose(true);
                }
        
                // Open the modal
                initializeModal(
                    React.cloneElement(addNewItem.modalForm, { linkedItem: addNewItem.linkedItem }), { 
                        modalSize: 'small', 
                        title: t('button.add_object_label', { object_name: t(objectName) }),
                        onSuccess: (response) => handleLinkNewAddedItem(response)  
                    }
                );
            }

            // Post directly to add the new item
            if (addNewItem.method === 'direct-post') {
                const source = axios.CancelToken.source();
                try {
                    const data = { name: newOption };
                    await saveData({ apiUrl: addNewItem.postEndpoint, method: 'post', data, source });

                    // Set the new saved option as the selected option
                    setSelectedOption({ value: newOption, name: newOption });
                } catch (error) {
                    console.error('Creating a new option failed', error);
                }
            }

            // Close the dropdown
            setIsOpen(false);
        }         
    };

    // Handle input change
    const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const newValue = e.target.value;
    
        // Set search if no option is yet selected
        if (!selectedOption) {
            setSearch(newValue);
            setIsOpen(true);
        }
    
        // If allow textual input is true, set textual editing to true to show the value
        if (allowTextualInput) {
            setTextualValue(newValue);
            setIsEditingTextualInput(true);
    
            // Update the selected value via onSelect
            if (onSelect) {
                onSelect({ name: newValue, value: newValue });
            }
        } else {
            setIsEditingTextualInput(false);
        }
    
        // If the value is a selected option from the search dropdown, set textual editing to false
        if (selectedOption && newValue === selectedOption.name) {
            setIsEditingTextualInput(false);
        }
    };

    // Set the existing value if given
    useEffect(() => {
        if (value) {
            setSelectedOption(value);

            // Determine if the component should start in text editing mode
            if (isTextualValue) {
                setIsEditingTextualInput(true);
                setTextualValue(value.value.toString());
            }
        }
    }, []);

    // Adjust the field height if the current value exceeds the field height
    useEffect(() => {
        adjustHeight(fieldRef);
    }, [search, selectedOption, selectionFormat]);

    // Handle the selection of an option
    const handleSelect = (option: FieldOption) => {
        setSelectedOption(option);
        if (onSelect) {
            onSelect(option);
        }
    };

    // Handle unlink of an option
    const handleUnlink = (e: React.MouseEvent) => {
        e.preventDefault();
        setSelectedOption(null);
        setSearch('');
        if (onUnlink) {
            onUnlink();
        }
        fieldRef.current?.focus();
        resetLastFetchValue();
    }

    // Close the dropdown list when the searchfield is made empty
    useEffect(() => {
        if (search === '') {
            setIsOpen(false);
        }
    }, [search]);

    // Use dropdownBehavior.ts to close the dropdown when clicking outside
    useClickOutside(isOpen, dropdownRef, setIsOpen);

    // Use dropdownBehavior.ts to set the dropdown width on open
    useSetDropdownWidth(isOpen, dropdownListRef, fieldRef, setDropdownWidth);

    // Use dropdownBehavior.ts to set the dropdown position and max height on open
    useSetDropdownPosition(isOpen, fieldRef, setDropdownAbove, setDropdownMaxHeight);

    // Calculate the height of the text to optionally resize the text area height
    const adjustHeight = (fieldRef: React.RefObject<HTMLTextAreaElement>) => {
        if (fieldRef.current) {
            fieldRef.current.style.height = 'auto';
            const newHeight = fieldRef.current.scrollHeight;

            if (newHeight > 34) {
                fieldRef.current.style.height = `${newHeight}px`;
            } else {
                fieldRef.current.style.height = '34px';
            }
        }
    }

    return (
        <div className='searchselect-container'>
            <textarea 
                name={name}
                ref={fieldRef}
                value={isTextualValue ? textualValue : selectedOption ? selectedOption[selectionFormat] : (search || '')} 
                onChange={handleInputChange}
                onClick={() => { if (selectedOption && !selectedOption[selectionFormat]) setSelectedOption(null); }}
                className={`${isInvalid ? 'is-invalid' : ''}`}
                placeholder={t(placeholder)}
                readOnly={!isEditingTextualInput && !!selectedOption}
                rows={1}
            />
            {loading && <div className="loader"></div>}
            {selectedOption && selectedOption[selectionFormat] && !isEditingTextualInput && (
                <div className='clear-button tooltip-icon' 
                     onClick={(e) => handleUnlink(e)}>
                    <FontAwesomeIcon icon={faTimes} />
                    <span className="tooltip">{t('forms.unlink_label')} {t(objectName)}</span>
                </div>
            )}
            {isOpen && !selectedOption && !loading && fetchFinished && (allowTextualInput ? options.length > 0 : true) && (
                <div className={`custom-dropdown ${dropdownAbove ? 'dropdown-above' : ''}`} ref={dropdownRef} style={{width: (dropdownWidth - 2), maxHeight: dropdownMaxHeight}}>
                    <div className='custom-dropdown-list' style={{width: dropdownWidth}}>
                        <div className='searchselect-options' ref={dropdownListRef}>
                            {options.map((option: FieldOption) => {
                                let selectedOptionId = null;
                                if (selectedOption !== null) {
                                    selectedOptionId = (selectedOption as FieldOption).id;
                                }
                                return (
                                    <div key={option.id || option.name} 
                                         className={`custom-dropdown-item ${selectedOption && selectedOptionId === option.id ? 'active' : ''}`}
                                         onClick={() => handleSelect(option)}>
                                            {formatOption(option)}
                                    </div>
                                )
                            })}
                            {options.length >= 50 && 
                                <div className="limit-message">
                                    {t('forms.dropdown_option_limit_message')}
                                </div>
                            }
                        </div>
                    </div>
                    {!isExactMatch() && fetchFinished && !disableAddNew && (
                        <div className='searchselect-add' onClick={() => createNewOption(search)}>
                            <div className='add-label'>
                                <Trans
                                    i18nKey="forms.searchselect_add_new_label"
                                    values={{ search: search, objectName: t(objectName) }}
                                />
                                </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default SearchSelect;