import { useState, useEffect } from 'react';
import axios, { CancelTokenSource } from 'axios';
import { apiBaseUrl } from 'App';
import { getCsrfToken } from 'services/authentication/csrfFunctions';
import { useFetchContext } from './FetchContext';
import { useGlobalContext } from 'GlobalContext';
import { useAuthContext } from 'services/authentication/AuthenticationContext';

interface FetchDataProps {
    apiUrl: string;
    params?: any;
    transform?: (data: any) => any;
    useCache?: boolean; // Use the earlier fetched and cached data
    useGuard?: boolean;
    guardValue?: any;
}

interface UseFetchDataReturn {
    response: any;
    loading: string;
    refetch: () => Promise<void>;
}

export const useFetchData = ({ apiUrl, params, transform, useCache, useGuard, guardValue }: FetchDataProps): UseFetchDataReturn => {
    const { activeFetches, registerFetch, unregisterFetch, getData, setData } = useFetchContext();
    const { setFormAlert } = useGlobalContext();
    const { handleLogout } = useAuthContext();
    const [response, setResponse] = useState(null);
    const [loading, setLoading] = useState('loading');
    const [trigger, setTrigger] = useState(0);

    useEffect(() => {
        // Skip the request if an empty string is given
        if (apiUrl === '') {
            return;
        }

        // If a guard is given, return and prevent fetching before the guard value is given
        if (useGuard && guardValue === null) {
            setLoading('idle');
            setResponse(null);
            return;
        }

        // Set loading state
        setLoading('loading');

        // Configure the url of the current fetch to use as key
        let url = `${apiBaseUrl}/${apiUrl}/`;

        // Ensure the url ends with a slash (to prevent redirects) if it does not have parameters
        if (!url.endsWith('/') && !url.includes('?')) {
            url += '/';
        }

        // Skip fetch if the url already exists in the active fetches list
        if (activeFetches.has(url)) {
            console.log('Fetch skipped:', apiUrl);
            return;
        };

        // TO DO: implement this at a later stage
        // Skip fetch if the url exists in the cache and return the data
        if (useCache) {
            const cachedData = getData(url);
            if (cachedData) {
                setResponse(cachedData);
                setLoading('success');
                return;
            };
        }
        
        // Set timer for loader and set the cancel source
        let timer: ReturnType<typeof setTimeout> | null = null;
        const source: CancelTokenSource = axios.CancelToken.source();

        // Register the fetch 
        registerFetch(params ? `${url}${params}` : url);

        const fetchData = async () => {
            // Update loading state to show loader after 250 ms
            timer = setTimeout(() => {
                if (loading === 'loading') {
                    setLoading('show-loader');
                }
            }, 250);

            // Get the csrf token from the cookie
            const csrfToken = getCsrfToken();

            // Configure the api request
            const apiRequestConfig = {
                url,
                method: 'get',
                params,
                cancelToken: source.token,
                withCredentials: true,
                headers: { 'X-CSRFToken': csrfToken }
            };

            try {
                // Fetch the data from the server
                const response = await axios(apiRequestConfig);

                // If response status is unauthorized, log out user
                if (response.status === 403 && setFormAlert && handleLogout) {
                    setFormAlert({ type: 'warning', message: "alert.session_expired_message" });
                    handleLogout();
                }

                // Transform the data or return response directly
                const resultData = transform ? transform(response.data.results) : response.data;

                // Set the response with the (transformed) data
                setResponse(resultData);

                // Set the response with the (transformed) data
                setData(url, resultData);

                // Set the loading state to success
                setLoading('success');
            } catch (error) {
                if (axios.isCancel(error)) {
                    console.log('Request canceled', error.message);
                } else {
                    console.log('Fetching data not possible', error);

                    // Set loading state tot error
                    setLoading('error');
                }
            } finally {
                // Unregister the url of a completed fetch from the fetch context
                unregisterFetch(url);

                // Clear timer when fetch is finished
                if (timer) clearTimeout(timer);
            }
        };

        // Execute the fetch function
        fetchData();

        // Clear timer and request on unmount
        return () => {
            if (timer) clearTimeout(timer);
        };
    }, [apiUrl, params, trigger]);

    const refetch = () => new Promise<void>((resolve) => {
        // Raise the trigger to rerun the useEffect
        setTrigger(prev => prev + 1);
        resolve();
    });

    return { response, loading, refetch };
};