import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ContactCardProps } from 'types/DetailPageTypes';
import { ContactType } from '../../views/contacts/ContactTypes'; 
import { useTranslation } from 'react-i18next';
import '../../style/scss/card.scss';
import { useFetchData } from 'services/api/useFetchData';
import { JobType } from '../../views/jobs/JobTypes';
import { ProposalType } from '../../views/proposals/ProposalTypes';
import JobModal from '../../views/jobs/JobModal';
import ProposalModal from '../../views/proposals/ProposalModal';
import { useModal } from 'components/modals/ModalContext';
import { renderDate, renderDocumentNumber, renderStatusBadge } from 'components/lists/functions/renderListColumn';
import { useSettings } from 'services/settings/SettingsContext';

const LinkedDocumentsCard: React.FC<ContactCardProps> = ({ 
    viewKey, itemId, loadStatus, data, isEditable, refetchData
}) => {
    const { t } = useTranslation();
    const { userLocale } = useSettings();
    const { initializeModal } = useModal();
    const { response, loading, refetch } = useFetchData({ apiUrl: `get_deal_linked_items/${itemId}` });
    const [linkedProposals, setLinkedProposals] = useState<ProposalType[] | null>(null);
    // const [linkedOrders, setLinkedOrders] = useState<OrderType[] | null>(null);
    const [linkedJobs, setLinkedJobs] = useState<JobType[] | null>(null);
    // const [linkedInvoices, setLinkedInvoices] = useState<InvoiceType[] | null>(null);

    // Set the linked items
    useEffect(() => {
        if (response) {
            setLinkedProposals(response.related_proposals);
            // setLinkedOrders(response.related_orders);
            setLinkedJobs(response.related_jobs);
            // setLinkedInvoices(response.related_invoices);
        }
    }, [response]);

    const handleOpenLinkedItem = (linkedItemType: 'proposal' | 'order' | 'job' | 'invoice', itemId: number | null ) => {
        if (!itemId) return;

        // Map the given modal component name to the right component
        const modalMapping: Record<string, React.FC<any>> = {
            proposal: ProposalModal,
            job: JobModal,
        };

        // Map the given linked item type to the right modal component
        const ModalComponent = modalMapping[linkedItemType];

        // Initialize the modal
        if (ModalComponent) {
            initializeModal(
                <ModalComponent itemId={itemId} refetchList={refetchData} />, { 
                    itemId, 
                    modalSize: 'large'
                }
            );
        }
    }

    return (
        <div className='card linked-documents-card'>
            <div className="header">
                <h6>
                    {(t('deal.general.linked_documents_card_label'))}
                </h6>
            </div>
            
            {loadStatus === "loading" || loading === "show-loader" ? (
                <div className="loader"></div>
            ) : loadStatus === "loaded" && loading === "success" && (
                <div className='linked-documents-list'>
                    {linkedProposals && (
                        <>
                            {/* <div className='header'>Offertes</div>   */}
                            {linkedProposals?.map((proposal) => (
                                <div onClick={() => handleOpenLinkedItem('proposal', proposal.id)}
                                     className='linked-document'
                                     key={proposal.id}>
                                    <div className='document-name'>
                                        {proposal.number && (renderDocumentNumber(proposal.number, proposal.status, 'proposal'))}
                                    </div>
                                    <span>
                                        {proposal.proposal_date && (renderDate(proposal.proposal_date, userLocale))}
                                    </span>
                                    <span>
                                        {renderStatusBadge(proposal.status)}
                                    </span>
                                </div>  
                            ))}
                        </>
                    )}


                    {}
                </div>
            )}
        </div>
    );
}

export default LinkedDocumentsCard;