import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSettings } from 'services/settings/SettingsContext';
import { useModal } from './ModalContext';
import { useFetchData } from 'services/api/useFetchData';
import { renderDate, renderDocumentNameAndNumber, renderStageBadge, renderStatusBadge } from 'components/lists/functions/renderListColumn';
import { DealType } from '../../views/deal/DealTypes';
import { ProposalType } from '../../views/proposals/ProposalTypes';
import { OrderType } from '../../views/order/OrderTypes';
import { JobType } from '../../views/jobs/JobTypes';
import { InvoiceType } from '../../views/invoices/InvoiceTypes';
import ProposalModal from '../../views/proposals/ProposalModal';
import OrderModal from '../../views/order/OrderModal';
import JobModal from '../../views/jobs/JobModal';
import InvoiceModal from '../../views/invoices/InvoiceModal';
import { useHistory } from 'react-router-dom';

interface LinkedItemsListProps {
    itemId: number;
    objectType: 'proposal' | 'order' | 'job' | 'project' | 'invoice';
    data: any;
    refetchData?: () => void;
}

const LinkedItemsList: React.FC<LinkedItemsListProps> = ({ 
    itemId, objectType, data, refetchData
}) => {
    const { t } = useTranslation();
    const { userLocale } = useSettings();
    const { initializeModal, closeModal } = useModal();
    const shouldFetch = objectType !== 'invoice';
    const { response, loading, refetch } = useFetchData({ apiUrl: shouldFetch ? `get_${objectType}_linked_items/${itemId}` : '' });
    const history = useHistory();
    const [linkedDeals, setLinkedDeals] = useState<DealType[]>([]);
    const [linkedProposals, setLinkedProposals] = useState<ProposalType[]>([]);
    const [linkedOrders, setLinkedOrders] = useState<OrderType[]>([]);
    const [linkedJobs, setLinkedJobs] = useState<JobType[]>([]);
    const [linkedInvoices, setLinkedInvoices] = useState<InvoiceType[]>([]);

    // Set the fetched related linked items
    useEffect(() => {
        if (response) {
            setLinkedDeals(response.related_deals);
            setLinkedProposals(response.related_proposals);
            setLinkedOrders(response.related_orders);
            setLinkedJobs(response.related_jobs);
            setLinkedInvoices(response.related_invoices);
        }
    }, [response]);

    // Set the directly linked items from the item data
    useEffect(() => {
        if (data) {
            // Proposal linked items
            if (objectType === 'proposal') {
                setLinkedDeals(data.deal ? [data.deal] : []);
            }

            // Order linked items
            if (objectType === 'order') {
                setLinkedDeals(data.deal ? [data.deal] : []);
                setLinkedProposals(data.proposal ? [data.proposal] : []);
            }

            // Job linked items
            if (objectType === 'job') {
                setLinkedDeals(data.deal ? [data.deal] : []);
                setLinkedProposals(data.proposal ? [data.proposal] : []);
                setLinkedOrders(data.order ? [data.order] : []);
            }

            // Invoice linked items
            if (objectType === 'invoice') {
                setLinkedDeals(data.deal ? [data.deal] : []);
                setLinkedProposals(data.proposal ? [data.proposal] : []);
                setLinkedOrders(data.order ? [data.order] : []);
                setLinkedJobs(data.job ? [data.job] : []);
            }
        }
    }, [data]);

    // Handle the opening of a linked item
    const handleOpenLinkedItem = (linkedItemType: 'deal' | 'proposal' | 'order' | 'job' | 'invoice', itemId: number | null ) => {
        if (!itemId) return;

        // Handle the opening of detail modals
        if (['proposal', 'order', 'job', 'invoice'].includes(linkedItemType)) {

            // Map the given modal component name to the right component
            const modalMapping: Record<string, React.FC<any>> = {
                proposal: ProposalModal,
                order: OrderModal,
                job: JobModal,
                invoice: InvoiceModal
            };

            // Map the given linked item type to the right modal component
            const ModalComponent = modalMapping[linkedItemType];

            // Initialize the modal
            if (ModalComponent) {
                initializeModal(
                    <ModalComponent itemId={itemId} refetchList={refetchData} />, { 
                        itemId, 
                        modalSize: 'large'
                    }
                );
            }
        }

        // Handle the opening of detail pages
        if (['deal'].includes(linkedItemType)) {

            // Open the deals page
            history.push(`/deals/${itemId}`);

            // Close the modal
            closeModal();
        }
    }

    return (
        loading === "show-loader" ? (
            <div className="loader"></div>
        ) : (

            <div className='linked-items-list'>
                <h6>{t('general.linked_items_label')}</h6>

                {linkedDeals?.map((deal) => (
                    <div onClick={() => handleOpenLinkedItem('deal', deal.id)}
                        className='linked-item'>
                        <div className='item-name'>
                            {deal.title}
                        </div>
                        <span>
                            {renderStageBadge(deal.stage_name, deal.resolution)}
                        </span>
                    </div>  
                ))}

                {linkedProposals?.map((proposal) => (
                    <div onClick={() => handleOpenLinkedItem('proposal', proposal.id)}
                        className='linked-item'>
                        <div className='item-name'>
                            {proposal.number && (renderDocumentNameAndNumber(proposal.number, 'proposal', proposal.status))}
                        </div>
                        {/* <span>
                            {proposal.proposal_date && (renderDate(proposal.proposal_date, userLocale))}
                        </span> */}
                        <span>
                            {renderStatusBadge(proposal.status)}
                        </span>
                    </div>  
                ))}

                {linkedOrders?.map((order) => (
                    <div onClick={() => handleOpenLinkedItem('order', order.id)}
                        className='linked-item'>
                        <div className='item-name'>
                            {order.number && (renderDocumentNameAndNumber(order.number, 'order'))}
                        </div>
                        {/* <span>
                            {order.order_date && (renderDate(order.order_date, userLocale))}
                        </span> */}
                    </div>  
                ))}

                {linkedJobs?.map((job) => (
                    <div onClick={() => handleOpenLinkedItem('job', job.id)}
                        className='linked-item'>
                        <div className='item-name'>
                            {job.number && (renderDocumentNameAndNumber(job.number, 'job'))}
                        </div>
                        <span>
                            {job.status_name && job.status_condition && (renderStatusBadge(job.status_name, job.status_condition))}
                        </span>
                    </div>  
                ))}

                {linkedInvoices?.map((invoice) => (
                    <div onClick={() => handleOpenLinkedItem('invoice', invoice.id)}
                        className='linked-item'>
                        <div className='item-name'>
                            {invoice.number && (renderDocumentNameAndNumber(invoice.number, 'invoice', invoice.status))}
                        </div>
                        {/* <span>
                            {invoice.invoice_date && (renderDate(invoice.invoice_date, userLocale))}
                        </span> */}
                        <span>
                            {renderStatusBadge(invoice.status)}
                        </span>
                    </div>  
                ))}
            </div>
        )
    );
}

export default LinkedItemsList