import React from 'react';
import { FieldType } from 'types/FieldTypes';
import FormModal from 'components/modals/FormModal';
import ContactForm from '../../views/contacts/ContactForm';
import PersonForm from '../../views/contacts/PersonForm';

// Rename the name of the function:
const DealForm: React.FC<{ viewKey?: string, linkedItem?: { [key: string]: number } }> = ({ viewKey, linkedItem }) => {

    const apiObject = 'deal'

    // Plug in the fields and their properties:
    const fields: FieldType[] = [
        { 
            type: 'searchselect', 
            name: 'contact',
            label: 'deal.general.contact_label',
            placeholder: 'deal.general.contact_placeholder',
            objectName: 'contact.general.object_name.singular',
            query: {
                // Get all contacts which have the contact type 'contact', 'prospect' and 'client'
                endpoint: 'get_contact_search',
                params: {
                    contact_type: ['contact', 'prospect', 'client'] 
                }
            },
            selectionFormat: 'name',
            optionFormat: {
                title: { field: 'name', format: (value) => value },
                subtitle: { field: 'primary_location', format: (value) => value.address, optional: true},
            },
            additionalSubmitDataField: 'entity_type',
            addNewItem: { method: 'modal-form', modalForm: <ContactForm /> }
        },
        { 
            type: 'searchselect', 
            name: 'contact_person',
            label: 'deal.general.contact_person_label',
            placeholder: 'deal.general.contact_person_placeholder',
            objectName: 'person.general.object_name.singular',
            query: {
                // Only get persons and when an organization contact is selected, get the persons connected to that organization
                endpoint: 'get_person_search',
                params: { }
            },
            dependentOn: { fieldName: 'entity_type', value: 'organization' },
            viewModeLinkUrl: { baseUrl: '/contacts/person' },
            selectionFormat: 'name',
            optionFormat: {
                title: { field: 'name', format: (value) => value },
            },
            addNewItem: { method: 'modal-form', modalForm: <PersonForm /> }
        },
        {
            type: 'text',
            name: 'title',
            label: 'deal.general.title_label',
            placeholder: 'deal.general.title_placeholder',
        },
        { 
            type: 'dropdown', 
            name: 'stage', 
            label: 'deal.general.stage_label',
            apiObject: 'pipeline_stage',
            disabled_selected: 'deal.general.stage_disabled_selected',
            allowNoneOption: false,
            showSearch: false,
            optionFormat: (option) => option.name,
            selectionFormat: 'name',
            params: {
                ...linkedItem,
                deleted: false,
            },
        },
        { 
            type: 'price', 
            name: 'value', 
            label: 'deal.general.value_label', 
            apiObject: 'currency',
        },
        {
            type: 'date',
            name: 'expected_close_date',
            label: 'deal.general.expected_close_date_label',
        },
    ];

    return (
        <FormModal
            viewKey={viewKey}
            apiObject={apiObject}
            fields={fields}       
        />
    );
};

export default DealForm;