import React, { useState, useEffect, useContext } from 'react';
import { FieldOptionFetchResponse, FieldData, PriceFieldType } from 'types/FieldTypes';
import { CurrencyType } from '../../../views/products/ProductTypes';
import FormFieldContext from '../FormFieldContext';
import { useTranslation } from 'react-i18next';
import { useGlobalContext } from 'GlobalContext';
import { useSettings } from 'services/settings/SettingsContext';
import { formatPriceString } from 'services/utils/amountFormatting';
import { parsePriceToNumberOnUserLocale } from 'services/utils/parsePriceString';
import FieldWrapper from '../FieldWrapper';
import FieldViewMode from '../fields/elements/FieldViewMode';
import '../../../style/scss/live-edit.scss';
import '../../../style/scss/forms.scss';
import '../../../style/scss/tooltip.scss';

interface PriceFieldProps extends PriceFieldType {
    onChange?: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void;
    dropdownData?: Record<string, FieldOptionFetchResponse>;
    errorMessage?: string | string[] | undefined;
    shouldAutoFocus?: boolean;
}

const PriceField: React.FC<PriceFieldProps & { data: FieldData, viewKey: string }> = ({ 
    viewKey, name, label, data, dropdownData, helperText, tooltipText, viewInEditMode, shouldAutoFocus, 
    alignment, isEditable, disabled, roundPrice
}) => {
    const { t } = useTranslation();
    const { userLocale, defaultCurrency } = useSettings();
    const { editing, updatedData, setUpdatedData, handleSubmit } = useContext(FormFieldContext);
    const { errorMessages, setUnsavedChanges } = useGlobalContext();
    const [price, setPrice] = useState('');
    const [selectedCurrency, setSelectedCurrency] = useState<CurrencyType | null>(null);

    // Set the current price from the data
    useEffect(() => {
        if (data && data[name]) {
            setPrice(formatPriceString(data[name], userLocale));
        }
    }, [data, name]);

    // Set the default currency as selected currency
    useEffect(() => {
        if (dropdownData && dropdownData.currency && dropdownData.currency.results && defaultCurrency) {
            // Cast the results to currency type
            const currencyResults = dropdownData.currency.results as unknown as CurrencyType[];

            // Map de currency response to the right type
            const processedCurrencies: CurrencyType[] = currencyResults.map((currency) => ({
                key: currency.key,
                id: currency.id,
                name: currency.name,
                symbol: currency.symbol,
            }));

            // Find the default currency in the processed currencies
            const defaultCurrencyOption = processedCurrencies.find(currency => currency.key == defaultCurrency);

            // Set the found currency as selected currency
            if (defaultCurrencyOption) {
                setSelectedCurrency(defaultCurrencyOption);
            } 
        }
    }, [dropdownData]);

    // Updates the value after changing the price
    const handlePriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        // Set the price
        setPrice(e.target.value);

        // Remove the currency icon from the value
        const trimmedValue = e.target.value.replace(selectedCurrency?.symbol || '', '').trim()

        // Parse to float value but keep de decimal separation
        const parsedValue = parsePriceToNumberOnUserLocale(trimmedValue, userLocale);

        setUpdatedData((prevData: Record<string, any>) => ({
            ...updatedData,
            ...prevData,
            [name]: parsedValue
        }));
        if (e.target.value !== data[name]) {
            setUnsavedChanges(viewKey, true);
        }
    };

    // Generate the right placeholder based on the user locale
    const placeholder = () => {
        // Set the formatter
        const formatter = new Intl.NumberFormat(userLocale, {
            style: "decimal",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });

        // Split the formatter value into parts
        const parts = formatter.formatToParts(0);

        // Determine the decimal separator
        const decimalSeparator = parts.find(part => part.type === "decimal")?.value || ",";

        // Configure the placeholder, rounded or not
        let configuredPlaceholder;
        if (roundPrice) {
            configuredPlaceholder = `0`;
        } else {
            configuredPlaceholder = `0${decimalSeparator}00`
        }

        return configuredPlaceholder;
    };

    // Submits the value after pressing enter
    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            if (name && !errorMessages[name]) {
                if (handleSubmit) handleSubmit();
            }
        }
    }

    // Get the error message from the errorState
    const errorMessage = name && errorMessages[name];

    return (
        <FieldWrapper
            name={name}
            label={label}
            tooltipText={tooltipText}
            helperText={helperText}
            isEditable={isEditable}
            disabled={disabled}
            viewInEditMode={viewInEditMode}
            alignment={alignment}>
            {(editing || viewInEditMode) ? (
                // Edit mode
                <div className='price-field'>
                    <span className="currency-symbol">{selectedCurrency?.symbol}</span>
                    <input 
                        type="text"
                        id={name}
                        name={name}
                        value={price}
                        onChange={handlePriceChange}
                        placeholder={placeholder()}
                        disabled={disabled}
                        autoFocus={shouldAutoFocus}
                        className={errorMessage ? 'is-invalid' : ''}
                        onKeyDown={handleKeyDown}
                    />
                </div>
            ) : (
                // View mode
                <FieldViewMode 
                    type="price"
                    value={price} 
                    currencySymbol={selectedCurrency && selectedCurrency.symbol ? selectedCurrency.symbol : null}
                    roundPrice={roundPrice}
                    alignment={alignment}
                />
            )}
        </FieldWrapper>
    );
};

export default PriceField;