import React from 'react';
import { ListColumnType } from 'types/ListTypes';
import DealSourceForm from './DealSourceForm';
import AbstractList from 'components/lists/AbstractList';

// Rename the name of the function:
const DealSourceList: React.FC = () => {

    // Specify the options of this list:
    const query = {
        endpoint: 'get_source_list',
        defaultParams: { 'deleted': false },
        object: 'source'
    }
    const objectName = 'deal.source.general.object_name'
    const showSearch = false
    const showDeleteOrDeactivate = 'flag_deleted'
    const isDraggable = true

    // Set isPaginated to true if this list may have 100.000+ items:
    const isPaginated = false

    // Specify form, detail modal or detailpage for this list
    const formModal = <DealSourceForm />;
    const onRowClick = 'editModal'

    // Specify the columns of this list:
    const columns: ListColumnType[] = [
        { width: '2fr', field: 'name', label: 'deal.source.general.name_label' },
    ];

    return (
        <AbstractList
            query={query}
            objectName={objectName}
            columns={columns}
            isDraggable={isDraggable}
            showSearch={showSearch}
            showDeleteOrDeactivate={showDeleteOrDeactivate}
            formModal={formModal}
            isPaginated={isPaginated}
            onRowClick={onRowClick}
        />
    );
};

export default DealSourceList;