import React, { createContext, useContext, useState, ReactNode, useRef, useEffect } from 'react';

export interface DraggingState<DraggableId, DroppableId> {
    draggableId: DraggableId;
    origin: DraggingOrigin<DroppableId>;
    index?: number;
}

interface DragDropContextProps<DraggableId, DroppableId> {
    isDragging: boolean;
    dragging: DraggingState<DraggableId, DroppableId> | null;
    dragOver: DroppableId | null;
    placeholder: DroppableId | null;
    draggingVisual: HTMLElement | null;
    setDraggingVisual: (image: HTMLElement | null) => void;
    startDrag: (draggableId: DraggableId, origin: DraggingOrigin<DroppableId>, ref?: HTMLElement | null, index?: number) => void;
    setDragOver: (droppableId: DroppableId) => void;
    updatePlaceholder: (droppableId: DroppableId) => void;
    endDrag: (destination: DroppingDestination<DroppableId> | null) => void;
    onDragEnd: ((event: DragDropEvent<DraggableId, DroppableId>) => void) | null;
}

export interface DragDropEvent<DraggableId, DroppableId> {
    source: DraggingOrigin<DroppableId>;
    destination: DroppingDestination<DroppableId> | null;
    draggableId: DraggableId;
}

export interface DraggingOrigin<DroppableId> {
    droppableId: DroppableId | null;
    rect?: DOMRect;
}

export interface DroppingDestination<DroppableId> {
    droppableId: DroppableId;
    index?: number;
}

// Create the context
const DragDropContext = createContext<DragDropContextProps<any, any> | null>(null);

function useDragDropContext<DraggableId, DroppableId>() {
    const context = useContext(DragDropContext);
    if (!context) {
        throw new Error('useDragDropContext must be used within a DragDropProvider');
    }
    return context as DragDropContextProps<DraggableId, DroppableId>;
}

// Drag drop provider
function DragDropProvider<DraggableId, DroppableId>({
    children,
    onDragStart,
    onDragEnd,
}: {
    children: ReactNode;
    onDragStart?: (event: { draggableId: DraggableId }) => void;
    onDragEnd: (event: DragDropEvent<DraggableId, DroppableId>) => void;
}) {
    const [isDragging, setIsDragging] = useState<boolean>(false);
    const [dragging, setDragging] = useState<DraggingState<DraggableId, DroppableId> | null>(null);
    const [dragOver, setDragOver] = useState<DroppableId | null>(null);
    const [placeholder, setPlaceholder] = useState<DroppableId | null>(null);
    const [draggingVisual, setDraggingVisual] = useState<HTMLElement | null>(null);
    const placeholderRef = useRef<DroppableId | null>(null);
    const activeRef = useRef<HTMLElement | null>(null);

    // Add mouse up eventlistener to listen for the end of the drag
    useEffect(() => {
        window.addEventListener('mouseup', handleMouseUp);

        return () => {
            window.removeEventListener('mouseup', handleMouseUp);
        };
    }, [dragging]);

    // Handle the mouse move
    const handleMouseMove = (event: MouseEvent) => {
        if (!draggingVisual) return;

        // Move the visual element with the mouse
        draggingVisual.style.left = `${event.clientX}px`;
        draggingVisual.style.top = `${event.clientY}px`;
    };

    // End the drag on mouse up
    const handleMouseUp = () => {
        if (dragging) {
            // Get the destination droppable area details from the placeholder
            const destination = placeholderRef.current ? { droppableId: placeholderRef.current } : null; 

            // Handle the end of the drag
            endDrag(destination);
        } else {
            endDrag(null);
        }

        // Delete mouse move and mouse up event listeners
        window.removeEventListener('mousemove', handleMouseMove);
    };

    // Start the drag
    const startDrag = (
        draggableId: DraggableId, 
        origin: DraggingOrigin<DroppableId>, 
        ref?: HTMLElement | null,
        index?: number
    ) => {
        // Set is dragging to true
        setIsDragging(true);

        // Set dragging state
        setDragging({ draggableId, origin, index });

        if (ref) {
            activeRef.current = ref;
            activeRef.current.style.pointerEvents = 'none';
        }

        // Call the onDragStart callback with the draggable id
        if (onDragStart) {
            onDragStart({ draggableId });
        }

        // Set the cursor to grabbing
        document.body.style.cursor = 'grabbing';

        // Add mousemove event listener
        window.addEventListener('mousemove', handleMouseMove);
    };

    // End the drag
    const endDrag = (destination: DroppingDestination<DroppableId> | null) => {   
        // Cleanup visual drag element
        if (draggingVisual && document.body.contains(draggingVisual)) {
            document.body.removeChild(draggingVisual);
            setDraggingVisual(null);
        }
    
        // Put the dropping data in the on drag end callback
        if (dragging && onDragEnd) {
            onDragEnd({
                source: dragging.origin,
                destination,
                draggableId: dragging.draggableId
            });
        }

        // Set the cursor to default
        document.body.style.cursor = 'default';
    
        // Set dragging states to null
        setDragging(null);
        setIsDragging(false);
        setPlaceholder(null);

        // Restore original element
        if (activeRef.current) {
            activeRef.current.style.visibility = 'visible';
            activeRef.current.style.pointerEvents = 'auto';
            activeRef.current = null;
        }

        // Set the placeholder ref to null
        placeholderRef.current = null;
    };

    // Update the placeholder
    const updatePlaceholder = (droppableId: DroppableId) => {
        placeholderRef.current = droppableId;
    };

    return (
        <DragDropContext.Provider
            value={{
                isDragging,
                dragging,
                dragOver,
                placeholder,
                draggingVisual,
                setDraggingVisual,
                startDrag,
                setDragOver,
                updatePlaceholder,
                endDrag,
                onDragEnd,
            }}>
            {children}
        </DragDropContext.Provider>
    );
}

export { DragDropProvider, useDragDropContext };