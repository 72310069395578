import React from 'react';
import { useSettings } from 'services/settings/SettingsContext';
import { ListColumnType, ListFilterType, QueryParamType } from 'types/ListTypes';
import AbstractList from 'components/lists/AbstractList';
import { proposalListFilters } from './ProposalList';
import { renderDate, renderDocumentNumber, renderPriceDecimalColumn, renderStatusBadge } from 'components/lists/functions/renderListColumn';
import ProposalModal from './ProposalModal';

const proposalDetailListConfigurations: Record<string, { params: (itemId: string) => QueryParamType }> = {
    contact: {
        params: (itemId: string) => ({ 'recipient': itemId }),
    },
    organization: {
        params: (itemId: string) => ({ 'recipient': itemId }),
    },
    person: {
        params: (itemId: string) => ({ 'recipient': itemId }),
    },
    location: {
        params: (itemId: string) => ({ 'location': itemId }),
    },
    deal: {
        params: (itemId: string) => ({ 'deal': itemId }),
    }
};

const ProposalDetailList: React.FC<{ itemId: string, detailPage: string }> = ({ itemId, detailPage }) => {
    const { userLocale } = useSettings();

    // Get the detail list configuration
    const configuration = proposalDetailListConfigurations[detailPage];

    // console.log("detailPage", detailPage);

    // Get the default params and dynamic column from the configuration
    const defaultParams = configuration.params(itemId);

    // Specify the columns of this list
    const columns: ListColumnType[] = [
        { width: '1fr', field: 'number', label: 'proposal.general.proposal_number_label', render: (_, row) => renderDocumentNumber(row.number, row.status, 'proposal') },
        { width: '2fr', field: 'internal_reference', label: 'proposal.general.internal_reference_label' },
        { width: '1fr', field: 'proposal_date', label: 'proposal.general.date_label', render: (_, row) => renderDate(row.proposal_date, userLocale) },
        { width: '1fr', field: 'amount', label: 'proposal.general.amount_label', render: (_, row) => renderPriceDecimalColumn(row.amount, row.currency, userLocale) },
        { width: '144px', field: 'status', label: 'proposal.general.status_label', render: (_, row) => renderStatusBadge(row.status) }
    ];

    // Add the page params to the default list filters
    const applyPageParamsToFilters = (filters: ListFilterType[], pageParams: QueryParamType) => {
        return filters.map(filter => {
            if ('filters' in filter) {
                filter.filters = filter.filters.map(subFilter => ({
                    ...subFilter,
                    params: { ...subFilter.params, ...pageParams }
                }));
            } else {
                filter.params = { ...(filter.params || {}), ...pageParams };
            }
            return filter;
        });
    };

    // Specify form, detail modal or detailpage for this list
    const postNewItem = {
        url: 'post_proposal',
        linkedItem: { 
            [detailPage]: itemId,
        }
    }
    const detailModal = <ProposalModal />;
    const onRowClick = 'detailModal' 

    return (
        <div className='detail-list'>
            <AbstractList
                query={{
                    endpoint: 'get_proposal_list',
                    defaultParams,
                    object: 'proposal'
                }}
                objectName='proposal.general.object_name'
                columns={columns}
                filters={applyPageParamsToFilters(proposalListFilters, defaultParams)}
                showSearch={true}
                showDeleteOrDeactivate='flag_deleted'
                buttonSize='small'
                isPaginated={true}
                postNewItem={postNewItem}
                detailModal={detailModal}
                onRowClick={onRowClick}
            />
        </div>
    );
};

export default ProposalDetailList;