import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from 'services/authentication/AuthenticationContext';
import { settingsConfig } from '../../views/settings/SettingsConfig';
import { filterReleasedFeatures } from 'services/permissions/releasedFeatures';
import SideBarNavItem from 'components/buttons/SideBarNavItem';
import '../../style/scss/navigation.scss';

const SettingsMenu: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const { t } = useTranslation();
    const { packageFeatures, activeFeatures, allowedRights } = useAuthContext();

    return (
        <div className='container-medium settings-menu'>
            <div className='col-20'>
                {Object.entries(settingsConfig).map(([groupName, groupConfig]) => {
                    
                    // Filters out the non-released features to hide their setting pages
                    const filteredPackageFeatures = filterReleasedFeatures(packageFeatures);

                    // Filters out the features that are not active and not allowed for the user
                    const visibleItems = groupConfig.items.filter(item => 
                        (!item.requiredFeature || (filteredPackageFeatures.includes(item.requiredFeature) && activeFeatures.includes(item.requiredFeature))) &&
                        (!groupConfig.permissionCheck || allowedRights.includes(groupConfig.permissionCheck))
                    );

                    return visibleItems.length > 0 && (
                        <div className='sidebar-nav-group' key={groupName}>
                            <h4>{t(groupConfig.label)}</h4>
                            <ul>
                                {visibleItems.map(item => (
                                    <SideBarNavItem key={item.route} to={item.route}>
                                        {t(item.label)}
                                    </SideBarNavItem>
                                ))}
                            </ul>
                        </div>
                    );
                })}
            </div>
            <div className='col-80'>
                {children}
            </div>
        </div>
    );
};

export default SettingsMenu;