import { fetchData } from "services/api/fetchData";

/*
 * handleDownloadPdf.ts
 * Handles the downloading of a pdf when clicking
 * a button.
 */

interface HandleDownloadPdfProps {
    itemId: number;
    buttonAction: string;
    data: any;
    t: (key: string, options?: any) => string,
    setButtonLoader: React.Dispatch<React.SetStateAction<Record<string, boolean>>>;
    handleLogout: (options?: { logoutFromServer?: boolean, showSessionAlert?: boolean }) => Promise<void>
    objectType: string;
    layoutId?: number;
}

export const handleDownloadPdf = async ({ 
    itemId, buttonAction, data, t, setButtonLoader, handleLogout, objectType, layoutId
}: HandleDownloadPdfProps) => {

    // If no layout id is given, return
    if (!layoutId) return;

    // Download the pdf
    try {
        // Start the button loader for this button
        setButtonLoader(prev => ({ ...prev, [buttonAction]: true }));

        // Call the api to initiate the generation of the pdf of the given item
        const blob = await fetchData({
            apiUrl: `generate-pdf/${layoutId}/${itemId}`,
            responseAsBlob: true,
            handleLogout
        });

        // Create a link to download the pdf
        const url = window.URL.createObjectURL(new Blob([blob], { type: 'application/pdf' }));
        const link = document.createElement('a');
        link.href = url;

        // Initialise the filename property
        let fileName;

        // Configure the filename for jobs
        if (objectType === 'job') {

            // Get the document name and number. If number is empty, fallback to id
            let documentName = `${t('job.general.object_name.singular')}`;
            let documentNumber = data['number'] !== null ? data['number'] : data['id'];

            // Capitalize the first letter of the documentName
            documentName = documentName.charAt(0).toUpperCase() + documentName.slice(1);

            // Configure the filename
            fileName = `${documentName}_${documentNumber}.pdf`;


        // Configure the filename for orders. They don't have a draft number
        } else if (objectType === 'order') {

            // Get the translation strings
            let documentName = `${t(`${objectType}.general.object_name.singular`)}`;
            let documentNumber = data['number'];

            // Capitalize the first letter of the documentName
            documentName = documentName.charAt(0).toUpperCase() + documentName.slice(1);

            // Configure the filename
            fileName = `${documentName}_${documentNumber}.pdf`;
        

        // Configure the filename for other objects, such as proposals, invoices, etc.
        } else {

            // Get the translation strings
            let documentName = `${t(`${objectType}.general.object_name.singular`)}`;
            let draftStatus = `${t(`${objectType}.status.draft`)}`;
            let documentNumber = data['number'];

            // Capitalize the first letter of the documentName
            documentName = documentName.charAt(0).toUpperCase() + documentName.slice(1);

            // Configure the file name for draft invoices
            if (data['status'] === 'draft') {
                fileName = `${documentName}_${draftStatus}_#${documentNumber}.pdf`;

            // Configure the file name for sent invoices
            } else {
                fileName = `${documentName}_${documentNumber}.pdf`;
            };
        }

        link.setAttribute('download', `${fileName}`);
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
    } catch (error) {
        console.error('Error downloading PDF:', error);

    // Stop button loader after successfully generating the pdf
    } finally {
        setButtonLoader(prev => ({ ...prev, [buttonAction]: false }));
    }
}