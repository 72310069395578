import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useGlobalContext } from 'GlobalContext';
import { useSettings } from 'services/settings/SettingsContext';
import { useAuthContext } from 'services/authentication/AuthenticationContext';
import { useModal } from 'components/modals/ModalContext';
import { useAllowedRight } from 'services/permissions/permissionChecks';
import { v4 as uuidv4 } from 'uuid';
import { fetchDataAndOptionsOfDetailCards } from 'services/api/fetchFieldData';
import { FieldType, FieldOptionFetchResponse, FieldOption, EditableStatusType } from 'types/FieldTypes';
import { DetailPageProps, DetailPageItemId } from 'types/DetailPageTypes';
import generateHeaderButtons from 'components/modals/functions/generateHeaderButtons';
import { TabBar } from './TabBar';
import LiveEditField from 'components/forms/LiveEditField';
import FieldsCard from 'components/detailcards/FieldsCard';
import PeopleCard from 'components/detailcards/PeopleCard';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../../style/scss/detailpage.scss';
import { isArrayOfConditions } from 'services/utils/isArrayOfConditions';
import StatusBar from './StatusBar';
import ContactCard from 'components/detailcards/ContactCard';
import LinkedDocumentsCard from 'components/detailcards/LinkedDocumentsCard';

const DetailPage: React.FC<DetailPageProps> = ({ 
    apiObject, pageObject, headerTitle, headerTitleDataField, headerTitleFields, headerButtons, statusBar, detailCards, tabs 
}) => {
    const { t } = useTranslation();
    const history = useHistory();
    const viewKey = useMemo(() => uuidv4(), []);
    const { id } = useParams<DetailPageItemId>();
    const { setFloatingAlert } = useGlobalContext();
    const { activeFeatures, allowedFeatures, allowedRights, handleLogout } = useAuthContext();
    const { userLocale, userTimezone } = useSettings();
    const { initializeModal } = useModal();
    const hasRightCheck = useAllowedRight;
    const itemId = id;
    const [data, setData] = useState<FieldType[]>([]);
    const [statuses, setStatuses] = useState(null);
    const [currentStatus, setCurrentStatus] = useState<number | null>(null);
    const [resolution, setResolution] = useState<string>('');
    const [header, setHeader] = useState<string | null>(null);
    const [loadStatus, setLoadStatus] = useState<"idle" | "loading" | "loaded">("idle");
    const [reloadTabs, setReloadTabs] = useState(0);
    const [dropdownData, setDropdownData] = useState<Record<string, FieldOptionFetchResponse>>({});
    const [groupOptions, setGroupOptions] = useState<FieldOption[]>([])
    const [buttonLoader, setButtonLoader] = useState<Record<string, boolean>>({});
    const [editable] = useState<EditableStatusType>({ editability: !hasRightCheck('only_view') });

    // Fetch the data of the fields and dropdown options of the page 
    useEffect(() => {
        setLoadStatus("idle");
        fetchData();
        // eslint-disable-next-line
    }, []);

    // Function to fetch the data to be used on initial render and for refetches
    const fetchData = async () => {
        const fetchedData = await fetchDataAndOptionsOfDetailCards(
            apiObject, 
            itemId, 
            dropdownData, 
            groupOptions, 
            headerButtons,
            detailCards, 
            setData, 
            setDropdownData, 
            setGroupOptions, 
            setLoadStatus,
            handleLogout,
            setHeader,
            headerTitleDataField
        );

        if (!fetchedData) return;

        // Get the fetched data of the object
        const [fetchedObjectData] = fetchedData;

        // Set the statuses from the received data
        if (statusBar) {
            // Determine the api fields to get the data from
            const workflowField = statusBar.apiFields?.workflowField ?? 'workflow';
            const statusesField = statusBar.apiFields?.statusesField ?? 'statuses';
            const statusField = statusBar.apiFields?.statusField ?? 'status';
            const resolutionField = statusBar.apiFields?.resolutionField ?? '';

            // Set the status data
            setStatuses(fetchedObjectData[workflowField][statusesField]);
            setCurrentStatus(fetchedObjectData[statusField]);
            setResolution(fetchedObjectData[resolutionField])
        };

        // Return the fetched data
        return fetchedData;
    }

    // Handle the refetch if needed
    const handleRefetch = async () => {
        await fetchData();
        setReloadTabs(prev => prev + 1);
    };

    // Initialize the tab bar if there are tabs given
    let tabLabel, tabComponent;
    if (tabs) {
        ({ tabLabel, tabComponent } = TabBar({tabs, viewKey, itemId: id, pageContext: pageObject, reloadTabs}));
    }

    // Gives the loader a delay to smoothen fast transactions
    useEffect(() => {
        if (loadStatus === "idle") {
            const timer = setTimeout(() => {
                setLoadStatus("loading")
            }, 250);

            return () => {
                clearTimeout(timer);
            };
        }
    }, [loadStatus]);

    // Generates the card components
    const generateCardComponent = (componentType: string, fields: FieldType[], title: string | undefined, itemId: string, pageObject: string, viewKey: string, data: any, apiField: string | undefined, alignment: 'horizontal' | 'stacked' | undefined, dataCondition?: { [key: string]: any }[]) => {
        // If a data condition is set for the card, only render it if the corresponding data object has this value
        if (dataCondition && data) {
            const shouldDisplayCard = dataCondition.every((condition: { [key: string]: any }) =>
                Object.entries(condition).every(([key, value]) => data[key] === value)
            );
            
            if (!shouldDisplayCard) {
                return null;
            }
        }

        switch (componentType) {
            case 'FieldsCard':
                return <FieldsCard 
                            apiObject={pageObject} 
                            itemId={itemId} 
                            viewKey={viewKey} 
                            title={title} 
                            fields={fields} 
                            data={data} 
                            dropdownData={dropdownData} 
                            groupOptions={groupOptions} 
                            alignment={alignment} 
                            loadStatus={loadStatus} 
                            refetchData={handleRefetch} />
            case 'PeopleCard':
                return <PeopleCard 
                            apiObject={pageObject} 
                            itemId={itemId} 
                            viewKey={viewKey} 
                            personIds={apiField ? data[apiField] : undefined} 
                            loadStatus={loadStatus} 
                            isEditable={editable}
                            refetchData={() => handleRefetch()}
                        />
            case 'ContactCard':
                return <ContactCard
                            itemId={itemId} 
                            viewKey={viewKey} 
                            apiObject={apiObject}
                            loadStatus={loadStatus} 
                            data={data}
                            isEditable={editable}
                            refetchData={() => handleRefetch()}
                        />
            case 'LinkedDocumentsCard':
                return <LinkedDocumentsCard
                            itemId={itemId}
                            viewKey={viewKey}
                            apiObject={apiObject}
                            loadStatus={loadStatus} 
                            data={data}
                            isEditable={editable}
                            refetchData={() => handleRefetch()}
                        />
            // Plug in other card components
            default:
                return null;
        }
    }

    // Generate the header buttons
    const generatedHeaderButtons = data && headerButtons && itemId ? generateHeaderButtons(
        headerButtons, 
        pageObject, 
        data, 
        dropdownData, 
        parseInt(itemId), 
        initializeModal, 
        buttonLoader, 
        setButtonLoader, 
        setFloatingAlert,
        handleLogout,
        { activeFeatures, allowedFeatures, allowedRights },
        userTimezone,
        userLocale,
        t,
        undefined,
        handleRefetch,
        undefined,
        history
    ) : null;

    return (
        <div className='detailpage container-filled'>
            <div className='detailpage-header row'>
                <div className='header-title'>
                    <span className='back-button'
                          onClick={() => history.goBack()}>
                        <FontAwesomeIcon icon={faAngleLeft} />
                    </span>
                    {headerTitle && (
                        <h2>{t(headerTitle)}</h2>
                    )}
                    {headerTitleDataField && (
                        <h2>{header}</h2>
                    )}
                    {headerTitleFields?.map((field, index) =>
                        <LiveEditField
                            key={index}
                            viewKey={viewKey}
                            apiObject={pageObject}
                            fieldItem={field}
                            itemId={itemId}
                            data={data} 
                            refetchData={handleRefetch}
                        />
                    )}
                </div>
                {headerButtons && 
                    // Render the header buttons of the detail page
                    <div className='button-container right-align'>
                        {generatedHeaderButtons}
                    </div>
                }
            </div>
            {statusBar && statuses && (
                // Render the status bar of the detail modal
                <StatusBar statuses={statuses} 
                           currentStatus={currentStatus}
                           pageId={parseInt(itemId)}
                           resolution={resolution}
                           statusClickable={statusBar.changeStatusOnClick}
                           refetchData={fetchData}
                />
            )}
            <div className='detailpage-content'
                 style={statusBar ? { marginTop: '1.75rem'} : { marginTop: '2.5rem'}}>
                <div>
                    {detailCards.map((card) =>
                        <div key={`${card.title}-${card.component}`}>
                            {card.component &&
                                generateCardComponent(
                                    card.component, 
                                    card.fields || [], 
                                    card.title, 
                                    itemId, 
                                    pageObject, 
                                    viewKey, 
                                    data, 
                                    card.apiField, 
                                    card.alignment, 
                                    isArrayOfConditions(card.dataCondition) ? card.dataCondition : undefined
                                )
                            }
                        </div>
                    )}
                </div>
                <div>
                    {tabLabel && tabLabel.length > 1 &&  (
                        <ul className='card tabbar'>
                            {tabLabel}
                        </ul>
                    )}
                    {tabComponent}
                </div>
            </div>
        </div>
    );
};

export default DetailPage;