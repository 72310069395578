import React from 'react';
import { FieldType } from 'types/FieldTypes';
import FormModal from 'components/modals/FormModal';

// Rename the name of the function:
const InvoicePaymentForm: React.FC<{ viewKey?: string, linkedItem?: { [key: string]: number } }> = ({ viewKey, linkedItem }) => {
    const apiObject = 'payment'

    // Plug in the fields and their properties:
    const fields: FieldType[] = [
        {
            name: 'amount',
            label: 'invoice.general.payment_amount_label',
            type: 'price',
            apiObject: 'currency',
        },
        {
            name: 'date',
            label: 'invoice.general.payment_date_label',
            type: 'date',
        },
        { 
            type: 'dropdown', 
            name: 'payment_method', 
            label: 'invoice.general.payment_method_label', 
            disabled_selected: 'forms.select_type_disabled_selected',
            defaultValue: { value: 'bank_transfer' },
            allowNoneOption: false,
            showSearch: false,
            hardcodedOptions: [
                { value: 'bank_transfer', name: 'invoice.payment_method.bank_transfer'},
                { value: 'card', name: 'invoice.payment_method.card'},
                { value: 'cash', name: 'invoice.payment_method.cash'},
                { value: 'internal_account', name: 'invoice.payment_method.internal_account'},
            ],
        },
        {
            name: 'note',
            label: 'invoice.general.payment_note_label',
            type: 'textarea',
            isNote: true,
            rows: 2,
            placeholder: 'invoice.general.payment_note_placeholder'
        }
    ];

    return (
        <FormModal 
            viewKey={viewKey}
            apiObject={apiObject}
            fields={fields}
            linkedItem={linkedItem}
        />
    );
};

export default InvoicePaymentForm;