import React, { ReactElement } from 'react';
import InvoicePaymentForm from '../../../views/invoices/InvoicePaymentForm';
import { ModalProps } from '../ModalContext';

export const handleRegisterPayment = ({ invoiceId, t, initializeModal, fetchData }: {
    invoiceId: number,
    t: (key: string, options?: any) => string,
    initializeModal: (content: ReactElement<any>, props?: ModalProps) => void,
    fetchData?: () => void,
}) => {

    // Initialize the register payment modal
    initializeModal(
        <InvoicePaymentForm linkedItem={{ invoice: invoiceId }} />, { 
            title: t('invoice.general.register_payment_header'),
            modalSize: 'small',
            onSuccess: () => {if (fetchData) fetchData()}
        }
    )
}