import React from 'react';
import { ListColumnType, ListFilterType } from 'types/ListTypes';
import AbstractList from 'components/lists/AbstractList';
import OrderModal from './OrderModal';
import { useSettings } from 'services/settings/SettingsContext';
import { renderDate, renderPriceDecimalColumn } from 'components/lists/functions/renderListColumn';

// Specify the filters of this list:
export const orderListFilters: ListFilterType[] = [
    {
        id: 'active',
        label: 'order.general.all_orders_filter_label',
        params: { 'deleted': false },
        default: true,
    },
    {
        group: 'general.archive',
        filters: [
            {
                id: 'deleted',
                label: 'order.general.deleted_orders_label',
                shortName: 'general.deleted',
                params: { 'deleted': true },
            }
        ]   
    }
]

// Rename the name of the function:
const OrderList: React.FC = () => {
    const { userLocale } = useSettings();

    // Specify the options of this list:
    const query = {
        endpoint: 'get_order_list',
        defaultParams: { 'deleted': false },
        object: 'order'
    }
    const objectName = 'order.general.object_name'
    const showSearch = true
    const showDeleteOrDeactivate = 'flag_deleted'
    const showImport = true
    const showExport = true

    // Set isPaginated to true if this list may have 100.000+ items:
    const isPaginated = true

    // Specify form, detail modal or detailpage for this list
    const postNewItem = { url: 'post_order' }

    const detailModal = <OrderModal />;
    const onRowClick = 'detailModal'  

    // Specify the columns of this list
    const columns: ListColumnType[] = [
        { width: '1.5fr', field: 'number', label: 'order.general.number_label' },
        { width: '3fr', field: 'internal_reference', label: 'order.general.internal_reference_label' },
        { width: '2fr', field: 'order_date', label: 'order.general.date_label', render: (_, row) => renderDate(row.order_date, userLocale) },
        { width: '4fr', field: 'client_name', label: 'order.general.client_label' },
        { width: '1.5fr', field: 'amount', label: 'order.general.amount_label', render: (_, row) => renderPriceDecimalColumn(row.amount, row.currency, userLocale) },
    ];

    return (
        <AbstractList
            query={query}
            objectName={objectName}
            columns={columns}
            filters={orderListFilters}
            showSearch={showSearch}
            showDeleteOrDeactivate={showDeleteOrDeactivate}
            showImport={showImport}
            showExport={showExport}
            postNewItem={postNewItem}
            detailModal={detailModal}
            isPaginated={isPaginated}
            onRowClick={onRowClick}
        />
    );
};

export default OrderList;