import React, { useContext, useEffect } from 'react';
import { ReactNode, createContext, useState } from 'react';
import { AlertType, FloatingAlertType } from 'types/AlertTypes';

/*
 * GlobalContext.tsx
 * This context is created as the top level global context for our React
 * app. The states and functions can be shared across components in the
 * whole application.
 */

interface GlobalContextType {
    // Keep track of the general alert on top of the form
    formAlert: AlertType | null;
    setFormAlert: (alert: AlertType | null) => void;

    // Keep track if a floating alert on the right of the screen has to be shown
    floatingAlert: FloatingAlertType | null;
    setFloatingAlert: (alert: FloatingAlertType | null) => void;

    // Keep track if a form has unsaved changes, to show the shakeElement function
    unsavedChangesMap: { [viewKey: string]: boolean };
    setUnsavedChanges: (viewKey: string, hasUnsavedChanges: boolean) => void;
    removeUnsavedChange: (viewKey: string) => void;

    // Keep track if a form or field wants to prevent click outside close
    preventClosingMap: { [viewKey: string]: boolean };
    setPreventClosing: (viewKey: string, preventClosing: boolean) => void;
    removePreventClose: (viewKey: string) => void;

    // Keep track of error messages to be shown on top of a from or below fields
    errorMessages: any;
    setErrorMessages: (value: any) => void;

    // Keep track if the top navbar is enabled
    isTopNavbarEnabled: boolean;
    setIsTopNavbarEnabled: (isEnabled: boolean) => void;

    // Keep track if the footer logo has to be shown
    showFooterLogo: boolean;
    setShowFooterLogo: (showFooterLogo: boolean) => void;
}

const initialState = {
    formAlert: null,
    setFormAlert: () => {},
    floatingAlert: null,
    setFloatingAlert: () => {},
    unsavedChangesMap: {},
    setUnsavedChanges: () => {},
    removeUnsavedChange: () => {},
    preventClosingMap: {},
    setPreventClosing: () => {},
    removePreventClose: () => {},
    errorMessages: {},
    setErrorMessages: () => {},
    isTopNavbarEnabled: false,
    setIsTopNavbarEnabled: () => {},
    showFooterLogo: true,
    setShowFooterLogo: () => {},
}

const GlobalContext = createContext<GlobalContextType>(initialState);

export const GlobalProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [formAlert, setFormAlert] = useState<AlertType | null>(initialState.formAlert)
    const [floatingAlert, setFloatingAlert] = useState<FloatingAlertType | null>(initialState.floatingAlert)
    const [unsavedChangesMap, setUnsavedChangesMap] = useState<{ [viewKey: string]: boolean }>(initialState.unsavedChangesMap);
    const [preventClosingMap, setPreventClosingMap] = useState<{ [viewKey: string]: boolean }>(initialState.preventClosingMap);
    const [errorMessages, setErrorMessages] = useState<Record<string, string | string[] | Record<string, string> | undefined>>(initialState.errorMessages);
    const [isTopNavbarEnabled, setIsTopNavbarEnabled] = useState<boolean>(false);
    const [showFooterLogo, setShowFooterLogo] = useState<boolean>(true);

    // Register the unsaved changes boolean in the unsaved changes map
    const setUnsavedChanges = (viewKey: string, hasUnsavedChanges: boolean) => {
        setUnsavedChangesMap(current => ({ ...current, [viewKey]: hasUnsavedChanges}));
    }

    // Remove a unsaved changes property for a certain view key from the unsaved changes map
    const removeUnsavedChange = (viewKey: string) => {
        setUnsavedChangesMap(current => {
            const newMap = { ...current };
            delete newMap[viewKey];
            return newMap;
        });
    }

    // Register the prevent closing boolean in the prevent closing map
    const setPreventClosing = (viewKey: string, preventClosing: boolean) => {
        setPreventClosingMap(current => ({ ...current, [viewKey]: preventClosing}));
    }

    // Remove a prevent close property for a certain view key from the prevent closing map
    const removePreventClose = (viewKey: string) => {
        setPreventClosingMap(current => {
            const newMap = { ...current };
            delete newMap[viewKey];
            return newMap;
        });
    }

    return (
        <GlobalContext.Provider value={{ formAlert, setFormAlert, floatingAlert, setFloatingAlert,
            unsavedChangesMap, setUnsavedChanges, removeUnsavedChange, preventClosingMap, setPreventClosing,
            removePreventClose, errorMessages, setErrorMessages, isTopNavbarEnabled, setIsTopNavbarEnabled,
            showFooterLogo, setShowFooterLogo }}>
                {children}
        </GlobalContext.Provider>
    )
}

// Create a custom hook for the global context
export const useGlobalContext = () => {
    return useContext(GlobalContext)
}