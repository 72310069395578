import React, { useEffect, useState } from 'react';
import { useModal } from 'components/modals/ModalContext';
import { EventType } from './SchedulingTypes';
import JobModal from '../jobs/JobModal';
import { Draggable } from 'services/dragdrop/Draggable';

interface EventProps {
    event: EventType,
    currentResource: string,
    currentDay: string,
    currentTimeSlot?: string,
    pixelsPerMinute?: number,
    resourceSize?: number | string,
    resourceDirection?: string,
    handleDragStart: (event: React.DragEvent, draggableProps: string) => void;
    handleDragEnd: (event: React.DragEvent) => void;
};

const Event: React.FC<EventProps> = ({
    event, currentResource, currentDay, currentTimeSlot, pixelsPerMinute, resourceSize, resourceDirection, 
    handleDragStart, handleDragEnd
}) => {
    const { initializeModal } = useModal();
    const [renderOnTimeSlot] = useState<boolean>(!!currentTimeSlot);
    const [isDraggable, setIsDraggable] = useState<boolean>(event.status !== 'done');
    // const [timeRenderedPosition, setTimeRenderedPosition] = useState()

    // Determine if the event is draggable based on its status
    useEffect(() => {
        if (event && event.status) {
            if (event.status === 'done') {
                setIsDraggable(false);
            } else {
                setIsDraggable(true);
            }
        }
    });

    // Calculate the event start time offset in pixels from the start of the timeslot
    const calculateStartOffset = () => {
        // Return if the needed values are missing
        if (!event.startDateTime || !currentTimeSlot || !pixelsPerMinute) return;

        // Get the minutes of the timeslot and the start time (e.g. '45' of '07:45')
        const startTimeString = event.startDateTime.split('T')[1];
        const startTimeMinutes = Number(startTimeString.split(':')[1]);
        const timeSlotStartMinutes = Number(currentTimeSlot.split(':')[1]);
        
        // Calculate the start offset in minutes and pixels
        const offsetMinutes = startTimeMinutes - timeSlotStartMinutes;
        const offsetPixels = offsetMinutes * pixelsPerMinute;

        return offsetPixels;
    };

    // Calculate the event duration in pixels
    const calculateDuration = () => {
        // Return if the needed values are missing
        if (!event.startDateTime || !event.endDateTime || !pixelsPerMinute) return;

        // Get the start datetime and end datetime in unix epoch
        const startEpoch = new Date(event.startDateTime).getTime();
        const endEpoch = new Date(event.endDateTime).getTime();

        // Calculate the duration in minutes and pixels
        const durationMinutes = (endEpoch - startEpoch) / 60000;
        const durationPixels = durationMinutes * pixelsPerMinute;

        return durationPixels;
    };

    // Sets the absolute position of timeline rendered events
    const timeRenderedPosition = () => {
        // Return if the needed values are missing
        if (!resourceSize) return;

        // Calculate the positioning values of the appointment
        const startOffset = calculateStartOffset();
        const durationPixels = calculateDuration();
        const appointmentSize = typeof resourceSize === 'number' ? `${Number(resourceSize) - 2}` : resourceSize;
        
        // For stacked direction, the appointments are positioned from left to right
        let timeRenderedStyle = {};
        if (resourceDirection === 'stacked') {
            timeRenderedStyle = {
                position: 'absolute', top: `1px`, left: `${startOffset}px`, width: `${durationPixels}px`, height: `${appointmentSize}px`
            };
        }
 
        // For columns direction, the appointments are positioned from top to bottom
        if (resourceDirection === 'columns') {
            timeRenderedStyle = {
                position: 'absolute', top: `${startOffset}px`, left: `1px`, width: `${appointmentSize}px`, height: `${durationPixels}px`, 
            };
        }

        return timeRenderedStyle;
    };

    // Handle clicking on the event
    const handleEventClick = (e: React.MouseEvent) => {
        e.preventDefault();

        // If the event belongs to an appointment of a job, open the job modal
        if (event.job) {
            initializeModal(
                React.cloneElement(<JobModal />, { itemId: event.job }), { itemId: event.job, modalSize: 'large' }
            );
        };

        // To be implemented: open timesheet or leave events
    };

    return (
        <Draggable 
            key={event.key}
            draggableId={{ 
                type: 'event', 
                key: event.key,
                event,
                job: event.job
            }}
            sourceDroppableArea={{ 
                type: 'timeSlot',
                resourceId: currentResource, 
                day: currentDay,
                timeSlot: currentTimeSlot
            }}>
            <div className={`event ${event.jobStatus}`} 
                // draggable={isDraggable}
                // onDragStart={(e) => handleDragStart(e, draggableProps)}
                // onDragEnd={(e) => handleDragEnd(e)}
                onClick={(e) => handleEventClick(e)}
                style={renderOnTimeSlot ? timeRenderedPosition() : {}}>
                {/* <div className='event-text'> */}
                    {event.startTime}

                    {event.jobClientName && (
                        <>
                            <span style={{ margin: '0 5px' }}>•</span>
                            {event.jobClientName}
                        </>
                    )}

                    {event.jobLocationCity && (
                        <>
                            <span style={{ margin: '0 5px' }}>•</span>
                            {event.jobLocationCity}
                        </>
                    )}
                {/* </div> */}
            </div>
        </Draggable>
    );
};

export default Event;