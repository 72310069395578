import React, { useEffect, useRef, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useAuthContext } from 'services/authentication/AuthenticationContext';
import { useGlobalContext } from 'GlobalContext';
import { useSettings } from 'services/settings/SettingsContext';
import { useModal } from 'components/modals/ModalContext';
import { useFetchData } from 'services/api/useFetchData';
import { useWebSocket } from 'services/api/useWebSocket';
import { saveData } from 'services/api/saveData';
import useJobInboxSpacerHeight from './functions/useJobInboxSpacerHeight';
import { generateWeekNumber } from 'services/utils/dateTimeUtils';
import { generateJobInboxWeeks } from './functions/generateJobInboxWeeks';
import { generateWeekKey, getFirstDateOfWeek, getLastDateOfWeek, initializeInboxJobsFetchParams, newTargetDateInCurrentOrFutureWeek } from './functions/jobInboxUtils';
import { JobType } from '../jobs/JobTypes';
import JobModal from '../jobs/JobModal';
import { formatDisplayDate } from 'internationalization/timezoneConversions';
import { formatAmount } from 'services/utils/amountFormatting';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleRight, faCircleQuestion, faGripLines } from '@fortawesome/free-solid-svg-icons';
import { DragDropEvent, useDragDropContext, DraggingState } from 'services/dragdrop/DragDropContext';
import { Droppable } from 'services/dragdrop/Droppable';
import { SchedulerDragEventDetails, SchedulerDraggableId, SchedulerDroppableId } from './SchedulingTypes';
import { Draggable } from 'services/dragdrop/Draggable';
import '../../style/scss/scheduling.scss'
import DraggableScheduler from './DraggableScheduler';

interface JobInboxProps {
    inboxHeight: number;
    dragging: DraggingState<SchedulerDraggableId, SchedulerDroppableId> | null;
    isDragging: boolean;
    dragOver: SchedulerDroppableId | null;
    completedDragEvent: DragDropEvent<SchedulerDraggableId, SchedulerDroppableId> | null;
    onLoaded: (loaded: boolean) => void;
    onClose: () => void
}

const JobInbox: React.FC<JobInboxProps> = ({ inboxHeight, dragging, isDragging, dragOver, completedDragEvent, onLoaded, onClose }) => {
    const { t } = useTranslation();
    const { environmentHash } = useAuthContext();
    const { setFloatingAlert } = useGlobalContext();
    const { userLocale } = useSettings();
    const { initializeModal } = useModal();
    // const { dragging, isDragging, dragOver } = useDragDropContext<SchedulerDraggableId, SchedulerDroppableId>();
    const [fetchParams, setFetchParams] = useState<{ from?: string, to?: string }>((initializeInboxJobsFetchParams));
    const { response: fetchedInboxJobs, loading: fetchInboxJobsLoading } = useFetchData({ apiUrl: 'get_job_inbox_list', params: fetchParams });
    const [isInitialFetch, setIsInitialFetch] = useState(true);
    const { message: inboxJobsWebsocket } = useWebSocket({ url: 'inbox_jobs', websocketGroup: environmentHash });
    const [visibleWeeks, setVisibleWeeks] = useState<string[]>([]);
    const [pastJobs, setPastJobs] = useState<JobType[]>([]);
    const [weekJobs, setWeekJobs] = useState<{ [week: string]: JobType[] }>({});
    const jobInboxRef = useRef<HTMLDivElement>(null);
    const spacerHeight = useJobInboxSpacerHeight(inboxHeight, visibleWeeks, weekJobs);
    const weekStartsOnSunday = false;

    // Show the first weeks from now on initial render
    useEffect(() => {
        setVisibleWeeks(generateJobInboxWeeks(20));
    }, []);

    // Set the fetched inbox jobs on page load
    useEffect(() => {
        if (fetchedInboxJobs && fetchedInboxJobs.results) {
            const pastJobsList: JobType[] = [...pastJobs];
            const futureJobs: { [week: string]: JobType[] } = {};

            fetchedInboxJobs.results.forEach((job: JobType) => {
                if (job.target_date) {
                    const targetDate = new Date(job.target_date);
                    const now = new Date();

                    // Add job to the past jobs list, if the target date is before today
                    if (targetDate < now) {
                        pastJobsList.push(job);

                    // Add job to the week lists, if the target date is in the future
                    } else {
                        // Generate the week key
                        const week = generateWeekNumber(targetDate, weekStartsOnSunday);
                        const year = targetDate.getFullYear();
                        const weekKey = `${year}-${week}`;

                        // Put the jobs in the week of the week key
                        if (!futureJobs[weekKey]) futureJobs[weekKey] = [];
                        futureJobs[weekKey].push(job);
                    }
                }
            });
    
            setPastJobs(pastJobsList);
            setWeekJobs(prev => ({ ...prev, ...futureJobs }));
            setVisibleWeeks(prev => Array.from(new Set([...prev, ...Object.keys(futureJobs)])));
            setIsInitialFetch(false);
        };
    }, [fetchedInboxJobs]);

    // If the data is successfully loaded on the initial fetch/render, set onLoaded to true
    useEffect(() => {
        if (isInitialFetch && fetchInboxJobsLoading === 'success') {
            onLoaded(true);
        } 
    }, [isInitialFetch, fetchInboxJobsLoading, onLoaded]);

    // Process received updates of inbox jobs by websocket
    useEffect(() => {
        const { type, message } = inboxJobsWebsocket || {};

        if (type === 'inbox_jobs_updated' ) {
            // Get the values from the message to use in this function
            const { id, target_date, status_condition, deleted, ...otherProps } = message;

            // Determine if the new target date is in the current or future weeks
            const targetDateInCurrentOrFutureWeek = newTargetDateInCurrentOrFutureWeek(target_date, weekStartsOnSunday)
            const newWeekKey = generateWeekKey(target_date, weekStartsOnSunday);

            // Update week jobs
            setWeekJobs(existingWeekJobs => {
                const updatedWeekJobs = { ...existingWeekJobs };

                // Remove the job from all existing weeks
                Object.keys(updatedWeekJobs).forEach(weekKey => {
                    updatedWeekJobs[weekKey] = updatedWeekJobs[weekKey].filter(job => job.id !== id);
                });

                // Add the job to the week that correspondents to the new target date
                if (!deleted && (status_condition === 'job_created' || status_condition === 'all_purchases_delivered' || status_condition === 'incomplete') && targetDateInCurrentOrFutureWeek) {
                    if (!updatedWeekJobs[newWeekKey]) {
                        updatedWeekJobs[newWeekKey] = [];
                    }
                    updatedWeekJobs[newWeekKey].push({ id, target_date, status_condition, ...otherProps });
                }

                return updatedWeekJobs;
            });

            // Add the job to the past jobs if the target date is in a past week
            setPastJobs(currentPastJobs => {
                let updatedPastJobs = currentPastJobs.filter(job => job.id !== id);

                if (!deleted && (status_condition === 'job_created' || status_condition === 'all_purchases_delivered' || status_condition === 'incomplete') && !targetDateInCurrentOrFutureWeek) {
                    updatedPastJobs.push({ id, target_date, status_condition, ...otherProps });
                }

                return updatedPastJobs;
            });
        }
    }, [inboxJobsWebsocket]);

    // Load more week rows when scrolling down
    useEffect(() => {
        const jobInboxElement = jobInboxRef.current;
        if (jobInboxElement == null) return;

        // Execute the loading when scrolling
        const handleScroll = () => {
            // Skip when dragging an inbox job, otherwise users may drag into new weeks which doesn't exist as destination yet
            if (isDragging) return;

            if (jobInboxElement && jobInboxElement.scrollTop + jobInboxElement.clientHeight >= jobInboxElement.scrollHeight - 10) {
                loadAdditionalInboxRows();
            };
        };
    
        // Add and remove event listener for scroll event
        jobInboxElement.addEventListener('scroll', handleScroll);
        return () => jobInboxElement.removeEventListener('scroll', handleScroll);
    }, [visibleWeeks, isDragging]);

    // Create and load additional weeks into the job inbox
    const loadAdditionalInboxRows = () => {
        // Determine the current last visible week
        const currentLastWeek = visibleWeeks[visibleWeeks.length - 1];

        // Generate the desired weeks from the current last week
        const additionalWeeksToCreate = 5
        const generatedAdditionalWeeks = generateJobInboxWeeks(additionalWeeksToCreate, currentLastWeek);

        // Add the generated additional weeks to the visible weeks 
        setVisibleWeeks(prev => Array.from(new Set([...prev, ...generatedAdditionalWeeks])));

        // Get the first and last date of the generated weeks
        const firstDate = getFirstDateOfWeek(generatedAdditionalWeeks);
        const lastDate = getLastDateOfWeek(generatedAdditionalWeeks);

        // Update the dates into the fetch params to trigger a new fetch
        setFetchParams({ from: firstDate, to: lastDate });
    };

    // Calculates the total estimated duration of all jobs within an inbox row
    const calculateRowDuration = (jobs: JobType[]): string | null => {
        if (!jobs || jobs.length === 0) {
            return null; 
        }

        const totalDuration = jobs.reduce((total, job) => total + (Number(job.estimated_duration) || 0), 0);
        return formatAmount(totalDuration, userLocale);
    };

    // Generate the week label to show in the week row
    const generateWeekLabel = (weekKey: string, previousWeekKey?: string, isCurrentWeek: boolean = false): string => {
        const [year, week] = weekKey.split('-').map(Number);

        // Determine if the week is the first week of the year
        const firstWeekOfYear = week === 1 || ((previousWeekKey && year !== parseInt(previousWeekKey.split('-')[0])));

        // In case of the first week of the year, show the year
        let weekLabel;
        if (isCurrentWeek) {
            weekLabel = `${t('date_time.general.current_week')}`;
        } else if (firstWeekOfYear) {
            weekLabel = `${t('date_time.general.week_short')} ${week} (${year})`;
        } else {
            weekLabel = `${t('date_time.general.week_short')} ${week}`;
        }

        return weekLabel;
    };
    
    // Extract the details of a completed drag event
    const extractDragEventDetails = (dragEvent: DragDropEvent<SchedulerDraggableId, SchedulerDroppableId>): SchedulerDragEventDetails => {
        // Get the values from the drag event
        const { source, destination, draggableId } = dragEvent;
    
        // Source values
        const sourceType = source?.droppableId?.type === 'jobInbox' ? source?.droppableId?.type : undefined;
        const sourceSection = source?.droppableId?.type === 'jobInbox' ? source?.droppableId?.section : undefined;
        const sourceWeekKey = source?.droppableId?.type === 'jobInbox' && source?.droppableId.weekKey ? source?.droppableId?.weekKey : undefined;
    
        // Destination values
        const destinationSection = destination?.droppableId?.type === 'jobInbox' ? destination?.droppableId?.section : undefined;
        const destinationWeekKey = destination?.droppableId?.type === 'jobInbox' ? destination?.droppableId?.weekKey : undefined;
    
        // Draggable item values
        const draggableItemType = draggableId.type ==='jobInbox' ? draggableId.type : undefined;
        const draggableItemId = draggableId.type ==='jobInbox' ? draggableId.id : undefined;
        const draggableJob = draggableId.type === 'jobInbox' ? draggableId.job : undefined;
        
        return { sourceType, sourceSection, sourceWeekKey, destinationSection, destinationWeekKey, draggableItemType, draggableItemId, draggableJob };
    };

    // Handle the completion of dragging of an inbox job
    useEffect(() => {
        if (completedDragEvent) {
            const handleInboxJobDrag = async () => {

                // Get the values from the completed drag event
                const { sourceType, sourceSection, sourceWeekKey, destinationSection, destinationWeekKey, draggableItemType, draggableItemId, draggableJob } = extractDragEventDetails(completedDragEvent);

                // Prevent users from moving jobs from a week row to a past jobs row
                if (destinationSection === 'pastJobs' && sourceSection !== 'pastJobs') {
                    setFloatingAlert({ 
                        type: 'danger',
                        message: t("scheduling.job_inbox.invalid_move_to_past_alert")
                    });
                    return;
                }

                // Handle dragging of inbox jobs to other week rows inside the job inbox
                if (draggableItemType === 'jobInbox' && destinationSection === 'weekJobs' && destinationWeekKey) {
                
                    // Skip if a job is dropped back into the same week it was taken from
                    if (sourceWeekKey === destinationWeekKey) {
                        return;
                    }
                    
                    // Set the dragging item to null to prevent flickering when updating the past- or weekjobs states
                    // setDraggingItem(null);

                    // Get the right list of jobs wheter the item is dragged from the past- or weekjobs
                    const initialJobs = sourceSection === 'pastJobs' ? [...pastJobs]
                        : sourceSection === 'weekJobs' && sourceWeekKey ? [...(weekJobs[sourceWeekKey] || [])]
                        : [];


                    // Find the index of the moved job
                    const jobIndex = initialJobs.findIndex(job => job.id === draggableItemId);

                    // Continue if the index is found
                    if (jobIndex > -1) {
                        const [movedJob] = initialJobs.splice(jobIndex, 1);

                        // Add the moved jobs to the destination week
                        const destinationJobs = [...(weekJobs[destinationWeekKey] || [])];
                        destinationJobs.push(movedJob);

                        // Update the past- and weekjobs states to remove the job from there
                        if (sourceType === 'pastJobs') {
                            setPastJobs(initialJobs);
                        } else {
                            if (sourceWeekKey) {
                                setWeekJobs(prev => ({ ...prev, [sourceWeekKey]: initialJobs }));
                            }
                        }

                        // Update the weekjobs state to add the job to the new destination week
                        setWeekJobs(prev => ({ ...prev, [destinationWeekKey]: destinationJobs }));

                        // Adjust the new target date to the same weekday
                        const originalTargetDate = new Date(movedJob.target_date!);
                        const originalTargetDateDay = originalTargetDate.getDay();

                        // If the original target date day is on sunday (day 0), replace it with 7, to put it on the end of the week instead of the beginning
                        const originalTargetDateDayOffset = (originalTargetDateDay === 0 ? 7 : originalTargetDateDay);
                        
                        // Calculate the new target date
                        const firstDayOfWeek = getFirstDateOfWeek([destinationWeekKey]);
                        const newTargetDate = new Date(firstDayOfWeek);

                        // Add the original target date day offset
                        newTargetDate.setDate(newTargetDate.getDate() + originalTargetDateDayOffset - (weekStartsOnSunday ? -1 : 0))

                        // Convert the new target date to an iso string
                        const formattedNewTargetDate = newTargetDate.toISOString().split('T')[0]

                        // Save the updated job details
                        const response = await saveData({
                            apiObject: 'job',
                            itemId: movedJob.id,
                            data: { target_date: formattedNewTargetDate }
                        });

                        // Get the week number from the destination week
                        const weekNumber = parseInt(destinationWeekKey.split('-')[1], 10);

                        // Show floating alert on success
                        if (response?.status === 200) {
                            setFloatingAlert({ 
                                type: 'success',
                                message: t("scheduling.job_inbox.target_date_changed_alert", {
                                    target_date: formatDisplayDate(new Date(formattedNewTargetDate), userLocale), 
                                    week_number: weekNumber
                                }) 
                            });
                        }
                    }                    
                };

                // Handle dragging of an appointment into the job inbox
                if (draggableItemType === 'event' && destinationSection === 'weekJobs' && destinationWeekKey) {
                    // To implement in a later stage
                }
            }
            handleInboxJobDrag();
        }
    }, [completedDragEvent]);

    // Render a dragging placeholder, when the drag is over the current droppable area
    const draggingPlaceholder = (droppableId: SchedulerDroppableId) => {
        if (dragging && (JSON.stringify(droppableId) === JSON.stringify(dragOver))) {
            return (
                <div
                    style={{
                        height: dragging.origin.rect?.height || '50px',
                        width: '100%',
                        backgroundColor: '#D8D8D8BF',
                        boxSizing: 'border-box',
                        pointerEvents: 'none',
                    }}
                />
            );
        }
        return null;
    };

    // Render an inbox row
    const renderInboxRow = ({ jobs, section, weekKey, label, calculateDuration }: {
        jobs: JobType[], section: 'pastJobs' | 'weekJobs', weekKey?: string, label: string, calculateDuration: (jobs: JobType[]) => string | null 
    }) => (
        <Droppable droppableId={{ type: 'jobInbox', section, ...weekKey ? { weekKey } : {} }} 
                   showPlaceholder={false}
                   key={`${section}${weekKey ? `-${weekKey}` : ``}`}>
            <div className='inbox-row'>
                <div className='header'>
                    <span className='label'>
                        {t(label)}
                    </span>
                    <span className='budget'>
                        {calculateDuration(jobs) ? `${calculateDuration(jobs)} ${t('date_time.general.hour.singular')}` : ''}
                    </span>
                </div>
                <div>
                    {jobs.filter((job => !job.deleted && (
                        job.status_condition === 'job_created' || job.status_condition === 'all_purchases_delivered' || job.status_condition === 'incomplete'
                    ))).map((job) => (
                        renderJobInboxItem(job, section, weekKey)
                    ))}
                    {draggingPlaceholder({ type: 'jobInbox', section, ...weekKey ? { weekKey } : {} })}
                    <div className='row-space'></div>
                </div>
            </div>
        </Droppable>
    );

    // Renders a job inbox item
    const renderJobInboxItem = (job: JobType, section: 'pastJobs' | 'weekJobs', weekKey?: string) => (
        <Draggable key={job.id}
                   draggableId={{ type: 'jobInbox', id: job.id, job, ...weekKey ? { weekKey } : {} }}
                   sourceDroppableArea={{ type: 'jobInbox', section, ...weekKey ? { weekKey } : {} }}>
            <div className='job-inbox-item'
                onClick={() => initializeModal(React.cloneElement(<JobModal />, { itemId: job.id }), { itemId: job.id, modalSize: 'large' }) }>
                <div className='info'>
                    <div className='title'>
                        {job.number} {job.client_name}
                        {job.status_name === 'order_received' && (
                            <span className={`status-badge ${job.status_condition}`}>{t(`workflow.default_status_names.${job.status_name}`)}</span>
                        )}
                    </div>
                    <div className='remark'>
                        {job.target_date && `${formatDisplayDate(new Date(job.target_date), userLocale)}`}
                        {job.estimated_duration && ` • ${formatAmount(Number(job.estimated_duration), userLocale)} ${t('date_time.general.hour.singular')}`}
                    </div>
                </div>
                <div className='order-icon'>
                    <FontAwesomeIcon icon={faGripLines} />
                </div>
            </div>
        </Draggable>
    );

    return (
        <div className='job-inbox'>
            <div className='header'>
                <div className='title'>
                    {t('scheduling.job_inbox.job_inbox_title')}
                </div>
                <div className='help tooltip-icon'>
                    <FontAwesomeIcon icon={faCircleQuestion} />
                    <span className="tooltip tooltip-bottom">{t('scheduling.job_inbox.job_inbox_tooltip')}</span>
                </div>
                <div className='icons'>
                    <div className='icon tooltip-icon'>
                        <FontAwesomeIcon icon={faAngleDoubleRight} onClick={() => onClose()}/>
                        <span className="tooltip tooltip-left">{t('scheduling.job_inbox.hide_icon_tooltip')}</span>
                    </div>
                </div>
            </div>
            <div className='inbox' 
                 style={{ height: '100%', maxHeight: inboxHeight }} 
                 ref={jobInboxRef}>
                {isInitialFetch ? (
                    fetchInboxJobsLoading === 'show-loader' && (
                        <div className='inbox-loader'>
                            <div className="loader"></div>
                        </div>
                    )
                ) : (
                    <>
                        <div className='content'>
                            <div className='inbox-rows'>
                                {pastJobs.length > 0 && (
                                    renderInboxRow({
                                        jobs: pastJobs,
                                        section: 'pastJobs',
                                        label: 'scheduling.job_inbox.past_week_label',
                                        calculateDuration: calculateRowDuration
                                    })
                                )}
                                {visibleWeeks.map((weekKey, index) => (
                                    renderInboxRow({
                                        jobs: weekJobs[weekKey] || [],
                                        section: 'weekJobs',
                                        weekKey,
                                        label: generateWeekLabel(weekKey, index > 0 ? visibleWeeks[index - 1] : undefined, index === 0),
                                        calculateDuration: calculateRowDuration
                                    })
                                ))}
                            </div>
                            <div style={{ height: spacerHeight }}></div>
                        </div>   
                        <span className='scroll-text'>
                            <Trans i18nKey="scheduling.job_inbox.load_more_weeks_helpertext" 
                                components={[<span onClick={loadAdditionalInboxRows} className="scroll-text-button"/>]}>
                                {t('scheduling.job_inbox.load_more_weeks_helpertext')}
                            </Trans>
                        </span>
                    </>
                )}
            </div>
        </div>
    );
};

export default JobInbox;


{/* <DraggableScheduler>
    <DragDropProvider<SchedulerDraggableId, SchedulerDroppableId>
        onDragEnd={(event) => setDragEvent(event)}>
        <Scheduler completedDragEvent={dragEvent}>
            <JobInbox inboxHeight={jobInboxHeight}
                    completedDragEvent={completedDragEvent}
                    onLoaded={() => {}}
                    onClose={() => setShowInbox(false)}>
            </JobInbox>
        </Scheduler>
    </DragDropProvider>
</DraggableScheduler> */}