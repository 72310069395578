import React, { useState, useRef, useEffect } from 'react';
import { TimelineItemType, TimelineUserType } from 'types/TimelineTypes';
import { useGlobalContext } from 'GlobalContext';
import axios from 'axios';
import { saveData } from 'services/api/saveData';
import { deleteData } from 'services/api/deleteData';
import { handleSaveErrors } from 'services/api/handleSaveErrors';
import { formatTimelineDate } from 'services/utils/dateTimeUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStickyNote, faChevronDown, faChevronUp, faThumbtack, faTrash, faPen } from '@fortawesome/free-solid-svg-icons';
import TimelineNoteForm from './TimelineNoteForm';
import { useTranslation } from 'react-i18next';
import '../../style/scss/tooltip.scss';

interface TimelineNoteProps {
    item: TimelineItemType;
    createdBy?: TimelineUserType | null;
    isPinned?: boolean;
    isAnotherPinned?: boolean;
}

const TimelineNote: React.FC<TimelineNoteProps> = ({ item, isPinned, createdBy, isAnotherPinned }) => {
    const { t } = useTranslation();
    const { setFloatingAlert } = useGlobalContext();
    const [isExpanded, setIsExpanded] = useState(false);
    const [isExpandable, setIsExpandable] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const textRef = useRef<HTMLParagraphElement>(null);
    const helperTextRef = useRef<HTMLDivElement>(null);
    
    // Format the date
    const noteDate = formatTimelineDate(item.ordering_date, false);

    // Expand the note
    const toggleIsExpanded = () => {
        setIsExpanded(!isExpanded);
    };

    // Shows the text expand icon if the helpertext element is higher than the visible text element
    useEffect(() => {
        if (textRef.current && helperTextRef.current) {
            const visibleTextHeight = textRef.current.offsetHeight;
            const helperTextHeight = helperTextRef.current.offsetHeight;
            setIsExpandable(helperTextHeight > visibleTextHeight);
        }
    }, [item]);

    // Delete the note in the database
    const deleteNote = async () => {
        if (item.note?.timeline_object_id) {
            try {
                await deleteData({ apiObject: 'note', itemId: item.note?.timeline_object_id, source: axios.CancelToken.source()})
                setFloatingAlert({ type: 'success' })
            } catch (error) {
                setFloatingAlert({ type: 'danger', message: 'timeline.note.alert.delete_note_failed_message' })
            }
        } else {
            console.error('No timeline object id found for this note');
        }
    }

    // Save the pin/unpin to the database
    const toggleIsPinned = async () => {
        try {
            // Configure the saveData request
            const apiObject = 'note';
            const itemId = item.note?.timeline_object_id;
            const data = { ...item.note, pinned: !item.note?.pinned };
            const source = axios.CancelToken.source();

            // Send the pin status to the backend
            await saveData({ apiObject, itemId, method: 'put', data, source })
        } catch (error) {
            // Handle the errors using the handleSaveErrors function
            const fieldNames = ['pinned'];
            const errorData = handleSaveErrors(error, fieldNames); 
        }
    };

    // Determine the pin tooltip text based on its state
    const pinTooltipText = isPinned ? 'timeline.note.general.unpin_note_label' : 'timeline.note.general.pin_note_label';

    // Determine the expand tooltip text based on its state
    const expandTooltipText = isExpanded ? 'general.shrink_label' : 'general.expand_label';

    return (
        <div className={`timeline-item note ${isPinned ? 'pinned' : ''}`}>
            <div className={`marker-container ${isPinned ? 'hide' : ''}`}>
                <div className="big-marker">
                    <FontAwesomeIcon icon={faStickyNote} className='note-icon fa-fw' />
                </div>
            </div>
            <div className={`note-content ${isEditing ? 'editing' : ''}`}>
                <div className='note-info'>
                    <div className='left-items'>
                        {noteDate}
                        {createdBy && createdBy.full_name && (
                            <>
                                <span style={{ margin: '0 5px' }}>•</span>
                                <span>{createdBy.full_name}</span>
                            </>
                        )}
                    </div>
                    <div className='right-items'>
                        {!isPinned && isExpandable && !isEditing &&
                            <div className='tooltip-icon expand-icon'
                                 onClick={toggleIsExpanded}>
                                    <FontAwesomeIcon icon={isExpanded ? faChevronUp : faChevronDown}/>
                                    <span className="tooltip">{t(expandTooltipText)}</span>
                            </div> 
                        }
                        {!isAnotherPinned && !isEditing &&
                            <div className='tooltip-icon pin-icon'
                                 onClick={toggleIsPinned}>
                                    <FontAwesomeIcon icon={faThumbtack} />
                                    <span className="tooltip">{t(pinTooltipText)}</span>
                            </div>   
                        }
                        {!isEditing &&
                            <>
                                <div className='tooltip-icon edit-icon'
                                    onClick={() => setIsEditing(true)}>
                                        <FontAwesomeIcon icon={faPen} />
                                        <span className="tooltip">{t('general.edit_label')}</span>
                                </div>
                                <div className='tooltip-icon delete-icon'
                                     onClick={deleteNote}>
                                        <FontAwesomeIcon icon={faTrash} />
                                        <span className="tooltip">{t('general.delete')}</span>
                                </div>  
                            </>
                        }
                    </div>
                </div>
                {isEditing ? (
                    <TimelineNoteForm
                        noteId={item.note?.timeline_object_id.toString()}
                        timeline_item={item.note?.timeline_item}
                        timeline_page_id={item.note?.timeline_page_id ?? ''}
                        isNoteFormOpen={isEditing}
                        setIsNoteFormOpen={setIsEditing}
                        resetActiveButtonItem={() => {}}
                    />
                ) : (item.note && 
                    <>
                        <p ref={textRef} 
                           className='note-text' 
                           style={!isPinned && !isExpanded ? { overflow: 'hidden', display: '-webkit-box', WebkitLineClamp: '2', WebkitBoxOrient: 'vertical', whiteSpace: 'pre-wrap', wordBreak: 'break-word' } : { whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}
                           dangerouslySetInnerHTML={{ __html: item.note.note }}>
                        </p>
                        {/* Help element to determine if the text expand icon needs to show */}
                        <p ref={helperTextRef} 
                           className='note-text' 
                           style={{ position: 'absolute', visibility: 'hidden', height: 'auto', width: '100%', whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}
                           dangerouslySetInnerHTML={{ __html: item.note.note }}>
                        </p>
                    </>
                )}
            </div>
        </div>
    );
};

export default TimelineNote;