import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from 'services/authentication/AuthenticationContext';
import { useSettings } from 'services/settings/SettingsContext';
import { roleSpecificFeatures } from 'services/permissions/roleSpecificFeatures';
import { getReleasedFeatures } from 'services/permissions/releasedFeatures';
import { navigationConfig } from '../../views/navigation/NavigationConfig';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import logo from '../../assets/crafter-logo-header.png';
import '../../style/scss/navigation.scss';
import { saveData } from 'services/api/saveData';
import { deleteData } from 'services/api/deleteData';
import { isHomepageAllowed } from 'services/routes/isHomePageAllowed';
import { useGlobalContext } from 'GlobalContext';

const Navbar: React.FC = () => {
    const { t } = useTranslation();
    const { isTopNavbarEnabled, setIsTopNavbarEnabled } = useGlobalContext();
    const { isLoggedIn, firstName, lastName, activeFeatures, allowedFeatures, allowedRights, redirectTo, environmentType, environmentHash, handleLogout } = useAuthContext();
    const { userPreferences } = useSettings();
    const [userName, setUserName] = useState<string | null>(null);
    const [profilePicture, setProfilePicture] = useState<string | null>(null);
    const [selectedMenu, setSelectedMenu] = useState<String | null>(null);
    const [dropdownVisible, setDropdownVisible] = useState<boolean>(false);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const currentLocation = useLocation();
    const trialWizard = redirectTo?.includes('trial-wizard');

    // Set the profile picture if the user preferences changes
    useEffect(() => {
        if (userPreferences) setProfilePicture(userPreferences.profile_picture);
    }, [userPreferences]);

    // Get the name of the logged in user from the local storage
    useEffect(() => {
        if (firstName && lastName) {
            setUserName(`${firstName} ${lastName}`);
        } else if (firstName) {
            setUserName(`${firstName}`)
        } else {
            setUserName(t('account.my_account_label'))
        }
    }, [isLoggedIn, firstName, lastName]);

    // Updates the selected menu item based on the current location
    useEffect(() => {
        const currentRoute = currentLocation.pathname;
        const selectedMenu = Object.keys(navigationConfig).find(menu => 
            navigationConfig[menu].route === currentRoute || isSubrouteOfMenu(currentRoute, menu)
        );
        setSelectedMenu(selectedMenu || null);
    }, [currentLocation]);

    // Listens to click outside to close the dropdown
    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (dropdownVisible && dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setDropdownVisible(false);
            }
        };
        window.addEventListener('click', handleClickOutside);
        return () => {
            window.removeEventListener('click', handleClickOutside);
        };
    }, [dropdownVisible]);

    // Checks if the current route is a sub menu of a main menu
    const isSubrouteOfMenu = (route: string, menu: string) => {
        if(route === "/" && navigationConfig[menu]?.subMenu?.some(subItem => subItem.route === "/home")) {
            return true;
        } else {
            return navigationConfig[menu]?.subMenu?.some(subItem => 
                route === subItem.route || route.startsWith(subItem.route + '/')
            );
        }
    }

    // Check if the feature is active in the environment and the user is allowed to see it based on its role
    const checkActiveFeature = (featureName: string) => {
        const isActive = activeFeatures.includes(featureName);
        const isRoleSpecific = roleSpecificFeatures.includes(featureName);
        const isReleased = getReleasedFeatures().includes(featureName);

        // Handle if it's a role specific feature
        if (isRoleSpecific) {

            // Check if the feature is in the activeFeatures, allowedFeatures and if it's released
            return isActive && allowedFeatures.includes(featureName) && isReleased;
        }

        // For normal features, check if it is in the activeFeatures and if it's released
        return isActive && isReleased;
    };

    // Renders the main menu items
    const renderMainMenu = () => {
        return Object.keys(navigationConfig).map(key => {
            const item = navigationConfig[key];

            // Hide this main menu item if its own feature is not active
            if (item.activeFeature && !checkActiveFeature(item.activeFeature)) {
                return null;
            }

            // Find the role specific features in the submenus of this main menu item
            const roleSpecificSubFeatures = item.subMenu?.filter(subItem => subItem.activeFeature && roleSpecificFeatures.includes(subItem.activeFeature));

            // Check if at least one role specific feature is active
            const isAnyRoleSpecificFeatureActive = roleSpecificSubFeatures?.some(subItem => subItem.activeFeature && checkActiveFeature(subItem.activeFeature));

            // If no role specific features are active, do not show this main menu item
            if (roleSpecificSubFeatures && roleSpecificSubFeatures.length > 0 && !isAnyRoleSpecificFeatureActive) {
                return null;
            }

            // Determine the route of the main menu item, it's the first active sub menu item
            let activeSubMenu = item.subMenu?.find(subItem => subItem.activeFeature && checkActiveFeature(subItem.activeFeature));
            let route = activeSubMenu ? activeSubMenu.route : item.route;

            // Check if the current menu item is active
            const isActive = currentLocation.pathname === item.route || (item.route !== '/' && currentLocation.pathname.startsWith(item.route)) || isSubrouteOfMenu(currentLocation.pathname, key);

            return (
                <Link key={key}
                    className={`navbar-item ${isActive ? 'active' : ''}`}  
                    to={route}
                    onClick={() => setSelectedMenu(key)}>
                    {item.icon ? (
                        <FontAwesomeIcon icon={item.icon} />
                    ) : (
                        t(item.name)
                    )}
                </Link>
            );
        });
    };

    // Renders the sub menu items
    const renderSubMenu = (menu: string) => {
        return navigationConfig[menu]?.subMenu?.map(item => {

            // Hide the homepages based on the environment type
            if (!isHomepageAllowed(item.route, environmentType)) {
                return null;
            }

            // Hide the menu item if the allowed feature is not active in the role
            if (item.allowedFeature && !allowedFeatures.includes(item.allowedFeature)) {
                return null;
            }

            // Hide the menu item if the feature is not active
            if (item.activeFeature && !checkActiveFeature(item.activeFeature)) {
                return null;
            }

            // Check if the current menu item is active
            const isActive = currentLocation.pathname === item.route || (currentLocation.pathname === '/' && item.route === '/home') || currentLocation.pathname.startsWith(item.route);

            return (
                <Link key={item.route}
                      className={`subnav-item ${isActive ? 'active' : ''}`} 
                      to={item.route}>
                    {t(item.name)}
                </Link>
            );
        });
    }

    // Function that disables the visibility of the dropdown menu
    const toggleDropdown = () => {
        setDropdownVisible(!dropdownVisible);
    };

    // Render top bar
    const renderTopBar = () => {

        // Render topbar for demo and admin environment types
        if (['demo', 'admin'].includes(environmentType)) {

            // Set top bar enabled property
            setIsTopNavbarEnabled(true);

            return (
                <div className={`topbar ${environmentType}`}>
                    {`- ${environmentType.charAt(0).toUpperCase() + environmentType.slice(1)} ${t('nav.top_bar_environment_label')} -`}
                </div>
            )
        }

        // Otherwise don't render topbar
        return null
    }

    const handleDeleteDemoData = async () => {

        if (environmentHash) {
            try {
                // const response = await deleteData({ apiUrl: `delete_trial_demo_data/${environmentHash}` });
                const response = await deleteData({ apiUrl: `delete_trial_demo_data/${parseInt('1')}` });


                if (response) console.log(response);

                if (response && response.status === 204) {

                }
            } catch (error) {
                console.log(error)
            };

        }
    }

    // Only shows the navbar if the user is logged in
    return isLoggedIn ? (
        <>
            {renderTopBar()}
            <nav className="navbar">
                <div className="navbar-container">
                    <div className="logo-container">
                        <Link to="/">
                            <img src={logo} alt="Logo" className='logo'/>
                        </Link>
                    </div>
                    {!trialWizard && (
                        // Hide nav bar buttons in the trial wizard
                        <>
                            <div className="main-menu-items">
                                {renderMainMenu()}
                            </div>
                            <div className='right-menu-items'>
                                <div className='profile-container' 
                                     ref={dropdownRef} 
                                     onClick={toggleDropdown}>
                                    <div style={{ backgroundImage: `url(${profilePicture})` }} 
                                        className='profile-image' />
                                    <div className='navbar-item'>
                                        {userName}
                                    </div>
                                </div>
                                {dropdownVisible && (
                                    <div className={`dropdown-menu ${isTopNavbarEnabled ? 'topbar-enabled' : ''}`}>
                                        {allowedRights.includes('change_settings') && (
                                            <Link className='dropdown-item' 
                                                  to="/settings"
                                                  onClick={() => setDropdownVisible(false)}>
                                                    {t('nav.settings_label')}
                                            </Link>
                                        )}
                                        <Link className='dropdown-item' 
                                              to="/preferences"
                                              onClick={() => setDropdownVisible(false)}>
                                                {t('nav.personal_preferences_label')}
                                        </Link>
                                        <div className="dropdown-item" 
                                             onClick={() => {
                                                handleLogout({ logoutFromServer: true }); 
                                                setDropdownVisible(false);
                                            }}>
                                            {t('nav.log_out_label')}
                                        </div>
                                        {/* <div className="dropdown-item" 
                                             onClick={() => {
                                                handleDeleteDemoData(); 
                                                setDropdownVisible(false);
                                            }}>
                                            {t('Delete demo data')}
                                        </div> */}
                                    </div>
                                )}
                            </div>
                        </>
                    )}
                </div>
            </nav>
            {selectedMenu && (
                <nav className="subnavbar">
                    <div className='container subnavbar-container'>
                        {renderSubMenu(String(selectedMenu))}
                    </div>
                </nav>
            )}
        </>
    ) : null;
};

export default Navbar;