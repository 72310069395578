import React from 'react';
import SettingsContainer, { SettingsSettingType } from '../../../components/settings/SettingsContainer';

const OrderSettings: React.FC = () => {
    const settings: SettingsSettingType[] = [
        {
            title: 'Order nummerreeks',
            explanationText: 'Uitleg hoe dit werkt',
            fields: [
                {
                    type: 'text',
                    label: 'Nummerreeks',
                    name: 'order_numbering_scheme',
                    placeholder: '',
                    group: 'field-row'
                },
                {
                    type: 'number',
                    label: 'Volgend nummer',
                    name: 'order_current_number',
                    group: 'field-row'
                }
            ]
        },
        // TODO: commented out, has to be implemented again
        // {
        //     title: 'Gewonnen offertes omzetten in orders',
        //     explanationText: 'Laat automatisch een order aanmaken zodra een offerte is geaccepteerd.',
        //     switchButton: {
        //         enabled: true,
        //         name: '',
        //     }
        // },
    ];

    return (
        <SettingsContainer settings={settings} />
    );
};

export default OrderSettings;