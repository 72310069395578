import React, { useState, useContext, useEffect } from 'react';
import { PhoneNumbersFieldsetType, FieldData } from 'types/FieldTypes';
import { PhoneNumberType, createEmptyPhoneNumber } from '../../../views/contacts/ContactTypes';
import { useTranslation } from 'react-i18next';
import { useGlobalContext } from 'GlobalContext';
import { useAllowedRight } from 'services/permissions/permissionChecks';
import { v4 as uuidv4 } from 'uuid';
import FormFieldContext from '../FormFieldContext';
import { saveData } from 'services/api/saveData';
import { handleSaveErrors } from 'services/api/handleSaveErrors';
import { formValidation } from 'services/utils/formValidation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import '../../../style/scss/live-edit.scss';
import '../../../style/scss/forms.scss';

interface PhoneNumberFieldsetProps extends PhoneNumbersFieldsetType {
    object?: string;
    errorMessage?: string | string[] | undefined;
    shouldAutoFocus?: boolean;
    handleFieldSubmit?: boolean;
    fieldSubmitted?: () => void;
    refetchData?: () => void;
}

const PhoneNumbersFieldset: React.FC<PhoneNumberFieldsetProps & { data: FieldData, viewKey: string }> = ({
    viewKey, name, object, data, helperText, viewInEditMode, alignment, handleFieldSubmit, fieldSubmitted, 
    refetchData, isEditable
}) => {
    const { t } = useTranslation();
    const { editing, setEditing, updatedData, setButtonLoader, setUpdatedData, showErrorAlert, setShowErrorAlert } = useContext(FormFieldContext);
    const { errorMessages, setErrorMessages, setUnsavedChanges, setFloatingAlert } = useGlobalContext();
    const hasRightCheck = useAllowedRight;
    const [rows, setRows] = useState<PhoneNumberType[]>([]);
    const [userHasOnlyViewRights] = useState<boolean>(hasRightCheck('only_view'));

    // If there are already phone numbers, set the current values in the fields
    useEffect(() => {
        if (data && data[name] && data[name].length > 0) {
            // Set the phone numbers
            const extractedPhoneNumbers = data[name].map((row: PhoneNumberType) => ({ ...row }));
            setRows(extractedPhoneNumbers)

        // If there are no phone numbers, create an empty row
        } else if ((editing || viewInEditMode) && object) {
            const emptyRow = createEmptyPhoneNumber(object, data, uuidv4());
            setRows([emptyRow]);
        }
    }, [data]);

    // Handle the phone number change
    const handlePhoneNumberChange = (event: React.ChangeEvent<HTMLInputElement>, identifier: string) => {
        // Copy the rows and find the item index
        const updatedRows = [...rows];
        const rowIndex = updatedRows.findIndex(row => row.id?.toString() === identifier || row.uuid === identifier);

        if (rowIndex !== -1) {
            // Set the phone number in the updated rows
            updatedRows[rowIndex].phone_number = event.target.value;
            
            // Update the rows and updated data
            setRows(updatedRows);
            setUpdatedData((prev: any) => ({ ...prev, [name]: updatedRows }))
        }        
    };

    // Handle deletion of a row
    const handleDeleteRow = (identifier: string) => {
        let updatedRows = [...rows];
        const rowIndex = updatedRows.findIndex(row => row.id?.toString() === identifier || row.uuid === identifier);

        if (rowIndex !== -1) {
            // Get the row from the updated rows
            const row = updatedRows[rowIndex]

            // Check if the row is a new row added in the frontend (it has an uuid but no id)
            if (!row.id && row.uuid) {
                // Directly remove it from the updated rows
                updatedRows = updatedRows.filter((_, index) => index !== rowIndex)
        
            } else {
                // Flag the backend known row as deleted without actually delete it
                updatedRows[rowIndex].deleted = true;
            }
    
            // Update the ordering of the items which are not marked as deleted
            const nonDeletedItems = updatedRows.filter(row => !row.deleted);
            const reorderedNonDeletedItems = nonDeletedItems.map((row, index) => ({
                ...row,
                position: index + 1,
            }));

            // Add the deleted items to the reordered list to keep them for the updated data
            const finalItems = [...reorderedNonDeletedItems, ...updatedRows.filter(row => row.deleted)];
    
            setRows(finalItems);
            setUpdatedData((currentUpdatedData: any) => ({ ...currentUpdatedData, [name]: finalItems }))
        } 
    }

    const handleAddRow = () => {
        if (object) {
            // Create a new row
            const newRow = createEmptyPhoneNumber(object, data, uuidv4());
        
            // Add the row to the list of rows
            const updatedRows = [...rows, newRow];
        
            // Update the rows and updated data
            setRows(updatedRows);
            setUpdatedData((currentUpdatedData: any) => ({ ...currentUpdatedData, [name]: updatedRows }))
        }
    };

    const handleFieldSubmitFunction = async() => {
        try {
            if (setButtonLoader) setButtonLoader(true);

            // Validate the phone numbers before submitting
            const validationErrors = rows.map((row) => formValidation('phonenumber-widget', {phone_number: row.phone_number}));
            if (validationErrors.some(error => error !== null)) { setErrorMessages(validationErrors); return };

            // Patch the phone numbers and refetch the data after saving
            await saveData({ apiUrl: 'patch_phonenumber_batch', method: 'patch', data: updatedData });
            if (refetchData) await refetchData();

            // Clear the updatedData to prevent resubmitting and duplicate items
            setUpdatedData({});

            // Clear the errors, show a success alert, set unsaved changes to false and close the edit mode
            setErrorMessages({});
            setFloatingAlert({ type: 'success' })
            setUnsavedChanges(viewKey, false);
            if (setEditing) setEditing(false);
        } catch (error) {
            const fieldNames = ['phone_number'];
            const errorData = handleSaveErrors(error, fieldNames); 
            if (setShowErrorAlert) setShowErrorAlert(true)
            setErrorMessages(errorData);
            setUnsavedChanges(viewKey, true);
        } finally {
            if (setButtonLoader) setButtonLoader(false);
            // If the submitting is finished, reset the submitting status in the parent component
            if (fieldSubmitted) fieldSubmitted();
        }
    }

    useEffect(() => {
        if (handleFieldSubmit === true) handleFieldSubmitFunction();
    }, [handleFieldSubmit]);

    // Submits the widget field after pressing enter
    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            if (!errorMessages['phone_number']) {
                handleFieldSubmitFunction();
            }
        }
    }

    const content = (
        <>
            <label htmlFor={name}>
                {t('phone.general.phone_label')}
                {alignment === 'horizontal' && !editing && !viewInEditMode && 
                    <span className='edit-icon'>
                        <FontAwesomeIcon icon={faPen} />
                    </span>
                }
            </label>
            <div>
                {showErrorAlert &&
                    <div className="alert form-alert alert-danger" role="alert">
                        {t(errorMessages.general, { defaultValue: errorMessages.general })}
                    </div>
                }
                {(editing || viewInEditMode) ? (
                    // Edit mode
                    <div className='edit-mode'>
                        {rows && rows.filter(row => row.deleted === false).map((row, index) => {
                            const identifier = row.id?.toString() || row.uuid;

                            return (
                                <div key={identifier} className='list-item'>
                                    <div className='single-field'>
                                        <input 
                                            type="text"
                                            id={`phone_number_${identifier}`}
                                            name={`phone_number_${identifier}`}
                                            value={row.phone_number}
                                            onChange={event => handlePhoneNumberChange(event, identifier || '')}
                                            placeholder={t('phone.general.phone_placeholder')}
                                            onKeyDown={handleKeyDown}
                                            autoFocus={true}
                                            className={errorMessages[index]?.phone_number ? 'is-invalid' : ''}
                                            style={{ marginRight: '0.3rem' }} />
                                        <div className='delete-icon'>
                                            <FontAwesomeIcon 
                                                icon={faTrash} 
                                                onClick={() => handleDeleteRow(identifier || '')} />
                                        </div>
                                    </div>
                                    {errorMessages[index]?.phone_number && 
                                        <div className='error-message'>
                                            {t(errorMessages[index]?.phone_number, { defaultValue: errorMessages[index]?.phone_number })}
                                        </div>
                                    }
                                </div>
                            )
                        })}
                        <div onClick={(e) => {e.preventDefault(); handleAddRow(); }} 
                            className="add-new-button">
                            {t('forms.add_new')}
                        </div>
                        {helperText && 
                            <div className="helper-text">
                                {t(helperText)}
                            </div>
                        }
                    </div>
                ) : (
                    // View mode
                    <div className="view-mode">
                        <span className='p'>
                            {rows.filter(row => !row.deleted).length > 0 ? (
                                <>
                                    <div className='item-list'>
                                        {rows.map((phoneNumber, index) => (
                                            <p key={index}>
                                                <a href={`tel:${phoneNumber.phone_number}`}
                                                className='item-link'
                                                onClick={(e) => e.stopPropagation()}>
                                                    {phoneNumber.phone_number}
                                                </a>
                                            </p>
                                        ))}
                                    </div>
                                    {alignment === 'stacked' &&
                                        <span className='edit-icon'>
                                            <FontAwesomeIcon icon={faPen} />
                                        </span>
                                    }
                                </>
                            ) : (
                                <span className="no-value">
                                    <span className='minus-sign'>-</span>
                                    <span className="hover-text">{t('forms.add_value')}</span>
                                </span>
                            )}
                        </span>
                    </div>
                )}
            </div>
        </>
    )

    return (
        <div className='widget-field'>
            { alignment === 'horizontal' 
                ? <div className={`horizontal-alignment ${isEditable && !userHasOnlyViewRights ? 'editable' : ''} ${editing ? 'editing' : ''}`}>{content}</div> 
                : <div className={`stacked-alignment ${isEditable && !userHasOnlyViewRights ? 'editable' : ''}`}>{content}</div> 
            }
        </div>
    );
}

export default PhoneNumbersFieldset;