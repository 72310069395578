import React, { useState } from 'react';
import { ListColumnType, ListFilterType } from 'types/ListTypes';
import AbstractList from 'components/lists/AbstractList';
import { useSettings } from 'services/settings/SettingsContext';
import { renderPriceDecimalColumn, renderStageBadge } from 'components/lists/functions/renderListColumn';
import DealForm from './DealForm';
import KanbanBoard, { KanbanCardDraggableId, KanbanDroppableId } from 'components/kanban/KanbanBoard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartKanban } from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { DragDropEvent, DragDropProvider } from 'services/dragdrop/DragDropContext';

// Specify the filters of this list:
export const dealListFilters: ListFilterType[] = [
    {
        id: 'active',
        label: 'deal.general.all_deals_filter_label',
        params: { 'deleted': false },
        default: true,
    },
    {
        group: 'deal.general.resolution_filter_group_label',
        filters: [
            {
                id: 'open',
                label: 'deal.general.open_deals_label',
                params: { 'resolution': 'open' },
            },
            {
                id: 'won',
                label: 'deal.general.won_deals_label',
                params: { 'resolution': 'won' },
            },
            {
                id: 'lost',
                label: 'deal.general.lost_deals_label',
                params: { 'resolution': 'lost' },
            },
        ]
    },
    {
        group: 'general.archive',
        filters: [
            {
                id: 'deleted',
                label: 'deal.general.deleted_deals_label',
                shortName: 'general.deleted',
                params: { 'deleted': true },
            }
        ]   
    }
]

// Rename the name of the function:
const DealPage: React.FC = () => {
    const { userLocale, defaultCurrency } = useSettings();
    const [activeView, setActiveView] = useState<'kanban' | 'list'>('kanban');
    const [dragEvent, setDragEvent] = useState<DragDropEvent<KanbanCardDraggableId, KanbanDroppableId> | null>(null);

    // Set the list config
    const listConfig = {
        query: {
            endpoint: 'get_deal_list',
            defaultParams: { 'deleted': false },
            object: 'deal'
        },
        objectName: 'deal.general.object_name',
        showSearch: true,
        showDeleteOrDeactivate: 'flag_deleted' as 'flag_deleted',
        // showImport: true,
        // showExport: true,
        isPaginated: true,
        formModal: <DealForm />,
        onRowClick: 'detailPage' as 'detailPage',
        detailPageUrl: 'deals',
        columns: [
            { width: '4fr', field: 'title', label: 'deal.general.title_label' },
            { width: '4fr', field: 'contact_name', label: 'deal.general.contact_label' },
            { width: '1.5fr', field: 'value', label: 'deal.general.value_label', render: (_, row) => renderPriceDecimalColumn(row.value, defaultCurrency ?? '', userLocale, true) },
            { width: '144px', field: 'status', label: 'deal.general.stage_label', render: (_, row) => renderStageBadge(row.stage_name, row.resolution) }
        ] as ListColumnType[],
    }

    // Set the kanban config
    const kanbanConfig = {
        detailPageUrl: 'deals',
    }

    // Set the change view button config
    const changeViewButtonConfig = {
        buttons: [
            { 
                icon: <FontAwesomeIcon icon={faChartKanban as IconProp} />,
                tabValue: 'kanban',
                tooltipText: 'deal.general.pipeline_tab_button_label'
            },
            { 
                icon: <FontAwesomeIcon icon={faBars} />,
                tabValue: 'list',
                tooltipText: 'deal.general.list_tab_button_label'
            },
        ],
        currentView: activeView,
        onViewChange: (view: string) => handleViewChange(view)
    }

    // Handle the view change
    const handleViewChange = (view: string) => {
        if (view === 'kanban' || view === 'list') {
            setActiveView(view);
        }
    }

    return (
        <>
            {activeView === 'kanban' ? (
                <DragDropProvider<KanbanCardDraggableId, KanbanDroppableId>
                    onDragEnd={(event) => setDragEvent(event)}>
                    <KanbanBoard 
                        detailPageUrl={kanbanConfig.detailPageUrl}
                        changeViewButton={changeViewButtonConfig}
                        completedDragEvent={dragEvent}
                    />
                </DragDropProvider>
            ) : (
                <AbstractList
                    query={listConfig.query}
                    objectName={listConfig.objectName}
                    columns={listConfig.columns}
                    filters={dealListFilters}
                    showSearch={listConfig.showSearch}
                    showDeleteOrDeactivate={listConfig.showDeleteOrDeactivate}
                    // showImport={listConfig.showImport}
                    // showExport={listConfig.showExport}
                    changeViewButton={changeViewButtonConfig}
                    formModal={listConfig.formModal}
                    detailPageUrl={listConfig.detailPageUrl}
                    isPaginated={listConfig.isPaginated}
                    onRowClick={listConfig.onRowClick}
                />
            )}
        </>
    );
};

export default DealPage;