import React, { ReactElement } from 'react';
import { saveData } from "services/api/saveData";
import { ModalProps } from "../ModalContext";
import InvoiceModal from "../../../views/invoices/InvoiceModal";

export const handleCreateInvoice = async ({
    itemId,
    initializeModal,
    setFloatingAlert,
    setButtonLoader,
    buttonAction,
    workflowId,
}: {
    itemId: number;
    initializeModal: (content: ReactElement<any>, props?: ModalProps, itemId?: number | null) => void; 
    setFloatingAlert: Function;
    setButtonLoader: React.Dispatch<React.SetStateAction<Record<string, boolean>>>;
    buttonAction: string;
    workflowId?: number;
}) => {
    // If no layout id is given, return
    if (!workflowId) return;

    try {
        // Start the button loader
        setButtonLoader((prev) => ({ ...prev, [buttonAction]: true }));

        // Configure the data object from the item data
        const submitData: Record<string, any> = {
            order_id: itemId,
        }

        // If a workflow id is given, add it to the submit data
        if (workflowId) {
            submitData.workflow = workflowId;
        }

        // Create the new invoice
        const response = await saveData({
            apiUrl: `post_invoice`,
            method: 'post',
            data: submitData
        });

        // Open the new invoice after succesfully creating
        if (response && response.status === 201) {
            const newInvoiceId = response.data.id;

            if (newInvoiceId) {
                initializeModal(
                    <InvoiceModal itemId={newInvoiceId} />,
                    { modalSize: 'large', itemId: newInvoiceId }
                )
            }

            // Show success alert
            setFloatingAlert({
                type: 'success',
                message: 'validation.invoice.created_successfully',
            });
        }
    } catch (error) {
        setFloatingAlert({
            type: 'danger',
            message: 'validation.invoice.creation_failed',
        });
    } finally {
        // Stop the button loader
        setButtonLoader((prev) => ({ ...prev, [buttonAction]: false }));
    }
};