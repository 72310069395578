import { EnvironmentSettingsType } from "services/settings/SettingsTypes";
import { SchedulingViewType } from "../SchedulingTypes";
import dayjs from 'dayjs';

// Generates a time key from a datetime string
export const generateTimeKeyFromDateTimeString = (start_datetime: string): string => {
    // Split the start time from the start datetime string
    const startTimeString = start_datetime.split('T')[1];

    // Add 0 prefix for hours/minutes below 10
    const pad = (number: number) => number < 10 ? '0' + number : number;

    // Get the hour and minutes from the starttime string
    const hour = pad(Number(startTimeString.split(':')[0]));
    const minutes = pad(Number(startTimeString.split(':')[1]))

    // Return the generated the time key
    return `${hour}:${minutes}`;
}

// Generates a time key from a time sting
export const generateTimeKeyFromTimeString = (time_string: string): string => {
    // Add 0 prefix for hours/minutes below 10
    const pad = (number: number) => number < 10 ? '0' + number : number;

    // Get the hour and minutes from the starttime string
    const hour = pad(Number(time_string.split(':')[0]));
    const minutes = pad(Number(time_string.split(':')[1]))

    // Return the generated the time key
    return `${hour}:${minutes}`;
}

// For events rendered on timeslots, determine the nearest timeslot to render the event in
export const determineNearestTimeSlot = (start_datetime: string): string => {
    // Split the start time from the start datetime string
    const startTimeString = start_datetime.split('T')[1];

    // Get the hour and minutes from the starttime string
    const hour = parseInt(startTimeString.split(':')[0], 10);
    const minutes = parseInt(startTimeString.split(':')[1], 10);

    // Determine the nearest timeslot start time slot by rounding down the minutes
    const roundedMinutes = minutes - (minutes % 15);

    // Format the hour and minutes to strings with leading zero if necessary
    const formattedHour = hour < 10 ? '0' + hour : '' + hour;
    const formattedMinutes = roundedMinutes < 10 ? '0' + roundedMinutes : '' + roundedMinutes;

    // Return the generated time slot key
    return `${formattedHour}:${formattedMinutes}`;
}

// Converts a day key and timeslot back to a local start datetime string
export const convertDayKeyAndTimeSlotToStartDateTimeString = (dayKey: string, timeSlot: string, userLocale: string, userTimezone: string): string => {
    // Combine the daykey and timeslot into a date string
    const combinedDateTimeString = `${dayKey}T${timeSlot}:00`;

    // Create a date object from the combined string
    const date = new Date(combinedDateTimeString);

    // Format the datetime to the users locale and timezone
    const dateTimeFormatter = new Intl.DateTimeFormat(userLocale, {
        timeZone: userTimezone,
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
    });

    // Extract formatted parts
    const formattedParts = dateTimeFormatter.formatToParts(date).reduce((acc: any, part: any) => {
        acc[part.type] = part.value;
        return acc;
    }, {});

    // Construct the iso-like datetime string
    const localDateTimeString = `${formattedParts.year}-${formattedParts.month}-${formattedParts.day}T${formattedParts.hour}:${formattedParts.minute}:${formattedParts.second}`;
    
    return localDateTimeString;
};

// Converts a day key, timeslot and duration back to a local end datetime string
export const convertDayKeyAndTimeSlotToEndDateTimeString = (dayKey: string, startTime: string, duration: string | null, userLocale: string, userTimezone: string): string => {
    // Combine the daykey and starttime into a date object
    const startDateTimeString = `${dayKey}T${startTime}:00`;
    const startDate = new Date(startDateTimeString);

    // Calculate the duration in milliseconds
    let durationInMinutes;
    if (!duration) {
        durationInMinutes = 1 * 60;
    } else {
        durationInMinutes = parseFloat(duration) * 60;
    }
  
    const durationInMilliseconds = durationInMinutes * 60 * 1000;

    // Create the enddate by adding the duration to the startdate
    const endDate = new Date(startDate.getTime() + durationInMilliseconds);

    // Formatter for the users locale and timezone
    const dateTimeFormatter = new Intl.DateTimeFormat(userLocale, {
        timeZone: userTimezone,
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
    });

    // Format the enddate into a string
    const formattedParts = dateTimeFormatter.formatToParts(endDate).reduce((acc: any, part: any) => {
        acc[part.type] = part.value;
        return acc;
    }, {});

    return `${formattedParts.year}-${formattedParts.month}-${formattedParts.day}T${formattedParts.hour}:${formattedParts.minute}:${formattedParts.second}`;
};


// Set the default scheduling view settings from the environment settings
export const getSchedulingSettings = (settings: EnvironmentSettingsType): SchedulingViewType => ({

    // Get the view type, direction and default days to show from the environment settings
    viewType: settings.scheduling_default_view || 'employee_list',
    direction: settings.scheduling_direction || 'stacked',
    showDays: settings.scheduling_display_mode || 'mon-sat',

    // Get the display times or set it by default to 06-18h, (and convert it to time keys ('07:00' instead of '07:00:00'))
    showTimes: {
        startTime: generateTimeKeyFromTimeString(settings.scheduling_display_start_time || '06:00:00'),
        endTime: generateTimeKeyFromTimeString(settings.scheduling_display_end_time || '18:00:00')
    },

    // By default, show the scheduling on today 'now' on opening
    displayDate: new Date()
});

// Generate the days to display in the current scheduling view
export const generateDisplayDays = (view: SchedulingViewType): Date[] => {

    // Get the display date and the days to show setting from the current view
    const { displayDate, showDays } = view;

    // Determine the the current day number of the display date
    const displayDateDay = displayDate.getDay();

    // Calculate the offset from the display date day to monday
    let offsetToMonday;
    if (displayDateDay === 0) {
        offsetToMonday = - 6;
    } else {
        offsetToMonday = 1 - displayDateDay;
    };

    // Revert the display date day to monday to generate the start date of the week 
    const startDateOfWeek = displayDate.setDate(displayDate.getDate() + offsetToMonday);

    // Convert the start date into a date object
    const startDate = new Date(startDateOfWeek);

    // Determine the number of days to show based of the show days scheduling setting
    let daysToShow = showDays === 'all' ? 7 : showDays === 'mon-sat' ? 6 : 5;

    // Generate an array with the number of days determined in the days to show
    const generatedDisplayDays = Array.from({ length: daysToShow }, (_, i) => {
        // Create a new date object from the start date
        const day = new Date(startDate);

        // Multiply the date + 1
        day.setDate(startDate.getDate() + i);

        // Add the date to the array
        return day;
    });

    return generatedDisplayDays;
};

// Generate the time slots to display in the current scheduling view
export const generateTimeSlots = (view: SchedulingViewType): string[] | undefined => {

    // Get the showable times from the current view. If no times are set, return undefined
    const { showTimes } = view;
    const viewStartTime = showTimes?.startTime;
    const viewEndTime = showTimes?.endTime;

    // If no view start- or end time is set, return undefined
    if (!viewStartTime || !viewEndTime) {
        return undefined;
    };
    
    // Parse the start and end time components
    const [startHour, startMinute] = viewStartTime.split(':').map(Number);
    const [endHour, endMinute] = viewEndTime.split(':').map(Number);

    // Create startTime and endTime with day.js
    let startTime = dayjs().hour(startHour).minute(startMinute);
    const endTime = dayjs().hour(endHour).minute(endMinute);

    // Create a new array to store the times
    const generatedTimes = [];

    // Generate times (in quarters) in the specified range
    while (startTime.isBefore(endTime)) {
        generatedTimes.push(startTime.format('HH:mm'));
        startTime = startTime.add(15, 'minutes');
    }

    return generatedTimes;
}

// Generate the hour slots to display in the scheduling header
export const generateHeaderHours = (view: SchedulingViewType): string[] | undefined => {

    // Get the showable times from the current view. If no times are set, return undefined
    const { showTimes } = view;
    const viewStartTime = showTimes?.startTime;
    const viewEndTime = showTimes?.endTime;

    // If no view start- or end time is set, return undefined
    if (!viewStartTime || !viewEndTime) {
        return undefined;
    };
    
    // Parse the start and end hour components
    const [startHour] = viewStartTime.split(':').map(Number);
    const [endHour] = viewEndTime.split(':').map(Number);

    // Create a new array to store the times
    let currentHour = startHour;
    const generatedHours: string[] = [];

    // Generate times (in quarters) in the specified range
    while (currentHour < endHour) {
        generatedHours.push(currentHour.toString());
        currentHour += 1;
    }

    return generatedHours;
}

// Convert JavaScript date objects to a date string without timezone conversion
export const convertDateToDateStringWithoutTimezoneConversion = (date: Date): string => {
    // Add 0 prefix for months/days below 10
    const pad = (number: number) => number < 10 ? '0' + number : number;

    // Get the year, month and day from the date object
    const year = date.getFullYear();
    const month = pad(date.getMonth() + 1); // Months are 0-indexed in JavaScript
    const day = pad(date.getDate());

    // Return as formatted iso date string
    return `${year}-${month}-${day}`
}

// Generate a day key from the given JavaScript date object
export const generateDayKey = (day: Date): string => {
    const generatedDayKey = convertDateToDateStringWithoutTimezoneConversion(day);
    return generatedDayKey;
}

// Determine the appointment fetch params based on the current display days 
export const determineAppointmentFetchParams = (displayDays: Date[]): { from?: string, to?: string } => {

    // Get the first and last dates from the display days array
    const firstDisplayDateObject = displayDays[0];
    const lastDisplayDateObject = displayDays[displayDays.length - 1];

    // Convert the date objects into iso string date values
    const firstDate = convertDateToDateStringWithoutTimezoneConversion(firstDisplayDateObject);
    const lastDate = convertDateToDateStringWithoutTimezoneConversion(lastDisplayDateObject);

    return { from: firstDate, to: lastDate };
}




export const navigatePeriod = (view: SchedulingViewType, direction: 'prev' | 'next'): SchedulingViewType => {
    let daysToMove = view.showDays === 'all' ? 7 : view.showDays === 'mon-sat' ? 6 : 5;
    const newDate = new Date(view.displayDate);

    if (direction === 'next') {
        newDate.setDate(newDate.getDate() + daysToMove);
        // Als de displayMode 'mon-fri' is, zorg ervoor dat we niet in het weekend eindigen
        if (view.showDays === 'mon-fri' || view.showDays === 'mon-sat') {
            // Controleer of de nieuwe datum in het weekend valt en pas zo nodig aan
            if (newDate.getDay() === 6 && view.showDays === 'mon-fri') { // Zaterdag
                newDate.setDate(newDate.getDate() + 2); // Ga naar maandag
            } else if (newDate.getDay() === 0) { // Zondag
                newDate.setDate(newDate.getDate() + 1); // Ga naar maandag
            }
        }
    } else {
        newDate.setDate(newDate.getDate() - daysToMove);
        // Extra logica voor 'vorige' navigatie indien nodig
        // Bijvoorbeeld, ervoor zorgen dat we niet eindigen op een weekenddag
    }

    return { ...view, displayDate: newDate };
};