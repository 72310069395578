import React from 'react';
import { t } from 'i18next';
import { currencySymbols } from 'internationalization/currencySymbols';
import { parsePriceToStringOnUserLocale } from 'services/utils/parsePriceString';

// Render labels as badges
export const renderLabelBadges = (labels: { id: number, name: string, color: string }[]) => {
    return (
        <div className='badge-container'>
            {labels.map(label => (
                <div key={label.id} className={`label-badge ${label.color}`}>
                    {label.name}
                </div>
            ))}
        </div>
    );
}

// Render status as badges
export const renderStatusBadge = (status_name: string, condition?: string) => {
    return (
        <div className='badge-container'>
            <div className={`status-badge ${condition ? condition : status_name}`}>
                {t(`workflow.default_status_names.${status_name}`, { defaultValue: status_name })}
            </div>                        
        </div>
    );
}

// Render stage as badges
export const renderStageBadge = (stage_name: string, resolution: string) => {
    return (
        <div className='badge-container'>
            <div className={`status-badge resolution-${resolution}`}>
                {resolution === 'open' && stage_name}
                {resolution === 'won' && t(`deal.general.won_label`)}
                {resolution === 'lost' && t(`deal.general.lost_label`)}
            </div>                        
        </div>
    );
}

// Render number as percentage
export const renderPercentage = (value: string) => {
    return (
        <div>{value}%</div>
    );
}

// Render document name and number
export const renderDocumentNameAndNumber = (number: string, objectType: string, status?: string, ) => {
    if (status && status === 'draft') {
        return (
            <div>
                {t('general.object_name_capitalize', { object_name: t(`${objectType}.general.object_name.singular`) })} {t(`${objectType}.general.${objectType}_draft_label`, { number: number })}
            </div>
        )
    } else {
        return (
            <div>{t('general.object_name_capitalize', { object_name: t(`${objectType}.general.object_name.singular`) })} {number}</div>
        )
    };
}

// Render document number
export const renderDocumentNumber = (number: string, status?: string, objectType?: string) => {
    if (status && objectType && status === 'draft') {
        return (
            <div>
                {t(`${objectType}.general.${objectType}_draft_label`, { number: number })}
            </div>
        )
    } else {
        return (
            <div>{number}</div>
        )
    };
}



// Render datetime as date
export const renderDate = (dateValue: string, userLocale: string) => {
    // If no date value exists, return an empty cell
    if (!dateValue) {
        return <div></div>;
    }

    // Format the date based on the user locale
    const formattedDate = new Date(dateValue).toLocaleDateString(userLocale, { year: 'numeric', month: '2-digit', day: '2-digit' })

    return (
        <div>{formattedDate}</div>
    );
}

// Render datetime as date and time
export const renderDateTime = (dateValue: string, userLocale: string) => {

    // Format the date based on the user locale
    const formattedDate = new Date(dateValue).toLocaleDateString(userLocale, { year: 'numeric', month: '2-digit', day: '2-digit', hour: 'numeric', minute: '2-digit' })

    return (
        <div>{formattedDate}</div>
    );
}

// Render price column, given as decimal by the backend
export const renderPriceDecimalColumn = (price: string, currency: string | undefined, userLocale: string, roundPrice?: boolean) => {
    // Check wheter a sale price currency exists
    const currencyKey = currency ? currency.toLowerCase() : '';

    // Get the currency symbol for the currency
    const currencySymbol = currencySymbols[currencyKey];

    // Format the price based on the user locale
    const formattedPrice = parsePriceToStringOnUserLocale(price, userLocale, roundPrice);

    // Return the price and the currency symbol
    return (
        <span className='text-right'>
            {currencySymbol} {formattedPrice}
        </span>
    )
}

// Render price column, given as float by the backend
export const renderPriceFloatColumn = (price: number | null | undefined, currency: string | null | undefined, userLocale: string) => {
    // If no price exists on this column, show a dash
    if (price == null) {
        return (
            <div className='text-right'>-</div>
        )
    }

    // Check wheter a currency exists
    const currencyKey = currency ? currency.toLowerCase() : '';

    // Get the currency symbol for the currency
    const currencySymbol = currencySymbols[currencyKey] || currency;

    // Format the price based on the user locale
    const formattedPrice = price.toLocaleString(userLocale, { minimumFractionDigits: 2, maximumFractionDigits: 2 });

    // Configure the price column string as div
    return (
        <div className='text-right'>
            {currencySymbol} {formattedPrice}
        </div>
    )
}