import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ListRowType, ListProps, ListColumnType, QueryParamType, ListMetaDataType } from 'types/ListTypes';
import { useInitializeParams, setBrowserUrl, executeQueryParamsUpdate } from './functions/queryParamsUtils';
import { useFetchData } from 'services/api/useFetchData';
import ListContext from './ListContext';
import ListComponent from './ListComponent';

const AbstractList = <T extends ListRowType>({
    query, objectName, filters, columns, additionalColumns, showSearch, showDeleteOrDeactivate, showAddButton, showImport, showExport, 
    explanationText, showColumnSelector, onRowClick, formModal, editModal, postNewItem, detailModal, detailModalName, releaseViewModal, 
    wizardModal, detailPageUrl, modalSize, detailModalSize, isPaginated, isDraggable, buttonSize, groupByField, changeViewButton
}: ListProps<T>) => {
    const history = useHistory();
    const location = useLocation();
    const [queryParams, setQueryParams] = useState(useInitializeParams(query.defaultParams ?? {}, isPaginated ?? false, location));
    const { response, loading, refetch } = useFetchData({ apiUrl: query.endpoint, params: queryParams });
    const [data, setData] = useState<ListRowType[]>([])
    const [listMetaData, setListMetaData] = useState<ListMetaDataType>({ currentPage: 1, totalRows: 0, totalPages: 0 })
    const [searchTerm, setSearchTerm] = useState('');
    const [lastSearchTerm, setLastSearchTerm] = useState<string | null>(null);
    const [ordering, setOrdering] = useState<string | null>(null);
    const [availableColumns, setAvailableColumns] = useState<ListColumnType[]>([]);
    const [selectedColumns, setSelectedColumns] = useState<ListColumnType[]>([]);
    const [tableWidthClass, setTableWidthClass] = useState('container-filled');

    useEffect(() => {
        // Get the current browser url search parameters
        const currentParams = new URLSearchParams(location.search);
        
        // Merge current browser url parameters with default query params of the list
        const paramsToSet = { ...query.defaultParams };
        for (const [key, value] of currentParams.entries()) {
            paramsToSet[key] = value;
        }

        // Set the browser url with current and default params
        setBrowserUrl(paramsToSet);

        // Synchronize the browser url with the default query params
        updateQueryParams(queryParams);
    }, []);

    useEffect(() => {
        if (response) {
            // In case of a draggable list, sort the results based on their ordering property
            if (isDraggable) {
                response.results.sort((a: T, b: T) => a.ordering - b.ordering);
            }

            // Set the data with the results
            setData(response.results);

            // Set the total items in the list meta data
            setListMetaData(prev => ({ ...prev, totalRows: response.count }))

            // In case of a paginated list, calculate the total pages
            if (isPaginated) {
                setListMetaData(prev => ({ ...prev, totalPages: Math.ceil(response.count / 50) }))
            }
        }
    }, [response, isDraggable, isPaginated]);

    useEffect(() => {
        // Execute the search query after a delay of 750ms to prevent unwanted fetches
        const debounceSearch = setTimeout(() => {

            // Execute search when a search term exist
            if (searchTerm !== '' && searchTerm !== lastSearchTerm) {
                updateQueryParams({ search: searchTerm, page: 1 });
                setLastSearchTerm(searchTerm);

            // If a search is executed and made empty, remove the search param from the query
            } else if (lastSearchTerm && searchTerm === '') {
                updateQueryParams({ search: undefined, page: 1 });
                setLastSearchTerm(searchTerm);
            }
        }, 750);

        return () => clearTimeout(debounceSearch);
    }, [searchTerm, lastSearchTerm, queryParams, executeQueryParamsUpdate])
    
    useEffect(() => {
        // Listens to column select changes, combines available and sets default columns
        if (columns) {
            setAvailableColumns([...columns, ...(additionalColumns ?? [])]);
            setSelectedColumns(columns);
        }
    }, [columns, additionalColumns]);

    useEffect(() => {
        // TODO: commented out this feature. Still works but only needed to set full width list view if total width exeeds filled view
        // Applies the right table width class when the selected columns change
        // const newWidthClass = calculateTableWidth(selectedColumns);
        // setTableWidthClass(newWidthClass);

        setTableWidthClass('container-filled');
    }, [selectedColumns]);

    // Handles refetching of the list, for example when deleting a row
    const refetchList = () => refetch();

    // Handles the ordering change, when reordering an item in the list by dragging
    const handleDragOrdering = (newOrder: ListRowType[]) => {
        setData(newOrder);
    };

    // Handles the ordering change of a clicked column header
    const handleColumnOrdering = (column: string) => {
        const newOrdering = ordering === column ? `-${column}` : column;
        setOrdering(newOrdering);
        updateQueryParams({ ordering: newOrdering }, true);
    }

    // Handle page change of paginated lists
    const handlePageChange = (newPage: number) => {
        updateQueryParams({ page: newPage }, true);
        setListMetaData({ ...listMetaData, currentPage: newPage });
    };

    // Register a search change
    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    }

    // Execute the query params update function for the new parameters
    const updateQueryParams = (newParams: QueryParamType, keepCurrentParams?: boolean) => {
        let combinedParams;

        // When executing a page or ordering change, add the ordering- or page param to the current params
        if (keepCurrentParams) {
            combinedParams = { ...queryParams, ...newParams };
        
        // For other changes, just update the new params and reset the ordering
        } else {
            combinedParams = { ...queryParams, ...newParams };
            setOrdering(null);
        }

        executeQueryParamsUpdate(combinedParams, queryParams, setQueryParams, history, location);
    };

    return (
        <ListContext.Provider value={{ loading, queryParams, updateQueryParams, data, setData, listMetaData, 
            setListMetaData, ordering, searchTerm, setSearchTerm, lastSearchTerm, setLastSearchTerm, 
            availableColumns, setAvailableColumns, selectedColumns, setSelectedColumns, tableWidthClass, 
            handlePageChange, handleSearchChange, handleColumnOrdering, handleDragOrdering, refetchList }}>
            <ListComponent 
                query={query}
                objectName={objectName}
                filters={filters}
                columns={columns}
                groupByField={groupByField}
                additionalColumns={additionalColumns}
                explanationText={explanationText}
                showSearch={showSearch}
                showDeleteOrDeactivate={showDeleteOrDeactivate}
                showAddButton={showAddButton}
                showImport={showImport}
                showExport={showExport}
                showColumnSelector={showColumnSelector}
                changeViewButton={changeViewButton}
                buttonSize={buttonSize}
                onRowClick={onRowClick}
                postNewItem={postNewItem}
                formModal={formModal}
                editModal={editModal}
                detailModal={detailModal}
                detailModalName={detailModalName}
                releaseViewModal={releaseViewModal}
                wizardModal={wizardModal}
                detailPageUrl={detailPageUrl}
                modalSize={modalSize}
                detailModalSize={detailModalSize}
                isPaginated={isPaginated}
                isDraggable={isDraggable}
                refetchList={refetchList} />
        </ListContext.Provider>
    );
};

export default AbstractList;