import React from 'react';
import { ListColumnType, ListFilterType } from 'types/ListTypes';
import AbstractList from 'components/lists/AbstractList';
import VatRateForm from './VatRateForm';
import { renderPercentage } from 'components/lists/functions/renderListColumn';

// Rename the name of the function:
const VatRateList: React.FC = () => {

    // Specify the options of this list:
    const query = {
        endpoint: 'get_vatrate_list',
        defaultParams: { 'is_active': true },
        object: 'job'
    }
    const objectName = 'vatrate.general.object_name'
    const showSearch = false
    const showDeleteOrDeactivate = 'flag_deactivated'

    // Set isPaginated to true if this list may have 100.000+ items:
    const isPaginated = false

    // Specify form, detail modal or detailpage for this list
    const formModal = <VatRateForm />;
    const onRowClick = 'editModal'

    // Specify the columns of this list:
    const columns: ListColumnType[] = [
        { width: '1fr', field: 'name', label: 'vatrate.general.name_label' },
        { width: '1fr', field: 'percentage', label: 'vatrate.general.percentage_label', render: (_, row) => renderPercentage(row.percentage) },
    ];

    // Specify the filters of this list:
    const filters: ListFilterType[] = [
        {
            id: 'active',
            label: 'vatrate.general.active_vat_rates_label',
            params: { 'is_active': true }
        },
        {
            id: 'inactive',
            label: 'vatrate.general.inactive_vat_rates_label',
            params: { 'is_active': false }
        }
    ]

    return (
        <AbstractList
            query={query}
            objectName={objectName}
            filters={filters}
            columns={columns}
            showSearch={showSearch}
            showDeleteOrDeactivate={showDeleteOrDeactivate}
            formModal={formModal}
            isPaginated={isPaginated}
            onRowClick={onRowClick}
        />
    );
};

export default VatRateList;