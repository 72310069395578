import React from 'react';
import { ListColumnType, ListFilterType, QueryParamType } from 'types/ListTypes';
import AbstractList from 'components/lists/AbstractList';
import JobForm from '../jobs/JobForm';
import JobModal from '../jobs/JobModal';
import { jobFilters } from './JobList';
import { renderStatusBadge } from 'components/lists/functions/renderListColumn';

const jobDetailListConfigurations: Record<string, { params: (itemId: string) => QueryParamType, dynamicColumn: ListColumnType }> = {
    contact: {
        params: (itemId: string) => ({ 'client': itemId }),
        dynamicColumn: { width: '6fr', field: 'work_location_address', label: 'job.general.work_location_label' }
    },
    organization: {
        params: (itemId: string) => ({ 'client': itemId }),
        dynamicColumn: { width: '6fr', field: 'work_location_address', label: 'job.general.work_location_label' }
    },
    person: {
        params: (itemId: string) => ({ 'client': itemId }),
        dynamicColumn: { width: '6fr', field: 'work_location_address', label: 'job.general.work_location_label' }
    },
    location: {
        params: (itemId: string) => ({ 'location': itemId }),
        dynamicColumn: { width: '6fr', field: 'client_name', label: 'job.general.client_label' }
    }
};

const JobDetailList: React.FC<{ itemId: string, detailPage: string }> = ({ itemId, detailPage }) => {
    // Get the job detail list configuration
    const configuration = jobDetailListConfigurations[detailPage];

    // Get the default params and dynamic column from the configuration
    const defaultParams = configuration.params(itemId);
    const dynamicColumn = configuration.dynamicColumn;

    // Specify the columns of this list
    const columns: ListColumnType[] = [
        { width: '2.5fr', field: 'id', label: 'job.general.job_number_label' },
        dynamicColumn,
        { width: '4fr', field: 'main_description_plain', label: 'job.general.main_description_label' },
        { width: '144px', field: 'status', label: 'job.general.status_label', render: (_, row) => renderStatusBadge(row.status_name, row.status_condition) }
    ];

    // Add the page params to the default list filters
    const applyPageParamsToFilters = (filters: ListFilterType[], pageParams: QueryParamType) => {
        return filters.map(filter => {
            if ('filters' in filter) {
                filter.filters = filter.filters.map(subFilter => ({
                    ...subFilter,
                    params: { ...subFilter.params, ...pageParams }
                }));
            } else {
                filter.params = { ...(filter.params || {}), ...pageParams };
            }
            return filter;
        });
    };

    return (
        <AbstractList
            query={{
                endpoint: 'get_job_list',
                defaultParams,
                object: 'job'
            }}
            objectName='job.general.object_name'
            columns={columns}
            filters={applyPageParamsToFilters(jobFilters, defaultParams)}
            showSearch={true}
            showDeleteOrDeactivate='flag_deleted'
            buttonSize='small'
            isPaginated={true}
            formModal={<JobForm />}
            detailModal={<JobModal />}
            onRowClick='detailModal'
        />
    );
};

export default JobDetailList;