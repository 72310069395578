import React, { useState } from 'react';
import PrimaryButton from 'components/buttons/PrimaryButton';
import { saveData } from 'services/api/saveData';

const ImportAction: React.FC = () => {
    const [buttonLoader, setButtonLoader] = useState<boolean>(false);
    const [responseData, setResponseData] = useState<string | null>(null);

    // Handle the submit of the import
    const handleSubmit = async () => {
        try {
            // Start the button loader
            setButtonLoader(true);

            // Post the request to the backend
            const response = await saveData({ 
                apiUrl: `correct_contact_names`, 
                method: 'post', 
                data: {} 
            })
            
            // Set the response data
            if (response && response.status === 202 && response.data) {
                setResponseData(response.data);
            }
        } catch (error) {
            setResponseData("Error while submitting the request")
            console.error("Error while submitting the request", error);
            setButtonLoader(false);
        };        
	};

    return (
        <>
            {responseData && <span>{responseData}</span>}
            <PrimaryButton
                label='Correct contact names'
                size='small'
                loading={buttonLoader}
                onClick={() => handleSubmit()} 
            />
        </>
    )

}

export default ImportAction;