import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalContext } from 'GlobalContext';
import { useSettings } from 'services/settings/SettingsContext';
import { useModal } from 'components/modals/ModalContext';
import { saveData } from 'services/api/saveData';
import { useFetchData } from 'services/api/useFetchData';
import { useAllowedRight } from 'services/permissions/permissionChecks';
import { AlertType } from 'types/AlertTypes';
import { AttachmentDetailListProps, AttachmentType, AttachmentTypeType, AttachmentUpdateType, createNewAttachment } from './AttachmentTypes';
import PrimaryButton from 'components/buttons/PrimaryButton';
import SecondaryButton from 'components/buttons/SecondaryButton';
import Dropdown from 'components/forms/basefields/Dropdown';
import FormAlert from 'components/alerts/FormAlert';
import AttachmentViewer from 'components/forms/fieldsets/AttachmentViewer';
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../../style/scss/detailpage.scss';
import '../../style/scss/list.scss';

const AttachmentDetailList: React.FC<AttachmentDetailListProps> = ({ detailPageId, detailPage }) => {
    // useContext hooks
    const { t } = useTranslation();
    const { setFloatingAlert } = useGlobalContext();
    const { userLocale } = useSettings();
    const { initializeModal } = useModal();
    const hasRightCheck = useAllowedRight;

    // Fetch the attachments
    const { response: fetchedAttachments, loading: attachmentsLoading, refetch } = useFetchData({ 
        apiUrl: `get_attachment_list/${detailPage}/${parseInt(detailPageId)}`, 
        params: useMemo(() => ({ deleted: false }), []) 
    });

    // Fetch the attachment types
    const { response: fetchedAttachmentTypes, loading: attachmentTypesLoading } = useFetchData({ 
        apiUrl: `get_attachmenttype_list`,
        params: useMemo(() => ({ deleted: false }), [])
    });

    // useStates
    const [attachments, setAttachments] = useState<AttachmentType[]>([]);
    const [attachmentTypes, setAttachmentTypes] = useState<AttachmentTypeType[]>([]);
    const [visibilityOptions] = useState<{ value: string, name: string }[]>([ 
        { value: 'office_and_field', name: 'attachment.visibility.office_and_field' }, 
        { value: 'only_office', name: 'attachment.visibility.only_office' }]);
    const [editableAttachment, setEditableAttachment] = useState<AttachmentType | null>(null);
    const [newAttachment, setNewAttachment] = useState<boolean>(false);
    const [updatedData, setUpdatedData] = useState<AttachmentUpdateType | null>(null);
    const [dragOver, setDragOver] = useState(false);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [showAlert, setShowAlert] = useState<AlertType | null>(null);  
    const acceptedFileTypes = ['.pdf', '.xls', '.xlsx', '.doc', '.docx', '.ppt', '.pptx', '.png', '.jpeg', '.jpg'];
    const [userHasOnlyViewRights] = useState<boolean>(hasRightCheck('only_view'));
    const fileInputRef = useRef<HTMLInputElement>(null);

    // Store the fetched attachments and types
    useEffect(() => {
        if (fetchedAttachments) {
            // Sort attachments on created date, newest first
            const sortedAttachments = [...fetchedAttachments.results].sort(
                (a, b) => new Date(b.created).getTime() - new Date(a.created).getTime()
            );
            setAttachments(sortedAttachments);
        }

        if (fetchedAttachmentTypes) {
            // Sort attachment types by their ordering
            const sortedTypes = [...fetchedAttachmentTypes.results].sort((a, b) => a.ordering - b.ordering);
            setAttachmentTypes(sortedTypes);
        }
    }, [fetchedAttachments, fetchedAttachmentTypes]);

    // Notice the dragging of files across the entire screen
    useEffect(() => {
        const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
            event.preventDefault();
            setDragOver(true);
        };

        const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
            event.preventDefault();
            setDragOver(false);
        };

        const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
            event.preventDefault();
            setDragOver(false);
            const file = event.dataTransfer.files ? event.dataTransfer.files[0] : null;
            uploadFile(file);
        };

        // Add and clean event listeners
        document.addEventListener('dragover', handleDragOver as any);
        document.addEventListener('dragleave', handleDragLeave as any);
        document.addEventListener('drop', handleDrop as any);
        return () => {
            document.removeEventListener('dragover', handleDragOver as any);
            document.removeEventListener('dragleave', handleDragLeave as any);
            document.removeEventListener('drop', handleDrop as any);
        };
    }, []);

    // Show the file selector window when clicking on the dropzone
    const handleFileSelectClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    // Handle image upload when selecting a file through the dropzone selector window
    const handleAttachmentUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files ? event.target.files[0] : null;
        uploadFile(file);
    };

    // Upload the selected or dragged file
    const uploadFile = async (file: File | null) => {

        // Validate if a file is selected
        if (!file) {
            setShowAlert({ type: 'warning', message: t('validation.attachment.no_file_selected')})
            return;
        }
    
        // Validate if the selected file is in the accepted file types
        const fileExtension = '.' + file.name.split('.').pop();
        if (!acceptedFileTypes.includes(fileExtension.toLowerCase())) {
            setShowAlert({ type: 'warning', message: t('validation.attachment.unsupported_file_type')});
            return;
        }

        // Try upload new file
        try {
            // Add the file to the form data object
            const newFile = new FormData();
            newFile.append('filename', file.name);
            newFile.append('file', file);
            
            // Based on the detail page, set the appropriate backend field in the form data
            switch (detailPage) {
                case 'organization':
                case 'person':
                    newFile.append('contact', detailPageId.toString());
                    break;
                case 'location':
                    newFile.append('location', detailPageId.toString());
                    break;
                case 'deal':
                    newFile.append('deal', detailPageId.toString());
                    break;
                case 'proposal':
                    newFile.append('proposal', detailPageId.toString());
                    break;
                case 'order':
                    newFile.append('order', detailPageId.toString());
                    break;
                case 'job':
                    newFile.append('job', detailPageId.toString());
                    break;
                case 'invoice':
                    newFile.append('invoice', detailPageId.toString());
                    break;
            };

            // Save the file to the backend
            const response = await saveData({ apiUrl: 'post_attachment', method: 'post', data: newFile });

            // Handle a successful response
            if (response && response.status === 201) {
                // Destructure the fields from the response data
                const { id, file, filename: fileName } = response.data;

                // Convert the detail page id to a number
                const detailPageIdInt = parseInt(detailPageId)

                // Put the saved data in the editable attachment to show in de fields
                setEditableAttachment(
                    createNewAttachment({
                        id: id,
                        file: file,
                        fileName: fileName,
                        dealId: detailPage === 'deal' ? detailPageIdInt : undefined,
                        proposalId: detailPage === 'proposal' ? detailPageIdInt : undefined,
                        orderId: detailPage === 'order' ? detailPageIdInt : undefined,
                        jobId: detailPage === 'job' ? detailPageIdInt : undefined,
                        invoiceId: detailPage === 'invoice' ? detailPageIdInt : undefined,
                        contactId: detailPage === 'organization' || detailPage === 'person' ? detailPageIdInt : undefined,
                        locationId: detailPage === 'location' ? detailPageIdInt : undefined
                    })
                );

                // Set new attachment to true
                setNewAttachment(true);
            };
        } catch (error) {
            console.error('Error during file upload:', error);
            setShowAlert({ type: 'danger', message: t('validation.attachment.file_upload_error')});
        }
    };

    // Group attachments by attachment type
    const groupedAttachments = useMemo(() => {
        const attachmentGroup: { [key: number]: AttachmentType[]; no_type: AttachmentType[] } = { no_type: [] };

        // Initialize each attachment type group, even if no attachments
        attachmentTypes.forEach(type => {
            attachmentGroup[type.id] = [];
        });

        // Loop through the attachments to group them
        attachments.forEach(attachment => {

            // Group the attachment which have a non-deleted attachment type
            if (attachment.attachment_type && attachmentGroup[attachment.attachment_type]) {
                attachmentGroup[attachment.attachment_type].push(attachment);

            // Group the attachments which are not linked to an attachment type (or to a deleted one)
            } else {
                attachmentGroup['no_type'].push(attachment);
            }
        });

        return attachmentGroup;
    }, [attachments, attachmentTypes]);

    // Handle viewing of an attachment
    const handleAttachmentView = (event: React.MouseEvent, attachment: AttachmentType) => {
        if (attachment.file && attachment.filename) {
            initializeModal(<AttachmentViewer file={attachment.file} filename={attachment.filename} type='attachment' />, { modalSize: 'medium-large' } )
        }
    };

    // Handle editing of an attachment
    const handleEditing = (itemId: number) => {
        // Get the selected attachment to edit
        const attachment = attachments.find(att => att.id === itemId);

        // Put the attachment in the edit form
        if (attachment) {
            setEditableAttachment(attachment);
        }
    };

    // Handle the change of a dropdown value
    const handleDropdownChange = (selectedValue: string, fieldName: 'visibility' | 'attachment_type') => {
        // Copy the current editable attachment
        const updatedAttachment = { ...editableAttachment };

        if (fieldName === 'visibility') {
            updatedAttachment[fieldName] = selectedValue;
        }

        if (fieldName === 'attachment_type') {
            updatedAttachment[fieldName] = parseFloat(selectedValue);
        }

        setEditableAttachment(updatedAttachment as AttachmentType);
        setUpdatedData((currentUpdatedData: any) => ({ 
            ...currentUpdatedData, [fieldName]: fieldName === 'attachment_type' ? parseFloat(selectedValue) : selectedValue 
        }));
    }

    // Handles the deletion of an attachment
    const handleDelete = async (itemId: number) => {
        // Show confirmation window
        const confirmAction = window.confirm(t('general.delete_confirm_message', { deletionLabel: t('general.delete').toLowerCase() }));

        if (confirmAction) {
            try {
                await saveData({ apiUrl: `patch_attachment/${itemId}`, method: 'patch', data: { deleted: true } });
            } catch (error) {
                // Show error if given
                console.error('Error during deleting.', error);
            } finally {
                // Show alert on success
                setFloatingAlert({ type: 'success', message: 'alert.floating_alert_deleted' });

                // Refetch list to remove deleted item
                await refetch();
            }
        }
    };

    // Handle saving of the updated attachment
    const handleSave = async () => {
        if (editableAttachment && updatedData) {
            try {
                // Start the button loader
                setButtonLoader(true);

                // Save the edited attachment to the server
                const response = await saveData({ 
                    apiUrl: `patch_attachment/${editableAttachment.id}`,
                    method: 'patch', 
                    data: updatedData
                });

                if (response && response.status === 200) {
                    // Show alert on success
                    setFloatingAlert({ type: 'success' });
                };
            } catch (error) {
                // Show error if given
                console.error('Error during saving.', error);
                setFloatingAlert({ type: 'danger', message: 'alert.general_save_error_message' });
            } finally {
                // Stop button loader
                setButtonLoader(false);

                // Hide the attachment form
                setEditableAttachment(null);

                // Remove the updated data
                setUpdatedData(null);

                // Refetch list to show the saved attachment
                await refetch();
            }
        } else if (!updatedData) {
            // If no data to save, just close the form
            setEditableAttachment(null);

            // Show success alert
            setFloatingAlert({ type: 'success' });

            // Refetch list
            await refetch();
        }
    };

    // Handle cancel of the attachment edit form
    const handleCancel = async (itemId: number | null) => {
        // If the adding of a new attachment is canceled, delete the already uploaded attachment
        if (newAttachment && itemId) {
            // Set the attachment in the backend to deleted
            try {
                await saveData({ apiUrl: `patch_attachment/${itemId}`, method: 'patch', data: { deleted: true } });
            } catch (error) {
                // Show error if given
                console.error('Error during saving.', error);
            }

            // Set new attachment to false
            setNewAttachment(false);
        }

        // Hide the attachment edit form
        setEditableAttachment(null);

        // Reset the updated data
        setUpdatedData(null);
    };

    // Render the attachment list with its attachments
    const renderAttachmentList = (attachments: AttachmentType[]) => {
        return (
            <table className='list attachment-list'>
                <tbody>
                    {attachments.map(attachment => {

                        // Format the created date
                        const formattedAddDate = attachment.created 
                            ? new Date(attachment.created).toLocaleDateString(userLocale, { year: 'numeric', month: 'numeric', day: '2-digit', hour: 'numeric', minute: '2-digit' })
                            : '';

                        // Get the right visibility name of the attachment
                        const visibilityName = visibilityOptions.find(option => option.value === attachment.visibility)?.name;

                        return (
                            <tr key={attachment.id}
                                onClick={e => handleAttachmentView(e, attachment)}>
                                <td className='data'>
                                    <span>{attachment.filename}</span>
                                    <span>{attachment.reference}</span>
                                </td>
                                <td className='data'>
                                    {formattedAddDate}
                                </td>
                                <td className='data'>
                                    {t(visibilityName)}
                                </td>
                                <td className='edit-column'>
                                    {!userHasOnlyViewRights && attachment.id !== null && ( 
                                        <div className='list-edit-icon tooltip-icon'>
                                            <FontAwesomeIcon
                                                icon={faPen}
                                                style={{ cursor: 'pointer' }}
                                                onClick={(e) => { e.stopPropagation(); handleEditing(attachment.id!);}}
                                            />
                                            <span className="tooltip">{t('general.modify')}</span>
                                        </div>
                                    )}
                                </td>
                                <td className='delete-column'>
                                    {hasRightCheck('can_delete') && attachment.id !== null &&
                                        <div className='list-delete-icon tooltip-icon'>
                                            <FontAwesomeIcon
                                                icon={faTrash}
                                                style={{ cursor: 'pointer' }}
                                                onClick={(e) => { e.stopPropagation(); handleDelete(attachment.id!);}} />
                                            <span className="tooltip">{t('general.delete')}</span>
                                        </div>
                                    }
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        )
    };

    return (
        <div className='attachment-detail-list'>
            {((attachmentsLoading || attachmentTypesLoading) !== 'success') && attachments.length === 0 ? (
                <div className='attachment-loader'>
                    <div className="loader"></div>
                </div>
            ) : (
                <>
                    {showAlert && (
                        <FormAlert
                            type={showAlert.type}
                            message={showAlert.message}
                            onClose={() => setShowAlert(null)}
                        />
                    )}
                    {!userHasOnlyViewRights && !editableAttachment && (
                        // Don't allow drag/select attachments for only view users
                        <div className={`dropzone ${dragOver ? 'hover' : ''}`}
                            onClick={handleFileSelectClick}>
                            <input type='file'
                                    onChange={handleAttachmentUpload} 
                                    accept={acceptedFileTypes.join(',')}
                                    ref={fileInputRef} />
                            <span onClick={handleFileSelectClick}>
                                {t('attachment.general.drag_drop_placeholder')}
                            </span>
                        </div>
                    )}

                    {editableAttachment && (
                        // Show form when editing an attachment
                        <div className="attachments-form">
                            <form className='formset add-edit-form' 
                                onSubmit={(e) => {e.preventDefault(); handleSave();}}>
                                <div className='form-header attachments-row'>
                                    <div className='header-item'>{t('attachment.general.name_label')}</div>
                                    <div className='header-item'>{t('attachment.general.visibility_label')}</div>
                                    <div className='header-item'>{t('attachment.general.attachment_type_label')}</div>
                                </div>
                                <div className='attachments-row'>
                                    <div className='p'>
                                        {editableAttachment.filename}
                                    </div>
                                    <Dropdown<{ value: string, name: string }>
                                        options={visibilityOptions}
                                        id='visibility'
                                        name='visibility'
                                        disabled_selected={t('attachment.general.visibility_disabled_selected')}
                                        selectedOption={visibilityOptions.find(option => option.value === editableAttachment.visibility)}
                                        onChange={(selectedValue) => handleDropdownChange(selectedValue, 'visibility')}
                                        selectionFormat={(option) => `${option.name}`}
                                        optionFormat={(option) => `${option.name}`}
                                        showSearch={false}
                                        allowNoneOption={false}                            
                                    />
                                    <Dropdown<AttachmentTypeType>
                                        options={attachmentTypes}
                                        id='attachment_type'
                                        name='attachment_type'
                                        disabled_selected={t('attachment.general.attachment_type_disabled_selected')}
                                        selectedOption={attachmentTypes.find(attachmentType => attachmentType.id === editableAttachment.attachment_type)}
                                        onChange={(selectedValue) => handleDropdownChange(selectedValue, 'attachment_type')}
                                        selectionFormat={(option) => `${option.name}`}
                                        optionFormat={(option) => `${option.name}`}
                                        showSearch={false}
                                        allowNoneOption={true}                            
                                    />
                                </div>
                                <div className='attachments-button-row'>
                                    <div>
                                        <div className='buttons-right'>
                                            <SecondaryButton
                                                onClick={() => handleCancel(newAttachment ? editableAttachment.id : null)}
                                                label='general.cancel'
                                                size="extra-small"/>
                                            <PrimaryButton
                                                type="submit" 
                                                label='general.save'
                                                size="extra-small"
                                                onlyViewRestriction={true}
                                                loading={buttonLoader}/>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    )}

                    {attachments.length === 0 ? (
                        <div className='empty-text'>
                            <p>
                                {t('attachment.general.no_attachments_added_label')}
                            </p>
                        </div>
                    ) : (
                        <>
                            {/* Group for attachments without type */}
                            {groupedAttachments['no_type'] && groupedAttachments['no_type'].length > 0 && (
                                <div className='attachment-group'>
                                    {renderAttachmentList(groupedAttachments['no_type'])}
                                </div>
                            )}

                            {/* Render each attachment type group */}
                            {attachmentTypes.map(type => {
                                const groupAttachments = groupedAttachments[type.id] || [];
                                if (groupAttachments.length > 0) {
                                    return (
                                        <div key={type.id} className='attachment-group'>
                                            <h5>{type.name}</h5>
                                            {renderAttachmentList(groupAttachments)}
                                        </div>
                                    );
                                }
                                return null;
                            })}
                        </>
                    )}

                    
                </>
            )}
        </div>
    )
}

export default AttachmentDetailList;