import { LabelType } from "../../views/settings/SettingsTypes";

export type LocationType = {
    id: number | null;
    uuid?: string;
    created: string | null;
    modified: string | null;
    address: string;
    street: string | null;
    house_number: string | null;
    house_number_base: string | null;
    house_number_addition: string | null; 
    postal_code: string | null;
    city: string | null;
    country: string | null;
    name: string | null;
    notes: string | null;
    is_billing_address: boolean;
    is_work_location: boolean;
    deleted: boolean;
    import_tag: number | null;
}

export type PhoneNumberType = {
    id: number | null;
    uuid?: string;
    phone_number: string;
    contact: number | null;
    person: number | null;
    organization: number | null;
    deleted: boolean;
    import_tag: number | null;
}

export type EmailAddressType = {
    id: number | null;
    uuid?: string;
    email: string;
    email_type: string;
    contact: number | null;
    person: number | null;
    organization: number | null;
    deleted: boolean;
    import_tag: number | null;
}

export type PersonType = {
    contact: number;
    labels: number[] | LabelType[];
    first_name: string | null;
    last_name: string | null;
    title: string | null;
    role: string | null;
    primary_locations: LocationType[];
    phone_numbers: PhoneNumberType[];
    email_addresses: EmailAddressType[];
    deleted: boolean;
}

export type OrganizationType = {
    contact: number;
    labels: number[] | LabelType[];
    primary_persons: PersonType[];
    work_locations: LocationType[];
    parent_organization: number | null;
    coc_number: string | null;
    vat_number: string | null;
    vat_reverse_charge: boolean;
    iban_number: string | null;
    deleted: boolean;
};

export type ContactType = {
    id: number;
    created: string;
    modified: string;
    name: string;
    contact_type: 'contact' | 'prospect' | 'client' | 'supplier';
    entity_type: 'person' | 'organization';
    number: string | null;
    reference: string | null;
    deleted: boolean;
    import_tag: number | null;
} & (
    | ({
          entity_type: 'person';
      } & PersonType)
    | ({
          entity_type: 'organization';
      } & OrganizationType)
);

export const createEmptyLocation = (locationType: string, uuid: string) => ({
    id: null,
    uuid: uuid,
    created: null,
    modified: null,
    address: '',
    street: null,
    house_number: null,
    house_number_base: null,
    house_number_addition: null,
    postal_code: null,
    city: null,
    country: null,
    name: null,
    notes: null,
    is_billing_address: locationType === 'billing' ? true : false,
    is_work_location: locationType === 'worklocation' ? true : false,
    deleted: false,
    import_tag: null
});

export const createEmptyPhoneNumber = (object: string, data: any, uuid: string) => ({
    id: null,
    uuid: uuid,
    phone_number: '',
    contact: (object === 'contact') ? data.id : null,
    person: (object === 'person') ? data.id : null,
    organization: (object === 'organization') ? data.id : null,
    deleted: false,
    import_tag: null
});

export const createEmptyEmailAddress = (object: string, data: any, uuid: string) => ({
    id: null,
    uuid: uuid,
    email: '',
    email_type: 'general',
    contact: (object === 'contact') ? data.id : null,
    person: (object === 'person') ? data.id : null,
    organization: (object === 'organization') ? data.id : null,
    deleted: false,
    import_tag: null
});