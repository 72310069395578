import React from 'react';
import { FieldType } from 'types/FieldTypes';
import FormModal from 'components/modals/FormModal';

// Rename the name of the function:
const VatRateForm: React.FC<{ viewKey?: string }> = ({ viewKey }) => {
    const apiObject = 'vatrate'

    // Plug in the fields and their properties:
    const fields: FieldType[] = [
        {
            name: 'name',
            label: 'vatrate.general.name_label',
            type: 'text',
            placeholder: 'vatrate.general.name_placeholder',
        },
        {
            name: 'percentage',
            label: 'vatrate.general.percentage_label',
            type: 'percentage',
        },
        {
            name: 'vat_reverse_charge',
            label: 'vatrate.general.vat_reverse_charge_label',
            type: 'checkbox',
        },
        {
            name: 'is_default',
            label: 'vatrate.general.is_default_label',
            type: 'checkbox',
        },
    ];

    return (
        <FormModal 
            viewKey={viewKey}
            apiObject={apiObject}
            fields={fields}
        />
    );
};

export default VatRateForm;